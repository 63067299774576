//
//  SDKExporterCustomBlockVariant.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type ExporterCustomBlockVariantModel = {
  key: string
  name: string
  isDefault: boolean
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class ExporterCustomBlockVariant {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  blockKey: string

  variantKey: string

  name: string

  isDefault: boolean

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: ExporterCustomBlockVariantModel, blockKey: string) {
    this.blockKey = blockKey
    this.variantKey = model.key
    this.name = model.name
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line no-prototype-builtins
    this.isDefault = model.hasOwnProperty("isDefault") ? model.isDefault : false
  }
}
