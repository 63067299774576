//
//  WorkspaceProfile.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceProfileRemoteModel = {
  name: string
  color: string
  billingDetails?: WorkspaceProfileBillingDetailsRemoteModel
  avatar?: string
}

export type WorkspaceProfileBillingDetailsRemoteModel = {
  firstName?: string
  lastName?: string
  email?: string
  companyName?: string
  companyId?: string
  notes?: string
  vat?: string
  address?: WorkspaceProfileAddressRemoteModel
}

export type WorkspaceProfileAddressRemoteModel = {
  postal?: string
  street1?: string
  street2?: string
  city?: string
  country?: string
  state?: string
}

export type WorkspaceProfileTransportModel = WorkspaceProfileRemoteModel

export type WorkspaceProfileUpdateModel = {
  name: string
  color: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceProfile {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Workspace name, doesn't have to be unique across workspace pool */
  name: string

  /** Workspace primary color */
  color: string

  /** Workspace avatar URL */
  avatar: string | null

  /** Workspace billing details object */
  billingDetails: {
    firstName: string | null
    lastName: string | null
    address: {
      postal: string | null
      street1: string | null
      street2: string | null
      city: string | null
      country: string | null
      state: string | null
    } | null
    email: string | null
    companyName: string | null
    companyId: string | null
    notes: string | null
    vat: string | null
  } | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceProfileRemoteModel) {
    this.name = model.name
    this.color = model.color
    this.avatar = model.avatar ?? null
    this.billingDetails = model.billingDetails
      ? {
          firstName: model.billingDetails.firstName ?? null,
          lastName: model.billingDetails.lastName ?? null,
          address: model.billingDetails.address
            ? {
                postal: model.billingDetails.address.postal ?? null,
                street1: model.billingDetails.address.street1 ?? null,
                street2: model.billingDetails.address.street2 ?? null,
                city: model.billingDetails.address.city ?? null,
                country: model.billingDetails.address.country ?? null,
                state: model.billingDetails.address.state ?? null,
              }
            : null,
          email: model.billingDetails.email ?? null,
          companyName: model.billingDetails.companyName ?? null,
          companyId: model.billingDetails.companyId ?? null,
          notes: model.billingDetails.notes ?? null,
          vat: model.billingDetails.vat ?? null,
        }
      : null
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs directly manipulable update object for workspace profile */
  toProfileUpdateObject(): WorkspaceProfileUpdateModel {
    return {
      name: this.name,
      color: this.color,
    }
  }

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): WorkspaceProfileTransportModel {
    return {
      name: this.name,
      color: this.color,
      avatar: this.avatar ?? undefined,
      billingDetails:
        {
          firstName: this.billingDetails?.firstName ?? undefined,
          lastName: this.billingDetails?.lastName ?? undefined,
          address: this.billingDetails?.address
            ? {
                postal: this.billingDetails?.address.postal ?? undefined,
                street1: this.billingDetails?.address.street1 ?? undefined,
                street2: this.billingDetails?.address.street2 ?? undefined,
                city: this.billingDetails?.address.city ?? undefined,
                country: this.billingDetails?.address.country ?? undefined,
                state: this.billingDetails?.address.state ?? undefined,
              }
            : undefined,
          email: this.billingDetails?.email ?? undefined,
          companyName: this.billingDetails?.companyName ?? undefined,
          companyId: this.billingDetails?.companyId ?? undefined,
          notes: this.billingDetails?.notes ?? undefined,
          vat: this.billingDetails?.vat ?? undefined,
        } ?? undefined,
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using fromTransport */
  toTransport(): WorkspaceProfileTransportModel {
    return this.toRemote()
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: WorkspaceProfileTransportModel) {
    return new WorkspaceProfile(model)
  }
}
