//
//  SDKDTTokenReferenceResolver.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  ReplacableTokenType,
  TokenType,
  tokenTypeIsReferencable,
} from "../../../model/enums/SDKTokenType"
import { Unit } from "../../../model/enums/SDKUnit"
import { ColorToken } from "../../../model/tokens/SDKColorToken"
import { AnyDimensionToken } from "../../../model/tokens/SDKDimensionToken"
import { AnyStringToken } from "../../../model/tokens/SDKStringToken"
import { Token } from "../../../model/tokens/SDKToken"
import {
  AnyOptionToken,
  AnyToken,
  replaceReferencedToken,
} from "../../../model/tokens/SDKTokenValue"
import { UnreachableCaseError } from "../../../utils/UnreachableCaseError"

import { DTProcessedTokenNode } from "./SDKDTJSONConverter"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Types

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Tool implementation

/** Utility allowing resolution of token references */
export class DTTokenReferenceResolver {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  // Keep original index of Token, so we can ignore updating token with same path but lower priority
  // if during processing it is resolved later, than token with higher priority
  private mappedTokens: Map<string, [Token, number]> = new Map<
    string,
    [Token, number]
  >()

  private nodes: Map<string, DTProcessedTokenNode> = new Map<
    string,
    DTProcessedTokenNode
  >()

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Utilities

  replaceRefs(existingToken: AnyToken, newToken: AnyToken) {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [path, [token, index]] of this.mappedTokens) {
      replaceReferencedToken(token, existingToken, newToken)
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [path, token] of this.nodes) {
      replaceReferencedToken(token.token, existingToken, newToken)
    }
  }

  addAtomicToken(token: DTProcessedTokenNode, indexOfNewNode: number) {
    const nodePath = this.tokenReferenceKey(token.path, token.token.name)

    // Plugin using `last one wins` strategy.
    // We process tokens in order from $metadata.json, keeping theirs original index.
    // We should update tokens of same path that have higher priority only.
    // And any priority token could be resolved first.
    // See `test_tooling_design_tokens_order` test
    const [existingNode, indexOfExistingNode] =
      this.mappedTokens.get(nodePath) ?? []

    if (
      !!existingNode &&
      Number.isInteger(indexOfNewNode) &&
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      indexOfExistingNode > indexOfNewNode
    ) {
      return
    }

    if (existingNode) {
      // We might have built refs for this node already
      // So need to replace existing refs to existingNode with token.token
      // Another option might be to update all the data inside existingNode inplace for any token type
      this.replaceRefs(existingNode as AnyToken, token.token)
    }

    this.mappedTokens.set(nodePath, [token.token, indexOfNewNode])
    this.nodes.set(nodePath, token)
  }

  addAtomicTokens(tokens: Array<DTProcessedTokenNode>) {
    for (const token of tokens) {
      this.addAtomicToken(token, Number.NaN)
    }
  }

  unmappedValues(): Array<DTProcessedTokenNode> {
    return [...this.nodes.values()]
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Lookup

  tokensOfType(type: TokenType): Array<Token> {
    return (
      [...this.mappedTokens.values()] // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([token, index]) => token.tokenType === type) // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([token, index]) => token)
    )
  }

  lookupReferencedToken(reference: string): Token | undefined {
    let ref = reference.trim()
    if (ref.includes("$")) {
      // Convert $ reference to {}-type of reference as keys are all defined as {}
      ref = `{${ref.substring(1)}}`
    }
    // Find single token reference
    return this.mappedTokens.get(ref)?.[0]
  }

  lookupReferencedNode(reference: string): DTProcessedTokenNode | undefined {
    let ref = reference.trim()
    if (ref.includes("$")) {
      // Convert $ reference to {}-type of reference as keys are all defined as {}
      ref = `{${ref.substring(1)}}`
    }
    // Find single token reference
    return this.nodes.get(ref)
  }

  lookupAllReferencedTokens(reference: string):
    | Array<{
        token: Token
        key: string
        location: number
        dollarTypeReference: boolean
      }>
    | undefined {
    const ref = reference.trim()
    const findings = [
      ...this.findBracketStrings(ref),
      ...this.findDollarStrings(ref),
    ]

    const result: Array<{
      token: Token
      key: string
      location: number
      dollarTypeReference: boolean
    }> = []

    for (const finding of findings) {
      const fullkey = `{${finding.value}}`

      if (this.mappedTokens.get(fullkey)?.[0]) {
        // Found referenced token
        result.push({
          // @ts-expect-error TS(2322): Type 'Token | undefined' is not assignable to type... Remove this comment to see the full error message
          token: this.mappedTokens.get(fullkey)?.[0],
          key: finding.value,
          location: finding.index,
          dollarTypeReference: finding.dollarTypeReference,
        })
      } else {
        // Skip as there is a reference that doesn't exist
        return undefined
      }
    }

    return result
  }

  replaceAllReferencedTokens(
    reference: string,
    replacements: Array<{
      token: Token
      key: string
      location: number
      dollarTypeReference: boolean
    }>
  ): string {
    // Seek from the last position so we don't have to deal with repositioning
    const sortedReps = replacements.sort((a, b) => b.location - a.location)
    let finalReference = reference.trim()

    for (const r of sortedReps) {
      if (!tokenTypeIsReferencable(r.token.tokenType)) {
        throw new Error(
          `Invalid reference ${finalReference} in computed token. Only dimensions, colors, generic/text, fontFamily/fontWeight, textCase/textDecoration tokens can be used as partial reference (fe. rgba({value}, 10%), however was ${r.token.tokenType}`
        )
      }

      finalReference = this.replaceToken(
        finalReference,
        r.token,
        r.key,
        r.location,
        r.dollarTypeReference
      )
    }

    return finalReference
  }

  replaceToken(
    base: string,
    token: Token,
    key: string,
    location: number,
    dollarTypeReference: boolean
  ): string {
    const fullkey = dollarTypeReference ? `$${key}` : `{${key}}`
    const value = this.replacableValue(token)
    return this.replaceAt(base, fullkey, value, location, dollarTypeReference)
  }

  replaceAt(
    s: string,
    what: string,
    replacement: string,
    index: number,
    // REVIEW: Not used?
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dollarTypeReference: boolean
  ): string {
    return (
      s.substring(0, index) + replacement + s.substring(index + what.length)
    )
  }

  replacableValue(token: Token): string {
    const type = token.tokenType as ReplacableTokenType

    switch (type) {
      case TokenType.color: {
        const color = token as ColorToken

        return `#${[
          color.value.color.r,
          color.value.color.g,
          color.value.color.b,
          Math.round(color.value.opacity.measure * 255),
        ]
          .map((x) => {
            const hex = x.toString(16)

            return hex.length === 1 ? `0${hex}` : hex
          })
          .join("")}`
      }

      case TokenType.dimension:
      case TokenType.size:
      case TokenType.space:
      case TokenType.opacity:
      case TokenType.fontSize:
      case TokenType.lineHeight:
      case TokenType.letterSpacing:
      case TokenType.paragraphSpacing:
      case TokenType.borderWidth:
      case TokenType.radius:
      case TokenType.duration:

      // eslint-disable-next-line no-fallthrough
      case TokenType.zIndex: {
        const dimension = token as AnyDimensionToken

        if (dimension.value.unit === Unit.percent) {
          return `${(token as AnyDimensionToken).value.measure.toString()}%`
        }

        return (token as AnyDimensionToken).value.measure.toString()
      }

      case TokenType.fontWeight:
      case TokenType.fontFamily:
      case TokenType.productCopy:
      case TokenType.string:
        return (token as AnyStringToken).value.text
      case TokenType.textCase:
      case TokenType.textDecoration:
      case TokenType.visibility:
        return (token as AnyOptionToken).value.value
      default:
        throw new UnreachableCaseError(
          type,
          "Invalid replacable value. Only dimensions, colors or generic/text tokens can provide value for complex tokens / inline replaces"
        )
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Conveniences

  isBalancedReference(syntax: string): boolean {
    return this.hasSameNumberOfCharacters(syntax, "{", "}")
  }

  valueHasReference(value: string | object | number): boolean {
    if (typeof value !== "string") {
      return false
    }

    return value.includes("{") || value.includes("}") || value.includes("$")
  }

  valueIsPureReference(value: string | object): boolean {
    if (typeof value !== "string") {
      return false
    }

    const trimmed = value.trim()

    // If there is more than one opening or closing bracket, it is not a pure reference. Must also open and close the syntax
    return (
      (trimmed.startsWith("{") &&
        trimmed.endsWith("}") &&
        this.countCharacter(trimmed, "{") === 1 &&
        this.countCharacter(trimmed, "}") === 1) ||
      this.isDollarIdentifier(trimmed)
    )
  }

  hasSameNumberOfCharacters(
    str: string,
    char1: string,
    char2: string
  ): boolean {
    let count1 = 0
    let count2 = 0

    for (const c of str) {
      if (c === char1) {
        count1 += 1
      } else if (c === char2) {
        count2 += 1
      }
    }

    return count1 === count2
  }

  countCharacter(str: string, char: string): number {
    let count = 0

    for (const c of str) {
      if (c === char) {
        count += 1
      }
    }

    return count
  }

  tokenReferenceKey(path: Array<string>, name: string): string {
    // Delete initial piece of path, as this is only grouping element
    const newPath = Array.from(path)

    newPath.splice(0, 1)

    // Return path key that is the same as what Design Tokens uses for referencing
    return `{${[...newPath, name].join(".")}}`
  }

  findBracketStrings(
    str: string
  ): { index: number; value: string; dollarTypeReference: boolean }[] {
    const results = []
    let currentIndex = 0

    while (currentIndex < str.length) {
      // Find the index of the next opening bracket
      const openBracketIndex = str.indexOf("{", currentIndex)

      // If no more opening brackets are found, we can stop searching
      if (openBracketIndex === -1) {
        break
      }

      // Find the index of the closing bracket that corresponds to the opening bracket
      const closeBracketIndex = str.indexOf("}", openBracketIndex)

      // If no closing bracket is found, we can stop searching
      if (closeBracketIndex === -1) {
        break
      }

      // Extract the string between the brackets and add it to the results
      const bracketString = str.substring(
        openBracketIndex + 1,
        closeBracketIndex
      )

      results.push({
        index: openBracketIndex,
        value: bracketString,
        dollarTypeReference: false,
      })
      // Continue searching after the closing bracket
      currentIndex = closeBracketIndex + 1
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return results
  }

  findDollarStrings(
    str: string
  ): { index: number; value: string; dollarTypeReference: boolean }[] {
    const results = []
    let match

    // Use a regex to find strings that start with $ and extend until a non-alphanumeric and non-dot character
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line no-useless-escape
    const regex = /\$([a-zA-Z0-9\.\-\_]+)/g

    // Use exec to find all matches in the string
    // tslint:disable-next-line: no-conditional-assignment
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(str)) !== null) {
      if (match[1])
        results.push({
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          index: match.index,
          // Exclude the leading $
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          value: match[1],
          dollarTypeReference: true,
        })
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return results
  }

  isDollarIdentifier(value: string): boolean {
    // This regex ensures the string starts with a $, is followed by the identifier defined by the regex, and nothing else
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line no-useless-escape
    const regex = /^\$([a-zA-Z0-9\.\-\_]+)$/
    return regex.test(value)
  }
}
