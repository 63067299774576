//
//  ComponentUtils.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Component } from "../model/components/SDKComponent"
import { ComponentGroup } from "../model/groups/SDKComponentGroup"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Component utilities

export class ComponentUtils {
  /** Map components for lookup */
  static componentsToReferenceMap(
    components: Array<Component>
  ): Map<string, Component> {
    const map = new Map<string, Component>()

    components.forEach((component) => {
      map.set(component.id, component)
    })

    return map
  }

  /** Map component groups for lookup */
  static componentGroupsToReferenceMap(
    groups: Array<ComponentGroup>
  ): Map<string, ComponentGroup> {
    const map = new Map<string, ComponentGroup>()

    groups.forEach((group) => {
      map.set(group.id, group)
    })

    return map
  }

  /** Creates a new component group. Default values will be used for all properties */
  static createDefaultComponentGroup(
    versionId: string,
    brandId: string,
    name?: string
  ): ComponentGroup {
    return new ComponentGroup({
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new component group
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      isRoot: false,
      meta: {
        name: name ?? "Group",
        description: "",
      },
      childrenIds: [],
      createdAt: new Date().toISOString(),
    })
  }

  /** Creates a new component. Default values will be used for all properties */
  static createDefaultComponent(
    versionId: string,
    brandId: string,
    name?: string
  ): Component {
    return new Component(
      {
        // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
        id: undefined, // Ommited id will create new component
        persistentId: uuidv4(),
        brandId,
        meta: {
          name: name ?? "Group",
          description: "",
        },
      },
      [],
      []
    )
  }
}
