//
//  ElementPropertyValue.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type ElementPropertyValueRemoteModel = {
  value: string | boolean | number

  id: string // unique
  designSystemVersionId: string // unique
  definitionId: string // corresponds to column - property persistentID
  targetElementId: string // corresponds to linked component - component persistentID
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class ElementPropertyValue {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: string | boolean | number

  id: string

  designSystemVersionId: string

  definitionId: string

  targetElementId: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: ElementPropertyValueRemoteModel) {
    this.id = model.id
    this.designSystemVersionId = model.designSystemVersionId

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line no-prototype-builtins
    if (model.hasOwnProperty("value")) {
      this.value = model.value
    } else {
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
      this.value = null
    }

    this.definitionId = model.definitionId
    this.targetElementId = model.targetElementId
  }
}
