export enum DocsBlockCategory {
  /** Shows as a "Text Blocks" */
  text = "Text",
  /** Show as "Layout blocks" */
  layout = "Layout",
  /** Show as "Media blocks" */
  media = "Media",
  /** Show as "Embed blocks" */
  embed = "Embed",
  /** Show as "Figma blocks" */
  figma = "Figma",
  /** Show as "Code blocks" */
  code = "Code",
  /** Show as "Guidelines blocks" */
  guidelines = "Guidelines",
  /** Show as "Token blocks" */
  token = "Tokens",
  /** Show as "Component blocks" */
  component = "Components",
  /** Shows as "Asset blocks" */
  asset = "Assets",
  /** Show as "Data blocks" */
  data = "Data",
  /** Shows as "Other blocks" */
  other = "Other",
}
