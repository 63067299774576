export enum DocsBlockRichTextPropertyStyle {
  /** Title type 1, roughly equivalent of h2 in size */
  title1 = "Title1",
  /** Title type 2, roughly equivalent of h3 in size */
  title2 = "Title2",
  /** Title type 3, roughly equivalent of h4 in size */
  title3 = "Title3",
  /** Title type 4, roughly equivalent of h5 in size */
  title4 = "Title4",
  /** Title type 5, roughly equivalent of h6 in size */
  title5 = "Title5",
  /** Quote */
  quote = "Quote",
  /** Callout */
  callout = "Callout",
  /** Default option */
  default = "Default",
}
