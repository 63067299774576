/* eslint-disable max-lines */
//
//  AreaAutomation.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Exporter, NewExporterPayload } from "../../model/exporters/SDKExporter"
import {
  NewPipelinePayload,
  Pipeline,
  PipelineUpdateModel,
} from "../../model/exporters/SDKPipeline"
import { ExporterCustomBlockVariant } from "../../model/exporters/custom_blocks/SDKExporterCustomBlockVariant"
import { ExporterConfigurationProperty } from "../../model/exporters/custom_properties/SDKExporterConfigurationProperty"
import { ExportBuild } from "../../model/exporters/exporter_builds/SDKExportBuild"
import { ExportBuildResultLog } from "../../model/exporters/exporter_builds/SDKExportBuildResults"
import { ImportJob } from "../../model/jobs/SDKImportJob"
import { DataCore } from "../data/SDKDataCore"

import { RemoteVersionIdentifier } from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Automation Area

export class AreaAutomation {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches all pipelines that are available by workspace id and design system id */
  async getPipelines(
    workspaceId: string,
    designSystemId?: string
  ): Promise<Array<Pipeline>> {
    return this.dataCore.pipelines(workspaceId, designSystemId)
  }

  /** Fetches all builds of pipelines
   * @param workspaceId - Identifier of the workspace where the pipeline is installed
   * @param versionId - Identifier of the version where the pipeline is installed
   */
  async getExportBuilds(
    workspaceId: string,
    versionId: string
  ): Promise<Array<ExportBuild>> {
    return this.dataCore.exportBuilds(workspaceId, versionId)
  }

  /** Fetches build logs of a specific build
   * @param workspaceId - Identifier of the workspace where the pipeline is installed
   * @param buildId - Identifier of the build to fetch logs for
   */
  async getExportBuildLogs(
    workspaceId: string,
    buildId: string
  ): Promise<Array<ExportBuildResultLog>> {
    return this.dataCore.buildLogs(workspaceId, buildId)
  }

  /** Fetches exporters belonging to workspace by id */
  async getExporters(
    workspaceId: string,
    filter?: { installedOnly?: boolean }
  ): Promise<Array<Exporter>> {
    return this.dataCore.exporters(workspaceId, filter)
  }

  /** Updates exporter
   * @param workspaceId - Identifier of the workspace where the exporter is installed
   * @param exporterId - Identifier of the exporter to update
   * @param payload - Payload to update exporter with
   */
  async updateExporter(
    workspaceId: string,
    exporterId: string,
    payload: NewExporterPayload
  ): Promise<Exporter> {
    return this.dataCore.updateExporter(workspaceId, exporterId, payload)
  }

  /** Updates exporter configuration property value
   * @param designSystemId
   * @param exporterId
   * @param propertyKey
   * @param propertyValue
   */
  async updateExporterConfigurationPropertyValue(
    designSystemId: string,
    exporterId: string,
    propertyKey: string,
    propertyValue: any
  ): Promise<void> {
    await this.dataCore.updateExporterConfigurationPropertyValue(
      designSystemId,
      exporterId,
      propertyKey,
      propertyValue
    )
  }

  /** Deletes exporter configuration property value
   * @param designSystemId
   * @param exporterId
   * @param propertyKey
   */
  async deleteExporterConfigurationPropertyValue(
    designSystemId: string,
    exporterId: string,
    propertyKey: string
  ): Promise<void> {
    await this.dataCore.deleteExporterConfigurationPropertyValue(
      designSystemId,
      exporterId,
      propertyKey
    )
  }

  /** Deletes exporter
   * @param workspaceId - Identifier of the workspace where the exporter is installed
   * @param exporterId - Identifier of the exporter to delete
   */
  async deleteExporter(workspaceId: string, exporterId: string): Promise<void> {
    return this.dataCore.deleteExporter(workspaceId, exporterId)
  }

  /** Pulls latest version of exporter from remote repository
   * @param workspaceId - Identifier of the workspace where the exporter is installed
   * @param exporterId - Identifier of the exporter to pull
   */
  async pullLatestExporter(
    workspaceId: string,
    exporterId: string
  ): Promise<Exporter> {
    return this.dataCore.pullLatestExporter(workspaceId, exporterId)
  }

  /** Fetches all custom configuration properties that specific exporter provides
   * @param exporterId - Identifier of the exporter to fetch configuration for
   * @param from - Remote version to fetch from
   * @returns All custom blocks
   */
  async getExporterConfiguration(
    workspaceId: string,
    exporterId: string,
    designSystemId: string
  ): Promise<Array<ExporterConfigurationProperty>> {
    return this.dataCore.exporterConfigurationProperties(
      exporterId,
      workspaceId,
      designSystemId
    )
  }

  /** Fetches all custom variants that specific exporter provides
   * @param workspaceId - Identifier of the workspace where the exporter is installed
   * @param exporterId - Identifier of the exporter to fetch configuration for
   * @returns All custom variants
   */
  async getExporterBlockVariants(
    workspaceId: string,
    exporterId: string
  ): Promise<Array<ExporterCustomBlockVariant>> {
    return this.dataCore.documentationExporterCustomVariants(
      workspaceId,
      exporterId
    )
  }

  /** Gets all import jobs that are currently in progress.
   * @param userId - User id to delete integration for. Can only be invoked on user id that is currently authenticated.
   * @returns Nothing
   */
  async getImportJobs(
    from: RemoteVersionIdentifier
  ): Promise<Array<ImportJob>> {
    return this.dataCore.getImportJobs(from.designSystemId, from.versionId)
  }

  async getImportJob(
    from: RemoteVersionIdentifier,
    jobId: string
  ): Promise<ImportJob> {
    return this.dataCore.getImportJob(
      from.designSystemId,
      from.versionId,
      jobId
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  async scheduleImportJobForFigmaSources(
    from: RemoteVersionIdentifier,
    sourceIds: Array<string>,
    dryRun = false
  ): Promise<ImportJob> {
    return this.dataCore.scheduleImportJobForFigmaSources(
      from.designSystemId,
      from.versionId,
      sourceIds,
      dryRun
    )
  }

  /** Create pipeline
   * @param workspaceId string
   * @param pipeline Pipeline
   * @returns Pipeline
   */
  async createPipeline(
    workspaceId: string,
    pipeline: NewPipelinePayload
  ): Promise<Pipeline> {
    return this.dataCore.createPipeline(workspaceId, pipeline)
  }

  /** Update pipeline
   * @param workspaceId string
   * @param pipeline Pipeline
   * @returns Pipeline
   */
  async updatePipeline(
    workspaceId: string,
    pipelineUpdateModel: PipelineUpdateModel
  ): Promise<void> {
    return this.dataCore.updatePipeline(workspaceId, pipelineUpdateModel)
  }

  /**
   * Run pipeline
   * @param workspaceId string
   * @param designSystemVersionId string
   * @param pipelineId string
   */
  async runPipeline(
    workspaceId: string,
    designSystemVersionId: string,
    pipelineId: string
  ): Promise<void> {
    return this.dataCore.runPipeline(
      workspaceId,
      designSystemVersionId,
      pipelineId
    )
  }

  /** Create exporter
   * @param workspaceId string
   * @param payload NewExporterPayload
   * @returns Exporter
   */
  async createExporter(
    workspaceId: string,
    payload: NewExporterPayload
  ): Promise<Exporter> {
    return this.dataCore.createExporter(workspaceId, payload)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  async deletePipeline(workspaceId: string, pipelineId: string): Promise<void> {
    return this.dataCore.deletePipeline(workspaceId, pipelineId)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Compute
}
