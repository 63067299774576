import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockBehaviorDataType } from "../../../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../../../enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.assets"
const name = "Assets"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v2/assets.svg"
const descriptionShort = "Display icons or illustrations"
const keywords = ["icons", "illustrations", "grid", "svg", "logos"]
const descriptionFull = "Display a grid of icons or illustrations."
const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/media-and-assets/asset/general-xa6EgXb2"

/**
 * 2. Block properties
 */
const properties = {
  assets: b.assetProperty({
    id: "assets",
    name: "Assets",
    options: {},
  }),
}

/**
 * 3. Block variants
 */
const variants: Array<DocsBlockVariant> = [
  b.variantUsingDefaultLayout({
    id: "default",
    name: "Simple grid",
    description:
      "A simple grid of assets. Both the title and description are displayed below the preview.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/assets-simple-grid.svg",
    properties: [properties.assets],
    maxColumns: 8,
  }),
  b.variantUsingDefaultLayout({
    id: "square-grid",
    name: "Square grid",
    description:
      "Bordered square grid tailored for displaying icon assets. Only the title is displayed.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/assets-square-grid.svg",
    properties: [properties.assets],
    appearance: {
      isEditorPresentationDifferent: true,
    },
    maxColumns: 8,
  }),
  b.variantUsingDefaultLayout({
    id: "borderless-grid",
    name: "Borderless grid",
    description:
      "Borderless grid, perfect for displaying assets of the same height. Only the title is visible.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/assets-borderless-grid.svg",
    properties: [properties.assets],
    appearance: {
      isEditorPresentationDifferent: true,
    },
    maxColumns: 8,
  }),
]

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.multiVariantItem({
  properties: [properties.assets],
  variants,
})

/**
 * 5. Block definition
 */
const block = b.dataBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.asset,
  description: descriptionShort,
  searchKeywords: keywords,
  dataType: DocsBlockBehaviorDataType.asset,
  selectionType: DocsBlockBehaviorSelectionType.entityAndGroup,
  item: blockItem,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as Assets }
