export function castEnum<O extends string>(value: `${O}`): O {
  return value as O
}

export function castOptionalEnum<O extends string>(
  value: `${O}` | undefined
): O | undefined {
  return value as O | undefined
}

export type NonEmptyArray<T> = [T, ...T[]]

export function sureOf<T>(entity: T | null | undefined): NonNullable<T> {
  if (entity == null) {
    throw new Error("sureOf(): value not present")
  }

  return entity
}

export function sortUsingMap<T, K>(
  items: Array<T>,
  indexes: Map<K, number>,
  keyFn: (item: T) => K
): Array<T> {
  return items.sort(
    (a, b) => (indexes.get(keyFn(a)) ?? -1) - (indexes.get(keyFn(b)) ?? -1)
  )
}
