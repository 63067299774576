import { printDevData } from "@supernovaio/cloud/utils/devTool/utils"
import { getHomeRoute } from "@supernovaio/cloud/utils/routing/getHomeRoute"
import { Supernova } from "@supernovaio/sdk"

import { storageKeyToken, storageKeyUID } from "./consts"

import Cookies from "js-cookie"

function clientSideGuard(functionName = "Function") {
  if (typeof window === "undefined") {
    throw new Error(`${functionName} can be used only on client side`)
  }
}

export function enableImpersonation() {
  clientSideGuard("enableImpersonation")

  window.supernova = {
    ...window.supernova,
    async applySessionTokenOverride(token: string) {
      try {
        const sdk = new Supernova(token, {
          apiUrl: process.env.NEXT_PUBLIC_SN_API_URL,
        })

        const user = await sdk.me.me()

        if (!user) {
          throw new Error(
            "Unable to impersonate, incorrect token (possibly from different environment?)"
          )
        }

        Cookies.set(storageKeyToken, token, {
          secure: true,
        })
        Cookies.set(storageKeyUID, user.id, {
          secure: true,
        })

        window.location.href = getHomeRoute()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn("Impersonation failed", error)
      }
    },
    removeTokenOverride() {
      return removeImpersonation()
    },
    isAccessTokenOverriden() {
      return getIsImpersonating()
    },
    printDevData() {
      return printDevData()
    },
  }
}

export function removeImpersonation(withRedirect = true) {
  clientSideGuard("removeImpersonation")

  Cookies.remove(storageKeyToken)
  Cookies.remove(storageKeyUID)

  if (withRedirect) {
    window.location.href = getHomeRoute()
  }
}

/** Works only on client side. Using it on server may lead to unexpected results. */
export function getImpersonationData() {
  return {
    token: Cookies.get(storageKeyToken),
    uid: Cookies.get(storageKeyUID),
  }
}

/** Works only on client side. Using it on server may lead to unexpected results. */
export function getIsImpersonating() {
  const { token, uid } = getImpersonationData()

  return !!(token && uid)
}
