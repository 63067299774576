export enum DocsBlockItemVariantLayoutGap {
  /** small gap between columns/row */
  small = "Small",
  /** medium gap between columns/row */
  medium = "Medium",
  /** large gap between columns/row */
  large = "Large",
  /** no gap between columns/row */
  none = "None",
}
