//
//  SDKDocsBlockVariant.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { PageBlockDefinitionVariant } from "@supernova-studio/model"

import { DocsBlockLayout } from "./SDKDocsBlockLayout"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DocsBlockVariantModel = PageBlockDefinitionVariant

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DocsBlockVariant {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Variant id, validated to be unique within a block, but not necessarily unique across all blocks */
  id: string

  /** Variant name that will show in the interface */
  name: string

  /** Variant image filename */
  image: string | null

  /** Variant description that will show in the interface */
  description: string | null

  /** Link to documentation or resource explaining the behavior of the block and its options */
  documentationLink: string | null

  /** Variant layout that defines how the variant is presented in the editor */
  layout: DocsBlockLayout

  /** Maximum of columns that items under this layout can form. Defaults to 1 */
  maxColumns: number

  /** Default number of columns with which the block will be spawned */
  defaultColumns: number

  /** Same appearance options that item has. When any appearance option is set on the level of variant, appearance is overriden, otherwise base item appearance is taken */
  appearance: {
    /** With border enabled, a light border will be rendered in editor around the item. For example, shortcuts or assets have this enabled */
    isBordered?: boolean
    /** When background option is enabled, background color can be set for the item */
    hasBackground?: boolean
    /** When enabled, signifies that variants renders differently in the editor and in the published version */
    isEditorPresentationDifferent?: boolean
    /** When enabled, the header with block name, description, and header will be displayed
     * Use only for custom blocks that don't have a good content do be displayed in the editor (e.g. Release notes, Lottie, etc.)
     * */
    showBlockHeaderInEditor?: boolean
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DocsBlockVariantModel) {
    this.id = model.id
    this.name = model.name
    this.image = model.image ?? null
    this.description = model.description ?? null
    this.documentationLink = model.documentationLink ?? null
    this.layout = new DocsBlockLayout(model.layout)
    this.maxColumns = model.maxColumns ?? 1
    this.defaultColumns = model.defaultColumns ?? 1
    this.appearance = {
      isBordered: model.appearance?.isBordered ?? undefined,
      hasBackground: model.appearance?.hasBackground ?? undefined,
      isEditorPresentationDifferent:
        model.appearance?.isEditorPresentationDifferent ?? undefined,
      showBlockHeaderInEditor:
        model.appearance?.showBlockHeaderInEditor ?? undefined,
    }
  }
}
