export enum DocsBlockBehaviorDataType {
  /** Item type uses item definition alone to construct items - examples of this are RichText, Shortcut, etc. In this mode, data panel will be hidden */
  item = "Item",
  /** Token type allows selection of token entity and associated groups * */
  token = "Token",
  /** Asset type allows selection of asset entity and associated groups * */
  asset = "Asset",
  /** Component type allows selection of asset entity and associated groups * */
  component = "Component",
  /** Figma frame type allows selection of any frame coming from Figma * */
  figmaNode = "FigmaNode",
  /** Figma Component type allows selection of any component coming from Figma * */
  figmaComponent = "FigmaComponent",
}
