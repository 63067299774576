//
//  DesignSystem.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTODesignSystem } from "@supernova-studio/client"

import {
  convertRemoteToDesignSystemRole,
  DesignSystemRole,
} from "../enums/SDKDesignSystemRole"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Interface

export type DesignSystemSwitcher = {
  isEnabled: boolean
  designSystemIds: Array<string>
}

export type DesignSystemAccessMode = "Open" | "InviteOnly"

export type DesignSystemTransportModel = Pick<
  DesignSystem,
  | "id"
  | "name"
  | "description"
  | "isPublic"
  | "isMultiBrand"
  | "documentationSlug"
  | "documentationUserSlug"
  | "documentationExporterId"
  | "designSystemSwitcher"
  | "workspaceId"
>

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class DesignSystem {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Unique identifier of design system */
  id: string

  /** Unique identifier of workspace that contains the design system */
  workspaceId: string

  /** Design system name */
  name: string

  /** Design system description */
  description: string

  /** If enabled, parts of the design system can be accessed by public (for example, documentation site) */
  isPublic: boolean

  /** If set, signals that multibrand functionality is enabled for this design system */
  isMultiBrand: boolean

  /** If set, signals that approval feature is enabled */
  isApprovalFeatureEnabled: boolean

  /** If set, restricts publishing to only pages with ready to publish state */
  approvalRequiredForPublishing: boolean

  /** Unique identifier of exporter package used for the documentation */
  documentationExporterId: string

  /** DocumentationLegacy URL slug */
  documentationSlug: string

  /** DocumentationLegacy URL slug - user override */
  documentationUserSlug: string | null

  /** Binding multiple design systems together */
  designSystemSwitcher: DesignSystemSwitcher

  /** Access mode of the design system */
  accessMode: DesignSystemAccessMode

  /** Effective role of the user in the design system.
   * Is `null` the DS role is inherited from the WS. */
  effectiveRole: DesignSystemRole | null

  /** Flag which determines if the design system is available to the user.
   * If `false` then the user doesn't have access to the design system, but knows about its existence (e.g. Owners). */
  isAvailableToUser: boolean

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    // TODO: temp fix. For some reason is public is set to true in the model types
    model: Omit<DTODesignSystem, "isPublic"> & { isPublic: boolean }
  ) {
    this.id = model.id
    this.workspaceId = model.workspaceId
    this.name = model.meta.name
    this.description = model.meta.description ?? ""
    this.isMultiBrand = model.isMultibrand
    this.isPublic = model.isPublic
    this.isApprovalFeatureEnabled = model.isApprovalFeatureEnabled
    this.approvalRequiredForPublishing = model.approvalRequiredForPublishing
    this.documentationExporterId = model.docExporterId
    this.documentationSlug = model.docSlug
    this.documentationUserSlug = model.docUserSlug ?? null
    this.designSystemSwitcher = model.designSystemSwitcher ?? {
      isEnabled: false,
      designSystemIds: [],
    }
    this.accessMode = model.accessMode
    this.effectiveRole = model.effectiveRole
      ? convertRemoteToDesignSystemRole(model.effectiveRole)
      : null
    this.isAvailableToUser = model.isAvailableToUser
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): DesignSystemTransportModel {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      isPublic: this.isPublic,
      isMultiBrand: this.isMultiBrand,
      documentationSlug: this.documentationSlug,
      documentationUserSlug: this.documentationUserSlug,
      documentationExporterId: this.documentationExporterId,
      designSystemSwitcher: this.designSystemSwitcher,
      workspaceId: this.workspaceId,
    }
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: DesignSystemTransportModel) {
    return new DesignSystem({
      id: model.id,
      meta: {
        name: model.name,
        description: model.description,
      },
      workspaceId: model.workspaceId,
      isMultibrand: model.isMultiBrand,

      docSlug: model.documentationSlug,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      docUserSlug: model.documentationUserSlug,
      docExporterId: model.documentationExporterId,
      designSystemSwitcher: model.designSystemSwitcher,
    })
  }
}
