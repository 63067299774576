export enum DocsBlockImagePropertyWidth {
  /** Default. Translates to width: 100% */
  full = "Full",
  /** width: 48px */
  icon = "Icon",
  /** width: 120px */
  small = "Small",
  /** width: 180px */
  medium = "Medium",
  /** width: 360px */
  large = "Large",
  /** width: 480px */
  poster = "Poster",
}
