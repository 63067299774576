import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.markdown"
const name = "Markdown"
const icon = "https://cdn-assets.supernova.io/blocks/icons/markdown.svg"
const descriptionShort = "Render Markdown URL"
const searchKeywords = ["md", "embed", "api", "props", "mdx"]
const helpText =
  "Render any markdown file directly inside of your documentation."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/code/markdown/general-B8wQVOem"

/**
 * 2. Block properties
 */
const properties = {
  markdownUrl: b.markdownProperty({
    id: "markdownUrl",
    name: "Markdown URL",
    options: {
      urlValidationRegex: "^(https?://)?(www\\.)?.+\\.md(\\?.*)?$",
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.markdownUrl],
})

/**
 * 3. Block variants
 */
const variants: Array<DocsBlockVariant> = [
  b.variantUsingDefaultLayout({
    id: "plain",
    name: "Plain",
    image: "https://cdn-assets.supernova.io/blocks/variants/markdown-plain.svg",
    properties: [properties.markdownUrl],
  }),
  b.variantUsingDefaultLayout({
    id: "bordered",
    name: "Bordered",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/markdown-bordered.svg",
    properties: [properties.markdownUrl],
  }),
  b.variantUsingDefaultLayout({
    id: "boxed",
    name: "Boxed",
    image: "https://cdn-assets.supernova.io/blocks/variants/markdown-boxed.svg",
    properties: [properties.markdownUrl],
  }),
]

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.multiVariantItem({
  properties: [properties.markdownUrl],
  variants,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.other,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  appearance: {
    isEditorPresentationDifferent: true,
  },
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as Markdown }
