//
//  AreaStorage.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Asset } from "../../model/assets/SDKAsset"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RenderedAsset } from "../../model/assets/SDKRenderedAsset"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AssetFormat } from "../../model/enums/SDKAssetFormat"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AssetScale } from "../../model/enums/SDKAssetScale"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AssetGroup } from "../../model/groups/SDKAssetGroup"
import { DataCore } from "../data/SDKDataCore"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RenderedAssetRequestSettings } from "../data/requests/SDKDataCoreRenderRequests"

// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RemoteVersionIdentifier } from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaStorage {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Read data from key-value storage
   * @param designSystemId - Design system ID to read the data from
   * @param key - Unique data key to read
   */
  async readKeyValueStorageData(
    designSystemId: string,
    key: string
  ): Promise<string | object | null> {
    const data = await this.dataCore.readKeyValueStorageData(
      designSystemId,
      key
    )

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (data && data[key]) {
      try {
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
        return JSON.parse(data[key]) ?? null
      } catch (error) {
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data[key] ?? null
      }
    }

    return null
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Set data to key-value storage. Limit of 20mb per a design system.
   * @param designSystemId - Design system ID to store data for
   * @param key - Unique data key under which the data will be stored
   * @param value - String or object to be stored. Anything else must be converted to JSON string first
   * @returns Nothing
   */
  async setKeyValueStorageData(
    designSystemId: string,
    key: string,
    value: string | object
  ): Promise<void> {
    const stringValue =
      typeof value === "string" ? value : JSON.stringify(value, null, 0)

    await this.dataCore.setKeyValueStorageData(designSystemId, key, stringValue)
  }

  /** Unsets the data from key-value storage based on the key name
   * @param designSystemId - Design system ID to remove the data from
   * @param key - Unique data key to delete
   */
  async unsetKeyValueStorageData(
    designSystemId: string,
    key: string
  ): Promise<void> {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    await this.dataCore.setKeyValueStorageData(designSystemId, key, null)
  }
}
