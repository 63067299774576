//
//  SDKDocsBlockDefinition.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { PageBlockDefinition } from "@supernova-studio/model"

import { castEnum } from "../../../../utils/CommonUtils"
import { DocsBlockMode } from "../../../enums/SDKDocBlockMode"
import { DocsBlockBehaviorDataType } from "../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockCategory } from "../../../enums/SDKDocsBlockCategory"

import { DocsBlockBehavior } from "./SDKDocsBlockBehavior"
import { DocsBlockItemDefinition } from "./SDKDocsBlockItemDefinition"
import { DocsBlockOnboarding } from "./SDKDocsBlockOnboarding"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DocsBlockDefinitionModel = PageBlockDefinition

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DocsBlockDefinition {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Unique id of the block. Block id must be unique across all blocks registered to Supernova in preparation for block store. Reserved ids will be created for all core blocks and they'll be installed by default to every exporter */
  id: string

  /** Defines the name of the block */
  name: string

  /** Describes the block in more detail */
  description: string

  /** Block category */
  category: DocsBlockCategory

  /** Block icon filename */
  icon: string | null

  /** Link to documentation or resource explaining the behavior of the block and its options */
  documentationLink: string | null

  /* Block tags for searching. Should contain synonyms for the block functionality */
  searchKeywords: Array<string>

  /** Primary definition of the block. See DocsBlockItemDefinition for more information */
  item: DocsBlockItemDefinition

  /** Primary definition of the layered block behavior. See Block Behaviors for more information */
  behavior: DocsBlockBehavior

  /** Top-level editor options for the block itself (but not for its items). This configuration has no bearing on front-end side of rendering and is only for editor */
  editorOptions: {
    /** When provided, the first time block is used (for every user individually), onboarding layer will show up for that block, explaining what it is. */
    onboarding: DocsBlockOnboarding | null
    /** When provided, it will be used for adding a new item to the block */
    newItemLabel: string | null
  }

  /** All appearance options for top-level block wrapper */
  appearance: {
    /** With border enabled, a light border will be rendered in editor around the block. For example, rich text have this disabled as it is just a text */
    isBordered: boolean
    /** When background option is enabled, background color can be set for the block */
    hasBackground: boolean
    /** When editor presentation is different, the block will render differently in editor than in the final output */
    isEditorPresentationDifferent: boolean
    /** When enabled, the header with block name, description, and header will be displayed
     * Use only for custom blocks that don't have a good content do be displayed in the editor (e.g. Release notes, Lottie, etc.)
     * */
    showBlockHeaderInEditor: boolean
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DocsBlockDefinitionModel) {
    this.id = model.id
    this.name = model.name
    this.description = model.description
    this.category = castEnum<DocsBlockCategory>(model.category)
    this.icon = model.icon ?? null
    this.documentationLink = model.documentationLink ?? null
    this.searchKeywords = model.searchKeywords ?? []
    this.item = new DocsBlockItemDefinition(model.item)
    this.behavior = new DocsBlockBehavior(model.behavior)
    this.editorOptions = {
      onboarding: model.editorOptions?.onboarding
        ? new DocsBlockOnboarding(model.editorOptions.onboarding)
        : null,
      newItemLabel: model.behavior?.items?.newItemLabel ?? null,
    }
    this.appearance = {
      isBordered: model.appearance?.isBordered ?? true,
      hasBackground: model.appearance?.hasBackground ?? false,
      isEditorPresentationDifferent:
        model.appearance?.isEditorPresentationDifferent ?? false,
      showBlockHeaderInEditor:
        model.appearance?.showBlockHeaderInEditor ?? false,
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Helpers

  get blockMode(): DocsBlockMode {
    if (this.behavior.dataType === DocsBlockBehaviorDataType.item) {
      if (this.behavior.items?.numberOfItems === 1) {
        return DocsBlockMode.singleItem
      }
      return DocsBlockMode.multipleItems
    }
    return DocsBlockMode.data
  }

  toRemote(): DocsBlockDefinitionModel {
    return this as DocsBlockDefinitionModel
  }
}
