//
//  SDKComponentResolver.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  Component,
  ComponentRemoteModel,
} from "../../model/components/SDKComponent"
import { ElementDataView } from "../../model/elements/SDKElementDataView"
import { ElementProperty } from "../../model/elements/SDKElementProperty"
import { ElementPropertyValue } from "../../model/elements/values/SDKElementPropertyValue"
import { sortUsingMap, sureOf } from "../../utils/CommonUtils"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class ComponentResolver {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Configuration

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Resolution

  resolveComponentData(
    components: Array<ComponentRemoteModel>,
    elementProperties: Array<ElementProperty>,
    elementValues: Array<ElementPropertyValue>,
    elementViews: Array<ElementDataView>
  ): Array<Component> {
    // Sort properties using views
    const firstView = sureOf(elementViews.filter((v) => v.isDefault)[0])
    const indexes = new Map<string, number>()

    for (const column of firstView.columns) {
      if (column.propertyDefinitionId) {
        indexes.set(
          column.propertyDefinitionId,
          firstView.columns.indexOf(column)
        )
      }
    }

    const sortedProperties = sortUsingMap(
      elementProperties,
      indexes,
      (p) => p.id
    )

    // Resolve components
    const resolvedComponents = components.map(
      (c) => new Component(c, sortedProperties, elementValues)
    )

    return resolvedComponents
  }
}
