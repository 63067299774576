//
//  AreaIntegrations.ts
//  Supernova SDK
//
//  Created by Martin Naščák.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  WorkspaceIntegrationType,
  WorkspaceIntegrations,
} from "../../model/workspaces/SDKWorkspaceIntegration"
import { WorkspaceIntegrationAccessTokenPayload } from "../../model/workspaces/SDKWorkspaceIntegrationAccessToken"
import { WorkspaceIntegrationGetGitProvidersInput } from "../../model/workspaces/SDKWorkspaceIntegrationGetGitProviders"
import {
  OAuth2IntegrationTypes,
  WorkspaceIntegrationOauthRequest,
} from "../../model/workspaces/SDKWorkspaceIntegrationOauthRequest"
import { GitProvidersBranches } from "../../model/workspaces/integrations/GitProvidersBranches"
import { GitProvidersOrganizations } from "../../model/workspaces/integrations/GitProvidersOrganizations"
import { GitProvidersProjects } from "../../model/workspaces/integrations/GitProvidersProjects"
import { GitProvidersRepositories } from "../../model/workspaces/integrations/GitProvidersRepositories"
import type { DataCore } from "../data/SDKDataCore"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaWorkspaceIntegrations {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Query (Read)

  /** Fetches integrations of one specific workspace by provided id
   * @param workspaceId - ID of the workspace
   * @returns Object with integrations of given workspace
   */
  async getIntegrations(workspaceId: string): Promise<WorkspaceIntegrations> {
    return this.dataCore.workspaceIntegrations(workspaceId)
  }

  /** Fetches redirect url for oauth of one specific workspace integration by workspace id and integration type
   * @param workspaceId - ID of the workspace
   * @param type - Type of integration
   * @returns Object with redirect url
   */
  async getRedirectUrl({
    workspaceId,
    type,
  }: {
    workspaceId: string
    type: OAuth2IntegrationTypes
  }): Promise<WorkspaceIntegrationOauthRequest> {
    return this.dataCore.workspaceIntegrationRedirectUrl({ workspaceId, type })
  }

  // --- --- --- --- --- --- --- --- --- ---
  // Git Providers

  /** Fetches Organizations
   * @param workspaceId - ID of the workspace
   * @param integrationId - ID of the integration
   * @returns
   */
  async fetchGitProviderOrganization({
    workspaceId,
    integrationId,
  }: {
    workspaceId: string
    integrationId: string
  }): Promise<GitProvidersOrganizations> {
    return this.dataCore.fetchGitProvidersOrganization({
      workspaceId,
      integrationId,
    })
  }

  /** Fetches Projects
   * @param workspaceId - ID of the workspace
   * @param integrationId - ID of the integration
   * @param params - Query string parameters
   * @returns
   */
  async fetchGitProviderProjects({
    workspaceId,
    integrationId,
    params,
  }: {
    workspaceId: string
    integrationId: string
    params?: WorkspaceIntegrationGetGitProvidersInput
  }): Promise<GitProvidersProjects> {
    return this.dataCore.fetchGitProviderProjects({
      workspaceId,
      integrationId,
      params,
    })
  }

  /** Fetches Repositaries
   * @param workspaceId - ID of the workspace
   * @param integrationId - ID of the integration
   * @param params - Query string parameters
   * @returns
   */
  async fetchGitProviderRepositories({
    workspaceId,
    integrationId,
    params,
  }: {
    workspaceId: string
    integrationId: string
    params?: WorkspaceIntegrationGetGitProvidersInput
  }): Promise<GitProvidersRepositories> {
    return this.dataCore.fetchGitProviderRepositories({
      workspaceId,
      integrationId,
      params,
    })
  }

  /** Fetches Branches
   * @param workspaceId - ID of the workspace
   * @param integrationId - ID of the integration
   * @param params - Query string parameters
   * @returns
   */
  async fetchGitProviderBranches({
    workspaceId,
    integrationId,
    params,
  }: {
    workspaceId: string
    integrationId: string
    params?: WorkspaceIntegrationGetGitProvidersInput
  }): Promise<GitProvidersBranches> {
    return this.dataCore.fetchGitProviderBranches({
      workspaceId,
      integrationId,
      params,
    })
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Mutations (Create/Delete/Write)

  /** Creates a new workspace integration via PAT by workspace id and PAT input
   * @param workspaceId - ID of the workspace
   * @param payload - PAT input
   * @returns Workspace integrations with updated integration
   */
  async createWorkspaceIntegrationAccessToken({
    workspaceId,
    payload,
  }: {
    workspaceId: string
    payload: WorkspaceIntegrationAccessTokenPayload
  }): Promise<WorkspaceIntegrations> {
    return this.dataCore.createWorkspaceIntegrationAccessToken({
      workspaceId,
      payload,
    })
  }

  /** Delete workspace integration's connection
   * @param workspaceId - ID of the workspace
   * @param integrationId - ID of the integration
   * @param credentialId - ID of the credential
   * @returns Workspace integrations with updated integration
   */
  async deleteWorkspaceIntegrationCredential(data: {
    workspaceId: string
    integrationId: string
    credentialId: string
  }): Promise<WorkspaceIntegrationType> {
    return this.dataCore.deleteWorkspaceIntegrationCredential(data)
  }

  /** Delete workspace integration
   * @param workspaceId - ID of the workspace
   * @param integrationId - ID of the integration
   * @returns Workspace integrations with updated integration
   */
  async deleteWorkspaceIntegration(data: {
    workspaceId: string
    integrationId: string
  }): Promise<WorkspaceIntegrationType> {
    return this.dataCore.deleteWorkspaceIntegration(data)
  }
}
