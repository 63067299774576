//
//  SDKDocsBlockSingleSelectProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsBlockOptionColor } from "../../../enums/SDKDocsBlockOptionColor"
import { DocsBlockOptionRenderingStyle } from "../../../enums/SDKDocsBlockOptionRenderStyle"
import { IconSet } from "../../../enums/SDKIconSet"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** SingleSelect property - Additional non-value options */
export type DocsBlockSingleSelectPropertyOptions = {
  /** Default choices to set when block is created */
  defaultChoice: string
  /** List of choices to choose from */
  choices: Array<{
    /** Option value / id */
    value: string
    /** Option name */
    name: string
    /** Option icon (from our set) */
    icon?: IconSet
    /** Option icon (from URL), gets preference over an icon if defined */
    customIconUrl?: string
    /** Option color */
    color?: DocsBlockOptionColor
  }>
  /** Visual representation of the property in the property panel */
  singleSelectStyle?: DocsBlockOptionRenderingStyle
}

/** SingleSelect property - Data */
export type DocsBlockSingleSelectPropertyData = DocsBlockPropertyData<string> &
  Record<string, never>

/** SingleSelect property - Definition model */
export type DocsBlockSingleSelectPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockSingleSelectPropertyOptions

/** SingleSelect property - Definition */
export class DocsBlockSingleSelectPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockSingleSelectPropertyOptions,
  DocsBlockSingleSelectPropertyData
> {
  type!: DocsBlockItemPropertyType.singleSelect
}
