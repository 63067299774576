import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.embed-lottie"
const name = "Lottie animation"
const icon = "https://cdn-assets.supernova.io/blocks/icons/lottie.svg"
const descriptionShort = "Preview a Lottie animation"
const searchKeywords = ["embed", "lottie", "animation", "rive", "json"]
const helpText = "Embed a Lottie animation to your documentation."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/media-and-assets/lottie-preview-7CqFdGv9"

/**
 * 2. Block properties
 */
const properties = {
  url: b.urlProperty({
    id: "url",
    name: "Lottie URL",
    description:
      "Add URL to your Lottie animation file. We support .json and .lottie file extensions.",
    options: {
      urlValidationRegex: "^(http|https)://.*.(json|lottie)$",
    },
  }),
  height: b.numericProperty({
    id: "height",
    name: "Height",
    options: {
      defaultValue: 270,
    },
  }),
  width: b.numericProperty({
    id: "width",
    name: "Width",
    options: {
      defaultValue: 400,
    },
  }),
  autoplay: b.booleanProperty({
    id: "autoplay",
    name: "Autoplay",
    options: {
      defaultValue: true,
    },
  }),
  playerControls: b.booleanProperty({
    id: "playerControls",
    name: "Show player controls",
    options: {
      defaultValue: true,
    },
  }),
  loop: b.booleanProperty({
    id: "loop",
    name: "Loop",
    options: {
      defaultValue: true,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [
    properties.url,
    properties.height,
    properties.width,
    properties.autoplay,
    properties.loop,
    properties.playerControls,
  ],
  image: "https://cdn-assets.supernova.io/blocks/variants/lottie-player.svg",
  description: helpText,
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [
    properties.url,
    properties.height,
    properties.width,
    properties.autoplay,
    properties.loop,
    properties.playerControls,
  ],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.media,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  appearance: {
    isEditorPresentationDifferent: true,
    showBlockHeaderInEditor: true,
  },
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as EmbedLottie }
