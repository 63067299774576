//
//  SDKDocsBlockFigmaComponentProperty.ts
//  Supernova SDK
//
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockContainerSize } from "../../../enums/SDKDocsBlockContainerSize"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsColorRef } from "../support/SDKDocsColorRef"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Figma Component property - Additional non-value options */
export type DocsBlockFigmaComponentPropertyOptions = {
  /** How should the Figma component data be rendered in editor.
   * Grid: it should also show additional options it Display options (preview, configuration booleans, etc.)
   * PropsTable: a simple table with the list of all properties
   * Canvas: similar as grid, but all the variant previews are rendered in a canvas
   * Playground: a playground where the user can switch between different variants of the component
   * */
  renderLayoutAs?: "Grid" | "PropsTable" | "Canvas" | "Playground"
  /** If enabled, all component properties will be selected by default. Component properties need to be explicitly deselected. */
  invertPropertySelection?: boolean
}

/** Figma Component property - Data */
export type DocsBlockFigmaComponentPropertyData = DocsBlockPropertyData<
  Array<{
    entityId: string
    entityType: "FigmaComponent"
    entityMeta?: {
      selectedComponentProperties?: Array<string>
    }
  }>
> & {
  /** Preview container size */
  previewContainerSize?:
    | DocsBlockContainerSize.centered
    | DocsBlockContainerSize.naturalHeight
  /** If enabled, the component title will be visible in the block */
  showComponentName?: boolean
  /** If enabled, the component description list will be visible in the block */
  showComponentDescription?: boolean
  /** If enabled, the list of properties list will be visible in the block */
  showPropertyList?: boolean
  /**
   * Defines the order for preview rendering using a list of entity IDs.
   * If not specified, the default order is applied.
   */
  previewOrderIds?: Array<string>
}

/** Figma Component property - Definition model */
export type DocsBlockFigmaComponentPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockFigmaComponentPropertyOptions

/** Figma Component property - Definition */
export class DocsBlockFigmaComponentPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockFigmaComponentPropertyOptions,
  DocsBlockFigmaComponentPropertyData
> {
  type!: DocsBlockItemPropertyType.figmaComponent
}
