import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockBehaviorDataType } from "../../../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../../../enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.design-tokens"
const name = "Design tokens"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v2/design-tokens.svg"
const descriptionShort = "A list of design tokens"

const keywords = [
  "tokens", // hack to make sure this block is always first in the list
  "color",
  "typography",
  "spacing",
  "grid",
  "material",
  "theme",
  "token list",
  "token group",
]

const descriptionFull =
  "Show a group of design tokens. Automatically display all subgroups too."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/token/tokens/general-84NRgxGl#section-token-group-09"

/**
 * 2. Block properties
 */
const properties = {
  tokens: b.tokenProperty({
    id: "tokens",
    name: "Tokens",
    options: {
      renderLayoutAs: "List",
    },
    variants: {
      grid: {
        renderLayoutAs: "Grid",
      },
    },
  }),
}

/**
 * 3. Block variants
 */
const variants: Array<DocsBlockVariant> = [
  b.variantUsingDefaultLayout({
    id: "table",
    name: "Table",
    image: "https://cdn-assets.supernova.io/blocks/variants/tokens-table.svg",
    properties: [properties.tokens],
  }),
  b.variantUsingDefaultLayout({
    id: "grid",
    name: "Grid",
    image: "https://cdn-assets.supernova.io/blocks/variants/tokens-grid.svg",
    properties: [properties.tokens],
    maxColumns: 4,
  }),
]

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.multiVariantItem({
  properties: [properties.tokens],
  variants,
})

/**
 * 5. Block definition
 */
const block = b.dataBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.token,
  description: descriptionShort,
  searchKeywords: keywords,
  dataType: DocsBlockBehaviorDataType.token,
  selectionType: DocsBlockBehaviorSelectionType.entityAndGroup,
  item: blockItem,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as DesignTokens }
