import { handleError } from "@supernovaio/cloud/utils/errorHandler"

import { AnalyticsBrowser } from "@segment/analytics-next"

let cachedAnalytics: AnalyticsBrowser | null = null

export function getSegment() {
  if (cachedAnalytics) return cachedAnalytics

  const writeKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
  if (!writeKey) {
    throw new Error("Segment write key is not defined")
  }

  const apiHost = process.env.NEXT_PUBLIC_SEGMENT_API_HOST

  const analytics = new AnalyticsBrowser()
  analytics
    .load(
      { writeKey },
      apiHost
        ? {
            // Set up proxy for Segment API requests
            integrations: {
              "Segment.io": {
                apiHost,
                protocol: "https",
              },
            },
          }
        : undefined
    )
    .catch((err) => {
      if (err instanceof Error) handleError(err)
    })
  cachedAnalytics = analytics
  return analytics
}
