//
//  SDKComponentGroupResolver.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Component } from "../../model/components/SDKComponent"
import {
  ComponentGroup,
  ComponentGroupRemoteModel,
} from "../../model/groups/SDKComponentGroup"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class ComponentGroupResolver {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Configuration

  components: Array<Component>

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(components: Array<Component>) {
    this.components = components
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Resolution

  resolveGroupData(
    data: Array<ComponentGroupRemoteModel>
  ): Array<ComponentGroup> {
    const hashedGroups = new Map<string, ComponentGroupRemoteModel>()
    const resolvedGroups = new Map<string, ComponentGroup>()
    const allowedComponentIds = this.components.map((a) => a.id)

    // Convert raw groups to resolved groups, not caring about the references just yet
    for (const rawGroup of data) {
      const group = new ComponentGroup(rawGroup)

      hashedGroups.set(rawGroup.persistentId, rawGroup)
      resolvedGroups.set(rawGroup.persistentId, group)
    }

    // Build the reference tree and list of components
    for (const rawGroup of data) {
      const filteredComponentIds = new Array<string>()
      const referencedGroup = resolvedGroups.get(rawGroup.persistentId)

      for (const id of rawGroup.childrenIds) {
        // Find if reference is group - if it is not, it is a asset or designComponent
        const childGroup = resolvedGroups.get(id)

        if (childGroup) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          referencedGroup.addChild(childGroup.id)
        } else if (allowedComponentIds.includes(id)) {
          // Only allow components, and filter out designComponents
          filteredComponentIds.push(id)
        }
      }

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      referencedGroup.componentIds = filteredComponentIds
    }

    // Retrieve resolved groups
    const groups = Array.from(resolvedGroups.values())

    this.recomputePaths(groups)
    this.recomputeParents(groups)
    return this.reorderGroupsByRoots(groups)
  }

  private recomputePaths(groups: Array<ComponentGroup>) {
    // Find roots, and compute the segments down from the roots
    for (const group of groups) {
      if (group.isRoot) {
        this.recomputePathsFromRoot(group, [], groups)
      }
    }

    // Drop first item because we don't want the core root category to be there
    for (const group of groups) {
      group.path.shift()
    }
  }

  private recomputePathsFromRoot(
    root: ComponentGroup,
    segments: Array<string>,
    groups: Array<ComponentGroup>
  ) {
    // Recursively go down the tree(s) and add segments to each
    const extendedPath = segments.concat(root.name)

    for (const groupId of root.subgroupIds) {
      const group = groups.find((g) => g.id === groupId)

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      group.path = extendedPath.concat()
      // @ts-expect-error TS(2345): Argument of type 'ComponentGroup | undefined' is n... Remove this comment to see the full error message
      this.recomputePathsFromRoot(group, extendedPath, groups)
    }
  }

  private reorderGroupsByRoots(
    groups: Array<ComponentGroup>
  ): Array<ComponentGroup> {
    let sortedGroups = new Array<ComponentGroup>()
    // Find the root groups, which will be initial sorting points
    const roots = groups.filter((g) => g.isRoot)

    for (const root of roots) {
      // For each group, traverse and add proper order
      sortedGroups = sortedGroups.concat(this.traverseSortGroup(root, groups))
    }

    return sortedGroups
  }

  private traverseSortGroup(
    group: ComponentGroup,
    groups: Array<ComponentGroup>
  ): Array<ComponentGroup> {
    let output = new Array<ComponentGroup>()

    // Iterated group always first
    output.push(group)

    // Add sorted groups to the array
    const sortedGroupIds = group.subgroupIds.sort(
      (g1, g2) => group.childrenIds.indexOf(g1) - group.childrenIds.indexOf(g2)
    )

    for (const subgroupId of sortedGroupIds) {
      const subgroup = groups.find((g) => g.id === subgroupId)

      // @ts-expect-error TS(2345): Argument of type 'ComponentGroup | undefined' is n... Remove this comment to see the full error message
      output = output.concat(this.traverseSortGroup(subgroup, groups))
    }

    return output
  }

  private recomputeParents(groups: Array<ComponentGroup>) {
    // Find roots, and compute the references down the chain. Root groups don't have parents
    for (const group of groups) {
      if (group.isRoot) {
        this.recomputeParentsFromRoot(group, groups)
        group.parentGroupId = null
      }
    }
  }

  private recomputeParentsFromRoot(
    rootGroup: ComponentGroup,
    groups: Array<ComponentGroup>
  ) {
    for (const subgroupId of rootGroup.subgroupIds) {
      const subgroup = groups.find((g) => g.id === subgroupId)

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      subgroup.setParentGroupId(rootGroup.id)
      // @ts-expect-error TS(2345): Argument of type 'ComponentGroup | undefined' is n... Remove this comment to see the full error message
      this.recomputeParentsFromRoot(subgroup, groups)
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Authenticated data fetching
}
