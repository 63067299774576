// A list of decorative colors we support in our systems
export enum DocsBlockOptionColor {
  green = "Green",
  red = "Red",
  yellow = "Yellow",
  blue = "Blue",
  purple = "Purple",
  orange = "Orange",
  pink = "Pink",
  teal = "Teal",
  grey = "Grey",
  lightGrey = "LightGrey",
  cyan = "Cyan",
  fuchsia = "Fuchsia",
}
