//
//  AreaIntegrations.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import type { DataCore } from "../data/SDKDataCore"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaIntegrations {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /**
   * @returns Array of AuthenticationTokens
   */
  async getAuthenticationTokens() {
    return this.dataCore.getAuthenticationTokens()
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Creates a Personal Access Token.
   * @param name - Name of the token.
   * @param scope - Scope of the token.
   */
  async createAuthenticationToken(name: string, scope?: string) {
    return this.dataCore.createAuthenticationToken(name, scope)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  /**
   * @returns Array of AuthenticationTokens
   */
  async deleteAuthenticationToken(id: string) {
    return this.dataCore.deleteAuthenticationToken(id)
  }
}
