//
//  SDKWorkspaceSubscription.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { WorkspaceSubscriptionPlanInterval } from "../enums/SDKWorkspaceSubscriptionPlanInterval"
import { WorkspaceSubscriptionProductCode } from "../enums/SDKWorkspaceSubscriptionProduct"
import { WorkspaceSubscriptionStatus } from "../enums/SDKWorkspaceSubscriptionStatus"

import { ProductFeature } from "./SDKWorkspaceProductFeatures"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceSubscriptionRemoteModel = {
  status: WorkspaceSubscriptionStatus
  seatLimit: number
  seats: number
  product: WorkspaceSubscriptionProductCode
  planInterval: WorkspaceSubscriptionPlanInterval
  planPriceId: string

  stripeCustomerId?: string
  stripeSubscriptionId?: string
  card?: WorkspaceSubscriptionCardRemoteModel
  currentPeriodStart?: string
  currentPeriodEnd?: string
  amount: number
  stripeProductDescription: string
  subscriptionStatusUpdatedAt?: string
  legacyVersion?: number
  isPricePerCreator: boolean
  stripeProductFeatures: Array<string>
  stripeProductAdditionalFeatures: Array<string>
  featuresSummary: ProductFeature
}

export type WorkspaceSubscriptionCardRemoteModel = {
  last4?: string
  expiryMonth?: string
  expiryYear?: string
  brand?: string
}

export type WorkspaceSubscriptionTransportModel =
  WorkspaceSubscriptionRemoteModel

export type WorkspaceSubscriptionUpdateModel = {
  priceId: string
  numberOfSeats: number
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceSubscription {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Main status of the workspace subscription */
  status: WorkspaceSubscriptionStatus

  /** Maximum seats workspace can have assigned. This limits number of editors+ only, visitors are always unlimited */
  seatLimit: number

  /** Current amount of seats workspace uses */
  seats: number

  /** Subscription tier */
  product: WorkspaceSubscriptionProductCode

  /** Subscription cycle interval */
  planInterval: WorkspaceSubscriptionPlanInterval

  /** Subscription price id */
  planPriceId: string

  /** Starting date of the current billing cycle */
  currentPeriodStart: Date | null

  /** Ending date of the current billing cycle */
  currentPeriodEnd: Date | null

  /** Stripe customer ID. Note: visible only for Owner and Admin role */
  stripeCustomerId: string | null

  /** Stripe subscription ID. Note: visible only for Owner and Admin role */
  stripeSubscriptionId: string | null

  /** Card information. Note: visible only for Owner and Admin roles */
  card: {
    last4: string | null
    expiryMonth: string | null
    expiryYear: string | null
    brand: string | null
  } | null

  /** Price of the subscription, in the smallest currency unit (e.g., cents) */
  amount: number

  /** Product description */
  stripeProductDescription: string

  /** Date when subscription status was updated */
  subscriptionStatusUpdatedAt: Date | null

  /** Product version when it is legacy */
  legacyVersion: number | null

  /** Indicates whether the price is per creator (self-serve) or not */
  isPricePerCreator: boolean

  /** List of features available to this product price */
  stripeProductFeatures: Array<string>

  /** List of additional features available to this product price */
  stripeProductAdditionalFeatures: Array<string>

  /** List of features defined in a machine-readable way */
  featuresSummary: ProductFeature

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceSubscriptionRemoteModel) {
    this.status = model.status
    this.seatLimit = model.seatLimit
    this.seats = model.seats
    this.product = model.product
    this.planInterval = model.planInterval
    this.planPriceId = model.planPriceId
    this.currentPeriodStart = model.currentPeriodStart
      ? new Date(model.currentPeriodStart)
      : null
    this.currentPeriodEnd = model.currentPeriodEnd
      ? new Date(model.currentPeriodEnd)
      : null
    this.stripeCustomerId = model.stripeCustomerId ?? null
    this.stripeSubscriptionId = model.stripeSubscriptionId ?? null
    // @ts-expect-error TS(2322): Type '{ last4: string | undefined; expiryMonth: st... Remove this comment to see the full error message
    this.card = model.card
      ? {
          last4: model.card.last4,
          expiryMonth: model.card.expiryMonth,
          expiryYear: model.card.expiryYear,
          brand: model.card.brand ?? null,
        }
      : null
    this.amount = model.amount
    this.stripeProductDescription = model.stripeProductDescription
    this.subscriptionStatusUpdatedAt = model.subscriptionStatusUpdatedAt
      ? new Date(model.subscriptionStatusUpdatedAt)
      : null
    this.legacyVersion = model.legacyVersion ?? null
    this.isPricePerCreator = model.isPricePerCreator
    this.stripeProductFeatures = model.stripeProductFeatures
    this.stripeProductAdditionalFeatures = model.stripeProductAdditionalFeatures
    this.featuresSummary = model.featuresSummary
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toRemote(): WorkspaceSubscriptionRemoteModel {
    // Implement according to your needs
    return {
      status: this.status,
      seatLimit: this.seatLimit,
      seats: this.seats,
      product: this.product,
      planInterval: this.planInterval,
      planPriceId: this.planPriceId,
      stripeCustomerId: this.stripeCustomerId ?? undefined,
      stripeSubscriptionId: this.stripeSubscriptionId ?? undefined,
      // @ts-expect-error TS(2322): Type '{ last4: string | null; expiryMonth: string ... Remove this comment to see the full error message
      card: this.card,
      currentPeriodStart: this.currentPeriodStart
        ? this.currentPeriodStart.toISOString()
        : undefined,
      currentPeriodEnd: this.currentPeriodEnd
        ? this.currentPeriodEnd.toISOString()
        : undefined,
      amount: this.amount,
      stripeProductDescription: this.stripeProductDescription,
      subscriptionStatusUpdatedAt:
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        this.subscriptionStatusUpdatedAt.toISOString(),
      // @ts-expect-error TS(2322): Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
      legacyVersion: this.legacyVersion,
      isPricePerCreator: this.isPricePerCreator,
      stripeProductFeatures: this.stripeProductFeatures,
      stripeProductAdditionalFeatures: this.stripeProductAdditionalFeatures,
      featuresSummary: this.featuresSummary,
    }
  }

  toTransport(): WorkspaceSubscriptionTransportModel {
    return this.toRemote()
  }

  static fromTransport(model: WorkspaceSubscriptionTransportModel) {
    return new WorkspaceSubscription(model)
  }
}
