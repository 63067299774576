//
//  SDKDesignComponent.ts
//  Supernova SDK
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTOFigmaComponent,
  DTOFigmaComponentBooleanProperty,
  DTOFigmaComponentInstanceSwapProperty,
  DTOFigmaComponentTextProperty,
  DTOFigmaComponentVariantProperty,
} from "@supernova-studio/client"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export enum DesignComponentPropertyType {
  Boolean = "Boolean",
  InstanceSwap = "InstanceSwap",
  Text = "Text",
  Variant = "Variant",
}

type DesignComponentPropertyDefinitionRemoteType = NonNullable<
  DTOFigmaComponent["componentPropertyDefinitions"]
>[string]

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export abstract class DesignComponentPropertyDefinition {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  abstract type: DesignComponentPropertyType

  abstract defaultValue: string | boolean

  name: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DesignComponentPropertyDefinitionRemoteType) {
    this.id = model.id
    this.name = model.name
  }
}

export class DesignComponentPropertyBoolean extends DesignComponentPropertyDefinition {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: DesignComponentPropertyType.Boolean

  defaultValue: boolean

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOFigmaComponentBooleanProperty) {
    super(model)
    this.type = DesignComponentPropertyType.Boolean
    this.defaultValue = model.defaultValue
  }
}

export class DesignComponentPropertyText extends DesignComponentPropertyDefinition {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: DesignComponentPropertyType.Text

  defaultValue: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOFigmaComponentTextProperty) {
    super(model)
    this.type = DesignComponentPropertyType.Text
    this.defaultValue = model.defaultValue
  }
}

type InstanceSwapDefaultValuePreview = {
  componentName: string
  componentSetName: string | null
  isRemote: boolean
}

export class DesignComponentPropertyInstanceSwap extends DesignComponentPropertyDefinition {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: DesignComponentPropertyType.InstanceSwap

  defaultValue: string

  defaultValuePreview: InstanceSwapDefaultValuePreview | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOFigmaComponentInstanceSwapProperty) {
    super(model)

    this.type = DesignComponentPropertyType.InstanceSwap
    this.defaultValue = model.defaultValue
    if (model.defaultValuePreview) {
      this.defaultValuePreview = {
        componentName: model.defaultValuePreview.componentName,
        componentSetName: model.defaultValuePreview.componentSetName ?? null,
        isRemote: model.defaultValuePreview.isRemote,
      }
    } else {
      this.defaultValuePreview = null
    }
  }
}

export class DesignComponentPropertyVariant extends DesignComponentPropertyDefinition {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: DesignComponentPropertyType.Variant

  defaultValue: string

  options: string[]

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOFigmaComponentVariantProperty) {
    super(model)
    this.type = DesignComponentPropertyType.Variant
    this.defaultValue = model.defaultValue
    this.options = model.options
  }
}

export function resolveComponentPropertyDefinition(
  model: DTOFigmaComponent["componentPropertyDefinitions"] = {}
) {
  return Object.values(model).reduce<DesignComponentPropertyDefinition[]>(
    (acc, propertyDefinition) => {
      switch (propertyDefinition.type) {
        case "Boolean":
          acc.push(new DesignComponentPropertyBoolean(propertyDefinition))
          break
        case "Text":
          acc.push(new DesignComponentPropertyText(propertyDefinition))
          break
        case "Variant":
          acc.push(new DesignComponentPropertyVariant(propertyDefinition))
          break
        case "InstanceSwap":
          acc.push(new DesignComponentPropertyInstanceSwap(propertyDefinition))
          break
        default:
          break
      }
      return acc
    },
    []
  )
}
