//
//  WorkspaceIntegration.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
import { DTOIntegrationCredentials } from "@supernova-studio/client"

import { OAuth2IntegrationTypes } from "./SDKWorkspaceIntegrationOauthRequest"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceIntegrationsRemoteModel = {
  integrations: Array<WorkspaceIntegrationType>
  hasFigmaIntegration: boolean
}

export type WorkspaceLinkedIntegrations = {
  Figma?: WorkspaceIntegrationType[]
  FigmaVariablesPlugin?: WorkspaceIntegrationType[]
  Github?: WorkspaceIntegrationType[]
  TokenStudio?: WorkspaceIntegrationType[]
  Gitlab?: WorkspaceIntegrationType[]
  Azure?: WorkspaceIntegrationType[]
  Bitbucket?: WorkspaceIntegrationType[]
}

type WorkspaceIntegrationCredential = DTOIntegrationCredentials & {
  profile: NonNullable<DTOIntegrationCredentials["profile"]>
  user: NonNullable<DTOIntegrationCredentials["user"]>

  // TODO: Delete this after we update client's version. Should be in DTOIntegrationCredentials
  state: "Active" | "Inactive"
}

type WorkspaceIntegrationDesignSystem = {
  brandId: string
  designSystemId: string
  date: string
  title?: string
  user?: DTOIntegrationCredentials["user"]
}

export type WorkspaceIntegrationType = {
  id: string
  createdAt: string
  type: keyof WorkspaceLinkedIntegrations
  workspaceId: string
  integrationCredentials?: Array<WorkspaceIntegrationCredential>
  integrationDesignSystems?: Array<WorkspaceIntegrationDesignSystem>
}

export type WorkspaceIntegrationTypeOnlyCredentials = Omit<
  WorkspaceIntegrationType,
  "integrationDesignSystems"
> & {
  type: OAuth2IntegrationTypes
}

export type WorkspaceIntegrationTypeOnlyDesignSystems = Omit<
  WorkspaceIntegrationType,
  "integrationCredentials"
> & {
  type: keyof Pick<
    WorkspaceLinkedIntegrations,
    "FigmaVariablesPlugin" | "TokenStudio"
  >
}

// Computed types
export type WorkspaceIntegrationCredentialType = NonNullable<
  WorkspaceIntegrationType["integrationCredentials"]
>[0]

export type WorkspaceIntegrationDesignType = NonNullable<
  WorkspaceIntegrationType["integrationDesignSystems"]
>[0]

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceIntegration implements WorkspaceIntegrationType {
  id: string

  createdAt: string

  type: keyof WorkspaceLinkedIntegrations

  workspaceId: string

  integrationCredentials?: Array<WorkspaceIntegrationCredential>

  integrationDesignSystems?: Array<WorkspaceIntegrationDesignSystem>

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceIntegrationType) {
    this.id = model.id
    this.createdAt = model.createdAt
    this.type = model.type
    this.workspaceId = model.workspaceId
    this.integrationCredentials = model.integrationCredentials
    this.integrationDesignSystems = model.integrationDesignSystems
  }
}

export class WorkspaceIntegrations {
  /** List of integrations of the workspace */
  integrations: Array<WorkspaceIntegrationType>

  get hasFigmaIntegration(): boolean {
    return this.integrations.some(
      ({ type, integrationCredentials }) =>
        type === "Figma" && Number(integrationCredentials?.length) > 0
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceIntegrationsRemoteModel) {
    this.integrations = model.integrations
  }
}
