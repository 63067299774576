import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.table"
const name = "Table"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v2/table.svg"
const descriptionShort = "Display a simple table"
const searchKeywords = ["grid", "data", "spreadsheet", "api"]
const helpText = "Use for displaying data in a tabular format."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/layout/table-R8KGnxej"

/**
 * 2. Block properties
 */
const properties = {
  table: b.tableProperty({
    id: "table",
    name: "Table",
    options: {},
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.table],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.table],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.layout,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as Table }
