//
//  SDKDocsBlockTokenTypeProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { TokenType } from "../../../enums/SDKTokenType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** TokenType property - Additional non-value options */
export type DocsBlockTokenTypePropertyOptions = {
  allowMultiSelect?: boolean
}

/** TokenType property - Data */
export type DocsBlockTokenTypePropertyData = DocsBlockPropertyData<
  Array<TokenType>
> &
  Record<string, never>

/** TokenType property - Definition model */
export type DocsBlockTokenTypePropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockTokenTypePropertyOptions

/** TokenType property - Definition */
export class DocsBlockTokenTypePropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockTokenTypePropertyOptions,
  DocsBlockTokenTypePropertyData
> {
  type!: DocsBlockItemPropertyType.tokenType
}
