export enum DocsBlockImagePropertyAspectRatio {
  /** Default. Automatic aspect ratio */
  auto = "Auto",
  /** 1:1 aspect ratio */
  square = "Square",
  /** 3:2 aspect ratio */
  landscape = "Landscape",
  /** 2:3 aspect ratio */
  portrait = "Portrait",
  /** 16:9 aspect ratio */
  wide = "Wide",
}
