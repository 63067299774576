//
//  DSKAcl.ts
//  Supernova SDK
//
//  Created by Miro Koczka.
//
import { UserRole } from "../enums/SDKUserRole"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type SingleResourceRemoteAcl = {
  role: UserRole
  extends: UserRole[]
  grants: string[]
}

export type AclRemoteModel = {
  workspaces: { roles: SingleResourceRemoteAcl[] }
  dsm: {
    designSystems: { roles: SingleResourceRemoteAcl[] }
    designSystemVersions: { roles: SingleResourceRemoteAcl[] }
    designSystemSources: { roles: SingleResourceRemoteAcl[] }
    tokens: { roles: SingleResourceRemoteAcl[] }
  }
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

type SingleResourceAcl = {
  role: UserRole
  extends: UserRole[]
  grants: string[]
}

export type AclPermissionResolution =
  | {
      hasPermission: true
      reason?: never
      closestRoleWithPermission?: never
    }
  | {
      hasPermission: false
      reason: string
      // closest available user role that has this permission
      closestRoleWithPermission?: UserRole
    }

export class Acl {
  workspaces: SingleResourceAcl[]

  designSystems: SingleResourceAcl[]

  designSystemVersions: SingleResourceAcl[]

  designSystemSources: SingleResourceAcl[]

  tokens: SingleResourceAcl[]

  constructor(model: AclRemoteModel) {
    this.workspaces = model.workspaces.roles
    this.designSystems = model.dsm.designSystems.roles
    this.designSystemVersions = model.dsm.designSystemVersions.roles
    this.designSystemSources = model.dsm.designSystemSources.roles
    this.tokens = model.dsm.tokens.roles
  }

  getExtendedGrants(
    acls: SingleResourceRemoteAcl[],
    acl: SingleResourceRemoteAcl
  ) {
    let aclGrants = [...acl.grants]

    if (acl.extends && acl.extends.length > 0) {
      const extendedAcl = acls.find((a) => a.role === acl.extends[0])

      if (extendedAcl) {
        aclGrants = [...aclGrants, ...this.getExtendedGrants(acls, extendedAcl)]
      }
    }

    // only get unique grants
    return [...new Set(aclGrants)]
  }

  findClosestRoleWithGrant(
    acls: SingleResourceRemoteAcl[],
    acl: SingleResourceRemoteAcl,
    permission: string
  ): UserRole | null {
    const aclGrants = this.getExtendedGrants(acls, acl)

    if (aclGrants.includes(permission)) {
      return acl.role
    }

    const aclIndex = acls.findIndex((a) => a.role === acl.role)
    const nextAcl = acls[aclIndex + 1]

    if (nextAcl) {
      return this.findClosestRoleWithGrant(acls, nextAcl, permission)
    }

    return null
  }

  hasPermission(
    resource: string,
    permission: string,
    role: UserRole
  ): AclPermissionResolution {
    // TODO:fix-sdk-eslint
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const acls = this[resource]
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const acl = acls.find((a: any) => a.role === role)

    if (!acl) {
      return {
        hasPermission: false,
        reason: `Role ${role} does not exist in ${resource}`,
      }
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const grants = this.getExtendedGrants(acls, acl)

    if (grants.includes(permission)) {
      return { hasPermission: true }
    }

    const closestRoleWithPermission = this.findClosestRoleWithGrant(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      acls,
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      acl,
      permission
    )

    return {
      hasPermission: false,
      reason: `Role ${role} does not have access to ${resource} with permission ${permission}`,
      // @ts-expect-error TS(2322): Type 'UserRole | null' is not assignable to type '... Remove this comment to see the full error message
      closestRoleWithPermission,
    }
  }
}
