import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockBehaviorDataType } from "../../../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../../../enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.figma-components-propstable"
const name = "Figma component properties"
const icon =
  "https://cdn-assets.supernova.io/blocks/icons/figma-component-properties.svg"
const descriptionShort = "Display list of Figma component properties"

const keywords = ["variants", "properties", "props", "table"]

const descriptionFull = "Display list of Figma component properties"

const docsUrl = ""

/**
 * 2. Block properties
 */
const properties = {
  figmaComponent: b.figmaComponentProperty({
    id: "figmaComponent",
    name: "Figma component",
    options: {
      renderLayoutAs: "PropsTable",
      invertPropertySelection: true,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.figmaComponent],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.figmaComponent],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.dataBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.figma,
  description: descriptionShort,
  searchKeywords: keywords,
  maxSelection: 1,
  dataType: DocsBlockBehaviorDataType.figmaComponent,
  selectionType: DocsBlockBehaviorSelectionType.entity,
  item: blockItem,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as FigmaComponentPropsTable }
