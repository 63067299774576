//
//  SDKVisibilityType.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Source type enum

export enum VisibilityType {
  visible = "Visible",
  hidden = "Hidden",
}
