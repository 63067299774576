/* eslint-disable max-lines */
//
//  TokenUtils.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  AnyToken,
  AnyDimensionToken,
  BlurType,
  BorderPosition,
  BorderStyle,
  DTProcessedTokenNode,
  GradientType,
  ShadowType,
  SupernovaError,
  TextCase,
  TokenGroup,
  TokenTheme,
  Unit,
  UnreachableCaseError,
  VisibilityType,
} from "../exports"
import { ElementProperty } from "../model/elements/SDKElementProperty"
import {
  DIMENSION_TOKEN_TYPES,
  DimensionTokenType,
  TokenType,
} from "../model/enums/SDKTokenType"
import { SIZE_UNITS } from "../model/enums/SDKUnit"
import { ThemeUtilities } from "../model/themes/SDKThemeUtilities"
import { BlurToken } from "../model/tokens/SDKBlurToken"
import { BorderToken } from "../model/tokens/SDKBorderToken"
import { ColorToken } from "../model/tokens/SDKColorToken"
import {
  BorderWidthToken,
  DimensionCategoryToken,
  DimensionToken,
  DurationToken,
  FontSizeToken,
  LetterSpacingToken,
  LineHeightToken,
  OpacityToken,
  ParagraphSpacingToken,
  RadiusToken,
  SizeToken,
  SpaceToken,
  ZIndexToken,
} from "../model/tokens/SDKDimensionToken"
import { GradientToken } from "../model/tokens/SDKGradientToken"
import { ShadowToken } from "../model/tokens/SDKShadowToken"
import {
  FontFamilyToken,
  FontWeightToken,
  ProductCopyToken,
  StringCategoryToken,
  StringToken,
} from "../model/tokens/SDKStringToken"
import { TextCaseToken } from "../model/tokens/SDKTextCaseToken"
import { TextDecorationToken } from "../model/tokens/SDKTextDecorationToken"
import { Token } from "../model/tokens/SDKToken"
import {
  AnyDimensionTokenValue,
  AnyMultiLayerToken,
  AnySingleLayerToken,
  AnyStringTokenValue,
  AnyTokenValue,
  BorderWidthTokenValue,
  ColorTokenValue,
  ColorValue,
  DimensionTokenValue,
  DurationTokenValue,
  FontFamilyTokenValue,
  FontSizeTokenValue,
  FontWeightTokenValue,
  GradientStopValue,
  LetterSpacingTokenValue,
  LineHeightTokenValue,
  OpacityTokenValue,
  ParagraphSpacingTokenValue,
  ProductCopyTokenValue,
  RadiusTokenValue,
  RawValue,
  ShadowTokenValue,
  SizeTokenValue,
  SpaceTokenValue,
  StringTokenValue,
  TextCaseTokenValue,
  TextDecorationTokenValue,
  VisibilityTokenValue,
  ZIndexTokenValue,
  replaceReferencedTokenIds,
} from "../model/tokens/SDKTokenValue"
import { TypographyToken } from "../model/tokens/SDKTypographyToken"
import { VisibilityToken } from "../model/tokens/SDKVisibilityToken"
import { DTTokenReferenceResolver } from "../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"

import { sureOf } from "./CommonUtils"
import {
  blurDefaultValue,
  borderDefaultValue,
  dimensionDefaultValues,
  shadowTokenDefaultValue,
  typographyTokenDefaultValue,
} from "./tokenDefaultValues"

import { cloneDeep, flatMap } from "lodash"
import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Types

// NOTE: a generic types checks that all token types are declared in the map
type TokenMapCompletenessTypeCheck<T extends { [key in TokenType]: AnyToken }> =
  T

type ActualTypeToTokenMap = {
  [TokenType.color]: ColorToken
  [TokenType.typography]: TypographyToken
  [TokenType.blur]: BlurToken
  [TokenType.border]: BorderToken
  [TokenType.shadow]: ShadowToken
  [TokenType.gradient]: GradientToken
  [TokenType.string]: StringToken
  [TokenType.productCopy]: ProductCopyToken
  [TokenType.fontFamily]: FontFamilyToken
  [TokenType.fontWeight]: FontWeightToken
  [TokenType.textCase]: TextCaseToken
  [TokenType.textDecoration]: TextDecorationToken
  [TokenType.visibility]: VisibilityToken
  [TokenType.borderWidth]: BorderWidthToken
  [TokenType.dimension]: DimensionToken
  [TokenType.size]: SizeToken
  [TokenType.space]: SpaceToken
  [TokenType.opacity]: OpacityToken
  [TokenType.fontSize]: FontSizeToken
  [TokenType.lineHeight]: LineHeightToken
  [TokenType.letterSpacing]: LetterSpacingToken
  [TokenType.paragraphSpacing]: ParagraphSpacingToken
  [TokenType.radius]: RadiusToken
  [TokenType.duration]: DurationToken
  [TokenType.zIndex]: ZIndexToken
}

type ActualTypeToTokenClassMap = {
  [TokenType.color]: typeof ColorToken
  [TokenType.typography]: typeof TypographyToken
  [TokenType.blur]: typeof BlurToken
  [TokenType.border]: typeof BorderToken
  [TokenType.shadow]: typeof ShadowToken
  [TokenType.gradient]: typeof GradientToken
  [TokenType.string]: typeof StringToken
  [TokenType.productCopy]: typeof ProductCopyToken
  [TokenType.fontFamily]: typeof FontFamilyToken
  [TokenType.fontWeight]: typeof FontWeightToken
  [TokenType.textCase]: typeof TextCaseToken
  [TokenType.textDecoration]: typeof TextDecorationToken
  [TokenType.visibility]: typeof VisibilityToken
  [TokenType.borderWidth]: typeof BorderWidthToken
  [TokenType.dimension]: typeof DimensionToken
  [TokenType.size]: typeof SizeToken
  [TokenType.space]: typeof SpaceToken
  [TokenType.opacity]: typeof OpacityToken
  [TokenType.fontSize]: typeof FontSizeToken
  [TokenType.lineHeight]: typeof LineHeightToken
  [TokenType.letterSpacing]: typeof LetterSpacingToken
  [TokenType.paragraphSpacing]: typeof ParagraphSpacingToken
  [TokenType.radius]: typeof RadiusToken
  [TokenType.duration]: typeof DurationToken
  [TokenType.zIndex]: typeof ZIndexToken
}

export type TokenTypeMapToken =
  TokenMapCompletenessTypeCheck<ActualTypeToTokenMap>

type TokenClassMapCompletenessTypeCheck<
  T extends {
    [key in TokenType]: new (...args: any[]) => TokenTypeMapToken[key]
  }
> = T

export type TokenClassTypeMapToken =
  TokenClassMapCompletenessTypeCheck<ActualTypeToTokenClassMap>

type DifferentTypeValidationResult = {
  isDifferentTypeValue: boolean
  errorMessage?: string
}

type ValueOutOfRangeValidationResult = {
  isTokenOutOfRange: boolean
  errorMessage?: string
}

const formatTokenTypeName = (tokenType: TokenType) =>
  tokenType
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .toLowerCase()
    // capitalize first letter
    .replace(/^./, (char) => char.toUpperCase())

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Implementation

export class TokenUtils {
  /** Map tokens for lookup */
  static tokensToReferenceMap(tokens: Array<Token>): Map<string, Token> {
    const map = new Map<string, Token>()

    tokens.forEach((token) => {
      map.set(token.id, token)
    })

    return map
  }

  /** Map token groups for lookup */
  static tokenGroupsToReferenceMap(
    groups: Array<TokenGroup>
  ): Map<string, TokenGroup> {
    const map = new Map<string, TokenGroup>()

    groups.forEach((group) => {
      map.set(group.id, group)
    })

    return map
  }

  static lookupReference<T extends Token>(
    id: string | undefined | null,
    tokens: Map<string, Token>
  ): T | undefined {
    if (!id) {
      return undefined
    }

    const token = tokens.get(id)

    if (token !== undefined) {
      return token as T
    }

    return undefined
  }

  static isDimensionToken(
    token?: AnyToken | undefined | null
  ): token is AnyDimensionToken {
    return DIMENSION_TOKEN_TYPES.includes(
      (token?.tokenType as DimensionTokenType) ?? ""
    )
  }

  static isDifferentTypeValue<T extends AnyToken>(
    token: T
  ): DifferentTypeValidationResult {
    if (!this.isDimensionToken(token)) {
      return {
        isDifferentTypeValue: false,
      }
    }

    const unit = DimensionCategoryToken.getCompatibleUnit(
      token.value.unit,
      token.tokenType
    )

    if (unit === token.value.unit) {
      return {
        isDifferentTypeValue: false,
      }
    }

    let errorMessage = `${formatTokenTypeName(
      token.tokenType
    )} token can only reference values ${
      unit === Unit.raw
        ? `without units (used: ${token.value.unit.toLowerCase()}).`
        : `in ${unit.toLowerCase()}.`
    }`

    switch (token.tokenType) {
      case TokenType.dimension:
      case TokenType.lineHeight:
        errorMessage = `${formatTokenTypeName(
          token.tokenType
        )} token can only reference values in pixels, percentages, rem, or without units (used: ${token.value.unit.toLowerCase()}).`
        break
      case TokenType.size:
      case TokenType.space:
      case TokenType.fontSize:
      case TokenType.letterSpacing:
      case TokenType.paragraphSpacing:
      case TokenType.radius: {
        errorMessage = `${formatTokenTypeName(
          token.tokenType
        )} token can only reference values in pixels, percentages, or rem (used: ${token.value.unit.toLowerCase()}).`
        break
      }
      case TokenType.opacity:
        if (token.value.referencedTokenId) {
          errorMessage = `${formatTokenTypeName(
            token.tokenType
          )} token cannot reference other dimensions. Remove the alias in Figma to resolve issue.`
        }
        break
      default:
        break
    }

    return {
      isDifferentTypeValue: true,
      errorMessage,
    }
  }

  static isTokenOutOfRange<T extends AnyToken>(
    token: T
  ): ValueOutOfRangeValidationResult {
    if (!this.isDimensionToken(token)) {
      return {
        isTokenOutOfRange: false,
      }
    }

    const isReference = token.origin?.id !== undefined

    switch (token.tokenType) {
      case TokenType.dimension:
      case TokenType.letterSpacing:
      case TokenType.paragraphSpacing:
        return {
          isTokenOutOfRange: false,
        }
      case TokenType.size:
      case TokenType.space:
      case TokenType.fontSize:
      case TokenType.lineHeight:
      case TokenType.borderWidth:
      case TokenType.radius:
      case TokenType.duration:
        if (token.value.measure < 0) {
          return {
            isTokenOutOfRange: true,
            errorMessage: `${formatTokenTypeName(
              token.tokenType
            )} token can only ${
              isReference ? "reference" : "contain"
            } values equal to or greater than 0.`,
          }
        }
        return {
          isTokenOutOfRange: false,
        }
      case TokenType.opacity: {
        const measure = DimensionCategoryToken.getCompatibleMeasure(
          token.value.unit,
          token.tokenType,
          token.value.measure
        )
        if (token.value.unit === Unit.raw && (measure < 0 || measure > 1)) {
          return {
            isTokenOutOfRange: true,
            errorMessage: `${formatTokenTypeName(
              token.tokenType
            )} token can only ${
              isReference ? "reference" : "contain"
            } values between 0 and 100.`,
          }
        }
        return {
          isTokenOutOfRange: false,
        }
      }
      case TokenType.zIndex: {
        return {
          isTokenOutOfRange: !Number.isInteger(token.value.measure),
          errorMessage: `${formatTokenTypeName(
            token.tokenType
          )} tokens can only ${
            isReference ? "reference" : "contain"
          } integer values.`,
        }
      }
      default:
        return {
          isTokenOutOfRange: false,
        }
    }
  }

  /** Creates a new token group for a given token type. Default values will be used for all properties */
  static createDefaultTokenGroup(
    type: TokenType,
    versionId: string,
    brandId: string,
    name?: string
  ): TokenGroup {
    return new TokenGroup({
      persistentId: uuidv4(),
      brandId,
      tokenType: type,
      designSystemVersionId: versionId,
      isRoot: false,
      meta: {
        name: name ?? "Group",
        description: "",
      },
      childrenIds: [],
      createdAt: new Date().toISOString(),
    })
  }

  /** Creates a new token theme. Default values will be used for all properties */
  static createDefaultTokenTheme(
    versionId: string,
    brandId: string
  ): TokenTheme {
    return new TokenTheme(
      {
        persistentId: uuidv4(),
        brandId,
        designSystemVersionId: versionId,
        meta: {
          name: "Dark",
          description: "",
        },
        codeName: "dark",
        overrides: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      },
      versionId
    )
  }

  /** Creates a new token for a given token type. Default values will be used for all properties */
  static createDefaultToken<T extends TokenType>(
    type: T,
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): TokenTypeMapToken[T] {
    switch (type) {
      case TokenType.color:
        return this.createDefaultColorToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.typography:
        return this.createDefaultTypographyToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.dimension:
        return this.createDefaultDimensionToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.size:
        return this.createDefaultSizeToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.space:
        return this.createDefaultSpaceToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.opacity:
        return this.createDefaultOpacityToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.fontSize:
        return this.createDefaultFontSizeToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.lineHeight:
        return this.createDefaultLineHeightToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.letterSpacing:
        return this.createDefaultLetterSpacingToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.paragraphSpacing:
        return this.createDefaultParagraphSpacingToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.borderWidth:
        return this.createDefaultBorderWidthToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.radius:
        return this.createDefaultRadiusToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.duration:
        return this.createDefaultDurationToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.zIndex:
        return this.createDefaultZIndexToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.shadow:
        return this.createDefaultShadowToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.border:
        return this.createDefaultBorderToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.gradient:
        return this.createDefaultGradientToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.string:
        return this.createDefaultStringToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.productCopy:
        return this.createDefaultProductCopyToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.fontFamily:
        return this.createDefaultFontFamilyToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.fontWeight:
        return this.createDefaultFontWeightToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.textCase:
        return this.createDefaultTextCaseToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.textDecoration:
        return this.createDefaultTextDecorationToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.visibility:
        return this.createDefaultVisibilityToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      case TokenType.blur:
        return this.createDefaultBlurToken(
          versionId,
          brandId,
          properties
        ) as TokenTypeMapToken[T]
      default:
        // NOTE: a typecheck if the switch is exhaustive
        // if you add a new token type a type error would pop up here
        throw new UnreachableCaseError(type)
    }
  }

  /** Creates a new default color token */
  private static createDefaultColorToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): ColorToken {
    const value = "#FFFFFF"

    return ColorToken.create(
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default typography token */
  private static createDefaultTypographyToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): TypographyToken {
    return TypographyToken.create(
      versionId,
      brandId,
      "Token",
      "",
      typographyTokenDefaultValue,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      new DTTokenReferenceResolver(),
      properties,
      []
    )
  }

  /** Creates a new default typography token */
  private static createDefaultGradientToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): GradientToken {
    const value = "linear-gradient(0deg, #e66465 0%, #9198e5 100%)"

    return GradientToken.create(
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      new DTTokenReferenceResolver(),
      properties,
      []
    )
  }

  /** Creates a new default dimension token */
  private static createDefaultDimensionToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): DimensionToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return DimensionToken.create(
      TokenType.dimension,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.dimension],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default size token */
  private static createDefaultSizeToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): SizeToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return SizeToken.create(
      TokenType.size,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.size],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default space token */
  private static createDefaultSpaceToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): SpaceToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return SpaceToken.create(
      TokenType.space,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.space],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default opacity token */
  private static createDefaultOpacityToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): OpacityToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return OpacityToken.create(
      TokenType.opacity,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.opacity],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default line height token */
  private static createDefaultLineHeightToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): LineHeightToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return LineHeightToken.create(
      TokenType.lineHeight,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.lineHeight],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default letter spacing token */
  private static createDefaultLetterSpacingToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): LetterSpacingToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return LetterSpacingToken.create(
      TokenType.letterSpacing,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.letterSpacing],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default paragraph spacing token */
  private static createDefaultParagraphSpacingToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): ParagraphSpacingToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return ParagraphSpacingToken.create(
      TokenType.paragraphSpacing,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.paragraphSpacing],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default border token */
  private static createDefaultBorderWidthToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): BorderWidthToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return BorderWidthToken.create(
      TokenType.borderWidth,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.borderWidth],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default border radius token */
  private static createDefaultRadiusToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): RadiusToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return RadiusToken.create(
      TokenType.radius,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.radius],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default font size token */
  private static createDefaultFontSizeToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): FontSizeToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return FontSizeToken.create(
      TokenType.fontSize,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.fontSize],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default font family token */
  private static createDefaultFontFamilyToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): FontFamilyToken {
    const value = "Inter"

    // @ts-expect-error TS(2322): Type 'StringCategoryToken<AnyStringTokenValue>' is... Remove this comment to see the full error message
    return FontFamilyToken.create(
      TokenType.fontFamily,
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default font weight token */
  private static createDefaultFontWeightToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): FontWeightToken {
    const value = "Regular"

    // @ts-expect-error TS(2322): Type 'StringCategoryToken<AnyStringTokenValue>' is... Remove this comment to see the full error message
    return FontWeightToken.create(
      TokenType.fontWeight,
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default duration token */
  private static createDefaultDurationToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): DurationToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return DurationToken.create(
      TokenType.duration,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.duration],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default z-index token */
  private static createDefaultZIndexToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): ZIndexToken {
    // @ts-expect-error TS(2322): Type 'DimensionCategoryToken<AnyDimensionTokenValu... Remove this comment to see the full error message
    return ZIndexToken.create(
      TokenType.zIndex,
      versionId,
      brandId,
      "Token",
      "",
      dimensionDefaultValues[TokenType.zIndex],
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default text case token */
  private static createDefaultTextCaseToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): TextCaseToken {
    const value = "Original"

    return TextCaseToken.create(
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default text case token */
  private static createDefaultTextDecorationToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): TextDecorationToken {
    const value = "None"

    return TextDecorationToken.create(
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default visibility token */
  private static createDefaultVisibilityToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): VisibilityToken {
    const value = VisibilityType.visible

    return VisibilityToken.create(
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default generic token */
  private static createDefaultBlurToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): BlurToken {
    return BlurToken.create(
      versionId,
      brandId,
      "Token",
      "",
      blurDefaultValue,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      new DTTokenReferenceResolver(),
      properties,
      []
    )
  }

  /** Creates a new default generic token */
  private static createDefaultStringToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): StringToken {
    const value = ""

    // @ts-expect-error TS(2322): Type 'StringCategoryToken<AnyStringTokenValue>' is... Remove this comment to see the full error message
    return StringToken.create(
      TokenType.string,
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default generic token */
  private static createDefaultProductCopyToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): ProductCopyToken {
    const value = ""

    // @ts-expect-error TS(2322): Type 'StringCategoryToken<AnyStringTokenValue>' is... Remove this comment to see the full error message
    return ProductCopyToken.create(
      TokenType.productCopy,
      versionId,
      brandId,
      "Token",
      "",
      value,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      properties,
      []
    )
  }

  /** Creates a new default generic token */
  private static createDefaultShadowToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): ShadowToken {
    return ShadowToken.create(
      versionId,
      brandId,
      "Token",
      "",
      shadowTokenDefaultValue,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      new DTTokenReferenceResolver(),
      properties,
      []
    )
  }

  /** Creates a new default generic token */
  private static createDefaultBorderToken(
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): BorderToken {
    return BorderToken.create(
      versionId,
      brandId,
      "Token",
      "",
      borderDefaultValue,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined,
      new DTTokenReferenceResolver(),
      properties,
      []
    )
  }

  static replaceIdAcrossAllPossibleReferences(
    override: DTProcessedTokenNode,
    newId: string,
    allTokens: Array<DTProcessedTokenNode>
  ) {
    const currentId = override.token.id

    override.token.id = newId
    this.replaceIdAcrossAllPossibleReferencesWithTokens(
      currentId,
      newId,
      allTokens.map((t) => t.token)
    )
  }

  static replaceIdAcrossAllPossibleReferencesWithTokens(
    currentId: string,
    newId: string,
    allTokens: Array<Token>
  ) {
    const mappedTokens = TokenUtils.tokensToReferenceMap(allTokens)

    for (const t of allTokens) {
      const token = t as AnyToken

      // Generic check for reference
      replaceReferencedTokenIds(token, currentId, newId, mappedTokens)
      TokenUtils.replaceIdForComplexTokens(token, currentId, newId)
    }
  }

  static replaceIdForComplexTokens(
    token: AnyToken,
    currentId: string,
    newId: string
  ) {
    // Specific checks for tokens that can reference other values internally
    if (token instanceof TypographyToken) {
      if (
        token.value.fontWeight?.referencedTokenId &&
        token.value.fontWeight?.referencedTokenId === currentId
      ) {
        token.value.fontWeight.referencedTokenId = newId
      }

      if (
        token.value.fontFamily?.referencedTokenId &&
        token.value.fontFamily?.referencedTokenId === currentId
      ) {
        token.value.fontFamily.referencedTokenId = newId
      }

      if (
        token.value.fontSize?.referencedTokenId &&
        token.value.fontSize?.referencedTokenId === currentId
      ) {
        token.value.fontSize.referencedTokenId = newId
      }

      if (
        token.value.letterSpacing?.referencedTokenId &&
        token.value.letterSpacing?.referencedTokenId === currentId
      ) {
        token.value.letterSpacing.referencedTokenId = newId
      }

      if (
        token.value.lineHeight?.referencedTokenId &&
        token.value.lineHeight?.referencedTokenId === currentId
      ) {
        token.value.lineHeight.referencedTokenId = newId
      }

      if (
        token.value.paragraphIndent?.referencedTokenId &&
        token.value.paragraphIndent?.referencedTokenId === currentId
      ) {
        token.value.paragraphIndent.referencedTokenId = newId
      }

      if (
        token.value.paragraphSpacing?.referencedTokenId &&
        token.value.paragraphSpacing?.referencedTokenId === currentId
      ) {
        token.value.paragraphSpacing.referencedTokenId = newId
      }

      if (
        token.value.textCase?.referencedTokenId &&
        token.value.textCase?.referencedTokenId === currentId
      ) {
        token.value.textCase.referencedTokenId = newId
      }

      if (
        token.value.textDecoration?.referencedTokenId &&
        token.value.textDecoration?.referencedTokenId === currentId
      ) {
        token.value.textDecoration.referencedTokenId = newId
      }
    } else if (token instanceof ShadowToken) {
      for (const tokenValue of token.value) {
        if (
          tokenValue.color?.referencedTokenId &&
          tokenValue.color?.referencedTokenId === currentId
        ) {
          tokenValue.color.referencedTokenId = newId
        }
      }
    } else if (token instanceof BorderToken) {
      if (
        token.value.color?.referencedTokenId &&
        token.value.color?.referencedTokenId === currentId
      ) {
        token.value.color.referencedTokenId = newId
      }

      if (
        token.value.width?.referencedTokenId &&
        token.value.width?.referencedTokenId === currentId
      ) {
        token.value.width.referencedTokenId = newId
      }
    } else if (token instanceof GradientToken) {
      for (const tokenValue of token.value) {
        for (const stop of tokenValue.stops) {
          if (
            stop.color?.referencedTokenId &&
            stop.color?.referencedTokenId === currentId
          ) {
            stop.color.referencedTokenId = newId
          }
        }
      }
    } else if (token instanceof BlurToken) {
      if (
        token.value.radius?.referencedTokenId &&
        token.value.radius?.referencedTokenId === currentId
      ) {
        token.value.radius.referencedTokenId = newId
      }
    }
  }

  /** Creates a new theme with updated overrides */
  static tokenThemeByUpdatingOverride(
    theme: TokenTheme,
    override: AnyToken
  ): TokenTheme {
    const overrides = theme.overriddenTokens
    const filteredOverrides = overrides.filter((o) => o.id !== override.id)

    filteredOverrides.push(override)
    theme.overriddenTokens = filteredOverrides
    return theme
  }

  /** Creates a new theme with removed override */
  static tokenThemeByRemovingOverride(
    theme: TokenTheme,
    tokenId: string
  ): TokenTheme {
    const overrides = theme.overriddenTokens

    const filteredOverrides = overrides.filter(
      (o) => o.id !== tokenId && o.idInVersion !== tokenId
    )

    theme.overriddenTokens = filteredOverrides
    return theme
  }

  /** Creates a new color token with updated .color value */
  static colorTokenByUpdatingColor(
    token: ColorToken,
    newValue: ColorValue | ColorToken
  ): ColorToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    if (newValue instanceof ColorToken) {
      update.value = {
        opacity: update.value.opacity,
        color: {
          r: newValue.value.color.r,
          g: newValue.value.color.g,
          b: newValue.value.color.b,
          referencedTokenId: newValue.value.color.referencedTokenId,
        },
        referencedTokenId: newValue.id,
      }
    } else {
      update.value = {
        opacity: update.value.opacity,
        color: {
          r: newValue.r,
          g: newValue.g,
          b: newValue.b,
          referencedTokenId: newValue.referencedTokenId,
        },
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new color token with updated .opacity value */
  static colorTokenByUpdatingOpacity(
    token: ColorToken,
    newValue: number | OpacityToken,
    checkReference = true
  ): ColorToken {
    if (checkReference) {
      TokenUtils.checkNotReference(token)
    }

    const update = ThemeUtilities.replicateTokenWithValue(token)

    if (newValue instanceof OpacityToken) {
      update.value.opacity = {
        measure: newValue.value.measure,
        unit: newValue.value.unit,
        referencedTokenId: newValue.id,
      }
    } else {
      update.value.opacity = {
        measure: newValue,
        unit: Unit.raw,
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new blur token with updated .type value */
  static blurTokenByUpdatingType(
    token: BlurToken,
    newValue: BlurType
  ): BlurToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.type = newValue

    return update
  }

  /** Creates a new blur token with updated .radius value */
  static blurTokenByUpdatingRadius(
    token: BlurToken,
    // @ts-expect-error TS(2344): Type 'DimensionTokenValue' does not satisfy the co... Remove this comment to see the full error message
    newValue: RawValue<DimensionTokenValue> | RadiusToken
  ): BlurToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.radius = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new typography token with updated .fontFamily value */
  static typographyTokenByUpdatingFontFamily(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'FontFamilyTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<FontFamilyTokenValue> | StringCategoryToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    // it can reference any string token. Possible to achieve from figma plugin.
    if (newValue instanceof StringCategoryToken) {
      update.value.fontFamily = {
        text: newValue.value.text,
        referencedTokenId: newValue.id,
      }
    } else {
      update.value.fontFamily = {
        text: newValue.text,
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new typography token with updated .fontWeight value */
  static typographyTokenByUpdatingFontWeight(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'FontWeightTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<FontWeightTokenValue> | StringCategoryToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    // it can reference any string token. Possible to achieve from figma plugin.
    if (newValue instanceof StringCategoryToken) {
      update.value.fontWeight = {
        text: newValue.value.text,
        referencedTokenId: newValue.id,
      }
    } else {
      update.value.fontWeight = {
        text: newValue.text,
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new typography token with updated .fontSize value */
  static typographyTokenByUpdatingFontSize(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'FontSizeTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<FontSizeTokenValue> | FontSizeToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.fontSize = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new typography token with updated .letterSpacing value */
  static typographyTokenByUpdatingLetterSpacing(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'LetterSpacingTokenValue' does not satisfy th... Remove this comment to see the full error message
    newValue: RawValue<LetterSpacingTokenValue> | LetterSpacingToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.letterSpacing = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new typography token with updated .lineHeight value */
  static typographyTokenByUpdatingLineHeight(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'LineHeightTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<LineHeightTokenValue> | LineHeightToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.lineHeight = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new typography token with updated .paragraphSpacing value */
  static typographyTokenByUpdatingParagraphSpacing(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'ParagraphSpacingTokenValue' does not satisfy... Remove this comment to see the full error message
    newValue: RawValue<ParagraphSpacingTokenValue> | ParagraphSpacingToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.paragraphSpacing = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new typography token with updated .paragraphSpacing value */
  static typographyTokenByUpdatingParagraphIndent(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'ParagraphSpacingTokenValue' does not satisfy... Remove this comment to see the full error message
    newValue: RawValue<ParagraphSpacingTokenValue> | ParagraphSpacingToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.paragraphIndent = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new typography token with updated .textCase value */
  static typographyTokenByUpdatingTextCase(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'TextCaseTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<TextCaseTokenValue> | TextCaseToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    if (newValue instanceof TextCaseToken) {
      update.value.textCase = {
        value: newValue.value.value,
        options: newValue.value.options,
        referencedTokenId: newValue.id,
      }
    } else {
      update.value.textCase = {
        value: newValue.value,
        options: newValue.options,
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new border token with updated .position value */
  static typographyTokenByUpdatingTextDecoration(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'TextDecorationTokenValue' does not satisfy t... Remove this comment to see the full error message
    newValue: RawValue<TextDecorationTokenValue> | TextDecorationToken
  ): TypographyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    if (newValue instanceof TextDecorationToken) {
      update.value.textDecoration = {
        value: newValue.value.value,
        options: newValue.value.options,
        referencedTokenId: newValue.id,
      }
    } else {
      update.value.textDecoration = {
        value: newValue.value,
        options: newValue.options,
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new border token with updated .color value. Do note, opacity is ignored */
  static borderTokenByUpdatingColor(
    token: BorderToken,
    newValue: ColorValue | ColorToken
  ): BorderToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    if (newValue instanceof ColorToken) {
      update.value.color = {
        opacity: update.value.color.opacity,
        color: {
          r: newValue.value.color.r,
          g: newValue.value.color.g,
          b: newValue.value.color.b,
          referencedTokenId: newValue.value.color.referencedTokenId,
        },
        referencedTokenId: newValue.id,
      }
    } else {
      update.value.color = {
        opacity: update.value.color.opacity,
        color: {
          r: newValue.r,
          g: newValue.g,
          b: newValue.b,
          referencedTokenId: newValue.referencedTokenId,
        },
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new border token with updated color.opacity value */
  static borderTokenByUpdatingOpacity(
    token: BorderToken,
    newValue: number | OpacityToken
  ): BorderToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    if (newValue instanceof OpacityToken) {
      update.value.color.opacity = {
        measure: newValue.value.measure,
        unit: newValue.value.unit,
        referencedTokenId: newValue.id,
      }
    } else {
      update.value.color.opacity = {
        measure: newValue,
        unit: Unit.raw,
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new border token with updated .width value */
  static borderTokenByUpdatingWidth(
    token: BorderToken,
    // @ts-expect-error TS(2344): Type 'BorderWidthTokenValue' does not satisfy the ... Remove this comment to see the full error message
    newValue: RawValue<BorderWidthTokenValue> | BorderWidthToken
  ): BorderToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.width = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new border token with updated .position value */
  static borderTokenByUpdatingPosition(
    token: BorderToken,
    newValue: BorderPosition
  ): BorderToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.position = newValue

    return update
  }

  /** Creates a new border token with updated .style value */
  static borderTokenByUpdatingStyle(
    token: BorderToken,
    newValue: BorderStyle
  ): BorderToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value.style = newValue

    return update
  }

  /** Creates a new dimension token with updated value */
  static dimensionTokenByUpdatingValue(
    token: DimensionToken,
    // @ts-expect-error TS(2344): Type 'DimensionTokenValue' does not satisfy the co... Remove this comment to see the full error message
    newValue: RawValue<DimensionTokenValue>
  ): DimensionToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new size token with updated value */
  static sizeTokenByUpdatingValue(
    token: SizeToken,
    // @ts-expect-error TS(2344): Type 'SizeTokenValue' does not satisfy the constra... Remove this comment to see the full error message
    newValue: RawValue<SizeTokenValue>
  ): SizeToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new space token with updated value */
  static spaceTokenByUpdatingValue(
    token: SpaceToken,
    // @ts-expect-error TS(2344): Type 'SpaceTokenValue' does not satisfy the constr... Remove this comment to see the full error message
    newValue: RawValue<SpaceTokenValue>
  ): SpaceToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new opacity token with updated value */
  static opacityTokenByUpdatingValue(
    token: OpacityToken,
    // @ts-expect-error TS(2344): Type 'OpacityTokenValue' does not satisfy the cons... Remove this comment to see the full error message
    newValue: RawValue<OpacityTokenValue>
  ): OpacityToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new font size token with updated value */
  static fontSizeTokenByUpdatingValue(
    token: FontSizeToken,
    // @ts-expect-error TS(2344): Type 'FontSizeTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<FontSizeTokenValue>
  ): FontSizeToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new font size token with updated value */
  static lineHeightTokenByUpdatingValue(
    token: LineHeightToken,
    // @ts-expect-error TS(2344): Type 'LineHeightTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<LineHeightTokenValue>
  ): LineHeightToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new letter spacing token with updated value */
  static letterSpacingTokenByUpdatingValue(
    token: LetterSpacingToken,
    // @ts-expect-error TS(2344): Type 'LetterSpacingTokenValue' does not satisfy th... Remove this comment to see the full error message
    newValue: RawValue<LetterSpacingTokenValue>
  ): LetterSpacingToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new paragraph spacing token with updated value */
  static paragraphSpacingTokenByUpdatingValue(
    token: ParagraphSpacingToken,
    // @ts-expect-error TS(2344): Type 'ParagraphSpacingTokenValue' does not satisfy... Remove this comment to see the full error message
    newValue: RawValue<ParagraphSpacingTokenValue>
  ): ParagraphSpacingToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new border width token with updated value */
  static borderWidthTokenByUpdatingValue(
    token: BorderWidthToken,
    // @ts-expect-error TS(2344): Type 'BorderWidthTokenValue' does not satisfy the ... Remove this comment to see the full error message
    newValue: RawValue<BorderWidthTokenValue>
  ): BorderWidthToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new radius token with updated value */
  static radiusTokenByUpdatingValue(
    token: RadiusToken,
    // @ts-expect-error TS(2344): Type 'RadiusTokenValue' does not satisfy the const... Remove this comment to see the full error message
    newValue: RawValue<RadiusTokenValue>
  ): RadiusToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new duration token with updated value */
  static durationTokenByUpdatingValue(
    token: DurationToken,
    // @ts-expect-error TS(2344): Type 'DurationTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<DurationTokenValue>
  ): DurationToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new z-index token with updated value */
  static zIndexTokenByUpdatingValue(
    token: ZIndexToken,
    // @ts-expect-error TS(2344): Type 'ZIndexTokenValue' does not satisfy the const... Remove this comment to see the full error message
    newValue: RawValue<ZIndexTokenValue>
  ): ZIndexToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedDimensionValue(newValue)

    return update
  }

  /** Creates a new string token with updated value */
  static stringTokenByUpdatingValue(
    token: StringToken,
    // @ts-expect-error TS(2344): Type 'StringTokenValue' does not satisfy the const... Remove this comment to see the full error message
    newValue: RawValue<StringTokenValue>
  ): StringToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedStringValue(newValue)

    return update
  }

  /** Creates a new product copy token with updated value */
  static productCopyTokenByUpdatingValue(
    token: ProductCopyToken,
    // @ts-expect-error TS(2344): Type 'ProductCopyTokenValue' does not satisfy the ... Remove this comment to see the full error message
    newValue: RawValue<ProductCopyTokenValue>
  ): ProductCopyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedStringValue(newValue)

    return update
  }

  /** Creates a new font family token with updated value */
  static fontFamilyTokenByUpdatingValue(
    token: FontFamilyToken,
    // @ts-expect-error TS(2344): Type 'FontFamilyTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<FontFamilyTokenValue>
  ): FontFamilyToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedStringValue(newValue)

    return update
  }

  /** Creates a new font weight token with updated value */
  static fontWeightTokenByUpdatingValue(
    token: FontWeightToken,
    // @ts-expect-error TS(2344): Type 'FontWeightTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<FontWeightTokenValue>
  ): FontWeightToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = this.rawOrReferencedStringValue(newValue)

    return update
  }

  /** Creates a new text case token with updated value */
  static textCaseTokenByUpdatingValue(
    token: TextCaseToken,
    // @ts-expect-error TS(2344): Type 'TextCaseTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<TextCaseTokenValue>
  ): TextCaseToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = {
      value: newValue.value,
      options: newValue.options,
      referencedTokenId: null,
    }

    return update
  }

  /** Creates a new text case token with updated value */
  static textDecorationTokenByUpdatingValue(
    token: TextDecorationToken,
    // @ts-expect-error TS(2344): Type 'TextDecorationTokenValue' does not satisfy t... Remove this comment to see the full error message
    newValue: RawValue<TextDecorationTokenValue>
  ): TextDecorationToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = {
      value: newValue.value,
      options: newValue.options,
      referencedTokenId: null,
    }

    return update
  }

  /** Creates a new visibility token with updated value */
  static visibilityTokenByUpdatingValue(
    token: VisibilityToken,
    // @ts-expect-error TS(2344): Type 'VisibilityTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<VisibilityTokenValue>
  ): VisibilityToken {
    this.checkNotReference(token)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = {
      value: newValue.value,
      options: newValue.options,
      referencedTokenId: null,
    }

    return update
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Multi-layer token manipulation

  /** Creates a new shadow token with one new extra layer added at the end of all layers */
  static shadowTokenByAddingLayer(token: ShadowToken): ShadowToken {
    const update = ThemeUtilities.replicateTokenWithValue(token)

    const layer: ShadowTokenValue = {
      color: {
        color: {
          r: 0,
          g: 0,
          b: 0,
          referencedTokenId: null,
        },
        opacity: {
          measure: 1,
          unit: Unit.raw,
          referencedTokenId: null,
        },
        referencedTokenId: null,
      },
      opacity: {
        measure: 1,
        unit: Unit.raw,
        referencedTokenId: null,
      },
      radius: 0,
      spread: 0,
      type: ShadowType.drop,
      x: 0,
      y: 4,
      referencedTokenId: null,
    }

    update.value.push(layer)

    return update
  }

  /** Creates a new shadow token by removing one of the layers */
  static shadowTokenByRemovingLayer(
    token: ShadowToken,
    layerIndex: number
  ): ShadowToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)

    update.value = update.value.splice(layerIndex, 1)

    return update
  }

  /** Creates a new shadow token with updated .color value. Do note, opacity is ignored */
  static shadowTokenByUpdatingColor(
    token: ShadowToken,
    layerIndex: number,
    newValue: ColorValue | ColorToken
  ): ShadowToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    if (newValue instanceof ColorToken) {
      layer.color = {
        opacity: layer.color.opacity,
        color: {
          r: newValue.value.color.r,
          g: newValue.value.color.g,
          b: newValue.value.color.b,
          referencedTokenId: newValue.value.color.referencedTokenId,
        },
        referencedTokenId: newValue.id,
      }
    } else {
      layer.color = {
        opacity: layer.color.opacity,
        color: {
          r: newValue.r,
          g: newValue.g,
          b: newValue.b,
          referencedTokenId: newValue.referencedTokenId,
        },
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new shadow token with updated .color value */
  static shadowTokenByUpdatingOpacity(
    token: ShadowToken,
    layerIndex: number,
    newValue: number | OpacityToken
  ): ShadowToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    if (newValue instanceof OpacityToken) {
      layer.opacity = {
        measure: newValue.value.measure,
        unit: newValue.value.unit,
        referencedTokenId: newValue.id,
      }
    } else {
      layer.opacity = {
        measure: newValue,
        unit: Unit.raw,
        referencedTokenId: null,
      }
    }

    return update
  }

  /** Creates a new shadow token with updated .x value */
  static shadowTokenByUpdatingX(
    token: ShadowToken,
    layerIndex: number,
    newValue: number
  ): ShadowToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.x = newValue

    return update
  }

  /** Creates a new shadow token with updated .y value */
  static shadowTokenByUpdatingY(
    token: ShadowToken,
    layerIndex: number,
    newValue: number
  ): ShadowToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.y = newValue

    return update
  }

  /** Creates a new shadow token with updated .radius value */
  static shadowTokenByUpdatingRadius(
    token: ShadowToken,
    layerIndex: number,
    newValue: number
  ): ShadowToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.radius = newValue

    return update
  }

  /** Creates a new shadow token with updated .spread value */
  static shadowTokenByUpdatingSpread(
    token: ShadowToken,
    layerIndex: number,
    newValue: number
  ): ShadowToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.spread = newValue

    return update
  }

  /** Creates a new shadow token with updated .type value */
  static shadowTokenByUpdatingType(
    token: ShadowToken,
    layerIndex: number,
    newValue: ShadowType
  ): ShadowToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.type = newValue

    return update
  }

  /** Creates a new gradient token with updated .to value */
  static gradientTokenByUpdatingTo(
    token: GradientToken,
    layerIndex: number,
    newValue: { x: number; y: number }
  ): GradientToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.to = newValue

    return update
  }

  /** Creates a new gradient token with updated .from value */
  static gradientTokenByUpdatingFrom(
    token: GradientToken,
    layerIndex: number,
    newValue: { x: number; y: number }
  ): GradientToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.from = newValue

    return update
  }

  /** Creates a new gradient token with updated .to value */
  static gradientTokenByUpdatingType(
    token: GradientToken,
    layerIndex: number,
    newValue: GradientType
  ): GradientToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.type = newValue

    return update
  }

  /** Creates a new gradient token with updated .aspectRatio value */
  static gradientTokenByUpdatingAspectRatio(
    token: GradientToken,
    layerIndex: number,
    newValue: number
  ): GradientToken {
    this.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    layer.aspectRatio = newValue

    return update
  }

  /** Creates a new gradient token with updated .to value */
  static gradientTokenByUpdatingStops(
    token: GradientToken,
    layerIndex: number,
    newValue: Array<{
      position: number
      color: ColorTokenValue | ColorToken
    }>
  ): GradientToken {
    TokenUtils.validateLayerAccess(token, layerIndex)

    const update = ThemeUtilities.replicateTokenWithValue(token)
    const layer = sureOf(update.value[layerIndex])

    const stops: Array<GradientStopValue> = new Array<GradientStopValue>()

    for (const v of newValue) {
      if (v.color instanceof ColorToken) {
        const stop: GradientStopValue = {
          position: v.position,
          color: {
            opacity: v.color.value.opacity,
            color: {
              r: v.color.value.color.r,
              g: v.color.value.color.g,
              b: v.color.value.color.b,
              referencedTokenId: v.color.value.color.referencedTokenId,
            },
            referencedTokenId: v.color.id,
          },
        }

        stops.push(stop)
      } else {
        const stop: GradientStopValue = {
          position: v.position,
          color: {
            opacity: v.color.opacity,
            color: {
              r: v.color.color.r,
              g: v.color.color.g,
              b: v.color.color.b,
              referencedTokenId: v.color.color.referencedTokenId,
            },
            referencedTokenId: null,
          },
        }

        stops.push(stop)
      }
    }

    layer.stops = stops

    return update
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Manipulating references

  /** Creates a new token where value is reference to the provided token. Token and reference must be of the same type */
  static tokenByReferencing<T extends AnyToken>(token: T, reference: T): T {
    const update = ThemeUtilities.replicateTokenWithoutValue(token)

    if (token.id === reference.id) {
      throw SupernovaError.fromMessage(
        "Can't create reference where token would reference itself"
      )
    }

    update.value = {
      ...cloneDeep(reference.value),
      referencedTokenId: reference.id,
    }

    return update
  }

  /** Creates a new multilayer token where one of the layers is reference to the provided token. Token and reference must be of the same type */
  static multilayerTokenByReferencing<T extends AnyMultiLayerToken>(
    token: T,
    layerIndex: number,
    reference: T
  ): T {
    const update = ThemeUtilities.replicateTokenWithValue(token)

    if (token.id === reference.id) {
      throw SupernovaError.fromMessage(
        "Can't create reference where token would reference itself"
      )
    }

    update.value[layerIndex] = {
      ...cloneDeep(sureOf(reference.value[layerIndex])),
      referencedTokenId: reference.id,
    }

    return update
  }

  /** Creates a new token where value is raw, but taken from the previously referenced token. Token must have had reference before */
  static tokenByDisconnectingReference<T extends AnySingleLayerToken>(
    token: T,
    options: {
      supressErrorOnNoReference?: boolean
    } = {}
  ): T {
    const update = ThemeUtilities.replicateTokenWithoutValue(token)

    if (!token.value.referencedTokenId && !options.supressErrorOnNoReference) {
      throw SupernovaError.fromMessage(
        "Can't create raw token from a token that isn't previously a reference"
      )
    }

    update.value = {
      ...cloneDeep(token.value),
      referencedTokenId: null,
    }

    return update
  }

  /** Creates a new multilayer token where one of the layers value is raw, but taken from the previously referenced token. That layer must have had reference before */
  static multilayerTokenByDisconnectingReference<T extends AnySingleLayerToken>(
    token: T,
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    layerIndex: number
  ): T {
    const update = ThemeUtilities.replicateTokenWithoutValue(token)

    if (!token.value.referencedTokenId) {
      throw SupernovaError.fromMessage(
        "Can't create raw token from a token that isn't previously a reference"
      )
    }

    // TODO: Multilayer shadow & gradient disconnect of the references

    return update
  }

  /** Checks if token as a whole is a reference to another token */
  static isReferencedToken(token: AnySingleLayerToken): boolean {
    return (
      token.value.referencedTokenId !== null &&
      token.value.referencedTokenId !== undefined
    )
  }

  /** Checks if a token layer of multilayer token is a reference to another token */
  static isReferencedMultilayerTokenLayer(
    token: AnyMultiLayerToken,
    layerIndex: number
  ): boolean {
    this.validateLayerAccess(token, layerIndex)

    const layer = sureOf(token.value[layerIndex])

    return (
      layer.referencedTokenId !== null && layer.referencedTokenId !== undefined
    )
  }

  /** Checks if a token was imported from some foreign source. Not imported tokens were always created in Supernova */
  static isTokenImported(token: AnyToken): boolean {
    return (
      token.origin?.sourceId !== null && token.origin?.sourceId !== undefined
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Conveniences

  private static checkNotReference(token: AnySingleLayerToken) {
    if (token.value.referencedTokenId) {
      throw SupernovaError.fromMessage(
        "Performing value-replated operation on referenced token is not allowed"
      )
    }
  }

  private static rawOrReferencedDimensionValue<T extends DimensionTokenValue>(
    // @ts-expect-error TS(2344): Type 'T' does not satisfy the constraint '{ refere... Remove this comment to see the full error message
    newValue: RawValue<T> | DimensionCategoryToken<AnyDimensionTokenValue>
  ): T {
    if (newValue instanceof DimensionCategoryToken) {
      return {
        measure: newValue.value.measure,
        unit: newValue.value.unit,
        referencedTokenId: newValue.id,
      } as T
    }

    return {
      measure: newValue.measure,
      unit: newValue.unit,
      referencedTokenId: null,
    } as T
  }

  private static rawOrReferencedStringValue<T extends StringTokenValue>(
    // @ts-expect-error TS(2344): Type 'T' does not satisfy the constraint '{ refere... Remove this comment to see the full error message
    newValue: RawValue<T> | StringCategoryToken<AnyStringTokenValue>
  ): T {
    if (newValue instanceof StringCategoryToken) {
      return {
        text: newValue.value.text,
        referencedTokenId: newValue.id,
      } as T
    }

    return {
      text: newValue.text,
      referencedTokenId: null,
    } as T
  }

  static validateLayerAccess(token: AnyMultiLayerToken, index: number) {
    let layers: Array<any>

    if (token instanceof ShadowToken) {
      layers = token.shadowLayers
    } else {
      layers = token.gradientLayers
    }

    if (index < 0 || index >= layers.length) {
      throw SupernovaError.fromMessage(
        `Can't access layer at index ${index} for ${token.id}, layer outside of allowed bounds`
      )
    }
  }

  static inlineValueIntoComplexTokens(
    token: AnyToken,
    candidateId: string,
    candidateValueToInline: AnyTokenValue
  ) {
    switch (token.tokenType) {
      case TokenType.blur: {
        const t = token
        if (t?.value?.radius?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.radius = candidateValueToInline as any
        }
        break
      }
      case TokenType.border: {
        const t = token
        if (t?.value?.color?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.color = candidateValueToInline as any
        }
        if (t?.value?.width?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.width = candidateValueToInline as any
        }
        break
      }
      case TokenType.gradient: {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (const tv of token?.value) {
          const stops = tv?.stops ?? []
          for (const stop of stops) {
            if (stop?.color?.referencedTokenId === candidateId) {
              // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              stop.color = candidateValueToInline as any
            }
          }
        }
        break
      }
      case TokenType.shadow: {
        const t = token
        for (const tv of t.value) {
          if (tv?.opacity?.referencedTokenId === candidateId) {
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            tv.opacity = candidateValueToInline as any
          }
          if (tv?.color?.referencedTokenId === candidateId) {
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            tv.color = candidateValueToInline as any
          }
        }
        break
      }
      case TokenType.typography: {
        const t = token
        if (t?.value?.fontSize?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.fontSize = candidateValueToInline as any
        }
        if (t?.value?.letterSpacing?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.letterSpacing = candidateValueToInline as any
        }
        if (t?.value?.lineHeight?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.lineHeight = candidateValueToInline as any
        }
        if (t?.value?.paragraphIndent?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.paragraphIndent = candidateValueToInline as any
        }
        if (t?.value?.paragraphSpacing?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.paragraphSpacing = candidateValueToInline as any
        }
        if (t?.value?.fontFamily?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.fontFamily = candidateValueToInline as any
        }
        if (t?.value?.fontWeight?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.fontWeight = candidateValueToInline as any
        }
        if (t?.value?.textCase?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.textCase = candidateValueToInline as any
        }
        if (t?.value?.textDecoration?.referencedTokenId === candidateId) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          t.value.textDecoration = candidateValueToInline as any
        }
        break
      }
    }
  }

  static getRefIdsFromComplexTokens(token: AnyToken) {
    switch (token.tokenType) {
      case TokenType.blur:
        return [token.value?.radius?.referencedTokenId]
      case TokenType.border: {
        const value = token?.value
        const refs = [value?.color, value?.width]
        return refs.map((r) => r?.referencedTokenId).filter(Boolean)
      }
      case TokenType.gradient: {
        const stops = flatMap(token?.value ?? [], (tv) => tv?.stops ?? [])
        return stops.map((r) => r?.color?.referencedTokenId).filter(Boolean)
      }
      case TokenType.shadow: {
        const refs = flatMap(token?.value ?? [], (tv) => [
          tv?.opacity,
          tv?.color,
        ])
        return refs.map((r) => r?.referencedTokenId).filter(Boolean)
      }
      case TokenType.typography: {
        const value = token?.value
        const refs = [
          value?.fontSize,
          value?.letterSpacing,
          value?.lineHeight,
          value?.paragraphIndent,
          value?.paragraphSpacing,
          value?.fontFamily,
          value?.fontWeight,
          value?.textCase,
          value?.textDecoration,
        ]
        return refs.map((r) => r?.referencedTokenId).filter(Boolean)
      }
      default:
        return []
    }
  }

  static buildKey(path: Array<string>, name: string): string {
    return [...path, name]
      .join("/")
      .toLowerCase()
      .replaceAll(" ", "-")
      .replaceAll("(", "")
      .replaceAll(")", "")
  }

  static buildReference(path: Array<string>, name: string): string {
    return `{${[...path.slice(1), name].join(".")}}`
  }

  static buildPath(token: Token, groups: Array<TokenGroup>): Array<string> {
    if (!token.parentGroupId) {
      throw new Error("Keys can only be built for tokens that have parents")
    }

    let parent = groups.find((g) => g.id === token.parentGroupId)
    let segments: any = []

    while (parent) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      segments = [parent.name, ...segments]

      const parentId = parent.parentGroupId

      parent = parentId ? groups.find((g) => g.id === parentId) : undefined
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return segments
  }

  static mapTypeToPrefix = (type: TokenType) => {
    switch (type) {
      // Font Size as 2nd fallback
      case TokenType.size:
        return "sizing"
      case TokenType.fontSize:
        return "font-sizing"
      case TokenType.lineHeight:
        return "line-height"
      case TokenType.letterSpacing:
        return "letter-spacing"
      case TokenType.paragraphSpacing:
        return "paragraph-spacing"
      case TokenType.borderWidth:
        return "border-width"
      // Space as 2nd fallback
      case TokenType.space:
        return "spacing"
      case TokenType.radius:
        return "border-radius"
      case TokenType.fontFamily:
        return "font-family"
      case TokenType.fontWeight:
        return "font-weight"

      case TokenType.textCase:
        return "text-case"
      case TokenType.textDecoration:
        return "text-decoration"
      case TokenType.visibility:
        return "visibility"
      default:
        return type.toLowerCase().replaceAll(" ", "-")
    }
  }

  static mapTypeToPrefixBack = (type: TokenType) => {
    switch (type) {
      // Font Size as 2nd fallback
      case TokenType.size:
        return "sizing"
      case TokenType.fontSize:
        return "font-sizing"
      case TokenType.lineHeight:
        return "line-height"
      case TokenType.letterSpacing:
        return "letter-spacing"
      case TokenType.paragraphSpacing:
        return "paragraph-spacing"
      case TokenType.borderWidth:
        return "border-width"
      // Space as 2nd fallback
      case TokenType.space:
        return "spacing"
      case TokenType.radius:
        return "radius"
      case TokenType.fontFamily:
        return "font-family"
      case TokenType.fontWeight:
        return "font-weight"
      default:
        return type.toLowerCase().replaceAll(" ", "-")
    }
  }

  static mapTypeToPrefix2 = (type: TokenType) => {
    switch (type) {
      // Font Size as 2nd fallback
      case TokenType.fontSize:
        return "font-size"
      case TokenType.space:
        return "space"
      default:
        return type.toLowerCase().replaceAll(" ", "-")
    }
  }

  static buildFallbackKey(
    node: Pick<DTProcessedTokenNode, "path" | "token">
  ): string {
    return this.buildKey(node.path, node.token.name)
      .replace(/^measure\//, `${this.mapTypeToPrefix(node.token.tokenType)}/`)
      .replace(/^generic\//, `${this.mapTypeToPrefix(node.token.tokenType)}/`)
      .replace(/^radius\//, `${this.mapTypeToPrefix(node.token.tokenType)}/`)
  }

  static buildFallbackKeyBack(
    node: Pick<DTProcessedTokenNode, "path" | "token">
  ): string {
    return this.buildKey(node.path, node.token.name)
      .replace(
        /^(dimension|font-size|font-sizing|line-height|letter-spacing|paragraph-spacing|spacing|space|opacity|size|sizing|border-width)\//,
        "measure/"
      )
      .replace(/^(font-family|font-weight)\//, "generic/")
      .replace(/^(border-radius)\//, "radius/")
  }

  static buildFallbackKey2(
    node: Pick<DTProcessedTokenNode, "path" | "token">
  ): string {
    return this.buildKey(node.path, node.token.name)
      .replace(/^measure\//, `${this.mapTypeToPrefix2(node.token.tokenType)}/`)
      .replace(/^generic\//, `${this.mapTypeToPrefix(node.token.tokenType)}/`)
      .replace(/^radius\//, `${this.mapTypeToPrefix(node.token.tokenType)}/`)
  }

  static getTokenByKeyOrFallbackKeys<T extends DTProcessedTokenNode | Token>(
    node: Pick<DTProcessedTokenNode, "path" | "token">,
    map: Map<string, T>
  ): T | undefined {
    const keys = TokenUtils.getKeysByKeyOrFallbackKeys(node)
    return map.get(keys.find((key) => !!map.get(key)) ?? "")
  }

  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getKeysByKeyOrFallbackKeys<T extends DTProcessedTokenNode | Token>(
    node: Pick<DTProcessedTokenNode, "path" | "token">
  ): string[] {
    return [
      ...new Set([
        TokenUtils.buildKey(node.path, node.token.name),
        TokenUtils.buildFallbackKey(node),
        TokenUtils.buildFallbackKey2(node),
        // FIXMENOW: Verify and test
        TokenUtils.buildFallbackKeyBack(node),
      ]),
    ]
  }

  static getTokenByKeyOrBackwardKey(
    node: Pick<DTProcessedTokenNode, "path" | "token">,
    map: Map<string, DTProcessedTokenNode>
  ): DTProcessedTokenNode | undefined {
    const key = TokenUtils.buildKey(node.path, node.token.name)
    const fallbackKey = TokenUtils.buildFallbackKeyBack(node)
    return map.get(key) ?? map.get(fallbackKey)
  }

  static getTextCaseTokenValue(value: TextCase) {
    switch (value) {
      case TextCase.upper:
        return "Uppercase"
      case TextCase.lower:
        return "Lowercase"
      case TextCase.camel:
        return "Capitalize"
      case TextCase.smallCaps:
        return "Small caps"
      case TextCase.original:
        return "Original"
      default:
        throw new Error("Unknown text case")
    }
  }
}
