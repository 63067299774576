import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockBehaviorDataType } from "../../../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../../../enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.component-checklist-all"
const name = "Component overview table"
const icon =
  "https://cdn-assets.supernova.io/blocks/icons/component-overview-table.svg"
const descriptionShort = "Show an overview of all components"
const keywords = ["components", "health", "overview", "status"]
const descriptionFull = "Show an overview of all your components"
const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/component/component-overview-table-W8eOtJ5t"

/**
 * 2. Block properties
 */
const properties = {
  components: b.componentProperty({
    id: "components",
    name: "Components",
    options: {
      renderLayoutAs: "Table",
    },
  }),
  showLastUpdatedColumn: b.booleanProperty({
    id: "showLastUpdatedColumn",
    name: "Show last updated column",
    options: {
      defaultValue: true,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.components, properties.showLastUpdatedColumn],
  image:
    "https://cdn-assets.supernova.io/blocks/variants/component-overview-table.svg",
  appearance: {
    isEditorPresentationDifferent: true,
  },
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.components, properties.showLastUpdatedColumn],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.dataBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.component,
  description: descriptionShort,
  searchKeywords: keywords,
  dataType: DocsBlockBehaviorDataType.component,
  selectionType: DocsBlockBehaviorSelectionType.group, // Until we will support component groups, this is the version of "all" for now
  maxSelection: 1,
  item: blockItem,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as ComponentChecklistAll }
