//
//  SDKTextToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2021 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
import { UnreachableCaseError } from "../../utils/UnreachableCaseError"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { TextDecoration } from "../enums/SDKTextDecoration"
import { OptionTokenType, TokenType } from "../enums/SDKTokenType"

import {
  TextCaseTokenRemoteModel,
  TextDecorationTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { TextDecorationTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { TextCaseToken } from "./SDKTextCaseToken"
import { Token } from "./SDKToken"
import {
  AnyOptionToken,
  AnyOptionTokenValue,
  TextDecorationTokenValue,
} from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class TextDecorationToken extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: TextDecorationTokenValue

  tokenType: TokenType.textDecoration = TokenType.textDecoration

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: TextDecorationTokenValue,
    alias: TextDecorationToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value

    if (alias) {
      this.value.referencedTokenId = alias.id
    }
  }

  static create(
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: string,
    alias: TextDecorationToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): TextDecorationToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type: TokenType.textDecoration,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      const tokenValue = this.textDecorationFromDefinition(value)

      return new this(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const tokenValue = {
        value: alias.value?.value,
        options: alias.value?.options,
        referencedTokenId: alias.id,
      }

      return new this(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "Text Decoration Token must be created using value or alias, but none was provided"
    )
  }

  static textDecorationFromDefinition(textDecoration: string) {
    let textDecorationValue = TextDecoration.original

    if (textDecoration && typeof textDecoration === "string") {
      if (textDecoration === "underline") {
        textDecorationValue = TextDecoration.underline
      } else if (
        textDecoration === "strikethrough" ||
        textDecoration === "line-through"
      ) {
        textDecorationValue = TextDecoration.strikethrough
      } else {
        textDecorationValue = TextDecoration.original
      }
    } else {
      textDecorationValue = TextDecoration.original
    }

    return {
      value: textDecorationValue,
      referencedTokenId: null,
    }
  }

  static optionValueFromDefinitionOrReference(
    definition: any,
    referenceResolver: DTTokenReferenceResolver,
    tokenType: OptionTokenType
  ): AnyOptionTokenValue {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (referenceResolver.valueHasReference(definition)) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (!referenceResolver.isBalancedReference(definition)) {
        // Internal syntax of reference corrupted
        throw new Error(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Invalid reference syntax in token value: ${definition}`
        )
      }

      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (referenceResolver.valueIsPureReference(definition)) {
        // When color is pure reference, we can immediately resolve it
        const reference = referenceResolver.lookupReferencedToken(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          definition
        ) as AnyOptionToken

        if (!reference) {
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'AnyOpt... Remove this comment to see the full error message
          return undefined
        }

        return {
          value: reference.value.value,
          options: reference.value.options,
          referencedTokenId: reference.id,
        }
      }

      // When color is not a pure reference, we must resolve it further before we can resolve it
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const references = referenceResolver.lookupAllReferencedTokens(definition)

      if (!references) {
        // Still unable to solve the reference, continue looking in some other tokens
        // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'AnyOpt... Remove this comment to see the full error message
        return undefined
      }

      // Resolved all internal references
      const resolvedValue = referenceResolver.replaceAllReferencedTokens(
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        definition,
        references
      )

      return this.optionValueFromDefinition(resolvedValue, tokenType)
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.optionValueFromDefinition(definition, tokenType)
  }

  static optionValueFromDefinition(
    definition: string,
    type: OptionTokenType
  ): AnyOptionTokenValue {
    switch (type) {
      case TokenType.textDecoration:
        return TextDecorationToken.textDecorationFromDefinition(definition)
      case TokenType.textCase:
        return TextCaseToken.textCaseFromDefinition(definition)
      case TokenType.visibility:
        return {
          value: definition || "",
          referencedTokenId: null,
        }
      default:
        throw new UnreachableCaseError(type)
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Write

  toWriteObject(): TextCaseTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as TextDecorationTokenRemoteModel

    specificData.data = TextDecorationToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: TextDecorationTokenValue): {
    aliasTo: string | undefined
    value: TextDecorationTokenRemoteValue
  } {
    const valueObject = !value.referencedTokenId ? value.value : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      value: valueObject,
    }
  }
}
