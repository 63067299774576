//
//  SDKSupernovaError.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type SupernovaFieldValidationError = {
  key: string
  message: string | null
  code: string | null
}

export type SupernovaBackendError = {
  message: string // Readable error message
  errorCode?: string // Unique id of error. When trying to detect error, please use this field.
  label?: string // Another error id, sometimes there is, sometimes there isn't
  reason?: string // Some error tag
  code: number // 404
  requestId: string // Unique ID of the request
  serverTime: string // Time on the server in ISO format
  formErrors?: Array<SupernovaFieldValidationError> // Possible form errors
}

const SupernovaNoNetworkResponse = "NO_NETWORK"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

/** Supernova-enhanced error. Contains both machine and user readable information about error */
export class SupernovaError extends Error {
  /** Status within 2xx - 5xx range */
  status: number | null

  /** Machine-processable label error unique to each error message thrown by Supernova backend */
  code: string | null

  /** Possible form errors that were thrown on POST/PUT requests. Empty when no form failed validation */
  formErrors: Array<SupernovaFieldValidationError>

  /** Unique ID of the request */
  requestId: string | null

  /** If error came from the network, it will also contain URL */
  url: string | null

  /** Time on the server in ISO format */
  serverTime: string | null

  private constructor(
    message: string,
    code: string | null,
    status: number | null,
    formErrors: Array<SupernovaFieldValidationError> | null,
    requestId: string | null,
    serverTime: string | null,
    url: string | null
  ) {
    super(message)
    this.code = code
    this.status = status
    // @ts-expect-error TS(2322): Type 'SupernovaFieldValidationError[] | null' is n... Remove this comment to see the full error message
    this.formErrors = formErrors
    this.requestId = requestId
    this.serverTime = serverTime
    this.url = url
  }

  /** Creates Supernova error from received but failed network response */
  static fromNetworkResponse(
    response: SupernovaBackendError,
    status: number,
    url: string
  ) {
    return new SupernovaError(
      response.message,
      response.errorCode || response.reason || response.label || null,
      response.code || status,
      response.formErrors ?? null,
      response.requestId ?? null,
      response.serverTime ?? null,
      url
    )
  }

  /** Creates Supernova error with generic network failure message */
  static fromNoNetworkResponse(url: string) {
    return new SupernovaError(
      "No network or internet unreachable",
      SupernovaNoNetworkResponse,
      12163, // Internet connection lost
      null,
      new Date().toISOString(),
      null,
      url
    )
  }

  /** Creates Supernova error from local validation failure code */
  static fromCode(code: string) {
    // Error was thrown in the processing layer - tooling etc.
    return new SupernovaError(code, code, null, null, null, null, null)
  }

  /** Creates Supernova error from generic error message */
  static fromMessage(message: string) {
    return new SupernovaError(message, null, null, null, null, null, null)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Conveniences

  /** Checks whether error contains some field validation error */
  isFieldErrored(key: string): boolean {
    return this.formErrors.find((f) => f.key === key) !== undefined
  }

  /** Retrieves error for a specific field, or null if no error is present for specific field */
  errorForField(key: string): SupernovaFieldValidationError | null {
    return this.formErrors.find((f) => f.key === key) ?? null
  }

  /** Returns all important information about the error, ideally suited for logging to console or sentry */
  toString(): string {
    return `
    Error thrown: ${this.message}\n
    Code: ${this.code ?? "-"}\n
    Status: ${this.status ?? "-"}\n
    Fields: ${JSON.stringify(this.formErrors, null, 2)}\n
    Request ID: ${this.requestId ?? "-"}\n
    Server time: ${this.serverTime ?? "-"}\n
    Network URL: ${this.url ?? "-"}\n
    Stack: ${this.stack ?? "-"}
    `
  }

  /** Returns error with most important information to show to the users */
  toHumanReadable(): string {
    return `${this.message}${
      this.requestId ? ` (request ${this.requestId})` : ""
    }`
  }
}
