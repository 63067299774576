//
//  DesignSystemVersion.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DesignSystemVersionRemoteModel = {
  id: string
  designSystemId: string
  isReadonly: boolean
  meta: {
    name: string
    description: string
  }
  version: string
  createdAt: string
  changeLog: string
}

export type DesignSystemVersionCreateRemoteModel = Omit<
  DesignSystemVersionRemoteModel,
  "id" | "createdAt"
> & { jobId: string }

type DesignSystemVersionCreationJobStatus = "Success" | "Error" | "InProgress"

export type DesignSystemVersionCreationJobRemoteModel = {
  id: string
  version: string
  designSystemId: string
  designSystemVersionId?: string
  status: DesignSystemVersionCreationJobStatus
  message?: string
}

export type DesignSystemVersionUpdateModel = {
  version: string
  name?: string
  description?: string
  changeLog?: string
}

export type DesignSystemVersionTransportModel = Pick<
  DesignSystemVersion,
  | "id"
  | "designSystemId"
  | "isReadonly"
  | "name"
  | "description"
  | "version"
  | "changeLog"
> & {
  createdAt: string
}

export type DesignSystemVersionCreateTransportModel = Pick<
  DesignSystemVersion,
  | "designSystemId"
  | "isReadonly"
  | "name"
  | "description"
  | "version"
  | "changeLog"
> & {
  jobId: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class DesignSystemVersionCreate {
  /** Identifier of design system this version is created in */
  designSystemId: string

  /** Design system version name */
  name: string

  /** Design system version description */
  description: string

  /** If version is in read-only mode, it can't be modified - only documentation that can be improved */
  isReadonly: boolean

  /** Semantic name of version. Will be null if the version is in draft mode */
  version: string | null

  /** Change log for the version. Will be null if the version is in draft mode */
  changeLog: string | null

  /** ID of job creation. */
  jobId: string

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DesignSystemVersionCreateRemoteModel) {
    this.designSystemId = model.designSystemId
    this.name = model.meta.name
    this.description = model.meta.description
    this.version = model.version
    this.changeLog = model.changeLog?.length > 0 ? model.changeLog : null
    this.isReadonly = model.isReadonly
    this.jobId = model.jobId
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): DesignSystemVersionCreateRemoteModel {
    return {
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      version: this.version ?? null,
      designSystemId: this.designSystemId,
      isReadonly: this.isReadonly,
      meta: {
        name: this.name,
        description: this.description,
      },
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      changeLog: this.changeLog ?? null,
      jobId: this.jobId,
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): DesignSystemVersionCreateTransportModel {
    return {
      version: this.version ?? null,
      designSystemId: this.designSystemId,
      isReadonly: this.isReadonly,
      name: this.name,
      description: this.description,
      changeLog: this.changeLog ?? null,
      jobId: this.jobId,
    }
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: DesignSystemVersionCreateTransportModel) {
    return new DesignSystemVersionCreate({
      designSystemId: model.designSystemId,
      isReadonly: model.isReadonly,
      meta: {
        name: model.name,
        description: model.description,
      },
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      version: model.version ?? null,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      changeLog: model.changeLog ?? null,
      jobId: model.jobId,
    })
  }
}

export class DesignSystemVersion {
  /** Unique identifier of design system version */
  id: string

  /** Identifier of design system this version is created in */
  designSystemId: string

  /** Design system version name */
  name: string

  /** Design system version description */
  description: string

  /** If version is in read-only mode, it can't be modified - only documentation that can be improved */
  isReadonly: boolean

  /** Semantic name of version. Will be null if the version is in draft mode */
  version: string | null

  /** Change log for the version. Will be null if the version is in draft mode */
  changeLog: string | null

  /** The initial date of the creation of this version */
  createdAt: Date

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DesignSystemVersionRemoteModel) {
    this.id = model.id
    this.designSystemId = model.designSystemId
    this.name = model.meta.name
    this.description = model.meta.description
    this.version = model.version
    this.changeLog = model.changeLog?.length > 0 ? model.changeLog : null
    this.isReadonly = model.isReadonly
    this.createdAt = new Date(model.createdAt)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): DesignSystemVersionRemoteModel {
    return {
      id: this.id,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      version: this.version ?? null,
      designSystemId: this.designSystemId,
      isReadonly: this.isReadonly,
      meta: {
        name: this.name,
        description: this.description,
      },
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      changeLog: this.changeLog ?? null,
      createdAt: this.createdAt.toISOString(),
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): DesignSystemVersionTransportModel {
    return {
      id: this.id,
      version: this.version ?? null,
      designSystemId: this.designSystemId,
      isReadonly: this.isReadonly,
      name: this.name,
      description: this.description,
      changeLog: this.changeLog ?? null,
      createdAt: this.createdAt.toISOString(),
    }
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: DesignSystemVersionTransportModel) {
    return new DesignSystemVersion({
      id: model.id,
      designSystemId: model.designSystemId,
      isReadonly: model.isReadonly,
      meta: {
        name: model.name,
        description: model.description,
      },
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      version: model.version ?? null,
      createdAt: model.createdAt,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      changeLog: model.changeLog ?? null,
    })
  }
}

export class DesignSystemVersionCreationJob {
  id: string

  /** Semantic name of version. */
  version: string

  /** Identifier of design system this version is created in */
  designSystemId: string

  /** Identifier of version in design system. Filled only when status is equal to Success */
  designSystemVersionId: string | null

  /** Status of current job */
  status: DesignSystemVersionCreationJobStatus

  /** Mesage if job failed. Otherwise null */
  message: string | null

  constructor(model: DesignSystemVersionCreationJobRemoteModel) {
    this.id = model.id
    this.version = model.version
    this.designSystemId = model.designSystemId
    this.designSystemVersionId = model.designSystemVersionId ?? null
    this.status = model.status
    this.message = model.message ?? null
  }
}
