//
//  SDKWorkspaceIntegrationOauthRequest.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions
export type OAuth2IntegrationTypes =
  | "Figma"
  | "Github"
  | "Gitlab"
  | "Bitbucket"
  | "Azure"

export type WorkspaceIntegrationOauthRequestRemoteModel = {
  url: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceIntegrationOauthRequest {
  /** Redirect url for oauth of given integration */
  url: string

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceIntegrationOauthRequestRemoteModel) {
    this.url = model.url
  }
}
