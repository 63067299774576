//
//  Workspace.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  WorkspaceProfile,
  WorkspaceProfileRemoteModel,
  WorkspaceProfileTransportModel,
} from "./SDKWorkspaceProfile"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceRemoteModel = {
  id: string
  profile: WorkspaceProfileRemoteModel
}

export type WorkspaceTransportModel = {
  id: string
  profile: WorkspaceProfileTransportModel
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class Workspace {
  /** Unique identifier of the workspace */
  id: string

  /** Workspace profile with all information about billing, workspace, avatars etc. */
  profile: WorkspaceProfile

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceRemoteModel) {
    this.id = model.id
    this.profile = new WorkspaceProfile(model.profile)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): WorkspaceRemoteModel {
    return {
      id: this.id,
      profile: this.profile.toRemote(),
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): WorkspaceTransportModel {
    return {
      id: this.id,
      profile: this.profile.toTransport(),
    }
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: WorkspaceTransportModel) {
    return new Workspace(model)
  }
}
