//
//  SDKDocsBlockAssetProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockContainerSize } from "../../../enums/SDKDocsBlockContainerSize"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsColorRef } from "../support/SDKDocsColorRef"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Asset property - Additional non-value options */
export type DocsBlockAssetPropertyOptions = Record<string, never>

/** Asset property - Data */
export type DocsBlockAssetPropertyData = DocsBlockPropertyData<
  Array<{
    entityId: string
    entityType: "Asset" | "AssetGroup"
    entityMeta?: {
      title?: string
      description?: string
      backgroundColor?: DocsColorRef | null
    }
  }>
> & {
  /** Selected asset ids */
  selectedPropertyIds?: Array<string>
  /** If enabled, search is visible */
  showSearch?: boolean
  /** Preview container size */
  previewContainerSize?:
    | DocsBlockContainerSize.centered
    | DocsBlockContainerSize.naturalHeight
}

/** Asset property - Definition model */
export type DocsBlockAssetPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockAssetPropertyOptions

/** Asset property - Definition */
export class DocsBlockAssetPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockAssetPropertyOptions,
  DocsBlockAssetPropertyData
> {
  type!: DocsBlockItemPropertyType.asset
}
