//
//  SDKDocsBlockRichTextProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsBlockTextPropertyColor } from "../../../enums/SDKDocsBlockTextPropertyColor"
import { DocsBlockTextPropertyStyle } from "../../../enums/SDKDocsBlockTextPropertyStyle"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Text property - Additional non-value options */
export type DocsBlockTextPropertyOptions = {
  /** Field placeholder */
  placeholder?: string
  /** Default value to set when block is created */
  defaultValue?: string
  /** Visual style of the text property */
  textStyle?: DocsBlockTextPropertyStyle
  /** Text color */
  color?: DocsBlockTextPropertyColor
  /** Allow line breaks (todo: should render as growing textarea in property panel if true) */
  allowLineBreaks?: boolean
}

/** Text property - Data */
export type DocsBlockTextPropertyData = DocsBlockPropertyData<string> &
  Record<string, never>

/** Text property - Definition model */
export type DocsBlockTextPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockTextPropertyOptions

/** Text property - Definition */
export class DocsBlockTextPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockTextPropertyOptions,
  DocsBlockTextPropertyData
> {
  type!: DocsBlockItemPropertyType.text
}
