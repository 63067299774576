//
//  SDKDocsBlockEmbedUrlProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
  DocsBlockPropertyData,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** EmbedUrl property - Additional non-value options */
export type DocsBlockEmbedUrlPropertyOptions = {
  /** Defines what should be the default height of the embed */
  defaultHeight: number
  /** If enabled, additional text can be provided for the embed property */
  allowCaption?: boolean
  /** If enabled, the embed can be resized */
  allowResize?: boolean
  /** Defined how to validate the embed URL */
  urlValidationRegex?: string
}

/** EmbedUrl property - Data */
export type DocsBlockEmbedUrlPropertyData = DocsBlockPropertyData<
  string | undefined
> & {
  alt?: string
  caption?: string
  height?: number
}

/** EmbedUrl property - Definition model */
export type DocsBlockEmbedUrlPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockEmbedUrlPropertyOptions

/** EmbedUrl property - Definition */
export class DocsBlockEmbedUrlPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockEmbedUrlPropertyOptions,
  DocsBlockEmbedUrlPropertyData
> {
  type!: DocsBlockItemPropertyType.embedURL
}
