//
//  SDKExporter.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  ExporterCustomBlock,
  ExporterCustomBlockModel,
} from "./custom_blocks/SDKExporterCustomBlock"
import { ExporterCustomBlockVariant } from "./custom_blocks/SDKExporterCustomBlockVariant"
import {
  ExporterConfigurationProperty,
  ExporterConfigurationPropertyModel,
} from "./custom_properties/SDKExporterConfigurationProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type ExporterProvider = "github" | "gitlab" | "bitbucket" | "azure"

export type ExporterModel = {
  id: string
  packageId: string

  isPrivate: boolean
  isDefaultDocumentationExporter: boolean
  isOwner?: boolean
  usesBrands: boolean
  usesThemes?: boolean

  name: string
  description: string
  version: string
  author: string
  organization: string
  homepage: string
  readme: string
  iconURL?: string
  tags: Array<string>

  gitProvider: ExporterProvider

  gitUrl: string
  gitBranch: string
  gitDirectory: string

  blockVariants?: object
  configurationProperties?: Array<ExporterConfigurationPropertyModel>
  customBlocks?: Array<ExporterCustomBlockModel>
  exporterType: "code" | "documentation"
}

export type NewExporterPayload = {
  url?: string
  provider?: ExporterProvider
}

export type ExporterMembershipModel = {
  exporterId: string
  workspaceId: string
  role: "Owner" | "User"
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -- - --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class Exporter {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Unique exporter installation id. This id is used to referenced exporter within Supernova system (for example, in ds.documentationExporterId) */
  id: string

  /** Original exporter package id, as defined in exporter.json */
  packageId: string

  /** Store status of the exporter - private exporters are only visible to your workspace, while public are visible to everyone and can be installed by anyone */
  isPrivate: boolean

  /** If true, this exporter is currently being used for documentation purposes */
  isDefaultDocumentationExporter: boolean

  /** If true, this exporter uses branding functionality  */
  usesBrands: boolean

  /** If true, this exporter uses branding functionality  */
  usesThemes: boolean | null

  /** Exporter human-readable name */
  name: string

  /** Exporter human-readable description */
  description: string | null

  /** Exporter semantic version */
  version: string

  /** Name of the package author */
  author: string | null

  /** Name of the package author's organization */
  organization: string | null

  /** Package marketing link */
  homepage: string | null

  /** Package ReadMe definition, defined as GitHub-style markdown */
  readme: string | null

  /** Exporter package icon */
  iconURL: string | null

  /** Exporter feature tags, such as iOS, React, token, styles, components etc. */
  tags: Array<string>

  exporterType: "code" | "documentation"

  gitProvider: ExporterProvider

  /** URL of the repository containing the exporter package */
  gitUrl: string

  /** Branch of the repository containing the exporter package. If not provided, exporter is located at the default branch */
  gitBranch: string | null

  /** Root directory in the repository containing the exporter package. If not provided, exporter is located at the root directory */
  gitDirectory: string | null

  /** If the workspace is owner of an exporter */
  isOwner?: boolean

  /** Contribution of this exporter to the Supernova system */
  contributes: {
    /** DocumentationLegacy block variants */
    blockVariants: Array<ExporterCustomBlockVariant>

    /** DocumentationLegacy blocks */
    blocks: Array<ExporterCustomBlock>

    /** Exporter configuration */
    configuration: Array<ExporterConfigurationProperty>
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(data: ExporterModel) {
    this.id = data.id
    this.packageId = data.packageId
    this.isPrivate = data.isPrivate
    this.isDefaultDocumentationExporter = data.isDefaultDocumentationExporter
    this.isOwner = data.isOwner
    this.usesBrands = data.usesBrands
    // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.usesThemes = data.usesThemes
    this.name = data.name
    this.description = data.description ?? null
    this.version = data.version
    this.author = data.author ?? null
    this.organization = data.organization ?? null
    this.homepage = data.homepage ?? null
    this.readme = data.readme ?? null
    this.iconURL = data.iconURL ?? null
    this.tags = data.tags ?? []
    this.exporterType = data.exporterType
    this.gitProvider = data.gitProvider
    this.gitUrl = data.gitUrl
    this.gitBranch = data.gitBranch ?? null
    this.gitDirectory = data.gitDirectory ?? null

    const variants = new Array<ExporterCustomBlockVariant>()

    if (data.blockVariants) {
      for (const [key, value] of Object.entries(data.blockVariants)) {
        for (const v of value) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const variant = new ExporterCustomBlockVariant(v, key)

          variants.push(variant)
        }
      }
    }

    this.contributes = {
      blockVariants: variants,
      blocks: data.customBlocks
        ? data.customBlocks.map((b) => new ExporterCustomBlock(b))
        : [],
      configuration: data.configurationProperties
        ? data.configurationProperties.map(
            (c) => new ExporterConfigurationProperty(c, null)
          )
        : [], // in this case, configuration property is just settings and so it doesn't have value
    }
  }
}
