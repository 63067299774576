//
//  SDKIconSet.ts
//  Supernova SDK
//
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Source type enum

export enum IconSet {
  checkCircle = "CheckCircle",
  crossCircle = "CrossCircle",
  alert = "Alert",
}
