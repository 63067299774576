//
//  SDKDocsBlockItem.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { PageBlockDefinitionItem } from "@supernova-studio/model"

import {
  DocsBlockItemPropertyAnyDefinition,
  DocsBlockPropertyDefinition,
} from "../properties/SDKDocsBlockProperty"

import { DocsBlockVariant, DocsBlockVariantModel } from "./SDKDocsBlockVariant"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DocsBlockItemDefinitionModel = PageBlockDefinitionItem

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DocsBlockItemDefinition {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** All properties that user can potentially input via one block / item */
  properties: Array<DocsBlockItemPropertyAnyDefinition>

  /** All appearance options for item wrapper */
  appearance: {
    /** With border enabled, a light border will be rendered in editor around the item. For example, shortcuts or assets have this enabled */
    isBordered: boolean
    /** When background option is enabled, background color can be set for the item */
    hasBackground: boolean
  }

  /** All variants that editors can select from. Variant primarily declares layout of the block for the editor and what properties can be set for variant */
  variants: Array<DocsBlockVariant>

  /** Key of the default variant. There must always be at least one variant to point to */
  defaultVariantKey: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DocsBlockItemDefinitionModel) {
    this.properties = model.properties.map(
      (propertyModel) => new DocsBlockPropertyDefinition(propertyModel)
    )

    this.appearance = {
      isBordered: model.appearance?.isBordered ?? true,
      hasBackground: model.appearance?.hasBackground ?? false,
    }
    this.variants = model.variants.map((v) => new DocsBlockVariant(v))
    this.defaultVariantKey = model.defaultVariantKey
  }
}
