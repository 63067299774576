import { APP_ENVIRONMENT } from "@supernovaio/cloud/utils/environment"

export const printDevData = () => {
  const commitSHA =
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
    process.env.WEBPACK_BUILD_ID ||
    "Not provided"

  // eslint-disable-next-line no-console
  console.log(
    `URL: ${document.location.href}` +
      `\n\nBuild SHA: ${commitSHA}` +
      `\n\nEnvironment: ${APP_ENVIRONMENT}` +
      `\n\nNODE_ENV: ${process.env.NODE_ENV}` +
      `\n\nBrowser: ${navigator.userAgent}`
  )
}
