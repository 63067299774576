//
//  SDKDocsBlockStorybookProperty.ts
//  Supernova SDK
//
//  Created by Supernova.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
  DocsBlockPropertyData,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Storybook property - Additional non-value options */
export type DocsBlockStorybookPropertyOptions = {
  /** Defines what should be the default height of the embed */
  defaultHeight: number
  /** If enabled, additional text can be provided for the embed property */
  allowCaption?: boolean
  /** If enabled, the embed can be resized */
  allowResize?: boolean
  /** Defined how to validate the embed URL */
  urlValidationRegex?: string
}

/** Storybook property - Data */
export type DocsBlockStorybookPropertyData = DocsBlockPropertyData<
  string | undefined
> & {
  alt?: string
  caption?: string
  height?: number
  embedUrl?: string
}

/** Storybook property - Definition model */
export type DocsBlockStorybookPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockStorybookPropertyOptions

/** Storybook property - Definition */
export class DocsBlockStorybookPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockStorybookPropertyOptions,
  DocsBlockStorybookPropertyData
> {
  // type!: DocsBlockItemPropertyType.storybook // TODO: Fix build failure when this is uncommented (comment: WTF? Not like everything else is the same...)
}
