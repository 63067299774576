//
//  SDKDocsBlockTokenProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { TokenType } from "../../../enums/SDKTokenType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Token property - Additional non-value options */
export type DocsBlockTokenPropertyOptions = {
  /** Token types that are allowed to be selected. All types allowed if nothing is provided */
  allowedTypes?: Array<TokenType>
  /** How should the tokens be rendered in editor */
  renderLayoutAs?: "Grid" | "List"
  /** Whether to allow selecting token custom properties. True by default */
  allowPropertySelection?: boolean
  /** Whether to allow selecting themes. True by default */
  allowThemeSelection?: boolean
}

/** Token property - Data */
export type DocsBlockTokenPropertyData = DocsBlockPropertyData<
  Array<{
    entityId: string
    entityType: "Token" | "TokenGroup"
    entityMeta?: {
      showNestedGroups?: boolean
    }
  }>
> & {
  /** Selected token ids */
  selectedPropertyIds?: Array<string>
  /** Swatches with selected theme ids */
  swatches?: Array<{ id: string; selectedThemeIds: Array<string> }>
  /**
   * Selected theme ids
   * @deprecated Swatches will be used instead
   */
  selectedThemeIds?: Array<string>
  /**
   * Display mode of themes associated with the token property
   * @deprecated Swatches will be used instead
   */
  themeDisplayMode?: "Split" | "Override"
}

/** Token property - Definition model */
export type DocsBlockTokenPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockTokenPropertyOptions

/** Token property - Definition */
export class DocsBlockTokenPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockTokenPropertyOptions,
  DocsBlockTokenPropertyData
> {
  type!: DocsBlockItemPropertyType.token
}
