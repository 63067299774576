//
//  SDKWorkspaceProduct.ts
//  Supernova SDK
//
//  Created by Supernova.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { UserRole } from "../enums/SDKUserRole"
import { WorkspaceSubscriptionProductCode } from "../enums/SDKWorkspaceSubscriptionProduct"

import {
  WorkspaceProductPrice,
  WorkspaceProductPriceRemoteModel,
} from "./SDKWorkspaceProductPrice"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceProductRemoteModel = {
  code: WorkspaceSubscriptionProductCode
  allowedInviteRoles: Array<UserRole>
  prices: Array<WorkspaceProductPriceRemoteModel>
}

export type WorkspaceProductTransportModel = WorkspaceProductRemoteModel

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceProduct {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** The code corresponding to this product */
  code: WorkspaceSubscriptionProductCode

  /** The roles that are allowed to be invited to the workspace under this product */
  allowedInviteRoles: Array<UserRole>

  /** The list of prices associated with the product */
  prices: Array<WorkspaceProductPrice>

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceProductTransportModel) {
    this.code = model.code
    this.allowedInviteRoles = model.allowedInviteRoles
    this.prices = model.prices.map((m) => new WorkspaceProductPrice(m))
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toRemote(): WorkspaceProductRemoteModel {
    return {
      code: this.code,
      allowedInviteRoles: this.allowedInviteRoles,
      prices: this.prices.map((price) => price.toRemote()),
    }
  }

  toTransport(): WorkspaceProductTransportModel {
    return this.toRemote()
  }

  static fromTransport(
    model: WorkspaceProductTransportModel
  ): WorkspaceProduct {
    return new WorkspaceProduct(model)
  }
}
