//
//  SDKDocsImageRef.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
import { Supernova } from "../../../../exports"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Ref definition

/** An image data container */
export type DocsImageRef = {
  /** Ref type */
  type: DocsImageRefType

  /** Referenced resource, if any */
  resource?: {
    /** Absolute, directly consumable URL */
    url: string
    /** Referenced resource, if any */
    resourceId: string
  }

  /** Referenced Figma file, if any */
  figmaNode?: {
    // Refer
    sourceId: string
    // Referenced Figma frame
    frameReferenceId: string
  }
}

export enum DocsImageRefType {
  resource = "Resource",
  figmaNode = "FigmaNode",
}

export function docsImageRefToUrl(
  image: DocsImageRef | undefined,
  sdk: Supernova,
  from: {
    dsId: string
    versionId: string
  }
): string | undefined {
  if (!image) {
    return undefined
  }
  switch (image.type) {
    case DocsImageRefType.resource:
      return image.resource?.url
    case DocsImageRefType.figmaNode: {
      if (
        !image.figmaNode ||
        !image.figmaNode.sourceId ||
        !image.figmaNode.frameReferenceId
      ) {
        return undefined
      }
      return sdk.resources.getFigmaFrameHostedUrl(
        {
          designSystemId: from.dsId,
          versionId: from.versionId,
        },
        image.figmaNode.frameReferenceId
      )
    }
    default:
      return undefined
  }
}
