//
//  SDKDataCoreRenderRequests.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2020 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Asset } from "../../../model/assets/SDKAsset"
import {
  RenderedAsset,
  RenderedAssetModel,
} from "../../../model/assets/SDKRenderedAsset"
import { AssetFormat } from "../../../model/enums/SDKAssetFormat"
import { AssetScale } from "../../../model/enums/SDKAssetScale"
import { AssetGroup } from "../../../model/groups/SDKAssetGroup"
import { DataBridge } from "../SDKDataBridge"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type RenderedAssetRequestSettings = Array<{
  prefix: string
  suffix: string
  scale: AssetScale
  format: AssetFormat
}>

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Request engine: Render

export class DataCoreRenderRequests {
  private bridge: DataBridge

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(bridge: DataBridge) {
    this.bridge = bridge
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Rendering

  async renderAssetsForConfiguration(
    designSystemId: string,
    versionId: string,
    settings: RenderedAssetRequestSettings,
    assets: Array<Asset>,
    groups: Array<AssetGroup>
  ): Promise<Array<RenderedAsset>> {
    // Configure payload
    const configuration = {
      settings,
      persistentIds: assets.map((a) => a.id),
    }

    // Render items
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "components/assets/download-list"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data = await this.bridge.post(endpoint, configuration)
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const items = data.result.items as Array<RenderedAssetModel>
    // Create rendered items index
    const renderedItemsMap = new Map<string, RenderedAssetModel>()

    for (const renderedItem of items) {
      renderedItemsMap.set(renderedItem.assetId, renderedItem)
    }

    if (Array.from(renderedItemsMap.entries()).length !== assets.length) {
      throw new Error(
        "Number of rendered assets doesn't align with number of requested assets"
      )
    }

    const assetsMap = new Map<string, Asset>()

    for (const asset of assets) {
      assetsMap.set(asset.id, asset)
    }

    const resultingAssets: Array<RenderedAsset> = []
    // For duplicates
    const names = new Map<string, number>()

    for (const asset of assets) {
      const item = renderedItemsMap.get(asset.id)
      let renderedGroup: AssetGroup

      for (const group of groups) {
        if (group.assetIds.includes(asset.id)) {
          renderedGroup = group
          break
        }
      }

      // @ts-expect-error TS(2454): Variable 'renderedGroup' is used before being assi... Remove this comment to see the full error message
      const assetPath = this.assetPath(asset, renderedGroup, groups)

      if (!names.get(assetPath)) {
        names.set(assetPath, 0)
      }

      // @ts-expect-error TS(2454): Variable 'renderedGroup' is used before being assi... Remove this comment to see the full error message
      if (!renderedGroup) {
        throw new Error(`Each asset must be assigned to some group`)
      }

      const renderedAsset = new RenderedAsset(
        // @ts-expect-error TS(2345): Argument of type 'RenderedAssetModel | undefined' ... Remove this comment to see the full error message
        item,
        asset,
        renderedGroup,
        names.get(assetPath)
      )

      // Increase number of duplicates
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      names.set(assetPath, names.get(assetPath) + 1)
      // Store
      resultingAssets.push(renderedAsset)
    }

    return resultingAssets
  }

  private assetPath(
    asset: Asset,
    parent: AssetGroup,
    allGroups: Array<AssetGroup>
  ): string {
    const segments = [asset.name]
    let possibleParent = parent

    while (possibleParent) {
      segments.push(possibleParent.name)

      const parentId = possibleParent.parentGroupId

      if (!parentId) {
        break
      }

      // @ts-expect-error TS(2322): Type 'AssetGroup | undefined' is not assignable to... Remove this comment to see the full error message
      possibleParent = allGroups.find((g) => g.persistentId === parentId)
    }

    return segments.reverse().join("/")
  }
}
