//
//  SDKGradientToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
import { sureOf } from "../../utils/CommonUtils"
import { StringUtils } from "../../utils/StringUtils"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { GradientType } from "../enums/SDKGradientType"
import { TokenType } from "../enums/SDKTokenType"

import { GradientTokenRemoteData } from "./remote/SDKRemoteTokenData"
import {
  GradientTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { GradientTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { ColorToken } from "./SDKColorToken"
import { Token } from "./SDKToken"
import { GradientStopValue, GradientTokenValue } from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class GradientToken extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: GradientTokenValue[]

  gradientLayers: Array<GradientToken>

  isVirtual: boolean

  tokenType: TokenType.gradient = TokenType.gradient

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: GradientTokenValue[],
    alias: GradientToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value.map((gradientTokenValue) => ({
      ...gradientTokenValue,
    })) // NOTE: ensure no same reference
    this.gradientLayers = new Array<GradientToken>()
    this.isVirtual = false

    if (alias) {
      this.value = this.value.map((tokenValueToUpdate) => ({
        ...tokenValueToUpdate,
        referencedTokenId: alias.id,
      }))
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Static building

  static create(
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: string,
    alias: GradientToken | null,
    referenceResolver: DTTokenReferenceResolver,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): GradientToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type: TokenType.gradient,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      // Raw value
      const tokenValue = this.gradientValueFromDefinition(
        value,
        referenceResolver
      )

      return new GradientToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const layers: GradientTokenValue[] = []

      for (const aliasValue of alias.value) {
        const tokenValue: GradientTokenValue = {
          to: {
            x: aliasValue.to.x,
            y: aliasValue.to.y,
          },
          from: {
            x: aliasValue.from.x,
            y: aliasValue.from.y,
          },
          type: aliasValue.type,
          aspectRatio: aliasValue.aspectRatio,
          stops: aliasValue.stops,
          // ALTERNATIVE: Keep original reference: `alias.value.length === 1 ? alias : aliasValue.referencedToken`
          referencedTokenId: alias.id,
        }

        layers.push(tokenValue)
      }

      return new GradientToken(
        versionId,
        baseToken,
        layers,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "Gradient Token must be created using value or alias, but none was provided"
    )
  }

  static gradientValueFromDefinition(
    definition: object | string,
    referenceResolver: DTTokenReferenceResolver
  ): GradientTokenValue[] {
    if (definition instanceof Array) {
      return definition.map((d) =>
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.gradientSingleValueFromDefinition(d, referenceResolver)
      )
    }

    return [
      this.gradientSingleValueFromDefinition(
        definition as unknown as string,
        referenceResolver
      ),
    ]
  }

  static gradientSingleValueFromDefinition(
    definition: string,
    referenceResolver: DTTokenReferenceResolver
  ): GradientTokenValue {
    let d = definition.trim()

    d = StringUtils.replaceAll(d, "( ", "(")
    d = StringUtils.replaceAll(d, ", ", ",")
    d = StringUtils.replaceAll(d, " ,", ",")
    d = d
      .substring(d.indexOf("(") + 1, d.lastIndexOf(")"))
      // There could be commas inside color def
      // linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%)
      .replace(/, (?=[^()]*\))/g, ",")

    // If gradient is defined without degrees, use default 180deg (to bottom)
    const parts = d.split(",")
    const firstPart = parts[0]

    if (
      parts.length === 2 ||
      (parts.length > 2 &&
        firstPart &&
        (firstPart.includes("#") ||
          firstPart.includes("rgb") ||
          firstPart.includes("hsl") ||
          firstPart.includes("hwb") ||
          firstPart.includes("lab") ||
          firstPart.includes("lch") ||
          firstPart.includes("%") ||
          firstPart.includes("{")))
    ) {
      d = `180deg,${d}`
    }

    const [gradientDegrees, ...colorStops] =
      StringUtils.splitIgnoringQuotedBracketedContent(d, ",")

    const degrees = parseFloat(
      (gradientDegrees ?? "180deg").split("deg").join("")
    )
    // Convert degrees to radians. Add 90 to rotate the angle 90 degrees counterclockwise
    const radians = -(degrees + 90) * (Math.PI / 180)
    const roundDecimal = (value: number) => Math.round(value * 10000) / 10000

    // Calculate coordinates, (0.5;0.5) is the center of x-axis and y-axis
    const from = {
      x: roundDecimal(0.5 + 0.5 * Math.cos(radians)),
      y: roundDecimal(0.5 - 0.5 * Math.sin(radians)),
    }

    const to = {
      x: roundDecimal(0.5 - 0.5 * Math.cos(radians)),
      y: roundDecimal(0.5 + 0.5 * Math.sin(radians)),
    }

    const preparedColorStops: [string | undefined, string | null][] = []

    // when there are multiple percentage for single color then we need to split it
    // red, orange 10% 30%, yellow 50% 70%, green 90% -> red, orange 10%, orange 30%, yellow 50%, yellow 70%, green 90%
    for (const colorStop of colorStops) {
      const [color, ...percentage] = colorStop.split(" ")

      if (percentage.length === 0) {
        preparedColorStops.push([color, null])
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      for (const percentageColorStop of percentage) {
        preparedColorStops.push([color, percentageColorStop])
      }
    }

    // if percentage is not defined then it is evenly distributed
    // valid values: red, orange, yellow, green, blue -> red 0%, orange 25%, yellow 50%, green 75%, blue 100%
    const firstStop = preparedColorStops[0]
    const lastStop = preparedColorStops[preparedColorStops.length - 1]
    if (firstStop && firstStop[1] === null) firstStop[1] = "0%"
    if (lastStop && lastStop[1] === null) lastStop[1] = "100%"

    for (let i = 0; i < preparedColorStops.length; i += 1) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const preparedColorStopI = sureOf(preparedColorStops[i])
      const [color, percentage] = preparedColorStopI

      if (percentage) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      // first and last colors have percentage defined before loop
      const prevPercentage = Number.parseFloat(
        sureOf(preparedColorStops[i - 1]?.[1])
      )
      // After this cycle nextPercentage would be the first next defined color % (or last, if all subsequent color has no %)
      let nextPercentage = Number.parseFloat(
        sureOf(preparedColorStops[preparedColorStops.length - 1]?.[1])
      )
      let step = 1

      while (i + step < preparedColorStops.length) {
        const nextStepColor = preparedColorStops[i + step]?.[1]
        if (nextStepColor) {
          nextPercentage = Number.parseFloat(nextStepColor)
          break
        }

        step += 1
      }

      const stepPercentage = (nextPercentage - prevPercentage) / (step + 1)

      const newPercentage = (prevPercentage + stepPercentage)
        .toFixed(4)
        .replace(/0+$/, "")

      preparedColorStopI[1] = `${newPercentage}%`
    }

    // Build gradient stops
    const gradientStops: Array<GradientStopValue> = preparedColorStops.map(
      ([color, percentage]) => {
        // Try our best to parse stop color and position for any stop
        // We could also return predefined default stop if any of them fails to parse
        // Or return predefined gradient all together, if any stop fails to parse
        const colorValue = ColorToken.colorValueFromDefinitionOrReference(
          color ?? "red",
          referenceResolver
        )!

        return {
          color: colorValue,
          position: parseFloat(percentage ?? "100") / 100,
        }
      }
    )

    // Construct gradient value by using
    return {
      from,
      to,
      type: GradientType.linear,
      aspectRatio: 1,
      stops: gradientStops,
      referencedTokenId: null,
    }
  }

  static gradientValueFromDefinitionOrReference(
    definition: any,
    referenceResolver: DTTokenReferenceResolver
  ): GradientTokenValue[] | undefined {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (referenceResolver.valueHasReference(definition)) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (!referenceResolver.isBalancedReference(definition)) {
        // Internal syntax of reference corrupted
        throw new Error(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Invalid reference syntax in token value: ${definition}`
        )
      }

      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (referenceResolver.valueIsPureReference(definition)) {
        // When color is pure reference, we can immediately resolve it
        const reference = referenceResolver.lookupReferencedToken(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          definition
        ) as GradientToken

        if (!reference) {
          return undefined
        }

        const layers: GradientTokenValue[] = []

        for (const aliasValue of reference.value) {
          const tokenValue: GradientTokenValue = {
            to: {
              x: aliasValue.to.x,
              y: aliasValue.to.y,
            },
            from: {
              x: aliasValue.from.x,
              y: aliasValue.from.y,
            },
            type: aliasValue.type,
            aspectRatio: aliasValue.aspectRatio,
            stops: aliasValue.stops,
            // ALTERNATIVE: Keep original reference: `alias.value.length === 1 ? alias : aliasValue.referencedToken`
            referencedTokenId: reference.id,
          }

          layers.push(tokenValue)
        }

        return layers
      }

      // When color is not a pure reference, we must resolve it further before we can resolve it
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const references = referenceResolver.lookupAllReferencedTokens(definition)

      if (!references) {
        // Still unable to solve the reference, continue looking in some other tokens
        return undefined
      }

      // Resolved all internal references
      const resolvedValue = referenceResolver.replaceAllReferencedTokens(
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        definition,
        references
      )

      return this.gradientValueFromDefinition(resolvedValue, referenceResolver)
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.gradientValueFromDefinition(definition, referenceResolver)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Writing

  toWriteObject(): GradientTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as GradientTokenRemoteModel

    specificData.data = GradientToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(
    value: GradientTokenValue[]
  ): GradientTokenRemoteData {
    const tokens = value.map((l) => this.singleValueToWriteObject(l))

    // If has one layer which is direct alias to another token => use aliasTo, otherwise build value
    // If every layer references same token, then it is a reference to this token
    const refSameToken = value.every(
      (layer) =>
        !!layer.referencedTokenId &&
        layer.referencedTokenId === value[0]?.referencedTokenId
    )

    const aliasTo = refSameToken
      ? value[0]?.referencedTokenId ?? undefined
      : undefined

    return {
      aliasTo,
      value: aliasTo ? undefined : tokens, // ALTERNATIVE: If we want to have single-layered for 1 shadow in db, we should flatten here
    }
  }

  static singleValueToWriteObject(value: GradientTokenValue): {
    aliasTo: string | undefined
    value: GradientTokenRemoteValue
  } {
    const valueObject = !value.referencedTokenId
      ? {
          to: {
            x: value.to.x,
            y: value.to.y,
          },
          from: {
            x: value.from.x,
            y: value.from.y,
          },
          type: value.type,
          aspectRatio: value.aspectRatio,
          stops: value.stops.map((s) => {
            return {
              position: s.position,
              color: {
                aliasTo: s.color.referencedTokenId
                  ? s.color.referencedTokenId
                  : undefined,
                value: s.color.referencedTokenId
                  ? null
                  : {
                      color: ColorToken.colorValueToHex8(s.color),
                      opacity: {
                        value: {
                          measure: s.color.opacity.measure,
                          unit: s.color.opacity.unit,
                        },
                      },
                    },
              },
            }
          }),
        }
      : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type '{ to: { x: number; y: number; }; from: { x: ... Remove this comment to see the full error message
      value: valueObject,
    }
  }
}
