export enum DocsBlockTextPropertyStyle {
  /** Title type 1, roughly equivalent of h2 in size */
  title1 = "Title1",
  /** Title type 2, roughly equivalent of h3 in size */
  title2 = "Title2",
  /** Title type 3, roughly equivalent of h4 in size */
  title3 = "Title3",
  /** Title type 4, roughly equivalent of h5 in size */
  title4 = "Title4",
  /** Title type 5, roughly equivalent of h6 in size */
  title5 = "Title5",
  /** Default text, equals regular */
  default = "Default",
  /** Bold text */
  defaultBold = "DefaultBold",
  /** Semibold text */
  defaultSemibold = "DefaultSemibold",
  /** Small text */
  small = "Small",
  /** Small bold text */
  smallBold = "SmallBold",
  /** Small semibold text */
  smallSemibold = "SmallSemibold",
  /** Custom (will render as text field) */
  custom = "Custom",
}
