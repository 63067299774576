//
//  SDKTokenGroupResolver.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  TokenGroup,
  TokenGroupRemoteModel,
} from "../../model/groups/SDKTokenGroup"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class TokenGroupResolver {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Configuration

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Resolution

  resolveGroupData(data: Array<TokenGroupRemoteModel>): Array<TokenGroup> {
    const hashedGroups = new Map<string, TokenGroupRemoteModel>()
    const resolvedGroups = new Map<string, TokenGroup>()

    // Convert raw groups to resolved groups, not caring about the references just yet
    for (const rawGroup of data) {
      const group = new TokenGroup(rawGroup)

      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      hashedGroups.set(rawGroup.persistentId, rawGroup)
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      resolvedGroups.set(rawGroup.persistentId, group)
    }

    // Build the reference tree and list of tokens
    for (const rawGroup of data) {
      const filteredTokenIds = new Array<string>()
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      const referencedGroup = resolvedGroups.get(rawGroup.persistentId)

      for (const id of rawGroup.childrenIds) {
        // Find if reference is group - if it is not, it is a token
        const childGroup = resolvedGroups.get(id)

        if (childGroup) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          referencedGroup.addChild(childGroup.id)
        } else {
          filteredTokenIds.push(id)
        }
      }

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      referencedGroup.tokenIds = filteredTokenIds
    }

    // Retrieve resolved groups
    const groups = Array.from(resolvedGroups.values())

    this.recomputePaths(groups)
    this.recomputeParents(groups)
    return this.reorderGroupsByRoots(groups)
  }

  private recomputePaths(groups: Array<TokenGroup>) {
    // Find roots, and compute the segments down from the roots
    for (const group of groups) {
      if (group.isRoot) {
        this.recomputePathsFromRoot(group, [], groups)
      }
    }

    // Drop first item because we don't want the core root category to be there
    for (const group of groups) {
      group.path.shift()
    }
  }

  private recomputePathsFromRoot(
    root: TokenGroup,
    segments: Array<string>,
    groups: Array<TokenGroup>
  ) {
    // Recursively go down the tree(s) and add segments to each
    const extendedPath = segments.concat(root.name)

    for (const groupId of root.subgroupIds) {
      const group = groups.find((g) => g.id === groupId)

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      group.path = extendedPath.concat()
      // @ts-expect-error TS(2345): Argument of type 'TokenGroup | undefined' is not a... Remove this comment to see the full error message
      this.recomputePathsFromRoot(group, extendedPath, groups)
    }
  }

  private reorderGroupsByRoots(groups: Array<TokenGroup>): Array<TokenGroup> {
    let sortedGroups = new Array<TokenGroup>()
    // Find the root groups, which will be initial sorting points
    const roots = groups.filter((g) => g.isRoot)

    for (const root of roots) {
      // For each group, traverse and add proper order
      sortedGroups = sortedGroups.concat(this.traverseSortGroup(root, groups))
    }

    return sortedGroups
  }

  private traverseSortGroup(
    group: TokenGroup,
    groups: Array<TokenGroup>
  ): Array<TokenGroup> {
    let output = new Array<TokenGroup>()

    // Iterated group always first
    output.push(group)

    // Add sorted groups to the array
    const sortedGroupIds = group.subgroupIds.sort(
      (g1, g2) => group.childrenIds.indexOf(g1) - group.childrenIds.indexOf(g2)
    )

    for (const subgroupId of sortedGroupIds) {
      const subgroup = groups.find((g) => g.id === subgroupId)

      // @ts-expect-error TS(2345): Argument of type 'TokenGroup | undefined' is not a... Remove this comment to see the full error message
      output = output.concat(this.traverseSortGroup(subgroup, groups))
    }

    return output
  }

  private recomputeParents(groups: Array<TokenGroup>) {
    // Find roots, and compute the references down the chain. Root groups don't have parents
    for (const group of groups) {
      if (group.isRoot) {
        this.recomputeParentsFromRoot(group, groups)
        group.parentGroupId = null
      }
    }
  }

  private recomputeParentsFromRoot(
    rootGroup: TokenGroup,
    groups: Array<TokenGroup>
  ) {
    for (const subgroupId of rootGroup.subgroupIds) {
      const subgroup = groups.find((g) => g.id === subgroupId)

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      subgroup.setParentGroupId(rootGroup.id)
      // @ts-expect-error TS(2345): Argument of type 'TokenGroup | undefined' is not a... Remove this comment to see the full error message
      this.recomputeParentsFromRoot(subgroup, groups)
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Authenticated data fetching
}
