//
//  SDKDocsBlockRichTextProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocumentationLegacyRichTextModel } from "../../../documentation/SDKDocumentationLegacyRichText"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsBlockRichTextPropertyStyle } from "../../../enums/SDKDocsBlockRichTextPropertyStyle"
import { DocumentationLegacyCalloutType } from "../../../enums/SDKDocumentationLegacyCalloutType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** RichText property - Additional non-value options */
export type DocsBlockRichTextPropertyOptions = {
  /** Placeholder to show when rich text area is empty */
  placeholder?: string
  /** Visual style of the rich text property */
  richTextStyle?: DocsBlockRichTextPropertyStyle
}

/** RichText property - Data */
export type DocsBlockRichTextPropertyData =
  DocsBlockPropertyData<DocumentationLegacyRichTextModel> & {
    /** Selected token ids */
    calloutType?: DocumentationLegacyCalloutType
  }

/** RichText property - Definition model */
export type DocsBlockRichTextPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockRichTextPropertyOptions

/** RichText property - Definition */
export class DocsBlockRichTextPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockRichTextPropertyOptions,
  DocsBlockRichTextPropertyData
> {
  type!: DocsBlockItemPropertyType.richText
}
