import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockMultiRichTextPropertyStyle } from "../../../../../enums/SDKDocsBlockMultiRichTextPropertyStyle"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.ordered-list"
const name = "Numbered list"
const icon = "https://cdn-assets.supernova.io/blocks/icons/list-ordered.svg"
const descriptionShort = "An ordered list with numbers"
const searchKeywords = ["ol", "ordered"]

const descriptionFull =
  "Display a sequence of numbers or letters to indicate order."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/text/list-UC5iPZLK"

/**
 * 2. Block properties
 */
const properties = {
  text: b.multiRichTextProperty({
    id: "text",
    name: "Text",
    options: {
      multiRichTextStyle: DocsBlockMultiRichTextPropertyStyle.ol,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.text],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.text],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.text,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as OrderedList }
