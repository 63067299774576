/* eslint-disable max-lines */
//
//  AreaTokens.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  BlurToken,
  BlurType,
  BorderPosition,
  BorderStyle,
  BorderToken,
  GradientToken,
  GradientType,
  OpacityToken,
  RadiusToken,
  ShadowToken,
  ShadowType,
  TextCaseToken,
  TextDecorationToken,
  TokenTheme,
  TypographyToken,
  VisibilityToken,
} from "../../exports"
import { ColorToken } from "../../model/tokens/SDKColorToken"
import {
  BorderWidthToken,
  DimensionToken,
  DurationToken,
  FontSizeToken,
  LetterSpacingToken,
  LineHeightToken,
  ParagraphSpacingToken,
  SizeToken,
  SpaceToken,
  ZIndexToken,
} from "../../model/tokens/SDKDimensionToken"
import {
  FontFamilyToken,
  FontWeightToken,
  ProductCopyToken,
  StringToken,
} from "../../model/tokens/SDKStringToken"
import {
  AnyMultiLayerToken,
  AnySingleLayerToken,
  AnyToken,
  BorderWidthTokenValue,
  ColorTokenValue,
  ColorValue,
  DimensionTokenValue,
  DurationTokenValue,
  FontFamilyTokenValue,
  FontSizeTokenValue,
  FontWeightTokenValue,
  LetterSpacingTokenValue,
  LineHeightTokenValue,
  OpacityTokenValue,
  ParagraphSpacingTokenValue,
  ProductCopyTokenValue,
  RadiusTokenValue,
  RawValue,
  SizeTokenValue,
  SpaceTokenValue,
  StringTokenValue,
  TextCaseTokenValue,
  TextDecorationTokenValue,
  VisibilityTokenValue,
  ZIndexTokenValue,
} from "../../model/tokens/SDKTokenValue"
import { TokenUtils } from "../../utils/TokenUtils"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Token Values Area

export class AreaTokenValues {
  /** Creates a new theme with updated overrides */
  tokenThemeByUpdatingOverride(
    theme: TokenTheme,
    override: AnyToken
  ): TokenTheme {
    return TokenUtils.tokenThemeByUpdatingOverride(theme, override)
  }

  /** Creates a new theme with removed override */
  tokenThemeByRemovingOverride(theme: TokenTheme, tokenId: string): TokenTheme {
    return TokenUtils.tokenThemeByRemovingOverride(theme, tokenId)
  }

  /** Creates a new color token with updated .color value */
  colorTokenByUpdatingColor(
    token: ColorToken,
    newValue: ColorValue | ColorToken
  ): ColorToken {
    return TokenUtils.colorTokenByUpdatingColor(token, newValue)
  }

  /** Creates a new color token with updated .opacity value */
  colorTokenByUpdatingOpacity(
    token: ColorToken,
    newValue: number | OpacityToken
  ): ColorToken {
    return TokenUtils.colorTokenByUpdatingOpacity(token, newValue)
  }

  /** Creates a new blur token with updated .type value */
  blurTokenByUpdatingType(token: BlurToken, newValue: BlurType): BlurToken {
    return TokenUtils.blurTokenByUpdatingType(token, newValue)
  }

  /** Creates a new blur token with updated .radius value */
  blurTokenByUpdatingRadius(
    token: BlurToken,
    // @ts-expect-error TS(2344): Type 'DimensionTokenValue' does not satisfy the co... Remove this comment to see the full error message
    newValue: RawValue<DimensionTokenValue> | RadiusToken
  ): BlurToken {
    return TokenUtils.blurTokenByUpdatingRadius(token, newValue)
  }

  /** Creates a new typography token with updated .fontFamily value */
  typographyTokenByUpdatingFontFamily(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'FontFamilyTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<FontFamilyTokenValue> | FontFamilyToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingFontFamily(token, newValue)
  }

  /** Creates a new typography token with updated .fontWeight value */
  typographyTokenByUpdatingFontWeight(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'FontWeightTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<FontWeightTokenValue> | FontWeightToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingFontWeight(token, newValue)
  }

  /** Creates a new typography token with updated .fontSize value */
  typographyTokenByUpdatingFontSize(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'FontSizeTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<FontSizeTokenValue> | FontSizeToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingFontSize(token, newValue)
  }

  /** Creates a new typography token with updated .letterSpacing value */
  typographyTokenByUpdatingLetterSpacing(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'LetterSpacingTokenValue' does not satisfy th... Remove this comment to see the full error message
    newValue: RawValue<LetterSpacingTokenValue> | LetterSpacingToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingLetterSpacing(token, newValue)
  }

  /** Creates a new typography token with updated .lineHeight value */
  typographyTokenByUpdatingLineHeight(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'LineHeightTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<LineHeightTokenValue> | LineHeightToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingLineHeight(token, newValue)
  }

  /** Creates a new typography token with updated .paragraphSpacing value */
  typographyTokenByUpdatingParagraphSpacing(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'ParagraphSpacingTokenValue' does not satisfy... Remove this comment to see the full error message
    newValue: RawValue<ParagraphSpacingTokenValue> | ParagraphSpacingToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingParagraphSpacing(token, newValue)
  }

  /** Creates a new typography token with updated .paragraphSpacing value */
  typographyTokenByUpdatingParagraphIndent(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'ParagraphSpacingTokenValue' does not satisfy... Remove this comment to see the full error message
    newValue: RawValue<ParagraphSpacingTokenValue> | ParagraphSpacingToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingParagraphIndent(token, newValue)
  }

  /** Creates a new border token with updated .position value */
  typographyTokenByUpdatingTextDecoration(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'TextDecorationTokenValue' does not satisfy t... Remove this comment to see the full error message
    newValue: RawValue<TextDecorationTokenValue> | TextDecorationToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingTextDecoration(token, newValue)
  }

  /** Creates a new typography token with updated .textCase value */
  typographyTokenByUpdatingTextCase(
    token: TypographyToken,
    // @ts-expect-error TS(2344): Type 'TextCaseTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<TextCaseTokenValue> | TextCaseToken
  ): TypographyToken {
    return TokenUtils.typographyTokenByUpdatingTextCase(token, newValue)
  }

  /** Creates a new border token with updated .color value. Do note, opacity is ignored */
  borderTokenByUpdatingColor(
    token: BorderToken,
    newValue: ColorValue | ColorToken
  ): BorderToken {
    return TokenUtils.borderTokenByUpdatingColor(token, newValue)
  }

  /** Creates a new border token with updated color.opacity value */
  borderTokenByUpdatingOpacity(
    token: BorderToken,
    newValue: number | OpacityToken
  ): BorderToken {
    return TokenUtils.borderTokenByUpdatingOpacity(token, newValue)
  }

  /** Creates a new border token with updated .width value */
  borderTokenByUpdatingWidth(
    token: BorderToken,
    // @ts-expect-error TS(2344): Type 'BorderWidthTokenValue' does not satisfy the ... Remove this comment to see the full error message
    newValue: RawValue<BorderWidthTokenValue> | BorderWidthToken
  ): BorderToken {
    return TokenUtils.borderTokenByUpdatingWidth(token, newValue)
  }

  /** Creates a new border token with updated .position value */
  borderTokenByUpdatingPosition(
    token: BorderToken,
    newValue: BorderPosition
  ): BorderToken {
    return TokenUtils.borderTokenByUpdatingPosition(token, newValue)
  }

  /** Creates a new border token with updated .style value */
  borderTokenByUpdatingStyle(
    token: BorderToken,
    newValue: BorderStyle
  ): BorderToken {
    return TokenUtils.borderTokenByUpdatingStyle(token, newValue)
  }

  /** Creates a new dimension token with updated value */
  dimensionTokenByUpdatingValue(
    token: DimensionToken,
    // @ts-expect-error TS(2344): Type 'DimensionTokenValue' does not satisfy the co... Remove this comment to see the full error message
    newValue: RawValue<DimensionTokenValue>
  ): DimensionToken {
    return TokenUtils.dimensionTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new size token with updated value */
  sizeTokenByUpdatingValue(
    token: SizeToken,
    // @ts-expect-error TS(2344): Type 'SizeTokenValue' does not satisfy the constra... Remove this comment to see the full error message
    newValue: RawValue<SizeTokenValue>
  ): SizeToken {
    return TokenUtils.sizeTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new space token with updated value */
  spaceTokenByUpdatingValue(
    token: SpaceToken,
    // @ts-expect-error TS(2344): Type 'SpaceTokenValue' does not satisfy the constr... Remove this comment to see the full error message
    newValue: RawValue<SpaceTokenValue>
  ): SpaceToken {
    return TokenUtils.spaceTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new opacity token with updated value */
  opacityTokenByUpdatingValue(
    token: OpacityToken,
    // @ts-expect-error TS(2344): Type 'OpacityTokenValue' does not satisfy the cons... Remove this comment to see the full error message
    newValue: RawValue<OpacityTokenValue>
  ): OpacityToken {
    return TokenUtils.opacityTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new font size token with updated value */
  fontSizeTokenByUpdatingValue(
    token: FontSizeToken,
    // @ts-expect-error TS(2344): Type 'FontSizeTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<FontSizeTokenValue>
  ): FontSizeToken {
    return TokenUtils.fontSizeTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new font size token with updated value */
  lineHeightTokenByUpdatingValue(
    token: LineHeightToken,
    // @ts-expect-error TS(2344): Type 'LineHeightTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<LineHeightTokenValue>
  ): LineHeightToken {
    return TokenUtils.lineHeightTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new letter spacing token with updated value */
  letterSpacingTokenByUpdatingValue(
    token: LetterSpacingToken,
    // @ts-expect-error TS(2344): Type 'LetterSpacingTokenValue' does not satisfy th... Remove this comment to see the full error message
    newValue: RawValue<LetterSpacingTokenValue>
  ): LetterSpacingToken {
    return TokenUtils.letterSpacingTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new paragraph spacing token with updated value */
  paragraphSpacingTokenByUpdatingValue(
    token: ParagraphSpacingToken,
    // @ts-expect-error TS(2344): Type 'ParagraphSpacingTokenValue' does not satisfy... Remove this comment to see the full error message
    newValue: RawValue<ParagraphSpacingTokenValue>
  ): ParagraphSpacingToken {
    return TokenUtils.paragraphSpacingTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new border width token with updated value */
  borderWidthTokenByUpdatingValue(
    token: BorderWidthToken,
    // @ts-expect-error TS(2344): Type 'BorderWidthTokenValue' does not satisfy the ... Remove this comment to see the full error message
    newValue: RawValue<BorderWidthTokenValue>
  ): BorderWidthToken {
    return TokenUtils.borderWidthTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new radius token with updated value */
  radiusTokenByUpdatingValue(
    token: RadiusToken,
    // @ts-expect-error TS(2344): Type 'RadiusTokenValue' does not satisfy the const... Remove this comment to see the full error message
    newValue: RawValue<RadiusTokenValue>
  ): RadiusToken {
    return TokenUtils.radiusTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new duration token with updated value */
  durationTokenByUpdatingValue(
    token: DurationToken,
    // @ts-expect-error TS(2344): Type 'DurationTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<DurationTokenValue>
  ): DurationToken {
    return TokenUtils.durationTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new z-index token with updated value */
  zIndexTokenByUpdatingValue(
    token: ZIndexToken,
    // @ts-expect-error TS(2344): Type 'ZIndexTokenValue' does not satisfy the const... Remove this comment to see the full error message
    newValue: RawValue<ZIndexTokenValue>
  ): ZIndexToken {
    return TokenUtils.zIndexTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new string token with updated value */
  stringTokenByUpdatingValue(
    token: StringToken,
    // @ts-expect-error TS(2344): Type 'StringTokenValue' does not satisfy the const... Remove this comment to see the full error message
    newValue: RawValue<StringTokenValue>
  ): StringToken {
    return TokenUtils.stringTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new product copy token with updated value */
  productCopyTokenByUpdatingValue(
    token: ProductCopyToken,
    // @ts-expect-error TS(2344): Type 'ProductCopyTokenValue' does not satisfy the ... Remove this comment to see the full error message
    newValue: RawValue<ProductCopyTokenValue>
  ): ProductCopyToken {
    return TokenUtils.productCopyTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new font family token with updated value */
  fontFamilyTokenByUpdatingValue(
    token: FontFamilyToken,
    // @ts-expect-error TS(2344): Type 'FontFamilyTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<FontFamilyTokenValue>
  ): FontFamilyToken {
    return TokenUtils.fontFamilyTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new font weight token with updated value */
  fontWeightTokenByUpdatingValue(
    token: FontWeightToken,
    // @ts-expect-error TS(2344): Type 'FontWeightTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<FontWeightTokenValue>
  ): FontWeightToken {
    return TokenUtils.fontWeightTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new text case token with updated value */
  textCaseTokenByUpdatingValue(
    token: TextCaseToken,
    // @ts-expect-error TS(2344): Type 'TextCaseTokenValue' does not satisfy the con... Remove this comment to see the full error message
    newValue: RawValue<TextCaseTokenValue>
  ): TextCaseToken {
    return TokenUtils.textCaseTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new text case token with updated value */
  textDecorationTokenByUpdatingValue(
    token: TextDecorationToken,
    // @ts-expect-error TS(2344): Type 'TextDecorationTokenValue' does not satisfy t... Remove this comment to see the full error message
    newValue: RawValue<TextDecorationTokenValue>
  ): TextDecorationToken {
    return TokenUtils.textDecorationTokenByUpdatingValue(token, newValue)
  }

  /** Creates a new visibility token with updated value */
  visibilityTokenByUpdatingValue(
    token: VisibilityToken,
    // @ts-expect-error TS(2344): Type 'VisibilityTokenValue' does not satisfy the c... Remove this comment to see the full error message
    newValue: RawValue<VisibilityTokenValue>
  ): VisibilityToken {
    return TokenUtils.visibilityTokenByUpdatingValue(token, newValue)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Multi-layer token manipulation

  /** Creates a new shadow token with one new extra layer added at the end of all layers */
  shadowTokenByAddingLayer(token: ShadowToken): ShadowToken {
    return TokenUtils.shadowTokenByAddingLayer(token)
  }

  /** Creates a new shadow token by removing one of the layers */
  shadowTokenByRemovingLayer(
    token: ShadowToken,
    layerIndex: number
  ): ShadowToken {
    return TokenUtils.shadowTokenByRemovingLayer(token, layerIndex)
  }

  /** Creates a new shadow token with updated .color value. Do note, opacity is ignored */
  shadowTokenByUpdatingColor(
    token: ShadowToken,
    layerIndex: number,
    newValue: ColorValue | ColorToken
  ): ShadowToken {
    return TokenUtils.shadowTokenByUpdatingColor(token, layerIndex, newValue)
  }

  /** Creates a new shadow token with updated .color value */
  shadowTokenByUpdatingOpacity(
    token: ShadowToken,
    layerIndex: number,
    newValue: number | OpacityToken
  ): ShadowToken {
    return TokenUtils.shadowTokenByUpdatingOpacity(token, layerIndex, newValue)
  }

  /** Creates a new shadow token with updated .x value */
  shadowTokenByUpdatingX(
    token: ShadowToken,
    layerIndex: number,
    newValue: number
  ): ShadowToken {
    return TokenUtils.shadowTokenByUpdatingX(token, layerIndex, newValue)
  }

  /** Creates a new shadow token with updated .y value */
  shadowTokenByUpdatingY(
    token: ShadowToken,
    layerIndex: number,
    newValue: number
  ): ShadowToken {
    return TokenUtils.shadowTokenByUpdatingY(token, layerIndex, newValue)
  }

  /** Creates a new shadow token with updated .radius value */
  shadowTokenByUpdatingRadius(
    token: ShadowToken,
    layerIndex: number,
    newValue: number
  ): ShadowToken {
    return TokenUtils.shadowTokenByUpdatingRadius(token, layerIndex, newValue)
  }

  /** Creates a new shadow token with updated .spread value */
  shadowTokenByUpdatingSpread(
    token: ShadowToken,
    layerIndex: number,
    newValue: number
  ): ShadowToken {
    return TokenUtils.shadowTokenByUpdatingSpread(token, layerIndex, newValue)
  }

  /** Creates a new shadow token with updated .type value */
  shadowTokenByUpdatingType(
    token: ShadowToken,
    layerIndex: number,
    newValue: ShadowType
  ): ShadowToken {
    return TokenUtils.shadowTokenByUpdatingType(token, layerIndex, newValue)
  }

  /** Creates a new gradient token with updated .to value */
  gradientTokenByUpdatingTo(
    token: GradientToken,
    layerIndex: number,
    newValue: { x: number; y: number }
  ): GradientToken {
    return TokenUtils.gradientTokenByUpdatingTo(token, layerIndex, newValue)
  }

  /** Creates a new gradient token with updated .from value */
  gradientTokenByUpdatingFrom(
    token: GradientToken,
    layerIndex: number,
    newValue: { x: number; y: number }
  ): GradientToken {
    return TokenUtils.gradientTokenByUpdatingFrom(token, layerIndex, newValue)
  }

  /** Creates a new gradient token with updated .to value */
  gradientTokenByUpdatingType(
    token: GradientToken,
    layerIndex: number,
    newValue: GradientType
  ): GradientToken {
    return TokenUtils.gradientTokenByUpdatingType(token, layerIndex, newValue)
  }

  /** Creates a new gradient token with updated .aspectRatio value */
  gradientTokenByUpdatingAspectRatio(
    token: GradientToken,
    layerIndex: number,
    newValue: number
  ): GradientToken {
    return TokenUtils.gradientTokenByUpdatingAspectRatio(
      token,
      layerIndex,
      newValue
    )
  }

  /** Creates a new gradient token with updated .to value */
  gradientTokenByUpdatingStops(
    token: GradientToken,
    layerIndex: number,
    newValue: Array<{
      position: number
      color: ColorTokenValue | ColorToken
    }>
  ): GradientToken {
    return TokenUtils.gradientTokenByUpdatingStops(token, layerIndex, newValue)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Manipulating references

  /** Creates a new token where value is reference to the provided token. Token and reference must be of the same type */
  tokenByReferencing<T extends AnyToken>(token: T, reference: T): T {
    return TokenUtils.tokenByReferencing(token, reference)
  }

  /** Creates a new multilayer token where one of the layers is reference to the provided token. Token and reference must be of the same type */
  multilayerTokenByReferencing<T extends AnyMultiLayerToken, R extends T>(
    token: T,
    layerIndex: number,
    reference: R
  ): T {
    return TokenUtils.multilayerTokenByReferencing(token, layerIndex, reference)
  }

  /** Creates a new token where value is raw, but taken from the previously referenced token. Token must have had reference before */
  tokenByDisconnectingReference<T extends AnySingleLayerToken>(token: T): T {
    return TokenUtils.tokenByDisconnectingReference(token)
  }

  /** Creates a new multilayer token where one of the layers value is raw, but taken from the previously referenced token. That layer must have had reference before */
  multilayerTokenByDisconnectingReference<T extends AnySingleLayerToken>(
    token: T,
    layerIndex: number
  ): T {
    return TokenUtils.multilayerTokenByDisconnectingReference(token, layerIndex)
  }

  /** Checks if token as a whole is a reference to another token */
  isReferencedToken(token: AnySingleLayerToken): boolean {
    return TokenUtils.isReferencedToken(token)
  }

  /** Checks if a token layer of multilayer token is a reference to another token */
  isReferencedMultilayerTokenLayer(
    token: AnyMultiLayerToken,
    layerIndex: number
  ): boolean {
    return TokenUtils.isReferencedMultilayerTokenLayer(token, layerIndex)
  }

  /** Checks if a token was imported from some foreign source. Not imported tokens were always created in Supernova */
  isTokenImported(token: AnyToken): boolean {
    return TokenUtils.isTokenImported(token)
  }
}
