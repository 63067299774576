//
//  SDKDataBridge.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { GitProviderValues } from "../../model/workspaces/SDKWorkspaceIntegrationGetGitProviders"
import {
  SupernovaBackendError,
  SupernovaError,
} from "../errors/SDKSupernovaError"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

type DataBridgeRequestHookResult = { skipDefaultAuth?: boolean }
export type DataBridgeRequestHook = (
  request: any
) =>
  | void
  | DataBridgeRequestHookResult
  | Promise<void | DataBridgeRequestHookResult>

export type DebugResponseObserver = (info: {
  requestUrl: string
  response: any
  executionTime: number
  error?: Error
}) => void

export type DebugRequestObserver = (info: {
  requestUrl: string
  requestMethod: string
}) => void

export type DataBridgeConfiguration = {
  apiUrl: string
  apiVersion: string
  accessToken: string
  target: string | null
  bypassEnvFetch: boolean
  requestHook: DataBridgeRequestHook | null
  debugRequestObserver?: DebugRequestObserver | null
  debugResponseObserver?: DebugResponseObserver | null
  proxyUrl?: string | null
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Bridge implementation

export class DataBridge {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Configuration

  authToken: string

  apiUrl: string

  apiVersion: string

  target: string | null

  bypassEnvFetch = false

  proxyUrl: string | undefined

  requestHook: DataBridgeRequestHook | null

  debugRequestObserver: DebugRequestObserver | null

  debugResponseObserver: DebugResponseObserver | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(conf: DataBridgeConfiguration) {
    this.authToken = conf.accessToken
    this.apiUrl = conf.apiUrl
    this.apiVersion = conf.apiVersion
    this.target = conf.target
    this.bypassEnvFetch = conf.bypassEnvFetch
    this.proxyUrl = conf.proxyUrl ?? undefined
    this.requestHook = conf.requestHook
    this.debugRequestObserver = conf.debugRequestObserver ?? null
    this.debugResponseObserver = conf.debugResponseObserver ?? null
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Requests

  async delete(endpoint: string): Promise<any> {
    return this.request(endpoint, undefined, "DELETE")
  }

  async get(endpoint: string): Promise<any> {
    return this.request(endpoint, undefined, "GET")
  }

  async getAsData(endpoint: string): Promise<any> {
    return this.request(endpoint, undefined, "GET", true)
  }

  async put(endpoint: string, payload: any | null): Promise<any> {
    return this.request(endpoint, payload, "PUT")
  }

  async post(endpoint: string, payload: any | null): Promise<any> {
    return this.request(endpoint, payload, "POST")
  }

  async upload(
    endpoint: string,
    formData: FormData,
    method: "PUT" | "POST" = "PUT"
  ): Promise<any> {
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    const config = await this.buildRequestConfig(method, undefined)
    const proxyAgent = await this.getProxyAgent()
    const invoker = await this.getFetchInvoker()

    // Make authorized ds request
    return new Promise((resolve, reject) => {
      try {
        const headers = { ...config.headers }

        // @ts-expect-error TS(2790): The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
        delete headers["Content-Type"]

        invoker(endpoint, {
          method: config.method,
          // @ts-expect-error TS(2322): Type '{ Authorization: undefined; "Content-Type": ... Remove this comment to see the full error message
          headers,
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          body: formData as any,
          agent: proxyAgent,
        })
          .then(async (response) => {
            // TODO:fix-sdk-eslint
            // @ts-expect-error TS(2345): Argument of type 'Response | Response' is not assi... Remove this comment to see the full error message
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return this.handleResponse(response, endpoint)
          })
          .then((jsonResponse) => {
            resolve(jsonResponse)
          })
          .catch((error) => {
            reject(error)
          })
      } catch (error) {
        reject(error)
      }
    })
  }

  private async request(
    endpoint: string,
    payload: any | null,
    method: "GET" | "POST" | "PUT" | "DELETE",
    asData = false
  ): Promise<any> {
    const config = await this.buildRequestConfig(method, "application/json")
    const proxyAgent = await this.getProxyAgent()
    const invoker = await this.getFetchInvoker()

    // Make authorized ds request
    return new Promise((resolve, reject) => {
      try {
        invoker(endpoint, {
          method: config.method,
          // @ts-expect-error TS(2322): Type '{ Authorization: undefined; "Content-Type": ... Remove this comment to see the full error message
          headers: config.headers,
          // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type '(B... Remove this comment to see the full error message
          body: payload ? JSON.stringify(payload) : null,
          agent: proxyAgent,
        })
          .then(async (response) => {
            // TODO:fix-sdk-eslint
            // @ts-expect-error TS(2345): Argument of type 'Response | Response' is not assi... Remove this comment to see the full error message
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return this.handleResponse(response, endpoint, asData)
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      } catch (error) {
        reject(error)
      }
    })
  }

  private async handleResponse(
    response: Response,
    endpoint: string,
    asData = false
  ): Promise<any> {
    if (!response.ok) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const errorResponse: SupernovaBackendError | undefined =
        await response.json()

      if (errorResponse) {
        // Server responded with proper error in JSON format
        const error = SupernovaError.fromNetworkResponse(
          errorResponse,
          response.status,
          endpoint
        )

        throw error
      } else {
        // Server didn't respond or responded with invalid JSON
        const error = SupernovaError.fromNoNetworkResponse(endpoint)
        throw error
      }
    }

    if (asData) {
      return response.arrayBuffer()
    }
    return response.json()
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Request configuration

  private async buildRequestConfig(
    method: "GET" | "POST" | "PUT" | "DELETE",
    contentType: "application/json" | "multipart/form-data"
  ) {
    const config = {
      method,
      headers: {
        ...(contentType && { "Content-Type": contentType }),
        Authorization: undefined,
      },
    }

    let skipAuth = false

    if (this.requestHook) {
      const hookResult = await this.requestHook(config)

      if (hookResult && hookResult.skipDefaultAuth) {
        skipAuth = true
      }
    }

    if (!skipAuth) {
      const token = this.authToken

      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  }

  private async getProxyAgent() {
    if (this.proxyUrl) {
      const { HttpsProxyAgent } = await import("https-proxy-agent")

      return new HttpsProxyAgent(this.proxyUrl)
    }

    return undefined
  }

  private async getFetchInvoker() {
    return this.bypassEnvFetch ? (await import("node-fetch")).default : fetch
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Endpoints

  getBaseEndpoint(fragment: string | null): string {
    if (fragment) {
      return `${this.apiUrl}/${fragment}`
    }

    return `${this.apiUrl}`
  }

  getPublicEndpoint(fragment: string): string {
    return `${this.apiUrl}/public/${fragment}`
  }

  getAclEndpoint(resource?: string): string {
    if (!resource) {
      return `${this.getPublicEndpoint("acl")}`
    }

    return `${this.getPublicEndpoint("acl")}/${resource}`
  }

  getUserEndpoint(userId: string, fragment: string | null): string {
    if (fragment) {
      return `${this.apiUrl}/users/${userId}/${fragment}`
    }

    return `${this.apiUrl}/users/${userId}`
  }

  getUserWorkspaceEndpoint(
    userId: string,
    wsId: string,
    fragment: string | null
  ): string {
    if (fragment) {
      return `${this.apiUrl}/users/${userId}/workspaces/${wsId}/${fragment}`
    }

    return `${this.apiUrl}/users/${userId}/workspaces/${wsId}`
  }

  getWorkspaceEndpoint(wsId: string, fragment: string | null): string {
    if (fragment) {
      return `${this.apiUrl}/workspaces/${wsId}/${fragment}`
    }

    return `${this.apiUrl}/workspaces/${wsId}`
  }

  getWorkspaceIntegrationCredentialEndpoint(
    wsId: string,
    integrationId: string,
    credentialId: string
  ): string {
    return `${this.getWorkspaceEndpoint(
      wsId,
      null
    )}/integrations/${integrationId}/credentials/${credentialId}`
  }

  getWorkspaceIntegrationEndpoint(wsId: string, integrationId: string): string {
    return `${this.getWorkspaceEndpoint(
      wsId,
      null
    )}/integrations/${integrationId}`
  }

  getWorkspaceIntegrationGitProvidersEndpoint(
    wsId: string,
    integrationId: string,
    operation: GitProviderValues
  ): string {
    return `${this.getWorkspaceIntegrationEndpoint(
      wsId,
      integrationId
    )}/git/${operation}`
  }

  getDesignSystemEndpoint(dsId: string, fragment: string | null): string {
    if (fragment) {
      return `${this.apiUrl}/design-systems/${dsId}/${fragment}`
    }

    return `${this.apiUrl}/design-systems/${dsId}`
  }

  getVersionEndpoint(
    dsId: string,
    dsVersionId: string,
    fragment: string | null
  ): string {
    if (fragment) {
      return `${this.getDesignSystemEndpoint(
        dsId,
        `versions/${dsVersionId}/${fragment}`
      )}`
    }

    return `${this.getDesignSystemEndpoint(dsId, `versions/${dsVersionId}`)}`
  }

  getVersionCreationJobEndpoint(dsId: string, jobId: string): string {
    return `${this.getDesignSystemEndpoint(dsId, `versions/job/${jobId}`)}`
  }

  getVersionCreationJobsEndpoint(dsId: string): string {
    return `${this.getDesignSystemEndpoint(dsId, `versions/jobs`)}`
  }
}
