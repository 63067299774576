//
//  SDKDocsBlockCodeProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { CodeLanguage } from "../../../enums/SDKCodeLanguage"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
  DocsBlockPropertyData,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Code property - Additional non-value options */
export type DocsBlockCodePropertyOptions = Record<string, never>

/** Code property - Data */
export type DocsBlockCodePropertyData = DocsBlockPropertyData<string> & {
  format?: CodeLanguage
  caption?: string
}

/** Code property - Definition model */
export type DocsBlockCodePropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockCodePropertyOptions

/** Code property - Definition */
export class DocsBlockCodePropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockCodePropertyOptions,
  DocsBlockCodePropertyData
> {
  type!: DocsBlockItemPropertyType.code
}
