//
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export enum ResourceType {
  asset = "Asset",
  font = "Font",
}

export type ResourceModel = {
  id: string
  persistentId: string
  url: string
  originalName?: string
  scope: "DesignSystem" | "Documentation"
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class Resource {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Unique identifier of the resource */
  id: string

  /** Unique identifier of the resource in the version */
  idInVersion: string

  /** URL of the resource */
  url: string

  /** Original filename of the resource */
  originalName: string | null

  /** Scope of the resource */
  scope: "DesignSystem" | "Documentation"

  /** Type of the resource */
  type: ResourceType

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: ResourceModel) {
    this.id = model.persistentId
    this.idInVersion = model.id
    this.url = model.url
    this.originalName = model.originalName ?? null
    this.scope = model.scope
    this.type = ResourceType.asset
  }
}
