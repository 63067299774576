//
//  AreaDataSources.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
import { FigmaStructure } from "../../model/support/SDKFigmaStructure"
import { FigmaStructureDetail } from "../../model/support/SDKFigmaStructureDetail"
import { Source, SourceRemoteModelCloud } from "../../model/support/SDKSource"
import { DataCore } from "../data/SDKDataCore"

import {
  RemoteBrandIdentifier,
  RemoteVersionIdentifier,
} from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaDataSources {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Retrieves a single source by its id
   * @param from - Remote version to fetch from
   * @returns Single data source
   */
  async getDataSourceById(
    from: RemoteVersionIdentifier,
    sourceId: string
  ): Promise<Source> {
    return this.dataCore.designSystemSource(from.designSystemId, sourceId)
  }

  /** Retrieves all sources associated with specified design system
   * @param from - Remote version to fetch from
   * @returns All data sources in the specified version
   */
  async getDataSources(from: RemoteVersionIdentifier): Promise<Array<Source>> {
    return this.dataCore.designSystemSources(from.designSystemId)
  }

  /** Retrieves all figma frames structures
   * @param from - Remote version to fetch from
   * @returns All data sources in the specified version
   */
  async getFigmaNodeStructures(
    from: RemoteVersionIdentifier
  ): Promise<Array<FigmaStructure>> {
    return this.dataCore.getFigmaNodeStructures(
      from.designSystemId,
      from.versionId
    )
  }

  /** Retrieves figma frame structure detail
   * @param from - Remote version to fetch from
   * @returns All data sources in the specified version
   */
  async getFigmaNodeStructureDetail(
    from: RemoteVersionIdentifier & {
      figmaStructureId: string
    }
  ): Promise<FigmaStructureDetail> {
    return this.dataCore.getFigmaNodeStructureDetail(
      from.designSystemId,
      from.versionId,
      from.figmaStructureId
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  async linkFigmaSource(
    from: RemoteBrandIdentifier,
    url: string,
    options: {
      scopes: {
        tokens: boolean
        components: boolean
        assets: boolean
        documentationFrames: boolean
        isUnpublishedContentFallbackEnabled: boolean
      }
      autoImport: SourceRemoteModelCloud["autoImportMode"]
      themeId: string | null
    }
  ): Promise<Source> {
    return this.dataCore.linkFigmaSource(
      from.designSystemId,
      from.brandId,
      url,
      options
    )
  }

  async updateFigmaSource(
    from: RemoteVersionIdentifier,
    sourceId: string,
    options: {
      scopes?: {
        tokens: boolean
        components: boolean
        assets: boolean
        documentationFrames: boolean
        isUnpublishedContentFallbackEnabled: boolean
      }
      autoImport?: SourceRemoteModelCloud["autoImportMode"]
      themeId?: string | null
    }
  ): Promise<Source> {
    return this.dataCore.updateFigmaSource(
      from.designSystemId,
      sourceId,
      options
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  async deleteFigmaSource(
    from: RemoteVersionIdentifier,
    sourceId: string
  ): Promise<void> {
    return this.dataCore.deleteFigmaSource(from.designSystemId, sourceId)
  }
}
