//
//  SDKDocsBlockTokenPropertyProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** TokenProperty property - Additional non-value options */
export type DocsBlockTokenPropertyPropertyOptions = Record<string, never>

/** TokenProperty property - Data */
export type DocsBlockTokenPropertyPropertyData = DocsBlockPropertyData<
  Array<string>
> &
  Record<string, never>

/** TokenProperty property - Definition model */
export type DocsBlockTokenPropertyPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockTokenPropertyPropertyOptions

/** TokenProperty property - Definition */
export class DocsBlockTokenPropertyPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockTokenPropertyPropertyOptions,
  DocsBlockTokenPropertyPropertyData
> {
  type!: DocsBlockItemPropertyType.tokenProperty
}
