//
//  SDKTokenTheme.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { UnreachableCaseError } from "../../utils/UnreachableCaseError"
import { TokenType } from "../enums/SDKTokenType"
import { BlurToken } from "../tokens/SDKBlurToken"
import { BorderToken } from "../tokens/SDKBorderToken"
import { ColorToken } from "../tokens/SDKColorToken"
import {
  BorderWidthToken,
  DimensionToken,
  DurationToken,
  FontSizeToken,
  LetterSpacingToken,
  LineHeightToken,
  OpacityToken,
  ParagraphSpacingToken,
  RadiusToken,
  SizeToken,
  SpaceToken,
  ZIndexToken,
} from "../tokens/SDKDimensionToken"
import { GradientToken } from "../tokens/SDKGradientToken"
import { ShadowToken } from "../tokens/SDKShadowToken"
import {
  FontFamilyToken,
  FontWeightToken,
  ProductCopyToken,
  StringToken,
} from "../tokens/SDKStringToken"
import { TextCaseToken } from "../tokens/SDKTextCaseToken"
import { TextDecorationToken } from "../tokens/SDKTextDecorationToken"
import { Token } from "../tokens/SDKToken"
import {
  AnyToken,
  AnyTokenValue,
  BlurTokenValue,
  BorderTokenValue,
  BorderWidthTokenValue,
  ColorTokenValue,
  DimensionTokenValue,
  DurationTokenValue,
  FontFamilyTokenValue,
  FontSizeTokenValue,
  FontWeightTokenValue,
  GradientTokenValue,
  LetterSpacingTokenValue,
  LineHeightTokenValue,
  OpacityTokenValue,
  ParagraphSpacingTokenValue,
  ProductCopyTokenValue,
  RadiusTokenValue,
  ShadowTokenValue,
  SizeTokenValue,
  SpaceTokenValue,
  StringTokenValue,
  TextCaseTokenValue,
  TextDecorationTokenValue,
  TypographyTokenValue,
  VisibilityTokenValue,
  ZIndexTokenValue,
} from "../tokens/SDKTokenValue"
import { TypographyToken } from "../tokens/SDKTypographyToken"
import { VisibilityToken } from "../tokens/SDKVisibilityToken"

import { TokenThemeOverrideRemoteModel } from "./SDKTokenThemeOverride"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type TokenThemeRemoteModel = {
  meta: {
    name: string
    description: string
  }
  id?: string
  persistentId: string
  designSystemVersionId: string
  brandId: string
  codeName: string
  createdAt?: string
  updatedAt?: string
  overrides: Array<TokenThemeOverrideRemoteModel>
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class TokenTheme {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  idInVersion: string

  brandId: string

  designSystemVersionId: string

  name: string

  description: string

  codeName: string

  createdAt: Date | null

  updatedAt: Date | null

  overriddenTokens: Array<Token>

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: TokenThemeRemoteModel, versionId: string) {
    this.id = model.persistentId
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.idInVersion = model.id
    this.brandId = model.brandId
    this.designSystemVersionId = versionId
    this.name = model.meta.name
    this.description = model.meta.description
    this.codeName = model.codeName
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
    // Note overrides are provided from the resolver when they are computed
    this.overriddenTokens = []
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Convenience

  addOverride(override: Token) {
    this.overriddenTokens.push(override)
  }

  addOverrides(overrides: Array<Token>) {
    this.overriddenTokens = this.overriddenTokens.concat(overrides)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Manipulation

  toWriteObject(): TokenThemeRemoteModel {
    return {
      id: this.idInVersion,
      brandId: this.brandId,
      designSystemVersionId: this.designSystemVersionId,
      persistentId: this.id,
      meta: {
        name: this.name,
        description: this.description ?? "",
      },
      createdAt: this.createdAt ? this.createdAt.toISOString() : undefined,
      updatedAt: this.updatedAt ? this.updatedAt.toISOString() : undefined,
      codeName: this.codeName,
      overrides: this.tokensToOverrides(),
    }
  }

  private tokensToOverrides(): Array<TokenThemeOverrideRemoteModel> {
    return this.overriddenTokens.map((o) => this.toWriteOverrideObject(o))
  }

  toWriteOverrideObject(token: Token): TokenThemeOverrideRemoteModel {
    const model = {
      data: this.valueToWriteObject(
        (token as unknown as AnyToken).value,
        token.tokenType,
        token as AnyToken
      ),
      tokenPersistentId: token.id,
      type: token.tokenType,
      origin:
        token.origin && token.origin.id && token.origin.sourceId
          ? {
              id: token.origin.id ?? undefined,
              name: token.origin.name ?? undefined,
              sourceId: token.origin.sourceId ?? undefined,
            }
          : undefined,
      createdAt: token.createdAt ? token.createdAt.toISOString() : undefined,
      updatedAt: token.updatedAt ? token.updatedAt.toISOString() : undefined,
    }

    if (!model.data.aliasTo && !model.data.value) {
      throw new Error("Token doesn't have value or alias to")
    }

    // @ts-expect-error TS(2322): Type '{ data: { aliasTo: string | undefined; value... Remove this comment to see the full error message
    return model
  }

  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  valueToWriteObject(value: AnyTokenValue, type: TokenType, token: AnyToken) {
    switch (type) {
      case TokenType.blur:
        return BlurToken.valueToWriteObject(value as BlurTokenValue)
      case TokenType.border:
        return BorderToken.valueToWriteObject(value as BorderTokenValue)
      case TokenType.color:
        return ColorToken.valueToWriteObject(value as ColorTokenValue)
      case TokenType.gradient:
        return GradientToken.valueToWriteObject(value as GradientTokenValue[])
      case TokenType.dimension:
        return DimensionToken.valueToWriteObject(value as DimensionTokenValue)
      case TokenType.size:
        return SizeToken.valueToWriteObject(value as SizeTokenValue)
      case TokenType.space:
        return SpaceToken.valueToWriteObject(value as SpaceTokenValue)
      case TokenType.opacity:
        return OpacityToken.valueToWriteObject(value as OpacityTokenValue)
      case TokenType.fontSize:
        return FontSizeToken.valueToWriteObject(value as FontSizeTokenValue)
      case TokenType.lineHeight:
        return LineHeightToken.valueToWriteObject(value as LineHeightTokenValue)
      case TokenType.letterSpacing:
        return LetterSpacingToken.valueToWriteObject(
          value as LetterSpacingTokenValue
        )
      case TokenType.paragraphSpacing:
        return ParagraphSpacingToken.valueToWriteObject(
          value as ParagraphSpacingTokenValue
        )
      case TokenType.borderWidth:
        return BorderWidthToken.valueToWriteObject(
          value as BorderWidthTokenValue
        )
      case TokenType.radius:
        return RadiusToken.valueToWriteObject(value as RadiusTokenValue)
      case TokenType.duration:
        return DurationToken.valueToWriteObject(value as DurationTokenValue)
      case TokenType.zIndex:
        return ZIndexToken.valueToWriteObject(value as ZIndexTokenValue)
      case TokenType.shadow:
        return ShadowToken.valueToWriteObject(value as ShadowTokenValue[])
      case TokenType.string:
        return StringToken.valueToWriteObject(value as StringTokenValue)
      case TokenType.productCopy:
        return ProductCopyToken.valueToWriteObject(
          value as ProductCopyTokenValue
        )
      case TokenType.fontFamily:
        return FontFamilyToken.valueToWriteObject(value as FontFamilyTokenValue)
      case TokenType.fontWeight:
        return FontWeightToken.valueToWriteObject(value as FontWeightTokenValue)
      case TokenType.typography:
        return TypographyToken.valueToWriteObject(value as TypographyTokenValue)
      case TokenType.textCase:
        return TextCaseToken.valueToWriteObject(value as TextCaseTokenValue)
      case TokenType.textDecoration:
        return TextDecorationToken.valueToWriteObject(
          value as TextDecorationTokenValue
        )
      case TokenType.visibility:
        return VisibilityToken.valueToWriteObject(value as VisibilityTokenValue)
      default:
        throw new UnreachableCaseError(type)
    }
  }
}
