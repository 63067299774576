//
//  SDKTokenOrigin.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
import { SourceType } from "../enums/SDKSourceType"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type TokenOriginModel = {
  id?: string
  name?: string
  sourceId?: string
  sourceType?: SourceType
  referenceOriginId?: string
  referencePersistentId?: string
  referenceResolutionFailed?: boolean
  referenceOriginRemote?: boolean
  referenceOriginName?: string
  key?: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class TokenOrigin {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string | null

  name: string | null

  sourceId: string | null

  sourceType?: SourceType | null

  referenceOriginId?: string | null

  referencePersistentId?: string | null

  referenceResolutionFailed?: boolean | null

  referenceOriginRemote?: boolean | null

  referenceOriginName?: string | null

  key?: string | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: TokenOriginModel) {
    this.id = model.id ?? null
    this.name = model.name ?? null
    this.sourceId = model.sourceId ?? null
    this.sourceType = model.sourceType ?? null
    this.referenceOriginId = model.referenceOriginId ?? null
    this.referencePersistentId = model.referencePersistentId ?? null
    this.referenceResolutionFailed = model.referenceResolutionFailed ?? null
    this.referenceOriginRemote = model.referenceOriginRemote ?? null
    this.referenceOriginName = model.referenceOriginName ?? null
    this.key = model.key ?? null
  }
}
