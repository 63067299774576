//
//  SDKDesignSystemMemberList.ts
//  Supernova SDK
//
//  Created by Vsevolod Krasnov.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTODesignSystemMemberListResponse } from "@supernova-studio/client"

import {
  convertRemoteToDesignSystemRole,
  DesignSystemRole,
} from "../enums/SDKDesignSystemRole"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Model Definition

/**
 * Re-mapped from DesignSystemMember to User, because "member" encapsulates both users and invitations.
 */
export type DesignSystemUser = {
  userId: string
  designSystemRole: DesignSystemRole | null
}

export type DesignSystemInvitation = {
  id: string
  workspaceInvitationId: string
  designSystemRole: DesignSystemRole | null
}

export class DesignSystemMemberList {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /**
   * List of existing users that belong to the design system.
   *
   * Re-mapped from "members" to "users", because "member" encapsulates both users and invitations.
   */
  users: Array<DesignSystemUser>

  /** List of invitations that belong to the design system */
  invitations: Array<DesignSystemInvitation>

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTODesignSystemMemberListResponse) {
    this.users = model.members.map((m) => {
      return {
        userId: m.userId,
        designSystemRole: m.designSystemRole
          ? convertRemoteToDesignSystemRole(m.designSystemRole)
          : null,
      }
    })
    this.invitations = model.invitations.map((i) => {
      return {
        id: i.id,
        workspaceInvitationId: i.workspaceInvitationId,
        designSystemRole: i.designSystemRole
          ? convertRemoteToDesignSystemRole(i.designSystemRole)
          : null,
      }
    })
  }
}
