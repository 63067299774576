//
//  Supernova SDK
//  Pulsar Language
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { AssetFormat } from "../enums/SDKAssetFormat"
import { AssetScale } from "../enums/SDKAssetScale"
import { AssetGroup } from "../groups/SDKAssetGroup"

import { Asset } from "./SDKAsset"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type RenderedAssetModel = {
  assetId: string
  fileName: string
  sourceUrl: string
  settings: {
    prefix: string
    suffix: string
    scale: AssetScale
    format: AssetFormat
  }
  originalName: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class RenderedAsset {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  assetId: string

  fileName: string

  originalName: string

  sourceUrl: string

  asset: Asset

  group: AssetGroup

  scale: AssetScale

  format: AssetFormat

  previouslyDuplicatedNames: number

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    model: RenderedAssetModel,
    asset: Asset,
    group: AssetGroup,
    duplicates: number
  ) {
    this.assetId = model.assetId
    this.fileName = model.fileName
    this.sourceUrl = model.sourceUrl
    this.originalName = model.originalName
    this.asset = asset
    this.group = group
    this.scale = model.settings.scale
    this.format = model.settings.format
    this.previouslyDuplicatedNames = duplicates
  }
}
