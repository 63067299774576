export enum DocsBlockRichTextEditorPropertyStyle {
  /** Ordered list */
  ol = "OL",
  /** Unordered list */
  ul = "UL",
  /** Bold text */
  bold = "Bold",
  /** Italic text */
  italic = "Italic",
  /** Hyperlink */
  link = "Link",
  /** Strikethrough text */
  strikethrough = "Strikethrough",
  /** Inline code (mark) */
  inlineCode = "InlineCode",
}
