//
//  SDKImportJob.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { ImportJobOperation } from "../enums/SDKImportJobOperation"
import { ImportJobState } from "../enums/SDKImportJobState"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Source, SourceRemoteModel } from "../support/SDKSource"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

// sources id is defined as SourceId
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type SourceRemoteModelWithSourceId = Omit<SourceRemoteModel, "id"> & {
  sourceId: string
}

export type ImportJobRemoteModel = {
  id: string
  designSystemId: string
  designSystemVersionId: string
  createdAt?: string
  stateChangedAt?: string
  state: ImportJobState
  error?: string
  operation: ImportJobOperation
  sourceIds: Array<string>
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class ImportJob {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Unique identifier of the job */
  id: string

  /** Unique identifier of the design system this job lives in */
  designSystemId: string

  /** Unique identifier of the design system version this job lives in */
  designSystemVersionId: string

  /** Job creation date */
  createdAt: Date | null

  /** Date of the last change of the job status */
  stateChangedAt: Date | null

  /** Job state - Queued, InProgress, Failed or Success */
  state: ImportJobState

  /** Reason for failure of this import job. Will only be present if `state === failed` */
  error: string | null

  /** Job operation - Check or Import */
  operation: ImportJobOperation

  /** All sources participating in this import job */
  sourceIds: Array<string>

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: ImportJobRemoteModel) {
    this.id = model.id
    this.designSystemId = model.designSystemId
    this.designSystemVersionId = model.designSystemVersionId
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.state = model.state
    this.operation = model.operation
    this.stateChangedAt = model.stateChangedAt
      ? new Date(model.stateChangedAt)
      : null
    this.error = model.error ?? null
    this.sourceIds = model.sourceIds
  }
}
