//
//  SDKShadowToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Brand } from "../base/SDKBrand"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { ShadowType } from "../enums/SDKShadowType"
import { TokenType } from "../enums/SDKTokenType"

import { ShadowTokenRemoteData } from "./remote/SDKRemoteTokenData"
import {
  ShadowTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { ShadowTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { ColorToken } from "./SDKColorToken"
import { DimensionToken, OpacityToken } from "./SDKDimensionToken"
import { Token } from "./SDKToken"
import { ShadowTokenValue } from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class ShadowToken extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: Array<ShadowTokenValue>

  shadowLayers: Array<ShadowToken>

  isVirtual: boolean

  tokenType: TokenType.shadow = TokenType.shadow

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: ShadowTokenValue[],
    alias: ShadowToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value.map((shadowTokenValue) => ({
      ...shadowTokenValue,
    })) // NOTE: ensure no same reference
    this.shadowLayers = new Array<ShadowToken>()
    this.isVirtual = false

    if (alias) {
      this.value = this.value.map((tokenValueToUpdate) => ({
        ...tokenValueToUpdate,
        referencedTokenId: alias.id,
      }))
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Static building

  static create(
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: object,
    alias: ShadowToken | null,
    referenceResolver: DTTokenReferenceResolver,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): ShadowToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type: TokenType.shadow,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      // Raw value
      const tokenValue = this.shadowValueFromDefinition(
        value,
        referenceResolver
      )

      return new ShadowToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const layers: ShadowTokenValue[] = []

      for (const aliasValue of alias.value) {
        const tokenValue: ShadowTokenValue = {
          color: aliasValue.color,
          x: aliasValue.x,
          y: aliasValue.y,
          spread: aliasValue.spread,
          radius: aliasValue.radius,
          opacity: aliasValue.opacity,
          type: aliasValue.type,
          // ALTERNATIVE: Keep original reference: `alias.value.length === 1 ? alias : aliasValue.referencedToken`
          referencedTokenId: alias.id,
        }

        layers.push(tokenValue)
      }

      return new ShadowToken(
        versionId,
        baseToken,
        layers,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "Shadow Token must be created using value or alias, but none was provided"
    )
  }

  static shadowValueFromDefinition(
    definition: object,
    referenceResolver: DTTokenReferenceResolver
  ): ShadowTokenValue[] {
    if (definition instanceof Array) {
      return definition.map((d) =>
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.shadowSingleValueFromDefinition(d, referenceResolver)
      )
    }

    return [this.shadowSingleValueFromDefinition(definition, referenceResolver)]
  }

  static shadowTokenColorAndOpacityToHex8(
    shadowTokenValue: ShadowTokenValue
  ): string {
    return `#${[
      shadowTokenValue.color.color.r,
      shadowTokenValue.color.color.g,
      shadowTokenValue.color.color.b,
      Math.round(
        (shadowTokenValue.opacity?.measure ??
          shadowTokenValue.color.opacity.measure) * 255
      ),
    ]
      .map((x) => {
        const hex = x.toString(16)

        return hex.length === 1 ? `0${hex}` : hex
      })
      .join("")
      .toLowerCase()}`
  }

  static shadowSingleValueFromDefinition(
    definition: object,
    referenceResolver: DTTokenReferenceResolver
  ): ShadowTokenValue {
    let data = definition

    // For now, handle only one shadow in multiple shadow layers
    if (data instanceof Array) {
      if (data.length > 0) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data = data[0]
      } else {
        // Empty definition needs to fallback to proper SN definition - make it transparent shadow with 0 0 0 0 values
        data = {
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
          color: "rgba(0,0,0,0)",
          type: "dropShadow",
        }
      }
    }

    if (
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !data.hasOwnProperty("x") ||
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !data.hasOwnProperty("y") ||
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !data.hasOwnProperty("blur") ||
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !data.hasOwnProperty("spread") ||
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !data.hasOwnProperty("color") || // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !data.hasOwnProperty("type")
    ) {
      throw SupernovaError.fromMessage(
        `Box Shadow definition is missing one of required properties (x, y, blur, spread, color, type), was ${JSON.stringify(
          definition
        )}`
      )
    }

    const value = {} as ShadowTokenValue // Empty container

    value.x = DimensionToken.dimensionValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (definition as any).x,
      referenceResolver,
      TokenType.dimension
    ).measure
    value.y = DimensionToken.dimensionValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (definition as any).y,
      referenceResolver,
      TokenType.dimension
    ).measure
    value.radius = DimensionToken.dimensionValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (definition as any).blur,
      referenceResolver,
      TokenType.radius
    ).measure
    value.spread = DimensionToken.dimensionValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (definition as any).spread,
      referenceResolver,
      TokenType.radius
    ).measure
    // @ts-expect-error TS(2322): Type 'ColorTokenValue | undefined' is not assignab... Remove this comment to see the full error message
    value.color = ColorToken.colorValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      (data as any).color,
      referenceResolver
    )

    // It makes more sense to cleanup shadow.opacity as TS doesn't have it and SN can fallback to shadow.color.opacity now
    // Alternative would be:
    // value.opacity = { ...value.color.opacity }
    // value.color.opacity = OpacityToken.dimensionValueFromDefinition(1, TokenType.opacity)
    value.opacity = undefined
    value.type =
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (data as any).type === "innerShadow" ? ShadowType.inner : ShadowType.drop

    if (value.x === undefined) {
      throw new Error(
        `Unable to resolve value 'x' for shadow token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    if (value.y === undefined) {
      throw new Error(
        `Unable to resolve value 'y' for shadow token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    if (value.radius === undefined) {
      throw new Error(
        `Unable to resolve value 'radius' for shadow token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    if (value.spread === undefined) {
      throw new Error(
        `Unable to resolve value 'spread' for shadow token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    if (value.color === undefined) {
      throw new Error(
        `Unable to resolve value 'color' for shadow token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    if (value.type === undefined) {
      throw new Error(
        `Unable to resolve value 'type' for shadow token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    return value
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Writing

  toWriteObject(): ShadowTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as ShadowTokenRemoteModel

    specificData.data = ShadowToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: ShadowTokenValue[]): ShadowTokenRemoteData {
    const tokens = value.map((l) => this.singleValueToWriteObject(l))

    // If has one layer which is direct alias to another token => use aliasTo, otherwise build value
    // If every layer references same token, then it is a reference to this token
    const refSameToken = value.every(
      (layer) =>
        !!layer.referencedTokenId &&
        layer.referencedTokenId === value[0]?.referencedTokenId
    )

    const aliasTo = refSameToken
      ? value[0]?.referencedTokenId ?? undefined
      : undefined

    return {
      aliasTo,
      value: aliasTo ? undefined : tokens, // ALTERNATIVE: If we want to have single-layered for 1 shadow in db, we should flatten here
    }
  }

  static singleValueToWriteObject(value: ShadowTokenValue): {
    aliasTo: string | undefined
    value: ShadowTokenRemoteValue
  } {
    const valueObject = !value.referencedTokenId
      ? {
          color: {
            aliasTo: value.color.referencedTokenId ?? undefined,
            value: value.color.referencedTokenId
              ? null
              : {
                  color: ColorToken.colorValueToHex8(value.color),
                  opacity: {
                    value: {
                      measure: value.color.opacity.measure,
                      unit: value.color.opacity.unit,
                    },
                  },
                },
          },
          x: value.x,
          y: value.y,
          spread: value.spread,
          radius: value.radius,
          opacity: !value.opacity
            ? null
            : {
                aliasTo: value.opacity.referencedTokenId ?? undefined,
                value: value.opacity.referencedTokenId
                  ? null
                  : {
                      measure: value.opacity.measure,
                      unit: value.opacity.unit,
                    },
              },
          type: value.type,
        }
      : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type '{ color: { aliasTo: string | undefined; valu... Remove this comment to see the full error message
      value: valueObject,
    }
  }
}
