//
//  SDKUnit.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Token type enum

// TODO: Add exchaustive checks in switch
export enum Unit {
  pixels = "Pixels",
  percent = "Percent",
  rem = "Rem",
  ms = "Ms",
  raw = "Raw",
}

export const SIZE_UNITS = [Unit.pixels, Unit.percent, Unit.rem] as const
export type SizeUnit = (typeof SIZE_UNITS)[number]

export const LINE_HEIGHT_UNITS = [
  Unit.pixels,
  Unit.percent,
  Unit.rem,
  Unit.raw,
] as const
export type LineHeightUnit = (typeof LINE_HEIGHT_UNITS)[number]

export const RAW_UNITS = [Unit.raw] as const
export type RawUnit = (typeof RAW_UNITS)[number]

export const PX_UNITS = [Unit.pixels] as const
export type PxUnit = (typeof PX_UNITS)[number]

export const MS_UNITS = [Unit.ms] as const
export type MsUnit = (typeof MS_UNITS)[number]
