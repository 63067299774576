//
//  SDKWorkspaceProductPrice.ts
//  Supernova SDK
//
//  Created by Supernova.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions
import { WorkspaceSubscriptionPlanInterval } from "../enums/SDKWorkspaceSubscriptionPlanInterval"

export type WorkspaceProductPriceRemoteModel = {
  stripePriceId: string
  stripeProductId: string
  stripeProductDescription: string
  stripeProductFeatures: Array<string>
  stripeProductAdditionalFeatures: Array<string>
  active: boolean
  amount: number
  interval: WorkspaceSubscriptionPlanInterval
  isPricePerCreator: boolean
  legacyVersion?: string
}

export type WorkspaceProductPriceTransportModel =
  WorkspaceProductPriceRemoteModel

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceProductPrice {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** The unique identifier for the Stripe price */
  stripePriceId: string

  /** The unique identifier for the Stripe product associated with the price */
  stripeProductId: string

  /** Product description */
  stripeProductDescription: string

  /** List of features available to this product price */
  stripeProductFeatures: Array<string>

  /** List of additional features available to this product price */
  stripeProductAdditionalFeatures: Array<string>

  /** Indicates whether the price is currently active */
  active: boolean

  /** The amount to be charged for the product, in the smallest currency unit (e.g., cents) */
  amount: number

  /** The billing interval (e.g., monthly, yearly) */
  interval: WorkspaceSubscriptionPlanInterval

  /** Indicates whether the price is per creator (self-serve) or not */
  isPricePerCreator: boolean

  /** An optional legacy version identifier, if applicable */
  legacyVersion?: string | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceProductPriceRemoteModel) {
    this.stripePriceId = model.stripePriceId
    this.stripeProductId = model.stripeProductId
    this.stripeProductDescription = model.stripeProductDescription
    this.stripeProductFeatures = model.stripeProductFeatures
    this.stripeProductAdditionalFeatures = model.stripeProductAdditionalFeatures
    this.active = model.active
    this.amount = model.amount
    this.interval = model.interval
    this.isPricePerCreator = model.isPricePerCreator
    this.legacyVersion = model.legacyVersion || null
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toRemote(): WorkspaceProductPriceRemoteModel {
    return {
      stripePriceId: this.stripePriceId,
      stripeProductId: this.stripeProductId,
      stripeProductDescription: this.stripeProductDescription,
      stripeProductFeatures: this.stripeProductFeatures,
      stripeProductAdditionalFeatures: this.stripeProductAdditionalFeatures,
      active: this.active,
      amount: this.amount,
      interval: this.interval,
      isPricePerCreator: this.isPricePerCreator,
      // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
      legacyVersion: this.legacyVersion,
    }
  }

  toTransport(): WorkspaceProductPriceTransportModel {
    return this.toRemote()
  }

  static fromTransport(
    model: WorkspaceProductPriceTransportModel
  ): WorkspaceProductPrice {
    return new WorkspaceProductPrice(model)
  }
}
