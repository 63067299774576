import { DTOGitProject } from "@supernova-studio/client"

export type GitProvidersProjectsRemoteModel = {
  projects: Array<DTOGitProject>
}

export class GitProvidersProject implements DTOGitProject {
  id: string

  name: string

  url: string

  slug: string

  constructor(model: DTOGitProject) {
    this.id = model.id
    this.name = model.name
    this.url = model.url
    this.slug = model.slug
  }
}

export class GitProvidersProjects {
  projects: Array<GitProvidersProject>

  constructor(model: GitProvidersProjectsRemoteModel) {
    this.projects = model.projects.map(
      (project) => new GitProvidersProject(project)
    )
  }
}
