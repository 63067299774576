import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.divider"
const name = "Divider"
const icon = "https://cdn-assets.supernova.io/blocks/icons/divider.svg"
const descriptionShort = "A section divider"
const searchKeywords = ["hr", "line", "rule", "separator"]

const descriptionFull =
  "A thematic break or horizontal rule, often used to separate content or define a change in topic."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/text/divider-tLuxooLH"

/**
 * 2. Block properties
 */
const properties = {
  divider: b.dividerProperty({
    id: "divider",
    name: "Divider",
    options: {},
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.divider],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.divider],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.layout,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as Divider }
