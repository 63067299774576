//
//  SDKSourceType.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Source type enum

export enum SourceType {
  figma = "Figma",
  tokenStudio = "TokenStudio",
  figmaVariablesPlugin = "FigmaVariablesPlugin",
}
