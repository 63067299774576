/* eslint-disable max-lines */

/* eslint-disable padding-line-between-statements */
//
//  SDKTokenResolver.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { ElementDataView } from "../../model/elements/SDKElementDataView"
import { ElementProperty } from "../../model/elements/SDKElementProperty"
import { ElementPropertyValue } from "../../model/elements/values/SDKElementPropertyValue"
import {
  DimensionTokenType,
  OptionTokenType,
  PureTokenType,
  StringTokenType,
  TokenType,
  tokenTypeIsPure,
} from "../../model/enums/SDKTokenType"
import { TokenGroup } from "../../model/groups/SDKTokenGroup"
import { Source } from "../../model/support/SDKSource"
import { TokenOrigin } from "../../model/support/SDKTokenOrigin"
import { ThemeUtilities } from "../../model/themes/SDKThemeUtilities"
import {
  TokenTheme,
  TokenThemeRemoteModel,
} from "../../model/themes/SDKTokenTheme"
import { TokenThemeOverrideRemoteModel } from "../../model/themes/SDKTokenThemeOverride"
import { BlurToken } from "../../model/tokens/SDKBlurToken"
import { BorderToken } from "../../model/tokens/SDKBorderToken"
import { ColorToken } from "../../model/tokens/SDKColorToken"
import {
  AnyDimensionToken,
  BorderWidthToken,
  DimensionToken,
  DurationToken,
  FontSizeToken,
  LetterSpacingToken,
  LineHeightToken,
  OpacityToken,
  ParagraphSpacingToken,
  RadiusToken,
  SizeToken,
  SpaceToken,
  ZIndexToken,
} from "../../model/tokens/SDKDimensionToken"
import { GradientToken } from "../../model/tokens/SDKGradientToken"
import { ShadowToken } from "../../model/tokens/SDKShadowToken"
import {
  AnyStringToken,
  FontFamilyToken,
  FontWeightToken,
  ProductCopyToken,
  StringToken,
} from "../../model/tokens/SDKStringToken"
import { TextCaseToken } from "../../model/tokens/SDKTextCaseToken"
import { TextDecorationToken } from "../../model/tokens/SDKTextDecorationToken"
import { Token } from "../../model/tokens/SDKToken"
import {
  AnyDimensionTokenValue,
  AnyOptionToken,
  AnyOptionTokenValue,
  AnyStringTokenValue,
  AnyToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  AnyTokenValue,
  BlurTokenValue,
  BorderTokenValue,
  BorderWidthTokenValue,
  ColorTokenValue,
  DimensionTokenValue,
  DurationTokenValue,
  FontSizeTokenValue,
  GradientStopValue,
  GradientTokenValue,
  LetterSpacingTokenValue,
  LineHeightTokenValue,
  OpacityTokenValue,
  ParagraphSpacingTokenValue,
  RadiusTokenValue,
  ShadowTokenValue,
  SizeTokenValue,
  SpaceTokenValue, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TextCaseTokenValue,
  TypographyTokenValue,
  ZIndexTokenValue,
} from "../../model/tokens/SDKTokenValue"
import { TypographyToken } from "../../model/tokens/SDKTypographyToken"
import { VisibilityToken } from "../../model/tokens/SDKVisibilityToken"
import {
  AnyDimensionTokenRemoteData,
  AnyOptionTokenRemoteData,
  AnyOptionTokenRemoteValue,
  AnyStringTokenRemoteData,
  BlurTokenRemoteData,
  BorderTokenRemoteData,
  BorderWidthTokenRemoteData,
  ColorTokenRemoteData,
  DimensionTokenRemoteData,
  DurationTokenRemoteData,
  FontSizeTokenRemoteData,
  GradientTokenRemoteData,
  LetterSpacingTokenRemoteData,
  LineHeightTokenRemoteData,
  OpacityTokenRemoteData,
  ParagraphSpacingTokenRemoteData,
  RadiusTokenRemoteData,
  ShadowTokenRemoteData,
  SizeTokenRemoteData,
  SpaceTokenRemoteData,
  StringTokenRemoteData,
  TextCaseTokenRemoteData,
  TextDecorationTokenRemoteData,
  TypographyTokenRemoteData,
  VisibilityTokenRemoteData,
  ZIndexTokenRemoteData,
} from "../../model/tokens/remote/SDKRemoteTokenData"
import {
  AnyOptionTokenRemoteModel,
  BlurTokenRemoteModel,
  BorderTokenRemoteModel,
  ColorTokenRemoteModel,
  DimensionTokenRemoteModel,
  GradientTokenRemoteModel,
  ShadowTokenRemoteModel,
  StringTokenRemoteModel,
  TextCaseTokenRemoteModel,
  TokenRemoteModel,
  TypographyTokenRemoteModel,
} from "../../model/tokens/remote/SDKRemoteTokenModel"
import {
  AnyDimensionTokenRemoteValue,
  BlurTokenRemoteValue,
  BorderTokenRemoteValue,
  ColorTokenRemoteValue,
  GradientStopRemoteValue,
  GradientTokenRemoteValue,
  ShadowTokenRemoteValue,
  StringTokenRemoteValue,
  TypographyTokenRemoteValue,
} from "../../model/tokens/remote/SDKRemoteTokenValue"
import { sortUsingMap, sureOf } from "../../utils/CommonUtils"
import { UnreachableCaseError } from "../../utils/UnreachableCaseError"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class TokenResolver {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Configuration

  hashedTokens = new Map<string, TokenRemoteModel>()

  resolvedTokens = new Map<string, Token>()

  hashedOverrides = new Map<string, TokenThemeOverrideRemoteModel>()

  hashedReconstructedOverrides = new Map<string, Token>()

  resolvedOverrides = new Map<string, Token>()

  designSystemId: string

  versionId: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(designSystemId: string, versionId: string) {
    this.designSystemId = designSystemId
    this.versionId = versionId
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Resolution

  resolveTokenData(
    data: Array<TokenRemoteModel>,
    tokenGroups: Array<TokenGroup>,
    elementProperties: Array<ElementProperty>,
    elementViews: Array<ElementDataView>,
    elementValues: Array<ElementPropertyValue>,
    sources: Array<Source>
  ): Array<Token> {
    // Core view always present
    const firstView = sureOf(elementViews.filter((v) => v.isDefault)[0])
    const indexes = new Map<string, number>()

    for (const column of firstView.columns) {
      if (column.propertyDefinitionId) {
        indexes.set(
          column.propertyDefinitionId,
          firstView.columns.indexOf(column)
        )
      }
    }

    const sortedElementProperties = sortUsingMap(
      elementProperties,
      indexes,
      (p) => p.id
    )

    data.forEach((token) => {
      this.hashedTokens.set(token.persistentId, token)
    })

    /*
     * Step 1: Create pure tokens that are not references
     */
    for (const rawToken of data) {
      // Skip creation of all tokens that can have reference
      if (rawToken.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      // Construct raw colors, fonts, texts, radii and dimensions first
      if (tokenTypeIsPure(rawToken.type)) {
        const token = this.constructValueToken(
          rawToken,
          sortedElementProperties,
          elementValues
        )

        this.resolvedTokens.set(token.id, token)
      }
    }

    /*
     * Step 2: Create pure tokens that are references. This will provide us with all possible tokens resolved for mixins later
     */
    for (const rawToken of data) {
      // This time, skip creation of all raw tokens because we already have them
      if (!rawToken.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      // Construct references for pure tokens, if any
      if (
        tokenTypeIsPure(rawToken.type) &&
        !this.resolvedTokens.get(rawToken.persistentId)
      ) {
        const token = this.constructReferencedToken(
          rawToken,
          sortedElementProperties,
          elementValues
        )

        this.resolvedTokens.set(token.id, token)
      }
    }

    /*
     * Step 3: Create color tokens that can potentially reference pure resolved tokens
     */
    for (const rawToken of data) {
      // Skip creation of all tokens that can have reference
      if (rawToken.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      // Construct colors
      if (rawToken.type === TokenType.color) {
        const token = this.constructValueToken(
          rawToken,
          sortedElementProperties,
          elementValues
        )

        this.resolvedTokens.set(token.id, token)
      }
    }

    /*
     * Step 4: Create all remaining color tokens
     */
    for (const rawToken of data) {
      // Skip creation of all tokens that are not references
      if (!rawToken.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      if (rawToken.type === TokenType.color) {
        const token = this.constructReferencedToken(
          rawToken,
          sortedElementProperties,
          elementValues
        )

        this.resolvedTokens.set(token.id, token)
      }
    }

    /*
     * Step 5: Create mixin tokens that can potentially reference pure resolved tokens
     */
    for (const rawToken of data) {
      // Skip creation of all tokens that can have reference
      if (rawToken.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      // Construct raw typography, gradient, shadow and border colors
      if (
        !tokenTypeIsPure(rawToken.type) &&
        rawToken.type !== TokenType.color
      ) {
        const token = this.constructValueToken(
          rawToken,
          sortedElementProperties,
          elementValues
        )

        this.resolvedTokens.set(token.id, token)
      }
    }

    /*
     * Step 6: Create all remaining tokens, as all pure tokens that can be references and all value tokens with possible mixins were all resolved.
     */
    for (const rawToken of data) {
      // Skip creation of all tokens that are not references
      if (!rawToken.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      if (!this.resolvedTokens.get(rawToken.persistentId)) {
        // We create the token only when it wasn't created already. In some cases, this can happen when there are multiple reference levels
        const token = this.constructReferencedToken(
          rawToken,
          sortedElementProperties,
          elementValues
        )

        this.resolvedTokens.set(token.id, token)
      }
    }

    /*
     * Step 7: Assign parents to the tree
     */
    for (const tokenGroup of tokenGroups) {
      for (const childId of tokenGroup.childrenIds) {
        const possibleToken = this.resolvedTokens.get(childId)

        if (possibleToken) {
          // If object exists, assign parent to it. Not existing means it is a group
          possibleToken.parentGroupId = tokenGroup.id
          possibleToken.setTokenPath([...tokenGroup.path, tokenGroup.name])
        }
      }
    }

    // Retrieve all properly resolved tokens
    const tokens = Array.from(this.resolvedTokens.values())

    // Temporary: Fix shadow, gradient and blur tokens
    this.fixMultilayerShadowTokens(
      tokens.filter(
        (t) => t.tokenType === TokenType.shadow
      ) as Array<ShadowToken>,
      tokenGroups
    )
    this.fixMultilayerGradientTokens(
      tokens.filter(
        (t) => t.tokenType === TokenType.gradient
      ) as Array<GradientToken>,
      tokenGroups
    )
    this.fixMultilayerBlurTokens(
      tokens.filter((t) => t.tokenType === TokenType.blur) as Array<BlurToken>,
      tokenGroups
    )

    // Assign sourceType to origin to differenciate Figma from Tokens Studio tokens
    for (const token of tokens.filter(
      (t) => t.origin?.sourceId && !t.origin.sourceType
    )) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const source = sources.find((s) => s.id === token.origin.sourceId)

      if (source) {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        token.origin.sourceType = source.type
      }
    }

    // Retrieve all properly resolved tokens
    return tokens
  }

  constructStringValueForReplica(
    type: StringTokenType,
    replica: Token,
    override: TokenThemeOverrideRemoteModel
  ) {
    switch (type) {
      case TokenType.string:
        ;(replica as StringToken).value = this.constructTextLikeTokenValue(
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          (override.data as StringTokenRemoteData).value
        )
        break
      case TokenType.productCopy:
        ;(replica as ProductCopyToken).value = this.constructTextLikeTokenValue(
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          (override.data as StringTokenRemoteData).value
        )
        break
      case TokenType.fontFamily:
        ;(replica as FontFamilyToken).value = this.constructTextLikeTokenValue(
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          (override.data as StringTokenRemoteData).value
        )
        break
      case TokenType.fontWeight:
        ;(replica as FontWeightToken).value = this.constructTextLikeTokenValue(
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          (override.data as StringTokenRemoteData).value
        )
        break
      default:
        throw new UnreachableCaseError(type)
    }
  }

  constructOptionValueForReplica(
    type: OptionTokenType,
    replica: Token,
    override: TokenThemeOverrideRemoteModel
  ) {
    switch (type) {
      case TokenType.textCase:
        ;(replica as TextCaseToken).value = this.constructOptionLikeTokenValue(
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          (override.data as TextCaseTokenRemoteData).value
        )
        break
      case TokenType.textDecoration:
        ;(replica as TextDecorationToken).value =
          this.constructOptionLikeTokenValue(
            // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            (override.data as TextDecorationTokenRemoteData).value
          )
        break
      case TokenType.visibility:
        ;(replica as VisibilityToken).value =
          this.constructOptionLikeTokenValue(
            // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            (override.data as VisibilityTokenRemoteData).value
          )
        break
      default:
        throw new UnreachableCaseError(type)
    }
  }

  constructDimensionValueForReplica(
    type: DimensionTokenType,
    replica: Token,
    override: TokenThemeOverrideRemoteModel
  ) {
    switch (type) {
      case TokenType.dimension:
        ;(replica as DimensionToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as DimensionTokenRemoteData).value,
          type
        )
        break
      case TokenType.size:
        ;(replica as SizeToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as SizeTokenRemoteData).value,
          type
        )
        break
      case TokenType.space:
        ;(replica as SpaceToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as SpaceTokenRemoteData).value,
          type
        )
        break
      case TokenType.opacity:
        ;(replica as OpacityToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as OpacityTokenRemoteData).value,
          type
        )
        break
      case TokenType.fontSize:
        ;(replica as FontSizeToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as FontSizeTokenRemoteData).value,
          type
        )
        break
      case TokenType.lineHeight:
        ;(replica as LineHeightToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as LineHeightTokenRemoteData).value,
          type
        )
        break
      case TokenType.letterSpacing:
        ;(replica as LetterSpacingToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as LetterSpacingTokenRemoteData).value,
          type
        )
        break
      case TokenType.paragraphSpacing:
        ;(replica as ParagraphSpacingToken).value =
          this.constructDimensionValue(
            // @ts-expect-error TS(2769): No overload matches this call.
            (override.data as ParagraphSpacingTokenRemoteData).value,
            type
          )
        break
      case TokenType.borderWidth:
        ;(replica as BorderWidthToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as BorderWidthTokenRemoteData).value,
          type
        )
        break
      case TokenType.radius:
        ;(replica as RadiusToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as RadiusTokenRemoteData).value,
          type
        )
        break
      case TokenType.duration:
        ;(replica as DurationToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as DurationTokenRemoteData).value,
          type
        )
        break
      case TokenType.zIndex:
        ;(replica as ZIndexToken).value = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          (override.data as ZIndexTokenRemoteData).value,
          type
        )
        break
      default:
        throw new UnreachableCaseError(type)
    }
  }

  constructPureValueForReplica(
    type: PureTokenType,
    replica: Token,
    override: TokenThemeOverrideRemoteModel
  ) {
    switch (type) {
      case TokenType.dimension:
      case TokenType.size:
      case TokenType.space:
      case TokenType.opacity:
      case TokenType.fontSize:
      case TokenType.lineHeight:
      case TokenType.letterSpacing:
      case TokenType.paragraphSpacing:
      case TokenType.borderWidth:
      case TokenType.radius:
      case TokenType.duration:
      case TokenType.zIndex:
        this.constructDimensionValueForReplica(type, replica, override)
        break
      case TokenType.string:
      case TokenType.productCopy:
      case TokenType.fontFamily:
      case TokenType.fontWeight:
        this.constructStringValueForReplica(type, replica, override)
        break
      case TokenType.textCase:
      case TokenType.textDecoration:
      case TokenType.visibility:
        this.constructOptionValueForReplica(type, replica, override)
        break
      default:
        throw new UnreachableCaseError(type)
    }
  }

  resolveThemeData(
    data: TokenThemeRemoteModel,
    tokens: Array<Token>,
    tokenGroups: Array<TokenGroup>,
    sources: Array<Source>
  ): TokenTheme {
    const themeId = data.id

    // Build cache for performance
    for (const token of tokens) {
      this.resolvedTokens.set(token.id, token)
    }

    /*
     * Step 0: Clean overrides that don't have any token associated with it
     */
    const overrideData: Array<TokenThemeOverrideRemoteModel> = []

    for (const override of data.overrides) {
      const token = this.resolvedTokens.get(override.tokenPersistentId)

      if (token) {
        overrideData.push(override)
      }
    }

    for (const override of overrideData) {
      this.hashedOverrides.set(override.tokenPersistentId, override)
    }

    /*
     * Step 1: Construct containers for tokens that have overrides - but don't assign value to them just yet
     */
    for (const override of overrideData) {
      const token = this.resolvedTokens.get(override.tokenPersistentId)
      const { origin } = override

      if (!token) {
        throw new Error(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Unable to resolve token ${override.tokenPersistentId} for theme ${data.id} as base token was not found`
        )
      }

      const replica = this.makeThemedValuelessTokenReplica(
        token,
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        themeId,
        origin
      )

      this.hashedReconstructedOverrides.set(replica.id, replica)
    }

    /*
     * Step 2: Create map with all tokens, using core tokens and the theme itself so we have all tokens prepared
     */
    for (const override of overrideData) {
      // Skip creation of all tokens that can have reference
      if (override.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      // Construct raw fonts, texts, radii and dimensions first
      if (tokenTypeIsPure(override.type)) {
        if (override.data.value === undefined || override.data.value === null) {
          throw new Error(
            `Override must always have alias or value, but ${override.tokenPersistentId} provided neither (1: raw)`
          )
        }

        // There is no reference to be solved, so we just assume we can create referenced without issues
        const replica = this.hashedReconstructedOverrides.get(
          override.tokenPersistentId
        )

        // @ts-expect-error TS(2345): Argument of type 'Token | undefined' is not assign... Remove this comment to see the full error message
        this.constructPureValueForReplica(override.type, replica, override)
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        this.resolvedOverrides.set(replica.id, replica)
      }
    }

    /*
     * Step 3: Create values for theme overrides that are raw and pure tokens
     */
    for (const override of overrideData) {
      // This time, skip creation of all raw tokens because we already have them
      if (!override.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      // Construct references for pure tokens, if any
      if (
        tokenTypeIsPure(override.type) &&
        !this.resolvedOverrides.get(override.tokenPersistentId)
      ) {
        const resolvedOverride = this.resolvedOverrides.get(
          override.tokenPersistentId
        )

        if (!resolvedOverride) {
          // Only construct reference if it wasn't constructed already
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          const replica = this.constructReferencedThemedToken(override, themeId)

          this.resolvedOverrides.set(replica.id, replica)
        }
      }
    }

    /*
     * Step 4: Create values for theme overrides that are color tokens
     */
    for (const override of overrideData) {
      // Skip creation of all tokens that can have reference in this step
      if (override.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      if (override.type === TokenType.color) {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const token = this.constructThemedValueToken(override, themeId)

        this.resolvedOverrides.set(token.id, token)
      }
    }

    /*
     * Step 5: Create color tokens that are references. End of this step will provide us with all possible tokens resolved for mixins later
     */
    for (const override of overrideData) {
      // Skip creation of all tokens that are not references
      if (!override.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      if (
        override.type === TokenType.color &&
        !this.resolvedOverrides.get(override.tokenPersistentId)
      ) {
        const resolvedOverride = this.resolvedOverrides.get(
          override.tokenPersistentId
        )

        if (!resolvedOverride) {
          // Only construct reference if it wasn't constructed already
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          const replica = this.constructReferencedThemedToken(override, themeId)

          this.resolvedOverrides.set(replica.id, replica)
        }
      }
    }

    /*
     * Step 6: Create pure tokens that are references.
     */
    for (const override of overrideData) {
      // Skip creation of all tokens that can have reference in this step
      if (override.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      // Construct raw typography, gradient, shadow and border colors
      if (
        !tokenTypeIsPure(override.type) &&
        override.type !== TokenType.color
      ) {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const token = this.constructThemedValueToken(override, themeId)

        this.resolvedOverrides.set(token.id, token)
      }
    }

    /*
     * Step 7: Create all remaining tokens, as all pure tokens that can be references and all value tokens with possible mixins were all resolved.
     */
    for (const override of overrideData) {
      // Skip creation of all tokens that are not references
      if (!override.data.aliasTo) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line no-continue
        continue
      }

      if (!this.resolvedOverrides.get(override.tokenPersistentId)) {
        // We create the token only when it wasn't created already. In some cases, this can happen when there are multiple reference levels
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const token = this.constructReferencedThemedToken(override, themeId)

        this.resolvedOverrides.set(token.id, token)
      }
    }

    /*
     * Step 8: Assign parents to the tree
     */
    for (const tokenGroup of tokenGroups) {
      for (const childId of tokenGroup.childrenIds) {
        const possibleToken = this.resolvedOverrides.get(childId)

        if (possibleToken) {
          // If object exists, assign parent to it. Not existing means it is a group
          possibleToken.parentGroupId = tokenGroup.id
        }
      }
    }

    /**
     * Step 9: Assign sourceType to origin to differenciate Figma from Tokens Studio tokens
     */
    // Assign sourceType to origin to differenciate Figma from Tokens Studio tokens
    for (const token of overrideData.filter(
      (t) => t.origin?.sourceId && !t.origin.sourceType
    )) {
      const source = sources.find((s) => s.id === token.origin?.sourceId)

      if (source && token.origin) {
        token.origin.sourceType = source.type
      }
    }

    /*
     * Step 10: Create all remaining tokens, as all pure tokens that can be references and all value tokens with possible mixins were all resolved.
     */

    const theme = new TokenTheme(data, this.versionId)

    const themeOverrides = Array.from(this.resolvedOverrides.values())

    this.fixMultilayerShadowTokens(
      themeOverrides.filter(
        (t) => t.tokenType === TokenType.shadow
      ) as Array<ShadowToken>,
      tokenGroups
    )
    this.fixMultilayerGradientTokens(
      themeOverrides.filter(
        (t) => t.tokenType === TokenType.gradient
      ) as Array<GradientToken>,
      tokenGroups
    )
    this.fixMultilayerBlurTokens(
      themeOverrides.filter(
        (t) => t.tokenType === TokenType.blur
      ) as Array<BlurToken>,
      tokenGroups
    )

    theme.overriddenTokens = themeOverrides

    return theme
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Token replication

  makeThemedValuelessTokenReplica(
    token: Token,
    themeId: string,
    origin: TokenOrigin | null
  ): Token {
    const replica = ThemeUtilities.replicateTokenAsThemePrefabWithoutValue(
      token,
      themeId,
      origin,
      this.versionId
    )

    replica.themeId = themeId

    return replica
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Temporary: Data structure manipulation

  fixMultilayerShadowTokens(
    tokens: Array<ShadowToken>,
    groups: Array<TokenGroup>
  ) {
    for (const token of tokens) {
      if (token.shadowLayers.length === 0) {
        token.shadowLayers = this.findAssociatedTokens(token, tokens, groups)
      }
    }
  }

  fixMultilayerGradientTokens(
    tokens: Array<GradientToken>,
    groups: Array<TokenGroup>
  ) {
    for (const token of tokens) {
      token.gradientLayers = this.findAssociatedTokens(token, tokens, groups)
    }
  }

  fixMultilayerBlurTokens(tokens: Array<BlurToken>, groups: Array<TokenGroup>) {
    for (const token of tokens) {
      token.blurLayers = this.findAssociatedTokens(token, tokens, groups)
    }
  }

  findAssociatedTokens<T extends Token>(
    token: T,
    tokens: Array<T>,
    groups: Array<TokenGroup>
  ): Array<T> {
    if (!token.origin?.id || !token.parentGroupId) {
      return [token]
    }

    // Find all tokens with same origin
    const tokenId = this.actualOriginTokenId(token.origin.id)

    const matchingTokens = tokens.filter(
      // @ts-expect-error TS(2345): Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
      (t) => this.actualOriginTokenId(t.origin?.id) === tokenId
    )

    // Sort by the parent, and set virtual tokens
    const sortedTokens = new Array<T>()
    let index = 0

    const parent = groups.find((g) => g.id === token.parentGroupId)
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    for (const id of parent.tokenIds) {
      for (const matchingToken of matchingTokens) {
        if (matchingToken.id === id) {
          sortedTokens.push(matchingToken)

          if (index === 0) {
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ;(matchingToken as any).isVirtual = false
          } else {
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ;(matchingToken as any).isVirtual = true
          }

          index += 1
        }
      }
    }

    return sortedTokens
  }

  actualOriginTokenId(id: string | null) {
    if (id && id.length > 0) {
      return id.split(",")[0]
    }

    return null
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Referencing

  constructReferencedToken(
    rawData: TokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>,
    path = new Set()
  ): Token {
    const referenceId = rawData.data.aliasTo
    let referencedToken: Token

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (this.resolvedTokens.get(referenceId)) {
      // If the referenced token already exists, we use it to create this token
      // @ts-expect-error TS(2322): Type 'Token | undefined' is not assignable to type... Remove this comment to see the full error message
      referencedToken = this.resolvedTokens.get(referenceId)
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    } else if (this.hashedTokens.get(referenceId)) {
      if (path.has(rawData.persistentId)) {
        // handle the circular reference
        // @ts-expect-error TS(2322): Type 'Token | undefined' is not assignable to type... Remove this comment to see the full error message
        referencedToken = this.resolvedTokens.get(referenceId)
      } else {
        // Otherwise, we request token with the model data of the other token coming from the base set of tokens
        referencedToken = this.constructReferencedToken(
          // @ts-expect-error TS(2345): Argument of type 'TokenRemoteModel | undefined' is... Remove this comment to see the full error message
          this.hashedTokens.get(referenceId),
          properties,
          values,
          path.add(rawData.persistentId)
        )
      }
    } else {
      // This situation can't happen because we are either resolving base tokens and using constructed references,
      // or resolving theme and this function should not be used at all
      throw new Error(
        `Data integrity problem detected, can't start creating reference for themed tokens when resolving base values. ` +
          `Token id ${rawData.id}, reference id: ${referenceId}`
      )
    }

    const token = this.constructResolvedToken(
      rawData,
      referencedToken,
      properties,
      values
    )

    this.resolvedTokens.set(token.id, token)
    return token
  }

  constructReferencedThemedToken(
    rawData: TokenThemeOverrideRemoteModel,
    themeId: string,
    path = new Set()
  ): Token {
    const referenceId = rawData.data.aliasTo
    let referencedToken: Token

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (this.resolvedOverrides.get(referenceId)) {
      // If the reference token exists from the current theme that is being resolved (note, that might not be always), prioritize reference first
      // @ts-expect-error TS(2322): Type 'Token | undefined' is not assignable to type... Remove this comment to see the full error message
      referencedToken = this.resolvedOverrides.get(referenceId)
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    } else if (this.hashedOverrides.get(referenceId)) {
      if (path.has(rawData.tokenPersistentId)) {
        // Handle the circular reference
        // @ts-expect-error TS(2345): Argument of type 'TokenThemeOverrideRemoteModel | ... Remove this comment to see the full error message
        referencedToken = this.resolvedTokens.get(referenceId)
      } else {
        // If the reference doesn't exists, there are two possible options:
        // The first one is that we are creating reference token within current theme, if provided.
        // In this case, we construct token from the override that we also construct
        referencedToken = this.constructReferencedThemedToken(
          // @ts-expect-error TS(2345): Argument of type 'TokenThemeOverrideRemoteModel | ... Remove this comment to see the full error message
          this.hashedOverrides.get(referenceId),
          themeId,
          path.add(rawData.tokenPersistentId)
        )
      }

      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    } else if (this.resolvedTokens.get(referenceId)) {
      // If the referenced token already exists, we use it to create this token
      // @ts-expect-error TS(2322): Type 'Token | undefined' is not assignable to type... Remove this comment to see the full error message
      referencedToken = this.resolvedTokens.get(referenceId)
    } else {
      // This situation can't happen because we are either resolving theme and using constructed references,
      // or resolving base tokens and this function should not be used at all
      throw new Error(
        "Data integrity problem detected, can't start creating reference for base tokens when resolving themes"
      )
    }

    const token = this.constructResolvedThemedToken(
      rawData,
      referencedToken,
      themeId
    )

    this.resolvedOverrides.set(token.id, token)
    return token
  }

  constructResolvedThemedToken(
    rawData: TokenThemeOverrideRemoteModel,
    referencedToken: Token,
    themeId: string
  ): Token {
    if (!rawData.data.aliasTo) {
      throw Error(
        `Incorrectly creating reference themed token ${rawData.tokenPersistentId} from pure value token`
      )
    }

    const baseToken = this.resolvedTokens.get(rawData.tokenPersistentId)

    const replica = ThemeUtilities.replicateTokenAsThemePrefabWithoutValue(
      // @ts-expect-error TS(2345): Argument of type 'Token | undefined' is not assign... Remove this comment to see the full error message
      baseToken,
      themeId,
      rawData.origin,
      this.versionId
    )

    // Each token has some value, let's use it
    replica.value = (referencedToken as AnyToken).value
    if (!Array.isArray(replica.value)) {
      replica.value = { ...replica.value }
      replica.value.referencedTokenId = referencedToken.id
    } else {
      // TODO:fix-sdk-eslint
      // @ts-expect-error TS(2322): Type '({ color: ColorTokenValue; x: number; y: num... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      replica.value = replica.value.map((replicatedValue) => ({
        ...replicatedValue,
      }))
      // @ts-expect-error TS(2488): Type 'StringTokenValue | ProductCopyTokenValue | F... Remove this comment to see the full error message
      for (const v of replica.value) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        v.referencedTokenId = referencedToken.id
      }
    }

    return replica
  }

  constructResolvedToken(
    rawData: TokenRemoteModel,
    referencedToken: Token,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>
  ): Token {
    if (!rawData.data.aliasTo) {
      throw Error("Incorrectly creating reference token from value token")
    }

    if (!referencedToken) {
      throw Error(
        `Data integrity problem detected, can't start creating reference for unexisting alias: ${rawData.id}, ${rawData.data}`
      )
    }

    const { type } = rawData

    // We make a copy of values for each constructed token
    switch (type) {
      case TokenType.color: {
        const ref = referencedToken as ColorToken

        return new ColorToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.border: {
        const ref = referencedToken as BorderToken

        return new BorderToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.gradient: {
        const ref = referencedToken as GradientToken

        return new GradientToken(
          this.versionId,
          rawData,
          ref.value,
          ref,
          properties,
          values
        )
      }

      case TokenType.dimension:
      case TokenType.size:
      case TokenType.space:
      case TokenType.opacity:
      case TokenType.fontSize:
      case TokenType.lineHeight:
      case TokenType.letterSpacing:
      case TokenType.paragraphSpacing:
      case TokenType.borderWidth:
      case TokenType.radius:
      case TokenType.duration:

      // eslint-disable-next-line no-fallthrough
      case TokenType.zIndex: {
        return this.constructResolvedDimensionToken(
          rawData,
          properties,
          values,
          type,
          referencedToken as AnyDimensionToken
        )
      }

      case TokenType.shadow: {
        const ref = referencedToken as ShadowToken

        return new ShadowToken(
          this.versionId,
          rawData,
          ref.value,
          ref,
          properties,
          values
        )
      }

      case TokenType.string:
      case TokenType.productCopy:
      case TokenType.fontFamily:

      // eslint-disable-next-line no-fallthrough
      case TokenType.fontWeight: {
        return this.constructResolvedStringToken(
          rawData,
          properties,
          values,
          type,
          referencedToken as AnyStringToken
        )
      }

      case TokenType.textCase:
      case TokenType.textDecoration:

      // eslint-disable-next-line no-fallthrough
      case TokenType.visibility: {
        return this.constructResolvedOptionToken(
          rawData,
          properties,
          values,
          type,
          referencedToken as AnyOptionToken
        )
      }

      case TokenType.typography: {
        const ref = referencedToken as TypographyToken

        return new TypographyToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.blur: {
        const ref = referencedToken as BlurToken

        return new BlurToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      default:
        throw new UnreachableCaseError(type)
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Token construction

  constructValueToken(
    rawData: TokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>
  ): Token {
    if (rawData.data.aliasTo) {
      throw Error("Incorrectly creating value token from referenced token")
    }

    const { type } = rawData

    switch (type) {
      case TokenType.color:
        return this.constructColorToken(
          rawData as ColorTokenRemoteModel,
          properties,
          values
        )
      case TokenType.border:
        return this.constructBorderToken(
          rawData as BorderTokenRemoteModel,
          properties,
          values
        )
      case TokenType.gradient:
        return this.constructGradientToken(
          rawData as GradientTokenRemoteModel,
          properties,
          values
        )
      case TokenType.dimension:
      case TokenType.size:
      case TokenType.space:
      case TokenType.opacity:
      case TokenType.fontSize:
      case TokenType.lineHeight:
      case TokenType.letterSpacing:
      case TokenType.paragraphSpacing:
      case TokenType.borderWidth:
      case TokenType.radius:
      case TokenType.duration:
      case TokenType.zIndex:
        return this.constructDimensionToken(
          rawData as DimensionTokenRemoteModel,
          properties,
          values,
          type
        )
      case TokenType.shadow:
        return this.constructShadowToken(
          rawData as ShadowTokenRemoteModel,
          properties,
          values
        )
      case TokenType.string:
      case TokenType.productCopy:
      case TokenType.fontFamily:
      case TokenType.fontWeight:
        return this.constructStringToken(
          rawData as StringTokenRemoteModel,
          properties,
          values,
          type
        )
      case TokenType.typography:
        return this.constructTypographyToken(
          rawData as TypographyTokenRemoteModel,
          properties,
          values
        )
      case TokenType.blur:
        return this.constructBlurToken(
          rawData as BlurTokenRemoteModel,
          properties,
          values
        )
      case TokenType.textCase:
      case TokenType.textDecoration:
      case TokenType.visibility:
        return this.constructOptionToken(
          rawData as TextCaseTokenRemoteModel,
          properties,
          values,
          type
        )
      default:
        throw new UnreachableCaseError(type)
    }
  }

  constructThemedValueToken(
    override: TokenThemeOverrideRemoteModel,
    themeId: string
  ): Token {
    if (override.data.aliasTo) {
      throw Error(
        "Incorrectly creating themed value token from referenced token"
      )
    }

    const baseToken = this.resolvedTokens.get(override.tokenPersistentId)

    const replica = ThemeUtilities.replicateTokenAsThemePrefabWithoutValue(
      // @ts-expect-error TS(2345): Argument of type 'Token | undefined' is not assign... Remove this comment to see the full error message
      baseToken,
      themeId,
      override.origin,
      this.versionId
    )

    const { type } = override

    switch (type) {
      case TokenType.color:
        ;(replica as ColorToken).value = this.constructColorValue(
          // @ts-expect-error TS(2345): Argument of type 'ColorTokenRemoteValue | undefine... Remove this comment to see the full error message
          (override.data as ColorTokenRemoteData).value
        )
        break
      case TokenType.dimension:
      case TokenType.size:
      case TokenType.space:
      case TokenType.opacity:
      case TokenType.fontSize:
      case TokenType.lineHeight:
      case TokenType.letterSpacing:
      case TokenType.paragraphSpacing:
      case TokenType.borderWidth:
      case TokenType.radius:
      case TokenType.duration:
      case TokenType.zIndex:
        this.constructDimensionValueForReplica(type, replica, override)
        break
      case TokenType.string:
      case TokenType.productCopy:
      case TokenType.fontFamily:
      case TokenType.fontWeight:
        this.constructStringValueForReplica(type, replica, override)
        break
      case TokenType.textCase:
      case TokenType.textDecoration:
      case TokenType.visibility:
        this.constructOptionValueForReplica(type, replica, override)
        break
      case TokenType.blur:
        ;(replica as BlurToken).value = this.constructBlurTokenValue(
          // @ts-expect-error TS(2345): Argument of type 'BlurTokenRemoteValue | undefined... Remove this comment to see the full error message
          (override.data as BlurTokenRemoteData).value
        )
        break
      case TokenType.border:
        ;(replica as BorderToken).value = this.constructBorderTokenValue(
          // @ts-expect-error TS(2345): Argument of type 'BorderTokenRemoteValue | undefin... Remove this comment to see the full error message
          (override.data as BorderTokenRemoteData).value
        )
        break
      case TokenType.gradient:
        ;(replica as GradientToken).value = this.constructGradientTokenValues(
          // @ts-expect-error TS(2345): Argument of type 'GradientTokenRemoteValue | Gradi... Remove this comment to see the full error message
          (override.data as GradientTokenRemoteData).value
        )
        break
      case TokenType.shadow:
        ;(replica as ShadowToken).value = this.constructShadowTokenValues(
          // @ts-expect-error TS(2345): Argument of type 'ShadowTokenRemoteValue | ShadowT... Remove this comment to see the full error message
          (override.data as ShadowTokenRemoteData).value
        )
        break
      case TokenType.typography:
        ;(replica as TypographyToken).value =
          this.constructTypographyTokenValue(
            // @ts-expect-error TS(2345): Argument of type 'TypographyTokenRemoteValue | und... Remove this comment to see the full error message
            (override.data as TypographyTokenRemoteData).value
          )
        break
      default:
        throw new UnreachableCaseError(type)
    }

    return replica
  }

  constructColorToken(
    rawData: ColorTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>
  ): ColorToken {
    // @ts-expect-error TS(2345): Argument of type 'ColorTokenRemoteValue | undefine... Remove this comment to see the full error message
    const value = this.constructColorValue(rawData.data.value)

    return new ColorToken(
      this.versionId,
      rawData,
      value,
      null,
      properties,
      values
    )
  }

  constructColorValue(rawValue: ColorTokenRemoteValue): ColorTokenValue {
    if (typeof rawValue.color === "string") {
      const hex = rawValue.color.substr(1) // RRGGBBAA
      const r = parseInt(hex.slice(0, 2), 16)
      const g = parseInt(hex.slice(2, 4), 16)
      const b = parseInt(hex.slice(4, 6), 16)

      const opacity = this.resolveReferencedDimensionTokenValue(
        rawValue.opacity,
        TokenType.opacity
      )

      return {
        color: {
          r,
          g,
          b,
          referencedTokenId: null,
        },
        opacity,
        referencedTokenId: null,
      }
    }

    // TEST: Limit depth
    const color = this.resolveReferencedColorTokenValue(rawValue.color)

    const opacity = this.resolveReferencedDimensionTokenValue(
      rawValue.opacity,
      TokenType.opacity
    )

    return {
      color: {
        r: color.color.r,
        g: color.color.g,
        b: color.color.b,
        // TEST: Proper resolution of color.
        // Maybe should set referencedTokenId above? Or here as one inside color.color.
        referencedTokenId: color.referencedTokenId,
      },
      opacity,
      referencedTokenId: null,
    }
  }

  constructResolvedStringToken(
    rawData: StringTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>,
    tokenType: StringTokenType,
    referencedToken: AnyStringToken
  ): AnyStringToken {
    switch (tokenType) {
      case TokenType.string: {
        const ref = referencedToken as StringToken

        return new StringToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.productCopy: {
        const ref = referencedToken as ProductCopyToken

        return new ProductCopyToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.fontFamily: {
        const ref = referencedToken as FontFamilyToken

        return new FontFamilyToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.fontWeight: {
        const ref = referencedToken as FontWeightToken

        return new FontWeightToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      default:
        throw new UnreachableCaseError(tokenType)
    }
  }

  constructStringToken(
    rawData: StringTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>,
    tokenType: StringTokenType
  ): AnyStringToken {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const value = this.constructTextLikeTokenValue(rawData.data.value)

    switch (tokenType) {
      case TokenType.string:
        return new StringToken(
          this.versionId,
          rawData,
          value,
          null,
          properties,
          values
        )
      case TokenType.productCopy:
        return new ProductCopyToken(
          this.versionId,
          rawData,
          value,
          null,
          properties,
          values
        )
      case TokenType.fontFamily:
        return new FontFamilyToken(
          this.versionId,
          rawData,
          value,
          null,
          properties,
          values
        )
      case TokenType.fontWeight:
        return new FontWeightToken(
          this.versionId,
          rawData,
          value,
          null,
          properties,
          values
        )
      default:
        throw new UnreachableCaseError(tokenType)
    }
  }

  constructResolvedOptionToken(
    rawData: AnyOptionTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>,
    tokenType: OptionTokenType,
    referencedToken: AnyOptionToken
  ): AnyOptionToken {
    switch (tokenType) {
      case TokenType.textCase: {
        const ref = referencedToken as TextCaseToken

        return new TextCaseToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.textDecoration: {
        const ref = referencedToken as TextDecorationToken

        return new TextDecorationToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.visibility: {
        const ref = referencedToken as VisibilityToken

        return new VisibilityToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      default:
        throw new UnreachableCaseError(tokenType)
    }
  }

  constructOptionToken(
    rawData: AnyOptionTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>,
    tokenType: OptionTokenType
  ): AnyOptionToken {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const value = this.constructOptionLikeTokenValue(rawData.data.value)

    switch (tokenType) {
      case TokenType.textCase:
        return new TextCaseToken(
          this.versionId,
          rawData,
          value,
          null,
          properties,
          values
        )
      case TokenType.textDecoration:
        return new TextDecorationToken(
          this.versionId,
          rawData,
          value,
          null,
          properties,
          values
        )
      case TokenType.visibility:
        return new VisibilityToken(
          this.versionId,
          rawData,
          value,
          null,
          properties,
          values
        )
      default:
        throw new UnreachableCaseError(tokenType)
    }
  }

  constructTextLikeTokenValue(
    rawData: StringTokenRemoteValue
  ): AnyStringTokenValue {
    const value = {
      text: rawData,
      referencedTokenId: null,
    }

    return value
  }

  constructOptionLikeTokenValue(
    rawData: AnyOptionTokenRemoteValue
  ): AnyOptionTokenValue {
    const value = {
      value: rawData,
      referencedTokenId: null,
    }

    return value
  }

  constructResolvedDimensionToken(
    rawData: DimensionTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>,
    tokenType: DimensionTokenType,
    referencedToken: AnyDimensionToken
  ): AnyDimensionToken {
    switch (tokenType) {
      case TokenType.dimension: {
        const ref = referencedToken as DimensionToken

        return new DimensionToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.size: {
        const ref = referencedToken as SizeToken

        return new SizeToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.space: {
        const ref = referencedToken as SpaceToken

        return new SpaceToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.opacity: {
        const ref = referencedToken as OpacityToken

        return new OpacityToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.fontSize: {
        const ref = referencedToken as FontSizeToken

        return new FontSizeToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.lineHeight: {
        const ref = referencedToken as LineHeightToken

        return new LineHeightToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.letterSpacing: {
        const ref = referencedToken as LetterSpacingToken

        return new LetterSpacingToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.paragraphSpacing: {
        const ref = referencedToken as ParagraphSpacingToken

        return new ParagraphSpacingToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.borderWidth: {
        const ref = referencedToken as BorderWidthToken

        return new BorderWidthToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.radius: {
        const ref = referencedToken as RadiusToken

        return new RadiusToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.duration: {
        const ref = referencedToken as DurationToken

        return new DurationToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      case TokenType.zIndex: {
        const ref = referencedToken as ZIndexToken

        return new ZIndexToken(
          this.versionId,
          rawData,
          { ...ref.value },
          ref,
          properties,
          values
        )
      }

      default:
        throw new UnreachableCaseError(tokenType)
    }
  }

  // TODO: <T> for safer approach?
  constructDimensionToken(
    rawData: DimensionTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>,
    tokenType: DimensionTokenType
  ): AnyDimensionToken {
    switch (tokenType) {
      case TokenType.dimension: {
        const dimensionValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new DimensionToken(
          this.versionId,
          rawData,
          dimensionValue,
          null,
          properties,
          values
        )
      }
      case TokenType.size: {
        const sizeValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new SizeToken(
          this.versionId,
          rawData,
          sizeValue,
          null,
          properties,
          values
        )
      }
      case TokenType.space: {
        const spaceValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        // TODO: of nominal typing, so pass sizeValue do not compile
        return new SpaceToken(
          this.versionId,
          rawData,
          spaceValue,
          null,
          properties,
          values
        )
      }
      case TokenType.opacity: {
        const opacityValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new OpacityToken(
          this.versionId,
          rawData,
          opacityValue,
          null,
          properties,
          values
        )
      }
      case TokenType.fontSize: {
        const fontSizeValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new FontSizeToken(
          this.versionId,
          rawData,
          fontSizeValue,
          null,
          properties,
          values
        )
      }
      case TokenType.lineHeight: {
        const lineHeightValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new LineHeightToken(
          this.versionId,
          rawData,
          lineHeightValue,
          null,
          properties,
          values
        )
      }
      case TokenType.letterSpacing: {
        const letterSpacingValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new LetterSpacingToken(
          this.versionId,
          rawData,
          letterSpacingValue,
          null,
          properties,
          values
        )
      }
      case TokenType.paragraphSpacing: {
        const paragraphSpacingValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new ParagraphSpacingToken(
          this.versionId,
          rawData,
          paragraphSpacingValue,
          null,
          properties,
          values
        )
      }
      case TokenType.borderWidth: {
        const borderWidthValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new BorderWidthToken(
          this.versionId,
          rawData,
          borderWidthValue,
          null,
          properties,
          values
        )
      }
      case TokenType.radius: {
        const borderRadiusValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new RadiusToken(
          this.versionId,
          rawData,
          borderRadiusValue,
          null,
          properties,
          values
        )
      }
      case TokenType.duration: {
        const durationValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new DurationToken(
          this.versionId,
          rawData,
          durationValue,
          null,
          properties,
          values
        )
      }
      case TokenType.zIndex: {
        const zIndexValue = this.constructDimensionValue(
          // @ts-expect-error TS(2769): No overload matches this call.
          rawData.data.value,
          tokenType
        )

        return new ZIndexToken(
          this.versionId,
          rawData,
          zIndexValue,
          null,
          properties,
          values
        )
      }
      default:
        throw new UnreachableCaseError(tokenType)
    }
  }

  // TODO: <T> for safer approach?
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.opacity
  ): OpacityTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.fontSize
  ): FontSizeTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.lineHeight
  ): LineHeightTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.letterSpacing
  ): LetterSpacingTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.paragraphSpacing
  ): ParagraphSpacingTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.borderWidth
  ): BorderWidthTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.radius
  ): RadiusTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.duration
  ): DurationTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.zIndex
  ): ZIndexTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.dimension
  ): DimensionTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.size
  ): SizeTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    type: TokenType.space
  ): SpaceTokenValue
  constructDimensionValue(
    rawData: AnyDimensionTokenRemoteValue,
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type: DimensionTokenType
  ): AnyDimensionTokenValue {
    return {
      unit: rawData.unit,
      measure: rawData.measure,
      referencedTokenId: null,
    }
  }

  constructGradientToken(
    rawData: GradientTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>
  ): GradientToken {
    // @ts-expect-error TS(2345): Argument of type 'GradientTokenRemoteValue | Gradi... Remove this comment to see the full error message
    const value = this.constructGradientTokenValues(rawData.data.value)

    return new GradientToken(
      this.versionId,
      rawData,
      value,
      null,
      properties,
      values
    )
  }

  constructGradientTokenValues(
    rawData: GradientTokenRemoteValue | GradientTokenRemoteData[]
  ): GradientTokenValue[] {
    if (!Array.isArray(rawData)) {
      return [this.constructGradientTokenValue(rawData)]
    }

    const tokens: GradientTokenValue[] = []

    for (const token of rawData) {
      // FIXME: Is it safe to assume no aliasTo is found? Probably good idea to have separate types for ref vs raw
      // if (token.aliasTo) {
      //   throw new Error(
      //     `Called constructShadowTokenValues for ${token.aliasTo}`
      //   )
      const value = this.resolveReferencedGradientTokenValue(token)
      const toAdd = Array.isArray(value) ? value : [value]

      tokens.push(...toAdd)
      // }
      // FIXME: Rethink whether we should go deep or not
      // let value = this.constructGradientTokenValues(token.value)
      // let toAdd = Array.isArray(value) ? value : [value]
      // tokens.push(...toAdd)
    }

    return tokens
  }

  constructGradientTokenValue(rawData: GradientTokenRemoteValue) {
    const value: GradientTokenValue = {
      to: rawData.to,
      from: rawData.from,
      type: rawData.type,
      aspectRatio: rawData.aspectRatio,
      stops: this.constructGradientStops(rawData.stops),
      referencedTokenId: null,
    }

    return value
  }

  constructGradientStops(
    rawData: Array<GradientStopRemoteValue>
  ): Array<GradientStopValue> {
    return rawData.map((stop) => {
      return {
        position: stop.position,
        color: this.resolveReferencedColorTokenValue(stop.color),
        referencedTokenId: null,
      }
    })
  }

  constructShadowToken(
    rawData: ShadowTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>
  ): ShadowToken {
    // @ts-expect-error TS(2345): Argument of type 'ShadowTokenRemoteValue | ShadowT... Remove this comment to see the full error message
    const value = this.constructShadowTokenValues(rawData.data.value)

    return new ShadowToken(
      this.versionId,
      rawData,
      value,
      null,
      properties,
      values
    )
  }

  constructShadowTokenValues(
    rawData: ShadowTokenRemoteValue | ShadowTokenRemoteData[]
  ): ShadowTokenValue[] {
    if (!Array.isArray(rawData)) {
      return [this.constructShadowTokenValue(rawData)]
    }

    const tokens: ShadowTokenValue[] = []

    for (const token of rawData) {
      // FIXME: Is it safe to assume no aliasTo is found? Probably good idea to have separate types for ref vs raw
      // if (token.aliasTo) {
      //   throw new Error(
      //     `Called constructShadowTokenValues for ${token.aliasTo}`
      //   )
      const value = this.resolveReferencedShadowTokenValue(token)
      const toAdd = Array.isArray(value) ? value : [value]

      tokens.push(...toAdd)
      // }
      // FIXME: Rethink whether we should go deep or not
      // let value = this.constructShadowTokenValues(token.value)
      // let toAdd = Array.isArray(value) ? value : [value]
      // tokens.push(...toAdd)
    }

    return tokens
  }

  constructShadowTokenValue(rawData: ShadowTokenRemoteValue): ShadowTokenValue {
    const value: ShadowTokenValue = {
      color: this.resolveReferencedColorTokenValue(rawData.color),
      x: rawData.x,
      y: rawData.y,
      radius: rawData.radius,
      spread: rawData.spread,
      opacity: rawData.opacity
        ? this.resolveReferencedDimensionTokenValue(
            rawData.opacity,
            TokenType.opacity
          )
        : undefined,
      type: rawData.type,
      referencedTokenId: null,
    }

    return value
  }

  constructBorderToken(
    rawData: BorderTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>
  ): BorderToken {
    // @ts-expect-error TS(2345): Argument of type 'BorderTokenRemoteValue | undefin... Remove this comment to see the full error message
    const value = this.constructBorderTokenValue(rawData.data.value)

    return new BorderToken(
      this.versionId,
      rawData,
      value,
      null,
      properties,
      values
    )
  }

  constructBorderTokenValue(rawData: BorderTokenRemoteValue): BorderTokenValue {
    const value: BorderTokenValue = {
      color: this.resolveReferencedColorTokenValue(rawData.color),
      width: this.resolveReferencedDimensionTokenValue(
        rawData.width,
        TokenType.borderWidth
      ),
      position: rawData.position,
      style: rawData.style,
      referencedTokenId: null,
    }

    return value
  }

  constructTypographyToken(
    rawData: TypographyTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>
  ): TypographyToken {
    // @ts-expect-error TS(2345): Argument of type 'TypographyTokenRemoteValue | und... Remove this comment to see the full error message
    const value = this.constructTypographyTokenValue(rawData.data.value)

    return new TypographyToken(
      this.versionId,
      rawData,
      value,
      null,
      properties,
      values
    )
  }

  constructTypographyTokenValue(
    rawData: TypographyTokenRemoteValue
  ): TypographyTokenValue {
    const value: TypographyTokenValue = {
      fontFamily: this.resolveReferencedStringTokenValue(rawData.fontFamily),
      fontWeight: this.resolveReferencedStringTokenValue(rawData.fontWeight),
      fontSize: this.resolveReferencedDimensionTokenValue(
        rawData.fontSize,
        TokenType.fontSize
      ),
      textDecoration: this.resolveReferencedOptionTokenValue(
        rawData.textDecoration
      ),
      textCase: this.resolveReferencedOptionTokenValue(rawData.textCase),
      letterSpacing: this.resolveReferencedDimensionTokenValue(
        rawData.letterSpacing,
        TokenType.letterSpacing
      ),
      lineHeight: rawData.lineHeight
        ? this.resolveReferencedDimensionTokenValue(
            rawData.lineHeight,
            TokenType.lineHeight
          )
        : null,
      paragraphIndent: this.resolveReferencedDimensionTokenValue(
        rawData.paragraphIndent,
        TokenType.paragraphSpacing
      ),
      paragraphSpacing: this.resolveReferencedDimensionTokenValue(
        rawData.paragraphSpacing,
        TokenType.paragraphSpacing
      ),
      referencedTokenId: null,
    }

    return value
  }

  constructBlurToken(
    rawData: BlurTokenRemoteModel,
    properties: Array<ElementProperty>,
    values: Array<ElementPropertyValue>
  ): BlurToken {
    // @ts-expect-error TS(2345): Argument of type 'BlurTokenRemoteValue | undefined... Remove this comment to see the full error message
    const value = this.constructBlurTokenValue(rawData.data.value)

    return new BlurToken(
      this.versionId,
      rawData,
      value,
      null,
      properties,
      values
    )
  }

  constructBlurTokenValue(rawData: BlurTokenRemoteValue): BlurTokenValue {
    const value: BlurTokenValue = {
      type: rawData.type,
      radius: this.resolveReferencedDimensionTokenValue(
        rawData.radius,
        TokenType.dimension
      ),
      referencedTokenId: null,
    }

    return value
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Token value resolution

  /** Resolve token color value - meaning we are not creating new tokens, and require raw tokens to be already present */
  resolveReferencedColorTokenValue(
    token: ColorTokenRemoteData
  ): ColorTokenValue {
    if (token.aliasTo) {
      const resolved = (this.resolvedOverrides.get(token.aliasTo) ??
        this.resolvedTokens.get(token.aliasTo)) as ColorToken

      return {
        ...resolved.value,
        referencedTokenId: resolved.id,
      }
    }

    // @ts-expect-error TS(2345): Argument of type 'ColorTokenRemoteValue | undefine... Remove this comment to see the full error message
    return this.constructColorValue(token.value)
  }

  /** Resolve token dimension value - meaning we are not creating new tokens, and require raw tokens to be already present */
  resolveReferencedBorderWidthTokenValue(
    token: BorderWidthTokenRemoteData
  ): BorderWidthTokenValue {
    if (token.aliasTo) {
      const resolved = (this.resolvedOverrides.get(token.aliasTo) ??
        this.resolvedTokens.get(token.aliasTo)) as BorderWidthToken

      return {
        ...resolved.value,
        referencedTokenId: resolved.id,
      }
    }

    // @ts-expect-error TS(2769): No overload matches this call.
    return this.constructDimensionValue(token.value, TokenType.borderWidth)
  }

  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.opacity
  ): OpacityTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.fontSize
  ): FontSizeTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.lineHeight
  ): LineHeightTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.letterSpacing
  ): LetterSpacingTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.paragraphSpacing
  ): ParagraphSpacingTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.borderWidth
  ): BorderWidthTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.radius
  ): RadiusTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.duration
  ): DurationTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.zIndex
  ): ZIndexTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.dimension
  ): DimensionTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.size
  ): SizeTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: TokenType.space
  ): SpaceTokenValue
  resolveReferencedDimensionTokenValue(
    token: AnyDimensionTokenRemoteData,
    type: DimensionTokenType
  ): AnyDimensionTokenValue {
    if (token.aliasTo) {
      const resolved = (this.resolvedOverrides.get(token.aliasTo) ??
        this.resolvedTokens.get(token.aliasTo)) as AnyDimensionToken

      return {
        ...resolved.value,
        referencedTokenId: resolved.id,
      }
    }

    // TODO:fix-sdk-eslint
    // @ts-expect-error TS(2769): No overload matches this call.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.constructDimensionValue(token.value, type as any)
  }

  resolveReferencedStringTokenValue(
    token: AnyStringTokenRemoteData
  ): AnyStringTokenValue {
    if (token.aliasTo) {
      const resolved = (this.resolvedOverrides.get(token.aliasTo) ??
        this.resolvedTokens.get(token.aliasTo)) as AnyStringToken

      return {
        ...resolved.value,
        referencedTokenId: resolved.id,
      }
    }

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.constructTextLikeTokenValue(token.value)
  }

  resolveReferencedGradientTokenValue(
    token: GradientTokenRemoteData
  ): GradientTokenValue | GradientTokenValue[] {
    if (token.aliasTo) {
      const resolved = (this.resolvedOverrides.get(token.aliasTo) ??
        this.resolvedTokens.get(token.aliasTo)) as GradientToken

      return {
        ...resolved.value,
        referencedTokenId: resolved.id,
      }
    }

    // @ts-expect-error TS(2345): Argument of type 'GradientTokenRemoteValue | Gradi... Remove this comment to see the full error message
    return this.constructGradientTokenValues(token.value)
  }

  resolveReferencedShadowTokenValue(
    token: ShadowTokenRemoteData
  ): ShadowTokenValue | ShadowTokenValue[] {
    if (token.aliasTo) {
      const resolved = (this.resolvedOverrides.get(token.aliasTo) ??
        this.resolvedTokens.get(token.aliasTo)) as ShadowToken

      return resolved.value.map((v) => ({
        ...v,
        referencedTokenId: resolved.id,
      }))
    }

    // @ts-expect-error TS(2345): Argument of type 'ShadowTokenRemoteValue | ShadowT... Remove this comment to see the full error message
    return this.constructShadowTokenValues(token.value)
  }

  resolveReferencedOptionTokenValue(
    token: AnyOptionTokenRemoteData
  ): AnyOptionTokenValue {
    if (token.aliasTo) {
      const resolved = (this.resolvedOverrides.get(token.aliasTo) ??
        this.resolvedTokens.get(token.aliasTo)) as AnyOptionToken

      return {
        ...resolved.value,
        referencedTokenId: resolved.id,
      }
    }

    return {
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      value: token.value,
      referencedTokenId: null,
    }
  }
}
