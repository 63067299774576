import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockOptionColor } from "../../../../../enums/SDKDocsBlockOptionColor"
import { DocsBlockOptionRenderingStyle } from "../../../../../enums/SDKDocsBlockOptionRenderStyle"
import { DocsBlockRichTextEditorPropertyStyle } from "../../../../../enums/SDKDocsBlockRichTextEditorPropertyStyle"
import { IconSet } from "../../../../../enums/SDKIconSet"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.do-dont-guidelines"
const name = "Guidelines"
const icon = "https://cdn-assets.supernova.io/blocks/icons/guidelines.svg"
const descriptionShort = "Document your Do's and Don'ts."

const keywords = ["do", "dont", "caution", "rules"]

const helpText =
  "Use link block to document your Do/Don't guidelines for your components and patterns."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/links/shortcuts/general-jVfNifo4"

/**
 * 2. Block properties
 */
const properties = {
  // Image
  image: b.imageProperty({
    id: "block.do-dont-guidelines.property.image",
    name: "Image",
    options: {
      allowCaption: true,
    },
  }),
  // Do/Don't/Caution switcher
  type: b.singleSelectProperty({
    id: "block.do-dont-guidelines.property.type",
    name: "Type",
    options: {
      defaultChoice: "do",
      choices: [
        {
          value: "do",
          name: "Do",
          color: DocsBlockOptionColor.green,
          icon: IconSet.checkCircle,
        },
        {
          value: "dont",
          name: "Don't",
          color: DocsBlockOptionColor.red,
          icon: IconSet.crossCircle,
        },
        {
          value: "caution",
          name: "Caution",
          color: DocsBlockOptionColor.orange,
          icon: IconSet.alert,
        },
      ],
      singleSelectStyle: DocsBlockOptionRenderingStyle.toggleButton,
    },
  }),
  // Content of the guideline
  description: b.richTextEditorProperty({
    id: "block.do-dont-guidelines.property.description",
    name: "Description",
    options: {
      allowedInlineStyles: [
        DocsBlockRichTextEditorPropertyStyle.bold,
        DocsBlockRichTextEditorPropertyStyle.italic,
        DocsBlockRichTextEditorPropertyStyle.strikethrough,
        DocsBlockRichTextEditorPropertyStyle.ul,
        DocsBlockRichTextEditorPropertyStyle.ol,
        DocsBlockRichTextEditorPropertyStyle.link,
        DocsBlockRichTextEditorPropertyStyle.inlineCode,
      ],
      placeholder: "Add description",
    },
  }),
}

/**
 * 3. Block variants
 */
const variants: Array<DocsBlockVariant> = [
  b.variantUsingDefaultLayout({
    id: "simple",
    name: "Simple",
    description:
      "With a minimalist badge at the top, this design is great for content guidelines.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/guidelines-simple.svg",
    properties: [properties.type, properties.image, properties.description],
    defaultColumns: 2,
    maxColumns: 3,
  }),
  b.variantUsingDefaultLayout({
    id: "prominent",
    name: "Prominent",
    description: "Recommended when you need guidelines that need to stand out.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/guidelines-prominent.svg",
    properties: [properties.type, properties.image, properties.description],
    maxColumns: 3,
    appearance: {
      isEditorPresentationDifferent: true,
    },
  }),
  b.variantUsingDefaultLayout({
    id: "contained",
    name: "Contained",
    description: "Perfect for component guidelines with spacious images.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/guidelines-contained.svg",
    properties: [properties.type, properties.image, properties.description],
    maxColumns: 3,
    appearance: {
      isEditorPresentationDifferent: true,
    },
  }),
  b.variantUsingDefaultLayout({
    id: "side-border",
    name: "Side border",
    description:
      "The side border makes this variant well-suited for longer text guidelines.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/guidelines-side-border.svg",
    properties: [properties.type, properties.image, properties.description],
    maxColumns: 3,
    appearance: {
      isEditorPresentationDifferent: true,
    },
  }),
]

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.multiVariantItem({
  properties: [properties.image, properties.type, properties.description],
  variants,
})

/**
 * 5. Block definition
 */
const block = b.multiItemBlock({
  id,
  name,
  icon,
  description: descriptionShort,
  item: blockItem,
  searchKeywords: keywords,
  options: {
    newItemLabel: "Add guideline",
  },
  category: DocsBlockCategory.guidelines,
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/**
 * 6. Block export
 */
export { block as DoDontGuidelines }
