//
//  SDKWorkspaceNPMRegistrySettings.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { WorkspaceNPMRegistryAuthType } from "../enums/SDKWorkspaceNPMRegistryAuthType"
import { WorkspaceNPMRegistryType } from "../enums/SDKWorkspaceNPMRegistryType"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceNPMRegistrySettingsRemoteModel = {
  registryType: WorkspaceNPMRegistryType
  authType: WorkspaceNPMRegistryAuthType
  accessToken?: string
  username?: string
  password?: string
  customRegistryUrl?: string
  enabledScopes: Array<string>
  bypassProxy?: boolean
  registryUrl: string
  proxyUrl: string
}

export type WorkspaceNPMRegistrySettingsUpdateModel = Omit<
  WorkspaceNPMRegistrySettingsRemoteModel,
  "registryUrl" | "proxyUrl"
>

export type WorkspaceNPMRegistrySettingsTransportModel =
  WorkspaceNPMRegistrySettingsRemoteModel

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceNPMRegistrySettings {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  registryType: WorkspaceNPMRegistryType

  authType: WorkspaceNPMRegistryAuthType

  enabledScopes: Array<string>

  registryUrl: string

  proxyUrl: string

  username: string | null

  customRegistryUrl: string | null

  bypassProxy: boolean | null

  accessToken: string | null

  password: string | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceNPMRegistrySettingsRemoteModel) {
    this.registryType = model.registryType
    this.authType = model.authType
    this.enabledScopes = model.enabledScopes
    this.registryUrl = model.registryUrl
    this.proxyUrl = model.proxyUrl
    this.username = model.username ?? null
    this.customRegistryUrl = model.customRegistryUrl ?? null
    this.bypassProxy = model.bypassProxy ?? null
    this.accessToken = model.accessToken ?? null
    this.password = model.password ?? null
  }

  static initial(): WorkspaceNPMRegistrySettings {
    return new WorkspaceNPMRegistrySettings({
      registryType: WorkspaceNPMRegistryType.npmJS,
      authType: WorkspaceNPMRegistryAuthType.basic,
      enabledScopes: [],
      registryUrl: "",
      proxyUrl: "",
    })
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toUpdateObject(): WorkspaceNPMRegistrySettingsUpdateModel {
    return {
      registryType: this.registryType,
      authType: this.authType,
      enabledScopes: this.enabledScopes,
      username: this.username ?? undefined,
      customRegistryUrl: this.customRegistryUrl ?? undefined,
      bypassProxy: this.bypassProxy ?? undefined,
      accessToken: this.accessToken ?? undefined,
      password: this.password ?? undefined,
    }
  }

  toRemote(): WorkspaceNPMRegistrySettingsTransportModel {
    return {
      registryType: this.registryType,
      authType: this.authType,
      username: this.username ?? undefined,
      customRegistryUrl: this.customRegistryUrl ?? undefined,
      enabledScopes: this.enabledScopes,
      bypassProxy: this.bypassProxy ?? undefined,
      accessToken: this.accessToken ?? undefined,
      password: this.password ?? undefined,
      registryUrl: this.registryUrl,
      proxyUrl: this.proxyUrl,
    }
  }

  toTransport(): WorkspaceNPMRegistrySettingsTransportModel {
    return this.toRemote()
  }

  static fromTransport(model: WorkspaceNPMRegistrySettingsTransportModel) {
    return new WorkspaceNPMRegistrySettings(model)
  }
}
