//
//  AreaBrands.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Brand, BrandUpdateModel } from "../../model/base/SDKBrand"
import { DataCore } from "../data/SDKDataCore"

import {
  RemoteBrandIdentifier,
  RemoteVersionIdentifier,
} from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaBrands {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches all remote brands in the version
   * @param from - Remote version to fetch from
   * @returns All brands in the specified version
   */
  async getBrands(from: RemoteVersionIdentifier): Promise<Array<Brand>> {
    return this.dataCore.getBrands(from.designSystemId, from.versionId)
  }

  /** Fetches a single brand
   * @param from - Remote brand to fetch from
   * @returns Brand in the specified version
   */
  async getBrand(from: RemoteBrandIdentifier): Promise<Brand> {
    return this.dataCore.getBrand(
      from.designSystemId,
      from.versionId,
      from.brandId
    )
  }

  /** Creates new brand and retrieves the newly created brand object.
   * @param from - Remote brand to fetch from
   * @param model - new brand model
   * @returns Newly created brand object
   */
  async createBrand(
    from: RemoteVersionIdentifier,
    model: BrandUpdateModel
  ): Promise<Brand> {
    return this.dataCore.createBrand(from.designSystemId, from.versionId, model)
  }

  /** Edits brand
   * @param from - Remote brand to fetch from
   * @param model - new version model
   * @returns nothing
   */
  async editBrand(from: RemoteBrandIdentifier, brand: Brand): Promise<void> {
    await this.dataCore.editBrand(
      from.designSystemId,
      from.versionId,
      from.brandId,
      brand
    )
  }

  /** Deletes brand
   * @param from - Remote brand to fetch from
   * @returns nothing
   */
  async deleteBrand(from: RemoteBrandIdentifier): Promise<void> {
    await this.dataCore.deleteBrand(
      from.designSystemId,
      from.versionId,
      from.brandId
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Compute
}
