//
//  AreaMe.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { User } from "../../model/users/SDKUser"
import { UserProfileUpdateModel } from "../../model/users/SDKUserProfile"
import { DataCore } from "../data/SDKDataCore"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaMe {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches current user profile based on the API key provided to Supernova instance. */
  async me(): Promise<User> {
    return this.dataCore.currentUser()
  }

  /** Invalidates all session keys for the current user, effectively logging out from all decises. */
  async logout(): Promise<void> {
    return this.dataCore.logoutCurrentUser()
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Updates the current user based on the API key provided to Supernova instance. */
  async updateProfile(
    userId: string,
    profile: UserProfileUpdateModel
  ): Promise<void> {
    return this.dataCore.updateUserProfile(userId, profile)
  }

  /** Updates the avatar of the current user */
  async updateAvatar(userId: string, avatar: File): Promise<void> {
    return this.dataCore.updateUserAvatar(userId, avatar)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  /** Deletes the current user user based on the API key provided to Supernova instance. Also removes all owned workspaces and updates memberships where user belongs. */
  async deleteMe(userId: string): Promise<void> {
    return this.dataCore.deleteUser(userId)
  }

  /** Checks whether user can be deleted. There are certain restrictions, like users who don't have all bills settled in their associated workspaces can't be deleted, for example */
  async canDeleteMe(userId: string): Promise<boolean> {
    return this.dataCore.canDeleteUser(userId)
  }

  /** Updates the avatar of the current user */
  async deleteAvatar(userId: string): Promise<void> {
    return this.dataCore.deleteUserAvatar(userId)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Compute
}
