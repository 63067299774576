//
//  ElementPropertyOption.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
import { ColorToken } from "../tokens/SDKColorToken"

import { parseToRgba, toHex } from "color2k"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

const badgeColorValues = {
  blue: "#EDF5FF",
  cyan: "#D5FBFE",
  fuchsia: "#FCF0FF",
  green: "#EBFEF6",
  grey: "#EDEFF3",
  "light-grey": "#F6F7F9",
  orange: "#FFF2E1",
  pink: "#FDF0F7",
  purple: "#F5F2FD",
  red: "#FEF1F2",
  teal: "#DFFDF6",
  yellow: "#FEF9C3",
  custom: null,
} as const

export type ElementPropertyOptionBadgeColor = keyof typeof badgeColorValues

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type ElementPropertyOptionRemoteModel = {
  id: string
  name: string
  /* Background color in the lower-cased 8-digit hex format */
  backgroundColor: {
    value: string
  }
}

export type ElementPropertyOptionCreationModel =
  ElementPropertyOptionRemoteModel

export type ElementPropertyOptionUpdateModel = ElementPropertyOptionRemoteModel

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class ElementPropertyOption {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  name: string

  /* Background color in upper-cased 6-digit hex format */
  backgroundColor: string | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: ElementPropertyOptionRemoteModel) {
    this.id = model.id
    this.name = model.name

    const remoteColor = model.backgroundColor?.value || "#FFFFFF"
    this.backgroundColor = remoteColor
      ? ElementPropertyOption.toHex8(remoteColor)
      : null
  }

  static toHex6(rawColor: string) {
    const rgba = parseToRgba(rawColor)

    return toHex(`rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, 1)`).toUpperCase()
  }

  static toHex8(rawColor: string) {
    const rgba = parseToRgba(rawColor)

    return toHex(
      `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3]})`
    ).toUpperCase()
  }

  /** Constructs directly manipulable update object for property option */
  toUpdateObject(): ElementPropertyOptionUpdateModel {
    return {
      id: this.id,
      name: this.name,
      backgroundColor: {
        value:
          ColorToken.colorStringToNormalizedServerValue(
            this.backgroundColor,
            false
          ) ?? ColorToken.normalizedDefaultServerValue(),
      },
    }
  }

  /** Returns background color represented as badge color.
   * If badge color is different from what badge defines as base, return `custom`.
   * */
  asBadgeColor(): ElementPropertyOptionBadgeColor {
    // Normalize current hex coming from BE and ignore alpha which is not supported by the badge
    if (!this.backgroundColor) {
      return "custom"
    }

    // Get badge color name where the hex matches
    const badgeColorName =
      Object.keys(badgeColorValues).find((colorName) => {
        const badgeColor =
          badgeColorValues[colorName as ElementPropertyOptionBadgeColor]

        return badgeColor === this.backgroundColor
      }) ?? "custom"

    return badgeColorName as ElementPropertyOptionBadgeColor
  }

  /**
   * Sets background color from hex color or badge color name.
   * @param color - Hex color or badge color name
   */
  setBackgroundColor(color: string) {
    const badgeColor =
      badgeColorValues[color as ElementPropertyOptionBadgeColor]
    if (badgeColor) {
      this.backgroundColor = badgeColor
    } else {
      this.backgroundColor = ElementPropertyOption.toHex8(color)
    }
  }
}
