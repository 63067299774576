//
//  FigmaStructureDetailModel.ts
//  Supernova SDK
//
import { FigmaFrame, FigmaFrameModel } from "./SDKFigmaFrame"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type FigmaStructureDetailModel = {
  assetsInFile: {
    componentSets: number
    components: number
    frames: number
  }
  designSystemVersionId: string
  id: string
  origin: {
    sourceId: string
  }
  persistentId: string
  rootNode: FigmaFrameModel
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class FigmaStructureDetail {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties
  assetsInFile: {
    componentSets: number
    components: number
    frames: number
  }

  designSystemVersionId: string

  id: string

  origin: {
    sourceId: string
  }

  // @ts-expect-error TS(2564): Property 'persistentId' has no initializer and is ... Remove this comment to see the full error message
  persistentId: string

  rootNode: FigmaFrameModel
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: FigmaStructureDetailModel) {
    this.assetsInFile = model.assetsInFile
    this.designSystemVersionId = model.designSystemVersionId
    this.id = model.id
    this.origin = model.origin
    this.rootNode = new FigmaFrame(model.rootNode)
  }
}
