//
//  SDKUserProfile.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export const UserOnboardingDepartments = [
  "Design",
  "Product",
  "Engineering",
  "Brand",
  "Other",
] as const

export type UserOnboardingDepartment =
  (typeof UserOnboardingDepartments)[number]

export const UserOnboardingJobLevels = [
  "Executive",
  "Manager",
  "IndividualContributor",
  "Other",
] as const

export type UserOnboardingJobLevel = (typeof UserOnboardingJobLevels)[number]

export const UserOnboardingCompanySizes = [
  "Just me",
  "2 - 49",
  "50 - 249",
  "250 - 999",
  "1,000 - 4,999",
  "5,000 - 9,999",
  "More than 10,000",
] as const

export type UserOnboardingCompanySize =
  (typeof UserOnboardingCompanySizes)[number]

export const UserOnboardingDesignSystemTeamSizes = [
  "We don’t have a design system team",
  "Only one person",
  "2 - 5",
  "6 - 10",
  "More than 10",
] as const

export type UserOnboardingDesignSystemTeamSize =
  (typeof UserOnboardingDesignSystemTeamSizes)[number]

export type UserOnboardingDefaultDestination = "tokens" | "docs"

export type UserProfileRemoteModel = {
  name?: string
  nickname?: string
  avatar?: string
  onboarding?: UserProfileOnboardingRemoteModel
  isOnboardingFinished?: boolean
}

export type UserProfileOnboardingRemoteModel = {
  companyName?: string
  numberOfPeopleInOrg?: UserOnboardingCompanySize
  numberOfPeopleInDesignTeam?: UserOnboardingDesignSystemTeamSize
  department?: UserOnboardingDepartment
  jobTitle?: string
  phase?: string
  jobLevel?: UserOnboardingJobLevel
  designSystemName?: string
  figmaUrl?: string
  defaultDestination?: UserOnboardingDefaultDestination
  isPageDraftOnboardingFinished?: boolean
  isApprovalsOnboardingFinished?: boolean
}

export type UserProfileTransportModel = UserProfileRemoteModel

export type UserProfileOnboardingUpdateModel = UserProfileOnboardingRemoteModel

export type UserProfileUpdateModel = {
  name?: string
  nickname?: string
  onboarding?: UserProfileOnboardingRemoteModel
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -- - --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class UserProfile {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** User name */
  name: string

  /** User nickname */
  nickname: string | null

  /** Avatar */
  avatar: string | null

  /** Is profile onboarding finished */
  isOnboardingFinished: boolean

  /** Onboarding object */
  onboarding: {
    companyName: string | null
    numberOfPeopleInOrg: UserOnboardingCompanySize | null
    numberOfPeopleInDesignTeam: UserOnboardingDesignSystemTeamSize | null
    department: UserOnboardingDepartment | null
    jobTitle: string | null
    phase: string | null
    jobLevel: UserOnboardingJobLevel | null
    designSystemName: string | null
    figmaUrl: string | null
    defaultDestination: UserOnboardingDefaultDestination | null
    isPageDraftOnboardingFinished: boolean | null
    isApprovalsOnboardingFinished: boolean | null
  } | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: UserProfileRemoteModel) {
    this.name = model.name ?? ""
    this.nickname = model.nickname ?? null
    this.avatar = model.avatar ?? null
    this.onboarding = model.onboarding
      ? {
          companyName: model.onboarding.companyName ?? null,
          numberOfPeopleInOrg: model.onboarding.numberOfPeopleInOrg ?? null,
          numberOfPeopleInDesignTeam:
            model.onboarding.numberOfPeopleInDesignTeam ?? null,
          department: model.onboarding.department ?? null,
          jobTitle: model.onboarding.jobTitle ?? null,
          phase: model.onboarding.phase ?? null,
          jobLevel: model.onboarding.jobLevel ?? null,
          designSystemName: model.onboarding.designSystemName ?? null,
          figmaUrl: model.onboarding.figmaUrl ?? null,
          defaultDestination: model.onboarding.defaultDestination ?? null,
          isPageDraftOnboardingFinished:
            model.onboarding.isPageDraftOnboardingFinished ?? null,
          isApprovalsOnboardingFinished:
            model.onboarding.isApprovalsOnboardingFinished ?? null,
        }
      : null
    this.isOnboardingFinished = model.onboarding?.phase === "finished"
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs directly manipulable update object for user profile */
  toProfileUpdateObject(): UserProfileUpdateModel {
    return {
      name: this.name ?? undefined,
      nickname: this.nickname ?? undefined,
    }
  }

  toOnboardingUpdateObject(): UserProfileOnboardingUpdateModel {
    const { onboarding } = this

    return {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      companyName: onboarding.companyName ?? undefined,
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      numberOfPeopleInOrg: onboarding.numberOfPeopleInOrg ?? undefined,
      numberOfPeopleInDesignTeam:
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        onboarding.numberOfPeopleInDesignTeam ?? undefined,
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      department: onboarding.department ?? undefined,
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      jobTitle: onboarding.jobTitle ?? undefined,
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      phase: onboarding.phase ?? undefined,
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      jobLevel: onboarding.jobLevel ?? undefined,
      designSystemName: onboarding?.designSystemName ?? undefined,
      figmaUrl: onboarding?.figmaUrl ?? undefined,
      defaultDestination: onboarding?.defaultDestination ?? undefined,
    }
  }

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): UserProfileTransportModel {
    return {
      name: this.name,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      nickname: this.nickname,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      avatar: this.avatar,
      // @ts-expect-error TS(2322): Type '{ companyName: string | null; numberOfPeople... Remove this comment to see the full error message
      onboarding:
        {
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          companyName: this.onboarding.companyName,
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          numberOfPeopleInOrg: this.onboarding.numberOfPeopleInOrg,
          numberOfPeopleInDesignTeam:
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            this.onboarding.numberOfPeopleInDesignTeam,
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          department: this.onboarding.department,
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          jobTitle: this.onboarding.jobTitle,
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          phase: this.onboarding.phase,
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          jobLevel: this.onboarding.jobLevel,
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          designSystemName: this.onboarding.designSystemName,
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          figmaUrl: this.onboarding.figmaUrl,
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          defaultDestination: this.onboarding.defaultDestination,
        } ?? null,
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): UserProfileTransportModel {
    return this.toRemote()
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: UserProfileTransportModel) {
    return new UserProfile(model)
  }
}
