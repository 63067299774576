//
//  SDKGradientType.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Source type enum

export enum GradientType {
  linear = "Linear",
  radial = "Radial",
  angular = "Angular",
}
