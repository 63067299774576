//
//  SDKTokenGroup.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { TokenType } from "../enums/SDKTokenType"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type TokenGroupRemoteModel = {
  id?: string
  parentId?: string
  brandId: string
  tokenType: TokenType
  designSystemVersionId: string
  persistentId?: string
  isRoot: boolean
  meta: {
    name: string
    description: string
  }
  childrenIds: string[]
  createdAt?: string
  updatedAt?: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class TokenGroup {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  idInVersion: string

  brandId: string

  designSystemVersionId: string

  name: string

  description: string

  path: Array<string>

  subgroupIds: Array<string>

  tokenType: TokenType

  isRoot: boolean

  childrenIds: Array<string>

  tokenIds: Array<string>

  parentGroupId: string | null

  sortOrder: number

  createdAt: Date | null

  updatedAt: Date | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: TokenGroupRemoteModel) {
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.id = model.persistentId
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.idInVersion = model.id
    this.brandId = model.brandId
    this.designSystemVersionId = model.designSystemVersionId
    this.name = model.meta.name
    this.description = model.meta.description || ""
    this.isRoot = model.isRoot
    this.tokenType = model.tokenType
    this.childrenIds = model.childrenIds
    this.path = new Array<string>()
    this.tokenIds = new Array<string>()
    this.subgroupIds = new Array<string>()
    this.parentGroupId = null
    // Set unordered when constructing
    this.sortOrder = -1
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Convenience

  addChild(groupId: string) {
    this.subgroupIds.push(groupId)
  }

  addChildren(groupsIds: Array<string>) {
    this.subgroupIds = this.subgroupIds.concat(groupsIds)
  }

  setPath(segments: Array<string>) {
    this.path = segments
  }

  setParentGroupId(parentGroupId: string | null) {
    this.parentGroupId = parentGroupId ?? null
  }

  setSortOrder(order: number) {
    this.sortOrder = order
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Write

  toWriteObject(): TokenGroupRemoteModel {
    return {
      id: this.idInVersion,
      brandId: this.brandId,
      tokenType: this.tokenType,
      designSystemVersionId: this.designSystemVersionId,
      persistentId: this.id,
      isRoot: this.isRoot,
      meta: {
        name: this.name,
        description: this.description,
      },
      childrenIds: this.childrenIds,
    }
  }

  toMutatedObject(childrenIds: Array<string>) {
    const group = new TokenGroup({
      id: this.idInVersion,
      brandId: this.brandId,
      tokenType: this.tokenType,
      designSystemVersionId: this.designSystemVersionId,
      persistentId: this.id,
      isRoot: this.isRoot,
      meta: {
        name: this.name,
        description: this.description,
      },
      childrenIds,
    })

    group.parentGroupId = this.parentGroupId
    group.sortOrder = this.sortOrder
    return group
  }
}
