import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.release-notes"
const name = "Release notes"
const icon = "https://cdn-assets.supernova.io/blocks/icons/release-notes.svg"
const descriptionShort = "Show version release notes"
const searchKeywords = ["version", "changelog", "history"]
const helpText =
  "Show formatted release notes from all previously released versions."

const docsUrl = undefined

/**
 * 2. Block properties
 */
const properties = {}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [],
  image: "https://cdn-assets.supernova.io/blocks/variants/release-notes-2.svg",
  description: helpText,
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.other,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  appearance: {
    isEditorPresentationDifferent: true,
    showBlockHeaderInEditor: true,
  },
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as ReleaseNotes }
