//
//  AreaVersions.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DesignSystemVersion,
  DesignSystemVersionCreate,
  DesignSystemVersionCreationJob,
  DesignSystemVersionUpdateModel,
} from "../../model/base/SDKDesignSystemVersion"
import { DataCore } from "../data/SDKDataCore"
import { SupernovaError } from "../errors/SDKSupernovaError"

import { RemoteVersionIdentifier } from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaVersions {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches active design system version - the one to which all changes are being written currently. There is always one active version available for any provided design system. */
  async getActiveVersion(
    designSystemId: string
  ): Promise<DesignSystemVersion | null> {
    const versions = await this.dataCore.versions(designSystemId)

    // Retrieve version that is currently active and available for write
    for (const version of versions) {
      if (!version.isReadonly) {
        return version
      }
    }

    throw SupernovaError.fromMessage(
      `Unable to retrieve active version for design system id ${designSystemId} - no version is currently active in selected design system`
    )
  }

  /** Creates new version and retrieves the newly created version object.
   * @param designSystemId - ID of the design system
   * @param model - new version model
   * @returns Newly created version object
   */
  async createVersion(
    designSystemId: string,
    model: DesignSystemVersionUpdateModel
  ): Promise<DesignSystemVersionCreate> {
    return this.dataCore.createVersion(designSystemId, model)
  }

  /** Edits version
   * @param designSystemId - ID of the design system
   * @param versionId - ID of version
   * @param model - new version model
   * @returns nothing
   */
  async editVersion(
    designSystemId: string,
    versionId: string,
    model: DesignSystemVersionUpdateModel
  ): Promise<void> {
    await this.dataCore.editVersion(designSystemId, versionId, model)
  }

  /** Deletes version
   * @param designSystemId - ID of the design system
   * @param versionId - ID of version
   * @returns nothing
   */
  async deleteVersion(
    designSystemId: string,
    versionId: string
  ): Promise<void> {
    await this.dataCore.deleteVersion(designSystemId, versionId)
  }

  /** Fetches all design system versions of provided design system */
  async getVersions(
    designSystemId: string
  ): Promise<Array<DesignSystemVersion>> {
    return this.dataCore.versions(designSystemId)
  }

  /** Fetches design system version by id */
  async getVersion(
    from: RemoteVersionIdentifier
  ): Promise<DesignSystemVersion> {
    return this.dataCore.version(from.designSystemId, from.versionId)
  }

  /** Fetches version creation job */
  async getVersionCreationJob(
    designSystemId: string,
    jobId: string
  ): Promise<DesignSystemVersionCreationJob> {
    return this.dataCore.versionCreationJob(designSystemId, jobId)
  }

  /** Fetches all version creation jobs */
  async getVersionCreationJobs(
    designSystemId: string
  ): Promise<DesignSystemVersionCreationJob[]> {
    return this.dataCore.versionCreationJobs(designSystemId)
  }

  async getTokenStudioData(from: RemoteVersionIdentifier): Promise<object> {
    return this.dataCore.getTokenStudioData(from.designSystemId, from.versionId)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  async writeTokenStudioData(
    from: RemoteVersionIdentifier,
    data: object
  ): Promise<object> {
    return this.dataCore.writeTokenStudioData(
      from.designSystemId,
      from.versionId,
      data
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Compute
}
