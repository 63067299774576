//
//  SDKWorkspaceInvitation.ts
//  Supernova SDK
//
//  Created by Supernova.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { UserRole } from "../enums/SDKUserRole"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceInvitationRemoteModel = {
  id: string
  email: string
  createdAt: string
  resentAt?: string
  role: UserRole
  workspaceId: string
  invitedBy: string
}

export type WorkspaceInvitationTransportModel = WorkspaceInvitationRemoteModel

export type WorkspaceInvitationUpdateModel = {
  email: string
  role: UserRole
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceInvitation {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Unique identifier of the invitation */
  id: string

  /** Email address associated with the invitation */
  email: string

  /** Date and time when the invitation was created */
  createdAt: Date

  /** Date and time when the invitation was last resent, or null if never resent */
  resentAt: Date | null

  /** Role assigned to the user being invited */
  role: UserRole

  /** ID of the workspace associated with the invitation */
  workspaceId: string

  /** User who sent the invitation */
  invitedBy: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceInvitationRemoteModel) {
    this.id = model.id
    this.email = model.email
    this.createdAt = new Date(model.createdAt)
    this.resentAt = model.resentAt ? new Date(model.resentAt) : null
    this.role = model.role
    this.workspaceId = model.workspaceId
    this.invitedBy = model.invitedBy
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toRemote(): WorkspaceInvitationTransportModel {
    return {
      id: this.id,
      email: this.email,
      createdAt: this.createdAt.toISOString(),
      resentAt: this.resentAt?.toISOString(),
      role: this.role,
      workspaceId: this.workspaceId,
      invitedBy: this.invitedBy,
    }
  }

  toTransport(): WorkspaceInvitationTransportModel {
    return this.toRemote()
  }

  static fromTransport(model: WorkspaceInvitationTransportModel) {
    return new WorkspaceInvitation(model)
  }
}
