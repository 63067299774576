//
//  SDKDocsBlockMarkdownProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Markdown property - Additional non-value options */
export type DocsBlockMarkdownPropertyOptions = {
  /** Defines how to validate the markdown URL */
  urlValidationRegex?: string
}

/** Markdown property - Data */
export type DocsBlockMarkdownPropertyData = DocsBlockPropertyData<string> &
  Record<string, never>

/** Markdown property - Definition model */
export type DocsBlockMarkdownPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockMarkdownPropertyOptions

/** Markdown property - Definition */
export class DocsBlockMarkdownPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockMarkdownPropertyOptions,
  DocsBlockMarkdownPropertyData
> {
  type!: DocsBlockItemPropertyType.markdown
}
