//
//  SDKDocsBlockTableProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocumentationLegacyRichTextModel } from "../../../documentation/SDKDocumentationLegacyRichText"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsImageRef } from "../support/SDKDocsImageRef"

import {
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
  DocsBlockPropertyData,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Table property - Additional non-value options */
export type DocsBlockTablePropertyOptions = Record<string, never>

export type DocsBlockTableRichTextNode = {
  type: "RichText"
  value: DocumentationLegacyRichTextModel
}

export type DocsBlockTableImageNode = {
  type: "Image"
  value: DocsImageRef
}

export type DocsBlockTableMultiRichTextNode = {
  type: "MultiRichText"
  value: Array<DocumentationLegacyRichTextModel>
}

export type DocsBlockTableNode =
  | DocsBlockTableRichTextNode
  | DocsBlockTableImageNode
  | DocsBlockTableMultiRichTextNode

/** Table property - Data */
export type DocsBlockTablePropertyData = DocsBlockPropertyData<
  Array<{
    cells: {
      id: string
      alignment: "Left" | "Center" | "Right"
      columnWidth: number
      nodes: Array<DocsBlockTableNode>
    }[]
  }>
> & {
  // Turns first column to TH. Default: false
  highlightHeaderColumn?: boolean
  // Turns first row to TH. Default: false
  highlightHeaderRow?: boolean
  // Shows table borders. Default: true
  showBorder?: boolean
}

/** Table property - Definition model */
export type DocsBlockTablePropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockTablePropertyOptions

/** Table property - Definition */
export class DocsBlockTablePropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockTablePropertyOptions,
  DocsBlockTablePropertyData
> {
  type!: DocsBlockItemPropertyType.table
}
