//
//  SDKDocumentationOldConfiguration.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { WorkspaceNPMRegistrySettings } from "../workspaces/SDKWorkspaceNPMRegistrySettings"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DocumentationLegacyConfigurationModel = {
  tabbed: boolean
  storybookEmbedErrorMessage?: string
  renderCodePackageJson?: string
  isPublic: boolean
  isPrivate: boolean
  isHidden: boolean
  serveDefaultVersionOnly: boolean
  selectedBrandId: string
  host: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DocumentationLegacyConfiguration {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  tabbedNavigation: boolean

  isPublic: boolean

  isPrivate: boolean

  isHidden: boolean

  serveDefaultVersionOnly: boolean

  selectedBrandId: string

  host: string

  storybookError: string | null

  packageJson: string | null

  npmRegistry: WorkspaceNPMRegistrySettings | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    model: DocumentationLegacyConfigurationModel,
    registry: WorkspaceNPMRegistrySettings | null
  ) {
    this.tabbedNavigation = model.tabbed
    this.storybookError = model.storybookEmbedErrorMessage ?? null
    this.packageJson = model.renderCodePackageJson ?? null
    this.isPublic = model.isPublic
    this.isPrivate = model.isPrivate
    this.isHidden = model.isHidden
    this.host = model.host
    this.selectedBrandId = model.selectedBrandId
    this.serveDefaultVersionOnly = model.serveDefaultVersionOnly
    this.npmRegistry = registry ?? null
  }

  toConfigurationModelWriteObject(): DocumentationLegacyConfigurationModel {
    return {
      tabbed: this.tabbedNavigation,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      storybookEmbedErrorMessage: this.storybookError,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      renderCodePackageJson: this.packageJson,
      isPublic: this.isPublic,
      serveDefaultVersionOnly: this.serveDefaultVersionOnly,
      selectedBrandId: this.selectedBrandId,
      host: this.host,
    }
  }
}
