//
//  SDKBorderToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import {
  ALL_BORDER_POSITIONS,
  BorderPosition,
} from "../enums/SDKBorderPosition"
import { ALL_BORDER_STYLES, BorderStyle } from "../enums/SDKBorderStyle"
import { TokenType } from "../enums/SDKTokenType"

import {
  BorderTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { BorderTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { ColorToken } from "./SDKColorToken"
import { DimensionToken } from "./SDKDimensionToken"
import { Token } from "./SDKToken"
import { BorderTokenValue } from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class BorderToken extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: BorderTokenValue

  tokenType: TokenType.border = TokenType.border

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: BorderTokenValue,
    alias: BorderToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value

    if (alias) {
      this.value.referencedTokenId = alias.id
    }
  }

  static create(
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: object,
    alias: BorderToken | null,
    referenceResolver: DTTokenReferenceResolver,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): BorderToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type: TokenType.border,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      // Raw value
      const tokenValue = this.borderValueFromDefinition(
        value,
        referenceResolver
      )

      return new BorderToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const tokenValue: BorderTokenValue = {
        color: alias.value.color,
        width: alias.value.width,
        position: alias.value.position,
        style: alias.value.style,
        referencedTokenId: alias.id,
      }

      return new BorderToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "Border Token must be created using value or alias, but none was provided"
    )
  }

  static borderValueFromDefinition(
    definition: object,
    referenceResolver: DTTokenReferenceResolver
  ): BorderTokenValue {
    let data = definition

    // For now, handle only one shadow in multiple shadow layers
    if (data instanceof Array) {
      if (data.length > 0) {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data = data[0]
      } else {
        // Empty definition needs to fallback to proper SN definition - make it transparent shadow with 0 0 0 0 values
        data = {
          color: "rgba(0,0,0,0)",
          position: "outside",
          style: "solid",
          width: 0,
          type: "border",
        }
      }
    }

    if (
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !definition.hasOwnProperty("color") || // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !definition.hasOwnProperty("width")
    ) {
      throw SupernovaError.fromMessage(
        `Border definition is missing one of required properties (color, width), was ${JSON.stringify(
          definition
        )}`
      )
    }

    const value = {} as BorderTokenValue // Empty container

    // @ts-expect-error TS(2322): Type 'ColorTokenValue | undefined' is not assignab... Remove this comment to see the full error message
    value.color = ColorToken.colorValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      (definition as any).color,
      referenceResolver
    )
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    value.position = this.toBorderPosition((definition as any).position)
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    value.style = this.toBorderStyle((definition as any).style)
    value.width = DimensionToken.dimensionValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (definition as any).width,
      referenceResolver,
      TokenType.borderWidth
    )
    // TODO: Position, style

    if (value.color === undefined) {
      throw new Error(
        `Unable to resolve value 'color' for border token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    if (value.position === undefined) {
      throw new Error(
        `Unable to resolve value 'position' for border token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    if (value.width === undefined) {
      throw new Error(
        `Unable to resolve value 'width' for border token definition \n${JSON.stringify(
          definition,
          null,
          2
        )}\n Did you possibly use incorrect reference?`
      )
    }

    return value
  }

  static toBorderStyle(value: any): BorderStyle {
    return (
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ALL_BORDER_STYLES.find((v) => v.toLowerCase() === value?.toLowerCase()) ??
      BorderStyle.solid
    )
  }

  static toBorderPosition(value: any): BorderPosition {
    return (
      ALL_BORDER_POSITIONS.find(
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        (v) => v.toLowerCase() === value?.toLowerCase()
      ) ?? BorderPosition.outside
    )
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Writing

  toWriteObject(): BorderTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as BorderTokenRemoteModel

    specificData.data = BorderToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: BorderTokenValue): {
    aliasTo: string | undefined
    value: BorderTokenRemoteValue
  } {
    const valueObject = !value.referencedTokenId
      ? {
          color: {
            aliasTo: value.color.referencedTokenId ?? undefined,
            value: value.color.referencedTokenId
              ? null
              : {
                  color: ColorToken.colorValueToHex8(value.color),
                  opacity: {
                    aliasTo: value.color.opacity.referencedTokenId ?? undefined,
                    value: value.color.opacity.referencedTokenId
                      ? null
                      : {
                          measure: value.color.opacity.measure,
                          unit: value.color.opacity.unit,
                        },
                  },
                },
          },
          width: {
            aliasTo: value.width.referencedTokenId ?? undefined,
            value: value.width.referencedTokenId
              ? null
              : {
                  measure: value.width.measure,
                  unit: value.width.unit,
                },
          },
          position: value.position,
          style: value.style,
        }
      : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type '{ color: { aliasTo: string | undefined; valu... Remove this comment to see the full error message
      value: valueObject,
    }
  }
}
