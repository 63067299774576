import { DTOGitOrganization } from "@supernova-studio/client"

export type GitProvidersOrganizationsRemoteModel = {
  organizations: Array<DTOGitOrganization>
}

export class GitProvidersOrganization implements DTOGitOrganization {
  id: string

  name: string

  url: string

  slug: string

  constructor(model: DTOGitOrganization) {
    this.id = model.id
    this.name = model.name
    this.url = model.url
    this.slug = model.slug
  }
}

export class GitProvidersOrganizations {
  organizations: Array<GitProvidersOrganization>

  constructor(model: GitProvidersOrganizationsRemoteModel) {
    this.organizations = model.organizations.map(
      (organization) => new GitProvidersOrganization(organization)
    )
  }
}
