import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockBehaviorDataType } from "../../../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../../../enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.figma-frames"
const name = "Figma images"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v2/figma-frames.svg"
const descriptionShort = "Generate images from Figma layers"
const keywords = ["figma", "frames", "image", "layer"]
const descriptionFull = "Generate images from Figma layers"
const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/figma/figma-frames/general-f3IYC5dg"

/**
 * 2. Block properties
 */
const properties = {
  figmaNodes: b.figmaNodeProperty({
    id: "figmaNodes",
    name: "Figma Frames",
    options: {},
  }),
}

/**
 * 3. Block variants
 */
const variants: Array<DocsBlockVariant> = [
  b.variantUsingDefaultLayout({
    id: "bordered",
    name: "Bordered",
    description:
      "Tiles with background and border, great for larger sets of frames.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/figma-nodes-boxed.svg",
    properties: [properties.figmaNodes],
    maxColumns: 8,
  }),
  b.variantUsingDefaultLayout({
    id: "plain",
    name: "Plain",
    description:
      "Frame as it is in Figma with no extra formatting, great for single images.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/figma-nodes-plain.svg",
    properties: [properties.figmaNodes],
    maxColumns: 8,
  }),
]

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.multiVariantItem({
  properties: [properties.figmaNodes],
  variants,
})

/**
 * 5. Block definition
 */
const block = b.dataBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.figma,
  description: descriptionShort,
  searchKeywords: keywords,
  dataType: DocsBlockBehaviorDataType.figmaNode,
  selectionType: DocsBlockBehaviorSelectionType.entity,
  item: blockItem,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as FigmaFrames }
