export enum DocsBlockOptionRenderingStyle {
  /** Option will be rendered as a segmented control */
  segmentedControl = "SegmentedControl",
  /** Option will be rendered as a toggle button */
  toggleButton = "ToggleButton",
  /** Option will be rendered as a select */
  select = "Select",
  /** Option will be rendered as checkboxes/radios */
  checkbox = "Checkbox",
}
