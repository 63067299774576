import { DTODesignSystemRole } from "@supernova-studio/client"

import { SupernovaError } from "../../core/errors/SDKSupernovaError"

import { UserRole } from "./SDKUserRole"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Role enum

export type DesignSystemRole = Exclude<
  UserRole,
  UserRole.owner | UserRole.billing
>

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Mapping

export const convertRemoteToDesignSystemRole = (
  remoteRole: DTODesignSystemRole
): DesignSystemRole => {
  switch (remoteRole) {
    case "Admin":
      return UserRole.admin
    case "Creator":
      return UserRole.creator
    case "Contributor":
      return UserRole.contributor
    case "Viewer":
      return UserRole.viewer
    default:
      throw SupernovaError.fromMessage(
        `Unknown design system role: ${remoteRole}`
      )
  }
}

export const resolveDesignSystemRoleFromWorkspaceRole = (
  wsRole: UserRole
): DesignSystemRole => {
  switch (wsRole) {
    case UserRole.owner:
    case UserRole.admin:
      return UserRole.admin
    case UserRole.creator:
      return UserRole.creator
    case UserRole.contributor:
      return UserRole.contributor
    case UserRole.billing:
    case UserRole.viewer:
    default:
      return UserRole.viewer
  }
}
