//
//  FigmaFrameModel.ts
//  Supernova SDK
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export enum FrameType {
  DOCUMENT = "DOCUMENT",
  CANVAS = "CANVAS",
  COMPONENT_SET = "COMPONENT_SET",
  COMPONENT_VARIANT = "COMPONENT_VARIANT",
  COMPONENT = "COMPONENT",
  SECTION = "SECTION",
  FRAME = "FRAME",
}

export type FigmaFrameModel = {
  id: string
  name: string
  type: FrameType
  children: Array<FigmaFrameModel>
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class FigmaFrame {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties
  id: string

  name: string

  type: FrameType

  children: Array<FigmaFrame>
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: FigmaFrameModel) {
    this.id = model.id
    this.name = model.name
    this.type = model.type
    this.children = model.children.map((child) => new FigmaFrame(child))
  }
}
