//
//  SDKDocsBlockComponentProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Component property - Additional non-value options */
export type DocsBlockComponentPropertyOptions = {
  /** How should the tokens be rendered in editor */
  renderLayoutAs?: "List" | "Table"
  /** Whether to allow selecting component custom properties. True by default */
  allowPropertySelection?: boolean
}

/** Component property - Data */
export type DocsBlockComponentPropertyData = DocsBlockPropertyData<
  Array<{
    entityId: string
    entityType: "Component" | "ComponentGroup"
  }>
> & {
  /** Selected component ids */
  selectedPropertyIds?: Array<string>
  /** Brand id */
  selectedBrandId?: string
}

/** Component property - Definition model */
export type DocsBlockComponentPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockComponentPropertyOptions

/** Component property - Definition */
export class DocsBlockComponentPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockComponentPropertyOptions,
  DocsBlockComponentPropertyData
> {
  type!: DocsBlockItemPropertyType.component
}
