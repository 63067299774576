import { DTOWorkspaceRole } from "@supernova-studio/client"

import { SupernovaError } from "../../core/errors/SDKSupernovaError"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Role enum

/**
 * Workspace role
 * */
export enum UserRole {
  owner = "Owner",
  admin = "Admin",
  creator = "Creator",
  contributor = "Contributor",
  billing = "Billing",
  viewer = "Viewer",
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Mapping

// TODO (Ydus): Remove?
export const convertRemoteToUserRole = (
  remoteRole: DTOWorkspaceRole
): UserRole => {
  switch (remoteRole) {
    case "Owner":
      return UserRole.owner
    case "Admin":
      return UserRole.admin
    case "Creator":
      return UserRole.creator
    case "Contributor":
      return UserRole.contributor
    case "Billing":
      return UserRole.billing
    case "Viewer":
      return UserRole.viewer
    default:
      throw SupernovaError.fromMessage(`Unknown role: ${remoteRole}`)
  }
}
