import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.code-react"
const name = "React code"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v3/code-react.svg"
const descriptionShort = "Render a code snippet"
const searchKeywords = [
  "code",
  "react",
  "snippet",
  "storybook",
  "editor",
  "example",
  "sandbox",
]
const descriptionFull = "Display rendered code example"
const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/code/render-react-code-vxufnkFm"

/**
 * 2. Block properties
 */
const properties = {
  code: b.codeSandboxProperty({
    id: "code",
    name: "Code",
    options: {
      renderLayoutAs: "PreviewOnTop",
    },
    variants: {
      codeTop: {
        renderLayoutAs: "PreviewOnBottom",
      },
      codeLeft: {
        renderLayoutAs: "PreviewOnRight",
      },
      codeRight: {
        renderLayoutAs: "PreviewOnLeft",
      },
    },
  }),
}

/**
 * 3. Block variants
 */
const variants: Array<DocsBlockVariant> = [
  b.variantUsingDefaultLayout({
    id: "codeBottom",
    name: "Full width, code bottom",
    description: "Full-width block of code, with a preview on top.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/react-code-bottom.svg",
    properties: [properties.code],
  }),
  b.variantUsingDefaultLayout({
    id: "codeTop",
    name: "Full width, code top",
    description: "Full-width block of code, with a preview on bottom.",
    image: "https://cdn-assets.supernova.io/blocks/variants/react-code-top.svg",
    properties: [properties.code],
  }),
  b.variantUsingDefaultLayout({
    id: "codeLeft",
    name: "Side by side, code left",
    description: "Side-by-side preview and code, with code on the left.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/react-code-left.svg",
    properties: [properties.code],
  }),
  b.variantUsingDefaultLayout({
    id: "codeRight",
    name: "Side by side, code right",
    description: "Side-by-side preview and code, with code on the right.",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/react-code-right.svg",
    properties: [properties.code],
  }),
]

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.multiVariantItem({
  properties: [properties.code],
  variants,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.code,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  appearance: {
    isBordered: false,
  },
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as CodeReact }
