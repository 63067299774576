//
//  SDKUserMembership.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { UserRole } from "../enums/SDKUserRole"

import { User, UserRemoteModel } from "./SDKUser"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type UserMembershipRemoteModel = {
  user: UserRemoteModel
  role: UserRole
  effectiveRole: UserRole
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -- - --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class UserMembership {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Full user object */
  user: User

  /** Role of the user in the workspace */
  role: UserRole

  /**
   * Effective role of the user in the workspace, which can be different from the regular role
   * when the workspace is suspended etc.
   */
  effectiveRole: UserRole

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: UserMembershipRemoteModel) {
    this.user = new User(model.user)
    this.role = model.role
    this.effectiveRole = model.effectiveRole
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): UserMembershipRemoteModel {
    return {
      user: this.user.toRemote(),
      role: this.role,
      effectiveRole: this.effectiveRole,
    }
  }
}
