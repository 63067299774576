//
//  SDKDocsBlockProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { PageBlockDefinitionProperty } from "@supernova-studio/model"

import { castEnum } from "../../../../utils/CommonUtils"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

export type DocsBlockPropertyDefinitionModel = PageBlockDefinitionProperty

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Property data

export type DocsBlockPropertyData<T> = {
  value: T
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Property definition

/** Generic property definition for when we don't care about specific types in options, just want to use the property high-level data */
export type DocsBlockItemPropertyAnyDefinition = DocsBlockPropertyDefinition<
  any,
  DocsBlockPropertyData<any>
>

export class DocsBlockPropertyDefinition<
  T extends object,
  D extends DocsBlockPropertyData<any>
> {
  /** Property id, validated to be unique within a block, but not necessarily unique across all blocks */
  id: string

  /** Human readable name that will show in the editor */
  name: string

  /** Property type. There are many different types of properties, explained later */
  type: DocsBlockItemPropertyType

  /** Human readable description that will show next to the property */
  description: string | null

  /** Options specific to this property type */
  options: T | null

  /**
   * Overrides of options for different variants.
   * Key/s must be set as id of the variant for override to go into effect.
   * Options are resolved as variantOptions[key] > options > default for any variant.
   * It is possible to override only a subset of options, in which case combination of default option settings and the override goes into effect */
  variantOptions: { [key: string]: T } | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DocsBlockPropertyDefinitionModel) {
    this.id = model.id
    this.name = model.name
    this.type = castEnum<DocsBlockItemPropertyType>(model.type)
    this.description = model.description ?? null
    this.options = (model.options as T) ?? null
    this.variantOptions = (model.variantOptions as { [key: string]: T }) ?? null
  }

  // --- --- Helpers

  defaultData(): D {
    return {
      value: undefined,
    } as D
  }
}
