//
//  SDKDocsBlockUrlProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
  DocsBlockPropertyData,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Url property - Additional non-value options */
export type DocsBlockUrlPropertyOptions = {
  urlValidationRegex?: string
}

/** Url property - Data */
export type DocsBlockUrlPropertyData = DocsBlockPropertyData<string> &
  Record<string, never>

/** Url property - Definition model */
export type DocsBlockUrlPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockUrlPropertyOptions

/** Url property - Definition */
export class DocsBlockUrlPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockUrlPropertyOptions,
  DocsBlockUrlPropertyData
> {
  type!: DocsBlockItemPropertyType.embedURL
}
