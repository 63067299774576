//
//  SDKDocsBlockMultiSelectProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsBlockOptionRenderingStyle } from "../../../enums/SDKDocsBlockOptionRenderStyle"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** MultiSelect property - Additional non-value options */
export type DocsBlockMultiSelectPropertyOptions = {
  defaultChoices: Array<string>
  choices: Array<{
    value: string
    name: string
    icon?: string
  }>
  multiSelectStyle?: Exclude<
    DocsBlockOptionRenderingStyle,
    DocsBlockOptionRenderingStyle.toggleButton
  >
}

/** MultiSelect property - Data */
export type DocsBlockMultiSelectPropertyData = DocsBlockPropertyData<
  Array<string>
> &
  Record<string, never>

/** MultiSelect property - Definition model */
export type DocsBlockMultiSelectPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockMultiSelectPropertyOptions

/** MultiSelect property - Definition */
export class DocsBlockMultiSelectPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockMultiSelectPropertyOptions,
  DocsBlockMultiSelectPropertyData
> {
  type!: DocsBlockItemPropertyType.multiSelect
}
