//
//  SDKDocsBlockRichTextEditorProperty.ts
//  Supernova SDK
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { PageBlockItemRichTextEditorValue } from "@supernova-studio/model"

import { DocumentationLegacyRichTextModel } from "../../../documentation/SDKDocumentationLegacyRichText"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsBlockRichTextEditorPropertyStyle } from "../../../enums/SDKDocsBlockRichTextEditorPropertyStyle"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** RichTextEditor property - Additional non-value options */
export type DocsBlockRichTextEditorPropertyOptions = {
  /** Placeholder to show when rich text area is empty */
  placeholder?: string
  /** Allowed styles for the rich text editor */
  allowedInlineStyles?: Array<DocsBlockRichTextEditorPropertyStyle>
}

/** RichTextEditor property - Data */
export type DocsBlockRichTextEditorPropertyData = DocsBlockPropertyData<
  PageBlockItemRichTextEditorValue["value"]
>

/** RichTextEditor property - Definition model */
export type DocsBlockRichTextEditorPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockRichTextEditorPropertyOptions

/** RichTextEditor property - Definition */
export class DocsBlockRichTextEditorPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockRichTextEditorPropertyOptions,
  DocsBlockRichTextEditorPropertyData
> {
  type!: DocsBlockItemPropertyType.richTextEditor
}
