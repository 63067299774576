//
//  SDKWorkspaceInvoice.ts
//  Supernova SDK
//
//  Created by Supernova.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceInvoiceRemoteModel = {
  id: string
  number: string
  amount_due: number
  date: string
  hostedUrl: string
  card?: WorkspaceInvoiceCardRemoteModel
}

export type WorkspaceInvoiceCardRemoteModel = {
  last4?: string
  expiryMonth?: string
  expiryYear?: string
  brand?: string
}

export type WorkspaceInvoiceTransportModel = WorkspaceInvoiceRemoteModel

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceInvoice {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Unique identifier of the invoice */
  id: string

  /** Invoice number */
  number: string

  /** Amount due on the invoice */
  amount_due: number

  /** Date of the invoice */
  date: Date

  /** Hosted URL for the invoice */
  hostedUrl: string

  /** Card information associated with the invoice */
  card: {
    last4: string | null
    expiryMonth: string | null
    expiryYear: string | null
    brand: string | null
  } | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceInvoiceRemoteModel) {
    this.id = model.id
    this.number = model.number
    this.amount_due = model.amount_due
    this.date = new Date(model.date)
    this.hostedUrl = model.hostedUrl

    if (model.card) {
      this.card = {
        last4: model.card.last4 || null,
        expiryMonth: model.card.expiryMonth || null,
        expiryYear: model.card.expiryYear || null,
        brand: model.card.brand || null,
      }
    } else {
      this.card = null
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toRemote(): WorkspaceInvoiceTransportModel {
    return {
      id: this.id,
      number: this.number,
      amount_due: this.amount_due,
      date: this.date.toISOString(),
      hostedUrl: this.hostedUrl,
      // @ts-expect-error TS(2322): Type '{ last4: string | null; expiryMonth: string ... Remove this comment to see the full error message
      card: this.card,
    }
  }

  toTransport(): WorkspaceInvoiceTransportModel {
    return this.toRemote()
  }

  static fromTransport(model: WorkspaceInvoiceTransportModel) {
    return new WorkspaceInvoice(model)
  }
}
