import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.storybook"
const name = "Storybook"
const icon = "https://cdn-assets.supernova.io/blocks/icons/storybook.svg"
const descriptionShort = "Embed Storybook canvas"

const searchKeywords = [
  "storybook",
  "story",
  "stories",
  "example",
  "preview",
  "code",
  "react",
]

const helpText = "Embed a Storybook story to your documentation."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/code/storybook-1EGPhwBl"

/**
 * 2. Block properties
 */
const properties = {
  storybookUrl: b.storybookProperty({
    id: "embed",
    name: "Storybook URL",
    options: {
      allowCaption: true,
      allowResize: true,
      defaultHeight: 400,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.storybookUrl],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.storybookUrl],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.media,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as Storybook }
