//
//  SDKTextToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2021 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
// TODO:fix-sdk-eslint
// eslint-disable-next-line max-classes-per-file
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
import {
  TokenClassTypeMapToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TokenTypeMapToken,
} from "../../utils/TokenUtils"
import { UnreachableCaseError } from "../../utils/UnreachableCaseError"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Brand } from "../base/SDKBrand"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { StringTokenType, TokenType } from "../enums/SDKTokenType"

import {
  StringTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { StringTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { Token } from "./SDKToken"
import {
  AnyStringTokenValue,
  FontFamilyTokenValue,
  FontWeightTokenValue,
  ProductCopyTokenValue,
  StringTokenValue,
} from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class StringCategoryToken<T extends AnyStringTokenValue> extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: T

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: T,
    alias: StringCategoryToken<T> | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value

    if (alias) {
      this.value.referencedTokenId = alias.id
    }
  }

  static create<
    TValue extends AnyStringTokenValue,
    TToken extends StringCategoryToken<TValue>
  >(
    this: new (...args: any[]) => TToken,
    type: StringTokenType,
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: string | number,
    alias: TToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): TToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      const tokenValue: AnyStringTokenValue = {
        text: value.toString(),
        referencedTokenId: null,
      }

      return new this(
        versionId,
        baseToken,
        tokenValue,
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const tokenValue: AnyStringTokenValue = {
        text: alias.value?.text,
        referencedTokenId: alias.id,
      }

      return new this(
        versionId,
        baseToken,
        tokenValue,
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "String Token must be created using value or alias, but none was provided"
    )
  }

  static stringValueFromDefinitionOrReference(
    definition: any,
    referenceResolver: DTTokenReferenceResolver,
    tokenType: StringTokenType
  ): AnyStringTokenValue | undefined {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (referenceResolver.valueHasReference(definition)) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (!referenceResolver.isBalancedReference(definition)) {
        // Internal syntax of reference corrupted
        throw new Error(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Invalid reference syntax in token value: ${definition}`
        )
      }

      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (referenceResolver.valueIsPureReference(definition)) {
        // When color is pure reference, we can immediately resolve it
        const reference = referenceResolver.lookupReferencedToken(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          definition
        ) as AnyStringToken

        if (!reference) {
          // NOTE: This might later be resolved (see Caviar default, fontWeight)
          return undefined
        }

        return {
          referencedTokenId: reference.id,
          text: reference.value.text,
        }
      }

      // When color is not a pure reference, we must resolve it further before we can resolve it
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const references = referenceResolver.lookupAllReferencedTokens(definition)

      if (!references) {
        // Still unable to solve the reference, continue looking in some other tokens
        return undefined
      }

      // Resolved all internal references
      const resolvedValue = referenceResolver.replaceAllReferencedTokens(
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        definition,
        references
      )

      return this.stringValueFromDefinition(resolvedValue, tokenType)
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.stringValueFromDefinition(definition, tokenType)
  }

  static stringValueFromDefinition(
    definition: string,
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tokenType: StringTokenType
  ): AnyStringTokenValue {
    return {
      text: definition || "",
      referencedTokenId: null,
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Write

  toWriteObject(): StringTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as StringTokenRemoteModel

    specificData.data = StringCategoryToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: AnyStringTokenValue): {
    aliasTo: string | undefined
    value: StringTokenRemoteValue
  } {
    const valueObject = !value.referencedTokenId ? value.text : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      value: valueObject,
    }
  }
}

export class StringToken extends StringCategoryToken<StringTokenValue> {
  tokenType: TokenType.string = TokenType.string
}

export class ProductCopyToken extends StringCategoryToken<ProductCopyTokenValue> {
  tokenType: TokenType.productCopy = TokenType.productCopy
}

export class FontFamilyToken extends StringCategoryToken<FontFamilyTokenValue> {
  tokenType: TokenType.fontFamily = TokenType.fontFamily
}

export class FontWeightToken extends StringCategoryToken<FontWeightTokenValue> {
  tokenType: TokenType.fontWeight = TokenType.fontWeight
}

export type AnyStringToken =
  | StringToken
  | ProductCopyToken
  | FontFamilyToken
  | FontWeightToken

// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TypeofAnyStringToken =
  | typeof StringToken
  | typeof ProductCopyToken
  | typeof FontFamilyToken
  | typeof FontWeightToken

export const resolveString = <TK extends StringTokenType>(
  type: TK
): TokenClassTypeMapToken[TK] => {
  switch (type) {
    case TokenType.string:
      return StringToken as TokenClassTypeMapToken[TK]
    case TokenType.productCopy:
      return ProductCopyToken as TokenClassTypeMapToken[TK]
    case TokenType.fontFamily:
      return FontFamilyToken as TokenClassTypeMapToken[TK]
    case TokenType.fontWeight:
      return FontWeightToken as TokenClassTypeMapToken[TK]
    default:
      throw new UnreachableCaseError(type)
  }
}
