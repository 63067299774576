//
//  SDKTextDecoration.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Source type enum

export enum TextDecoration {
  original = "None",
  underline = "Underline",
  strikethrough = "Strikethrough",
}
