//
//  AreaComponents.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Component } from "../../model/components/SDKComponent"
import { DesignComponent } from "../../model/components/SDKDesignComponent"
import { ElementDataView } from "../../model/elements/SDKElementDataView"
import { ElementDataViewColumn } from "../../model/elements/SDKElementDataViewColumn"
import {
  ElementProperty,
  ElementPropertyCreationModel,
  ElementPropertyTargetElementType,
  ElementPropertyUpdateModel,
} from "../../model/elements/SDKElementProperty"
import { ElementPropertyValue } from "../../model/elements/values/SDKElementPropertyValue"
import { ComponentGroup } from "../../model/groups/SDKComponentGroup"
import { DesignComponentGroup } from "../../model/groups/SDKDesignComponentGroup"
import { ComponentUtils } from "../../utils/ComponentUtils"
import { DataCore } from "../data/SDKDataCore"
import { SupernovaError } from "../errors/SDKSupernovaError"

import { RemoteVersionIdentifier } from "./SDKRemoteIdentifiers"

// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaComponents {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches all remote components in the version
   * @param from - Remote version to fetch from
   * @returns All components in the specified version
   */
  async getComponents(
    from: RemoteVersionIdentifier,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<Component>> {
    return this.dataCore.components(from.designSystemId, from.versionId, filter)
  }

  /** Fetches all remote component groups in the version
   * @param from - Remote version to fetch from
   * @returns All component groups in the specified version
   */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  async getComponentGroups(
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from: RemoteVersionIdentifier
  ): Promise<Array<ComponentGroup>> {
    // For now, retrieve just the root group that we create by hand
    throw SupernovaError.fromMessage(
      "getComponentGroups should not be used right now. Components form a flat list and root group can be safely ignored."
    )
  }

  /** Fetches all remote design components in the version
   * @param from - Remote version to fetch from
   * @returns All design components in the specified version
   */
  async getDesignComponents(
    from: RemoteVersionIdentifier,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<DesignComponent>> {
    return this.dataCore.designComponents(
      from.designSystemId,
      from.versionId,
      filter
    )
  }

  /** Fetches all remote design component groups in the version
   * @param from - Remote version to fetch from
   * @param filter - Optional filter
   * @returns All design component groups in the specified version
   */
  async getDesignComponentGroups(
    from: RemoteVersionIdentifier,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<DesignComponentGroup>> {
    return this.dataCore.designComponentGroups(
      from.designSystemId,
      from.versionId,
      filter
    )
  }

  /** Retrieves all remote properties for components
   * @param from - Remote version to fetch from
   * @returns All component properties in the specified version
   */
  async getComponentProperties(
    from: RemoteVersionIdentifier
  ): Promise<Array<ElementProperty>> {
    return this.dataCore.elementPropertyDefinitions(
      from.designSystemId,
      from.versionId,
      ElementPropertyTargetElementType.component
    )
  }

  /** Retrieves all remote property definitions for components
   * @param from - Remote version to fetch from
   * @returns All property definitions in the specified version
   */
  async getComponentPropertyValues(
    from: RemoteVersionIdentifier
  ): Promise<Array<ElementPropertyValue>> {
    return this.dataCore.elementPropertyValues(
      from.designSystemId,
      from.versionId
    )
  }

  /** Retrieves all remote property views for components
   * @param from - Remote version to fetch from
   * @returns All component views in the specified version
   */
  async getComponentDataViews(
    from: RemoteVersionIdentifier
  ): Promise<Array<ElementDataView>> {
    return this.dataCore.elementViews(
      from.designSystemId,
      from.versionId,
      ElementPropertyTargetElementType.component
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Creates remote component. This method can only be used once per component and will assign persistent id to the component. Calling this version on component that already has id assigned will result in error
   * @param to - Remote version to write to
   * @param component - Component to create
   * @param parentId - Id of the group to add the component to. This is id of the group, not the versioned id
   * @returns New remote component ids
   */
  async createComponent(
    to: RemoteVersionIdentifier,
    component: Component
    // parentId: string
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    return this.dataCore.createComponent(
      to.designSystemId,
      to.versionId,
      component
      // parentId
    )
  }

  /** Creates local component. This component doesn't have id associated with it on remote, and must be created once with `createComponent` to be stored in design system
   * @returns New component filled with default values not associated with remote (yet)
   */
  createLocalComponent(versionId: string, brandId: string): Component {
    return ComponentUtils.createDefaultComponent(versionId, brandId)
  }

  /** Creates remote component group. This method can only be used once per group and will assign persistent id to the group. Calling this version on group that already has id assigned will result in error
   * @param to - Remote version to write to
   * @param group - Group to create
   * @param parentId - Id of the group to add the group to. This is id of the group, not the versioned id
   * @returns New remote component group ids
   */
  async createComponentGroup(
    to: RemoteVersionIdentifier,
    group: ComponentGroup
    // parentId: string
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    return this.dataCore.createComponentGroup(
      to.designSystemId,
      to.versionId,
      group
      // parentId
    )
  }

  /** Creates local component group. This group doesn't have id associated with it on remote, and must be created once with `createComponentGroup` to be stored in design system
   * @param versionId - Id of the version to create the group in
   * @param brandId - Id of the brand to create the group in
   * @returns New group with id assigned
   */
  createLocalComponentGroup(
    versionId: string,
    brandId: string
  ): ComponentGroup {
    return ComponentUtils.createDefaultComponentGroup(versionId, brandId)
  }

  /** Updates remote component. This method can only be used on components that already exist in the version
   * @param to - Remote version to write to
   * @param component - Component to update
   * @returns Nothing
   */
  async updateComponent(
    to: RemoteVersionIdentifier,
    component: Component
  ): Promise<void> {
    return this.dataCore.updateComponent(
      to.designSystemId,
      to.versionId,
      component
    )
  }

  /** Updates remote component groups. This method can only be used on component groups that already exist in the version
   * @param to - Remote version to write to
   * @param component - Component group to update
   * @returns Nothing
   */
  async updateComponentGroup(
    to: RemoteVersionIdentifier,
    group: ComponentGroup
  ): Promise<void> {
    return this.dataCore.updateComponentGroup(
      to.designSystemId,
      to.versionId,
      group
    )
  }

  /** Updates remote component property value. This also creates the property value if it doesn't exist
   * @param to - Remote version to write to
   * @param newValue - Value to set - this can be multiple things, from ID to raw string value to others
   * @param forComponent - Component to assign the value to
   * @param forProperty - Property to assign the value to
   */
  async updateComponentPropertyValue(
    to: RemoteVersionIdentifier,
    newValue: string,
    forComponent: Component,
    forProperty: ElementProperty
  ) {
    return this.dataCore.updateComponentPropertyValue(
      to.designSystemId,
      to.versionId,
      newValue,
      forComponent,
      forProperty
    )
  }

  /** Deletes remote component property value
   * @param to - Remote version to write to
   * @param valueId - Value identifier
   */
  async deleteComponentPropertyValue(
    to: RemoteVersionIdentifier,
    valueId: string
  ): Promise<void> {
    return this.dataCore.deleteComponentPropertyValue(
      to.designSystemId,
      to.versionId,
      valueId
    )
  }

  /** Create component property
   * @param to - Remote version to write to
   * @param model - Property creation model to create the property from
   */
  async createComponentProperty(
    to: RemoteVersionIdentifier,
    model: ElementPropertyCreationModel
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    return this.dataCore.createComponentProperty(
      to.designSystemId,
      to.versionId,
      model
    )
  }

  /** Update component property
   * @param to - Remote version to write to
   * @param propertyIdInVersion - Remote element property to update
   * @param model - Property update model to apply to property
   */
  async updateComponentProperty(
    to: RemoteVersionIdentifier,
    propertyIdInVersion: string,
    model: ElementPropertyUpdateModel
  ): Promise<void> {
    return this.dataCore.updateComponentProperty(
      to.designSystemId,
      to.versionId,
      propertyIdInVersion,
      model
    )
  }

  /** Delete component property. This will remove all values associated with the property
   * @param to - Remote version to write to
   * @param propertyIdInVersion - Id of the property to delete
   */
  async deleteComponentProperty(
    to: RemoteVersionIdentifier,
    propertyIdInVersion: string
  ): Promise<void> {
    return this.dataCore.deleteComponentProperty(
      to.designSystemId,
      to.versionId,
      propertyIdInVersion
    )
  }

  /** Resize specific component column
   * @param to - Remote version to write to
   * @param columnIdInVersion - Id of the column to update
   * @param newWidth - New width of the column
   */
  async updateResizeComponentColumn(
    to: RemoteVersionIdentifier,
    view: ElementDataView,
    column: ElementDataViewColumn,
    newWidth: number
  ): Promise<void> {
    return this.dataCore.updateResizeComponentColumn(
      to.designSystemId,
      to.versionId,
      view,
      column,
      newWidth
    )
  }

  /** Move column to a new index and reorder all other columns around it
   * @param to - Remote version to write to
   * @param colum - Column object to move
   * @param newIndex - New index to move the component to
   */
  async updateReorderComponentColumn(
    to: RemoteVersionIdentifier,
    view: ElementDataView,
    column: ElementDataViewColumn,
    newIndex: number
  ): Promise<void> {
    return this.dataCore.updateReorderComponentColumn(
      to.designSystemId,
      to.versionId,
      view,
      column,
      newIndex
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  /** Deletes remote component. Calling this method on component that doesn't exist in the version will result in error
   * @param to - Remote version to write to
   * @param componentIdInVersion - Component idInVersion to delete
   * @returns Nothing
   */
  async deleteComponent(
    to: RemoteVersionIdentifier,
    componentIdInVersion: string
  ): Promise<void> {
    return this.dataCore.deleteComponent(
      to.designSystemId,
      to.versionId,
      componentIdInVersion
    )
  }

  /** Deletes remote component group. This method will also fix the component group tree by moving any subgroups to parent - do note it will NOT delete contents of the group. Calling this method on group that doesn't exist in the version will result in error
   * @param to - Remote version to write to
   * @param componentGroupIdInVersion - Component group idInVersion to delete
   * @returns Nothing
   */
  async ungroupComponentGroup(
    to: RemoteVersionIdentifier,
    componentGroupIdInVersion: string
  ): Promise<void> {
    return this.dataCore.ungroupComponentGroup(
      to.designSystemId,
      to.versionId,
      componentGroupIdInVersion
    )
  }

  /** Deletes remote component group and all its descendants. All items (groups and components) underneath will be deleted. Calling this method on group that doesn't exist in the version will result in error
   * @param to - Remote version to write to
   * @param componentGroupToDelete - Component group to delete
   * @param allComponents - Components for lookup
   * @param allComponentGroup - Component group for lookup
   * @returns Nothing
   */
  async deleteComponentGroup(
    to: RemoteVersionIdentifier,
    componentGroupToDelete: ComponentGroup,
    components: Array<Component>,
    componentGroups: Array<ComponentGroup>
  ): Promise<void> {
    return this.dataCore.deleteComponentGroup(
      to.designSystemId,
      to.versionId,
      componentGroupToDelete,
      components,
      componentGroups
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Compute
}
