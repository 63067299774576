//
//  SDKDocsBlockDividerProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Divider property - Additional non-value options */
export type DocsBlockDividerPropertyOptions = Record<string, never>

/** Divider property - Data */
export type DocsBlockDividerPropertyData = DocsBlockPropertyData<never> &
  Record<string, never>

/** Divider property - Definition model */
export type DocsBlockDividerPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockDividerPropertyOptions

/** Divider property - Definition */
export class DocsBlockDividerPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockDividerPropertyOptions,
  DocsBlockDividerPropertyData
> {
  type!: DocsBlockItemPropertyType.divider
}
