//
//  DSKAcl.ts
//  Supernova SDK
//
//  Created by Miro Koczka.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type ProductFeature = {
  [key: string]: {
    enabled: boolean
    max?: number
    errorMessage: string
    errorReason: string
  }
}

export type SubscriptionProductFeaturesRemoteModel = {
  free: ProductFeature
  team: ProductFeature
  team_test: ProductFeature
  company: ProductFeature
  enterprise: ProductFeature
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export type FeaturePermissionResolution = { max?: number } & (
  | {
      hasPermission: false
      reason: string
    }
  | {
      hasPermission: true
      reason?: never
    }
)

export class SubscriptionProductFeatures {
  free: ProductFeature

  team: ProductFeature

  // @ts-expect-error TS(2564): Property 'team_test' has no initializer and is not... Remove this comment to see the full error message
  team_test: ProductFeature

  company: ProductFeature

  enterprise: ProductFeature

  constructor(model: SubscriptionProductFeaturesRemoteModel) {
    this.free = model.free
    this.team = model.team
    this.company = model.company
    this.enterprise = model.enterprise
  }
}
