//
//  SDKWorkspaceSSOSettings.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceSSOSettingsRemoteModel = {
  providerId: string
  defaultAutoInviteValue: boolean
  autoInviteDomains: Record<string, boolean>
  areInvitesDisabled: boolean
  skipDocsSupernovaLogin: boolean
}

export type WorkspaceSSOSettingsUpdateModel = WorkspaceSSOSettingsRemoteModel

export type WorkspaceSSOSettingsTransportModel = WorkspaceSSOSettingsRemoteModel

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceSSOSettings {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  providerId: string

  defaultAutoInviteValue: boolean

  autoInviteDomains: Record<string, boolean>

  areInvitesDisabled: boolean

  skipDocsSupernovaLogin: boolean

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceSSOSettingsRemoteModel) {
    this.providerId = model.providerId
    this.defaultAutoInviteValue = model.defaultAutoInviteValue
    this.autoInviteDomains = model.autoInviteDomains
    this.areInvitesDisabled = model.areInvitesDisabled
    this.skipDocsSupernovaLogin = model.skipDocsSupernovaLogin
  }

  // SSO settings can't be created or updated, only fetched, for now

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toUpdateObject(): WorkspaceSSOSettingsUpdateModel {
    return {
      providerId: this.providerId,
      defaultAutoInviteValue: this.defaultAutoInviteValue,
      autoInviteDomains: this.autoInviteDomains,
      areInvitesDisabled: this.areInvitesDisabled,
      skipDocsSupernovaLogin: this.skipDocsSupernovaLogin,
    }
  }

  toRemote(): WorkspaceSSOSettingsTransportModel {
    return {
      providerId: this.providerId,
      defaultAutoInviteValue: this.defaultAutoInviteValue,
      autoInviteDomains: this.autoInviteDomains,
      areInvitesDisabled: this.areInvitesDisabled,
      skipDocsSupernovaLogin: this.skipDocsSupernovaLogin,
    }
  }

  toTransport(): WorkspaceSSOSettingsTransportModel {
    return this.toRemote()
  }

  static fromTransport(model: WorkspaceSSOSettingsTransportModel) {
    return new WorkspaceSSOSettings(model)
  }
}
