//
//  SDKWorkspaceOnboarding.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type WorkspaceOnboardingRemoteModel = {
  supernovaTokenCreated: boolean
  codeExported: boolean
  hookCreated: boolean
  documentationPublished: boolean
  assetsExported: boolean
  componentCreated: boolean
  designSystemSourceCreated?: boolean
}

export type WorkspaceOnboardingTransportModel = WorkspaceOnboardingRemoteModel

export type WorkspaceOnboardingUpdateModel = WorkspaceOnboardingRemoteModel

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceOnboarding {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  supernovaTokenCreated: boolean

  codeExported: boolean

  hookCreated: boolean

  documentationPublished: boolean

  assetsExported: boolean

  componentCreated: boolean

  designSystemSourceCreated: boolean

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceOnboardingRemoteModel) {
    this.supernovaTokenCreated = model.supernovaTokenCreated
    this.codeExported = model.codeExported
    this.hookCreated = model.hookCreated
    this.documentationPublished = model.documentationPublished
    this.assetsExported = model.assetsExported
    this.componentCreated = model.componentCreated
    this.designSystemSourceCreated = model.designSystemSourceCreated ?? false
  }

  static initial(): WorkspaceOnboarding {
    return new WorkspaceOnboarding({
      supernovaTokenCreated: false,
      codeExported: false,
      hookCreated: false,
      documentationPublished: false,
      assetsExported: false,
      componentCreated: false,
      designSystemSourceCreated: false,
    })
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs directly manipulable update object for workspace onboarding */
  toUpdateObject(): WorkspaceOnboardingUpdateModel {
    return {
      supernovaTokenCreated: this.supernovaTokenCreated,
      codeExported: this.codeExported,
      hookCreated: this.hookCreated,
      documentationPublished: this.documentationPublished,
      assetsExported: this.assetsExported,
      componentCreated: this.componentCreated,
      designSystemSourceCreated: this.designSystemSourceCreated,
    }
  }

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): WorkspaceOnboardingTransportModel {
    return {
      supernovaTokenCreated: this.supernovaTokenCreated,
      codeExported: this.codeExported,
      hookCreated: this.hookCreated,
      documentationPublished: this.documentationPublished,
      assetsExported: this.assetsExported,
      componentCreated: this.componentCreated,
      designSystemSourceCreated: this.designSystemSourceCreated,
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): WorkspaceOnboardingTransportModel {
    return this.toRemote()
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: WorkspaceOnboardingTransportModel) {
    return new WorkspaceOnboarding(model)
  }
}
