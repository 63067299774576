import { DimensionTokenType, TokenType } from "../model/enums/SDKTokenType"

export const typographyTokenDefaultValue = {
  fontFamily: "Inter",
  fontWeight: "Regular",
  fontSize: 14,
  lineHeight: 20,
  letterSpacing: 0,
  paragraphIndent: 0,
  paragraphSpacing: 0,
}

export const shadowTokenDefaultValue = {
  x: 0,
  y: 4,
  blur: 4,
  spread: 0,
  color: "#000000",
  opacity: 0.25,
  type: "dropShadow",
}

export const borderDefaultValue = {
  color: "#000000",
  position: "outside",
  style: "solid",
  width: 1,
}

export const blurDefaultValue = {
  radius: 10,
  type: "Background",
}

export const opacityDefaultValue = 100

export const dimensionDefaultValues: Record<
  DimensionTokenType,
  number | string
> = {
  [TokenType.radius]: 0,
  [TokenType.borderWidth]: 1,
  [TokenType.duration]: 200,
  [TokenType.fontSize]: 14,
  [TokenType.dimension]: "0",
  [TokenType.letterSpacing]: 0,
  [TokenType.lineHeight]: 1,
  [TokenType.opacity]: 1,
  [TokenType.paragraphSpacing]: 0,
  [TokenType.size]: 0,
  [TokenType.space]: "0",
  [TokenType.zIndex]: 1,
}
