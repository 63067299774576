//
//  SDKWorkspaceIntegrationGetGitProviders.ts
//  Supernova SDK
//
//  Created by Martin Naščák.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export const GitProviderEndpoints = {
  Organizations: "organizations",
  Projects: "projects",
  Repositories: "repositories",
  Branches: "branches",
} as const

export type GitProviderEndpointsKeys = keyof typeof GitProviderEndpoints
export type GitProviderValues =
  (typeof GitProviderEndpoints)[GitProviderEndpointsKeys]

// Query Params
export type WorkspaceIntegrationGetGitProvidersInput = {
  organization?: string // Azure Organization | Bitbucket Workspace slug | Gitlab Group and Sub-Groups | Github Account (User or Organization)

  project?: string // Only for Bitbucket and Azure

  repository?: string // For all providers. Pay attention for Gitlab, they call repositories "projects".

  branch?: string // For all providers, useful for PR creations.

  user?: string // Only for Gitlab User Repositories
}
