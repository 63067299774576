//
//  SDKDocumentation.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DocumentationLegacyConfiguration,
  DocumentationLegacyConfigurationModel,
} from "../documentation/SDKDocumentationLegacyConfiguration"
import { WorkspaceNPMRegistrySettings } from "../workspaces/SDKWorkspaceNPMRegistrySettings"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DocumentationLegacyModel = {
  settings: DocumentationLegacyConfigurationModel
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class DocumentationLegacy {
  /** DocumentationLegacy settings */
  settings: DocumentationLegacyConfiguration

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    model: DocumentationLegacyModel,
    registry: WorkspaceNPMRegistrySettings | null
  ) {
    this.settings = new DocumentationLegacyConfiguration(
      model.settings,
      registry
    )
  }
}
