import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockBehaviorDataType } from "../../../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../../../enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.component-checklist"
const name = "Component checklist"
const icon =
  "https://cdn-assets.supernova.io/blocks/icons/v2/component-checklist.svg"
const descriptionShort = "Highlight specific component properties"
const keywords = ["components", "health", "properties", "overview", "status"]
const descriptionFull = "Highlight specific component properties"
const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/component/component-checklist-NXepPIpZ"

/**
 * 2. Block properties
 */
const properties = {
  components: b.componentProperty({
    id: "components",
    name: "Components",
    options: {
      renderLayoutAs: "List",
    },
  }),
  showDescription: b.booleanProperty({
    id: "showDescription",
    name: "Show description",
    options: {
      defaultValue: true,
    },
  }),
  title: b.textProperty({
    id: "title",
    name: "Title",
    options: {
      defaultValue: "Component checklist",
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.components, properties.showDescription],
  image:
    "https://cdn-assets.supernova.io/blocks/variants/component-checklist.svg",
  appearance: {
    isEditorPresentationDifferent: true,
  },
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [
    properties.components,
    properties.title,
    properties.showDescription,
  ],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.dataBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.component,
  description: descriptionShort,
  searchKeywords: keywords,
  dataType: DocsBlockBehaviorDataType.component,
  selectionType: DocsBlockBehaviorSelectionType.entity,
  maxSelection: 1,
  item: blockItem,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as ComponentChecklist }
