//
//  FigmaStructureModel.ts
//  Supernova SDK
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type FigmaStructureModel = {
  assetsInFile: {
    componentSets: number
    components: number
    frames: number
  }
  designSystemVersionId: string
  id: string
  origin: {
    sourceId: string
  }
  persistentId: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class FigmaStructure {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties
  assetsInFile: {
    componentSets: number
    components: number
    frames: number
  }

  designSystemVersionId: string

  id: string

  origin: {
    sourceId: string
  }

  // @ts-expect-error TS(2564): Property 'persistentId' has no initializer and is ... Remove this comment to see the full error message
  persistentId: string
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: FigmaStructureModel) {
    this.assetsInFile = model.assetsInFile
    this.designSystemVersionId = model.designSystemVersionId
    this.id = model.id
    this.origin = model.origin
  }
}
