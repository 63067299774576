//
//  SDKDocsBlockCodeSandboxProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
  DocsBlockPropertyData,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Code Sandbox property - Additional non-value options */
export type DocsBlockCodeSandboxPropertyOptions = {
  /** How should we render the code example in editor */
  renderLayoutAs?:
    | "PreviewOnTop"
    | "PreviewOnBottom"
    | "PreviewOnLeft"
    | "PreviewOnRight"
}

/** Code Sandbox property - Data */
export type DocsBlockCodeSandboxPropertyData = DocsBlockPropertyData<string> & {
  showCode?: boolean
  showControls?: boolean
  backgroundColor?: string
  alignPreview?: "Left" | "Center"
  previewHeight?: number
}

/** Code Sandbox property - Definition model */
export type DocsBlockCodeSandboxPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockCodeSandboxPropertyOptions

/** Code Sandbox property - Definition */
export class DocsBlockCodeSandboxPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockCodeSandboxPropertyOptions,
  DocsBlockCodeSandboxPropertyData
> {
  type!: DocsBlockItemPropertyType.codeSandbox
}
