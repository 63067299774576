import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockImagePropertyAspectRatio } from "../../../../../enums/SDKDocsBlockImagePropertyAspectRatio"
import { DocsBlockImagePropertyWidth } from "../../../../../enums/SDKDocsBlockImagePropertyWidth"
import { DocsBlockItemVariantLayoutColumnResizing } from "../../../../../enums/SDKDocsBlockItemVariantLayoutColumnResizing"
import { DocsBlockItemVariantLayoutGap } from "../../../../../enums/SDKDocsBlockItemVariantLayoutGap"
import { DocsBlockTextPropertyColor } from "../../../../../enums/SDKDocsBlockTextPropertyColor"
import { DocsBlockTextPropertyStyle } from "../../../../../enums/SDKDocsBlockTextPropertyStyle"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.shortcut-links"
const name = "Shortcut links"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v2/shortcuts.svg"
const descriptionShort = "Link to a page or external URL"

const keywords = ["url", "page", "card"]

const helpText =
  "Use link block to create single or multiple links to places in or out of your documentation."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/links/shortcuts/general-jVfNifo4"

/**
 * 2. Block properties
 */
const properties = {
  // Icon/illustration asset picker
  image: b.imageProperty({
    id: "block.links.property.image",
    name: "Image",
    options: {
      aspectRatio: DocsBlockImagePropertyAspectRatio.landscape,
      allowCaption: false,
    },
    variants: {
      iconOnTop: {
        width: DocsBlockImagePropertyWidth.icon,
        aspectRatio: DocsBlockImagePropertyAspectRatio.square,
      },
      imageOnLeft: {
        width: DocsBlockImagePropertyWidth.medium,
      },
      iconOnLeft: {
        width: DocsBlockImagePropertyWidth.icon,
        aspectRatio: DocsBlockImagePropertyAspectRatio.square,
      },
    },
  }),
  // Card heading styled bold
  title: b.textProperty({
    id: "block.links.property.title",
    name: "Title",
    options: {
      textStyle: DocsBlockTextPropertyStyle.title5,
      placeholder: "Add title",
      allowLineBreaks: false,
    },
  }),
  // Card description styled regularly
  description: b.textProperty({
    id: "block.links.property.description",
    name: "Short description",
    options: {
      textStyle: DocsBlockTextPropertyStyle.default,
      color: DocsBlockTextPropertyColor.neutralFaded,
      placeholder: "Add description",
    },
  }),
}

/**
 * 3. Block variants
 */
const variants: Array<DocsBlockVariant> = [
  b.variantUsingDefaultLayout({
    id: "imageOnTop",
    name: "Image on top",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/shortcuts-image-on-top.svg",
    properties: [properties.image, properties.title, properties.description],
    defaultColumns: 3,
    maxColumns: 4,
  }),
  b.variantUsingCustomLayout({
    id: "imageOnLeft",
    name: "Image on left",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/shortcuts-leading-image.svg",
    layout: b.rowLayout([
      b.columnLayout([properties.image], {
        resizing: DocsBlockItemVariantLayoutColumnResizing.hug,
      }),
      b.columnLayout([properties.title, properties.description], {
        gap: DocsBlockItemVariantLayoutGap.small,
      }),
    ]),
    maxColumns: 1,
  }),
  b.variantUsingDefaultLayout({
    id: "iconOnTop",
    name: "Icon on top",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/shortcuts-icon-on-top.svg",
    properties: [properties.image, properties.title, properties.description],
    maxColumns: 4,
  }),
  b.variantUsingCustomLayout({
    id: "iconOnLeft",
    name: "Icon on left",
    image:
      "https://cdn-assets.supernova.io/blocks/variants/shortcuts-leading-icon.svg",
    layout: b.rowLayout([
      b.columnLayout([properties.image], {
        resizing: DocsBlockItemVariantLayoutColumnResizing.hug,
      }),
      b.columnLayout([properties.title, properties.description], {
        gap: DocsBlockItemVariantLayoutGap.small,
      }),
    ]),
    maxColumns: 2,
  }),
]

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.multiVariantItem({
  properties: [properties.image, properties.title, properties.description],
  variants,
})

/**
 * 5. Block definition
 */
const block = b.multiItemBlock({
  id,
  name,
  icon,
  description: descriptionShort,
  item: blockItem,
  options: {
    linkable: true,
    newItemLabel: "Add shortcut",
  },
  searchKeywords: keywords,
  category: DocsBlockCategory.media,
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/**
 * 6. Block export
 */
export { block as ShortcutLinks }
