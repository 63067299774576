import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.code"
const name = "Code"
const icon = "https://cdn-assets.supernova.io/blocks/icons/code.svg"
const descriptionShort = "Display simple code snippets"
const searchKeywords = ["code"]

const helpText = "Display simple code snippets"

/**
 * 2. Block properties
 */
const properties = {
  code: b.codeProperty({
    id: "code",
    name: "Code",
    options: {},
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.code],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.code],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.code,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  appearance: {
    isBordered: false,
  },
  onboarding: b.onboarding({
    helpText,
  }),
})

/** 6. Block export */
export { block as Code }
