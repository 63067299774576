// TODO:fix-sdk-eslint

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
//
//  SDKTypographyToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Brand } from "../base/SDKBrand"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { TokenType } from "../enums/SDKTokenType"
import { Unit } from "../enums/SDKUnit"

import {
  TokenRemoteModel,
  TypographyTokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { TypographyTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { DimensionToken } from "./SDKDimensionToken"
import { StringToken } from "./SDKStringToken"
import { TextDecorationToken } from "./SDKTextDecorationToken"
import { Token } from "./SDKToken"
import {
  FontFamilyTokenValue,
  FontSizeTokenValue,
  LetterSpacingTokenValue,
  LineHeightTokenValue,
  ParagraphSpacingTokenValue,
  TextCaseTokenValue,
  TextDecorationTokenValue,
  TypographyTokenValue,
} from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class TypographyToken extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: TypographyTokenValue

  tokenType: TokenType.typography = TokenType.typography

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: TypographyTokenValue,
    alias: TypographyToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value

    if (alias) {
      this.value.referencedTokenId = alias.id
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Static building

  static create(
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: object,
    alias: TypographyToken | null,
    referenceResolver: DTTokenReferenceResolver,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>,
    reference?: string
  ): TypographyToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type: TokenType.typography,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      // Raw value
      const tokenValue = this.typographyValueFromDefinition(
        value,
        referenceResolver,
        reference
      )

      return new TypographyToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const tokenValue: TypographyTokenValue = {
        fontFamily: alias.value.fontFamily,
        fontWeight: alias.value.fontWeight,
        fontSize: alias.value.fontSize,
        letterSpacing: alias.value.letterSpacing,
        lineHeight: alias.value.lineHeight,
        paragraphIndent: alias.value.paragraphIndent,
        paragraphSpacing: alias.value.paragraphSpacing,
        textDecoration: alias.value.textDecoration,
        textCase: alias.value.textCase,
        referencedTokenId: alias.id,
      }

      return new TypographyToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "Typography Token must be created using value or alias, but none was provided"
    )
  }

  static typographyValueFromDefinition(
    definition: any,
    referenceResolver: DTTokenReferenceResolver,
    reference?: string
  ): TypographyTokenValue {
    // No validation because
    const value = {} as TypographyTokenValue // Empty container

    // If we have theme, then missing typography props should first try to fallback to base values, and then - to defaults
    // Double check that we have `base` === we are resolving theme (otherwise pass this info from upstream)
    const base = reference
      ? (referenceResolver.lookupReferencedToken(reference) as TypographyToken)
      : null

    // TODO: Ideallly should not compile without casting
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    value.fontFamily = definition.fontFamily
      ? (StringToken.stringValueFromDefinitionOrReference(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          definition.fontFamily,
          referenceResolver,
          TokenType.fontFamily
        ) as FontFamilyTokenValue)
      : base?.value?.fontFamily
      ? base?.value?.fontFamily
      : StringToken.stringValueFromDefinition("Roboto", TokenType.fontFamily)
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    value.fontWeight = definition.fontWeight
      ? (StringToken.stringValueFromDefinitionOrReference(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          definition.fontWeight,
          referenceResolver,
          TokenType.fontWeight
        ) as FontFamilyTokenValue)
      : base?.value?.fontWeight
      ? base?.value?.fontWeight
      : StringToken.stringValueFromDefinition("Regular", TokenType.fontWeight)
    value.fontSize = (
      definition.fontSize
        ? DimensionToken.dimensionValueFromDefinitionOrReference(
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            definition.fontSize,
            referenceResolver,
            TokenType.fontSize
          )
        : base?.value?.fontSize
        ? base?.value?.fontSize
        : DimensionToken.dimensionValueFromDefinition(
            "12px",
            TokenType.fontSize
          )
    ) as FontSizeTokenValue
    value.letterSpacing = // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (
        definition.letterSpacing
          ? DimensionToken.dimensionValueFromDefinitionOrReference(
              // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              definition.letterSpacing,
              referenceResolver,
              TokenType.letterSpacing
            )
          : base?.value?.letterSpacing
          ? base?.value?.letterSpacing
          : DimensionToken.dimensionValueFromDefinition(
              "0",
              TokenType.letterSpacing
            )
      ) as LetterSpacingTokenValue
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    value.lineHeight = definition.lineHeight
      ? (DimensionToken.dimensionValueFromDefinitionOrReference(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          definition.lineHeight,
          referenceResolver,
          TokenType.lineHeight
        ) as LineHeightTokenValue)
      : base?.value?.lineHeight
      ? base?.value?.lineHeight
      : null
    value.paragraphIndent = // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (
        definition.paragraphIndent
          ? DimensionToken.dimensionValueFromDefinitionOrReference(
              // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              definition.paragraphIndent,
              referenceResolver,
              TokenType.paragraphSpacing
            )
          : base?.value?.paragraphIndent
          ? base?.value?.paragraphIndent
          : DimensionToken.dimensionValueFromDefinition(
              "0",
              TokenType.paragraphSpacing
            )
      ) as ParagraphSpacingTokenValue
    value.paragraphSpacing = // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (
        definition.paragraphSpacing
          ? DimensionToken.dimensionValueFromDefinitionOrReference(
              // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              definition.paragraphSpacing,
              referenceResolver,
              TokenType.paragraphSpacing
            )
          : base?.value?.paragraphSpacing
          ? base?.value?.paragraphSpacing
          : DimensionToken.dimensionValueFromDefinition(
              "0",
              TokenType.paragraphSpacing
            )
      ) as ParagraphSpacingTokenValue
    value.paragraphIndent.referencedTokenId = null
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    value.referencedTokenId = undefined
    value.textDecoration =
      TextDecorationToken.optionValueFromDefinitionOrReference(
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        definition.textDecoration ?? base?.value?.textDecoration,
        referenceResolver,
        TokenType.textDecoration
      ) as TextDecorationTokenValue
    value.textCase = TextDecorationToken.optionValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      definition.textCase ?? base?.value?.textCase,
      referenceResolver,
      TokenType.textCase
    ) as TextCaseTokenValue

    return value
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Writing

  toWriteObject(): TypographyTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as TypographyTokenRemoteModel

    specificData.data = TypographyToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: TypographyTokenValue): {
    aliasTo: string | undefined
    value: TypographyTokenRemoteValue
  } {
    const prepareFontSizeValue = (fontSize: FontSizeTokenValue) => {
      const { unit, measure } = fontSize

      let fixedMeasure = measure
      if (unit === Unit.rem && measure <= 0) {
        fixedMeasure = 0.01
      } else if (unit !== Unit.rem && measure < 1) {
        fixedMeasure = 1
      }

      return {
        measure: fixedMeasure, // Font size must be > 0
        unit,
      }
    }

    const valueObject = !value.referencedTokenId
      ? {
          fontFamily: {
            aliasTo: value.fontFamily.referencedTokenId ?? undefined,
            value: value.fontFamily.referencedTokenId
              ? null
              : value.fontFamily.text,
          },
          fontWeight: {
            aliasTo: value.fontWeight.referencedTokenId ?? undefined,
            value: value.fontWeight.referencedTokenId
              ? null
              : value.fontWeight.text,
          },
          fontSize: {
            aliasTo: value.fontSize.referencedTokenId ?? undefined,
            value: value.fontSize.referencedTokenId
              ? null
              : prepareFontSizeValue(value.fontSize),
          },
          letterSpacing: {
            aliasTo: value.letterSpacing.referencedTokenId ?? undefined,
            value: value.letterSpacing.referencedTokenId
              ? null
              : {
                  measure: value.letterSpacing.measure,
                  unit: value.letterSpacing.unit,
                },
          },
          paragraphIndent: {
            aliasTo: value.paragraphIndent.referencedTokenId ?? undefined,
            value: value.paragraphIndent.referencedTokenId
              ? null
              : {
                  measure: value.paragraphIndent.measure,
                  unit: value.paragraphIndent.unit,
                },
          },
          paragraphSpacing: {
            aliasTo: value.paragraphSpacing.referencedTokenId ?? undefined,
            value: value.paragraphSpacing.referencedTokenId
              ? null
              : {
                  measure: value.paragraphSpacing.measure,
                  unit: value.paragraphSpacing.unit,
                },
          },
          lineHeight: value.lineHeight
            ? {
                aliasTo: value.lineHeight.referencedTokenId ?? undefined,
                value: value.lineHeight.referencedTokenId
                  ? null
                  : {
                      measure: value.lineHeight.measure,
                      unit: value.lineHeight.unit,
                    },
              }
            : null,
          textCase: {
            aliasTo: value.textCase.referencedTokenId ?? undefined,
            value: value.textCase.referencedTokenId
              ? null
              : value.textCase.value,
          },
          textDecoration: {
            aliasTo: value.textDecoration.referencedTokenId ?? undefined,
            value: value.textDecoration.referencedTokenId
              ? null
              : value.textDecoration.value,
          },
        }
      : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type '{ fontFamily: { aliasTo: string | undefined;... Remove this comment to see the full error message
      value: valueObject,
    }
  }
}
