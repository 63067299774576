/* eslint-disable max-lines */
import { PageBlockDefinitionItem } from "@supernova-studio/model"

import { DocsBlockDefinition } from "../../model/docs/blocks/definition/SDKDocsBlockDefinition"
import { DocsBlockItemDefinition } from "../../model/docs/blocks/definition/SDKDocsBlockItemDefinition"
import { DocsBlockLayout } from "../../model/docs/blocks/definition/SDKDocsBlockLayout"
import { DocsBlockOnboarding } from "../../model/docs/blocks/definition/SDKDocsBlockOnboarding"
import { DocsBlockVariant } from "../../model/docs/blocks/definition/SDKDocsBlockVariant"
import {
  DocsBlockAssetPropertyDefinition,
  DocsBlockAssetPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockAssetProperty"
import {
  DocsBlockAssetPropertyPropertyDefinition,
  DocsBlockAssetPropertyPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockAssetPropertyProperty"
import {
  DocsBlockBooleanPropertyDefinition,
  DocsBlockBooleanPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockBooleanProperty"
import {
  DocsBlockCodePropertyDefinition,
  DocsBlockCodePropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockCodeProperty"
import {
  DocsBlockCodeSandboxPropertyDefinition,
  DocsBlockCodeSandboxPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockCodeSandboxProperty"
import {
  DocsBlockComponentPropertyDefinition,
  DocsBlockComponentPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockComponentProperty"
import {
  DocsBlockComponentPropertyPropertyDefinition,
  DocsBlockComponentPropertyPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockComponentPropertyProperty"
import {
  DocsBlockDividerPropertyDefinition,
  DocsBlockDividerPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockDividerProperty"
import {
  DocsBlockEmbedUrlPropertyDefinition,
  DocsBlockEmbedUrlPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockEmbedUrlProperty"
import {
  DocsBlockFigmaComponentPropertyDefinition,
  DocsBlockFigmaComponentPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockFigmaComponentProperty"
import {
  DocsBlockFigmaNodePropertyDefinition,
  DocsBlockFigmaNodePropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockFigmaNodeProperty"
import {
  DocsBlockImagePropertyDefinition,
  DocsBlockImagePropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockImageProperty"
import {
  DocsBlockMarkdownPropertyDefinition,
  DocsBlockMarkdownPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockMarkdownProperty"
import { DocsBlockMultiRichTextPropertyOptions } from "../../model/docs/blocks/properties/SDKDocsBlockMultiRichTextProperty"
import {
  DocsBlockMultiSelectPropertyDefinition,
  DocsBlockMultiSelectPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockMultiSelectProperty"
import {
  DocsBlockNumberPropertyDefinition,
  DocsBlockNumberPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockNumberProperty"
import { DocsBlockItemPropertyAnyDefinition } from "../../model/docs/blocks/properties/SDKDocsBlockProperty"
import {
  DocsBlockRichTextEditorPropertyDefinition,
  DocsBlockRichTextEditorPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockRichTextEditorProperty"
import {
  DocsBlockRichTextPropertyDefinition,
  DocsBlockRichTextPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockRichTextProperty"
import {
  DocsBlockSingleSelectPropertyDefinition,
  DocsBlockSingleSelectPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockSingleSelectProperty"
import {
  DocsBlockStorybookPropertyDefinition,
  DocsBlockStorybookPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockStorybookProperty"
import {
  DocsBlockTablePropertyDefinition,
  DocsBlockTablePropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockTableProperty"
import {
  DocsBlockTextPropertyDefinition,
  DocsBlockTextPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockTextProperty"
import {
  DocsBlockTokenPropertyDefinition,
  DocsBlockTokenPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockTokenProperty"
import {
  DocsBlockTokenPropertyPropertyDefinition,
  DocsBlockTokenPropertyPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockTokenPropertyProperty"
import {
  DocsBlockTokenTypePropertyDefinition,
  DocsBlockTokenTypePropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockTokenTypeProperty"
import {
  DocsBlockUrlPropertyDefinition,
  DocsBlockUrlPropertyOptions,
} from "../../model/docs/blocks/properties/SDKDocsBlockUrlProperty"
import { DocsBlockBehaviorDataType } from "../../model/enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../model/enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../model/enums/SDKDocsBlockCategory"
import { DocsBlockItemPropertyType } from "../../model/enums/SDKDocsBlockItemPropertyType"
import { DocsBlockItemVariantLayoutAlign } from "../../model/enums/SDKDocsBlockItemVariantLayoutAlign"
import { DocsBlockItemVariantLayoutColumnResizing } from "../../model/enums/SDKDocsBlockItemVariantLayoutColumnResizing"
import { DocsBlockItemVariantLayoutGap } from "../../model/enums/SDKDocsBlockItemVariantLayoutGap"
import { DocsBlockItemVariantLayoutType } from "../../model/enums/SDKDocsBlockItemVariantLayoutType"

export class DocsBlockBuilder {
  /** Create a layout definition with selected properties (or sublayouts) stacked into column */
  columnLayout(
    children: Array<DocsBlockItemPropertyAnyDefinition | DocsBlockLayout>,
    options?: {
      resizing?: DocsBlockItemVariantLayoutColumnResizing | undefined
      align?: DocsBlockItemVariantLayoutAlign | undefined
      gap?: DocsBlockItemVariantLayoutGap | undefined
    }
  ): DocsBlockLayout {
    const propOrLayout: Array<string | DocsBlockLayout> = children.map((c) => {
      // From property definition, extract prop id
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      if (c.hasOwnProperty("id")) {
        return (c as DocsBlockItemPropertyAnyDefinition).id
      }

      // Otherwise this is layout definition, just return it
      return c as DocsBlockLayout
    })

    return new DocsBlockLayout({
      type: DocsBlockItemVariantLayoutType.column,
      columnResizing: options?.resizing,
      columnAlign: options?.align,
      gap: options?.gap,
      children: propOrLayout,
    })
  }

  /** Create a layout definition with selected properties (or sublayouts) stacked into row */
  rowLayout(
    children: Array<DocsBlockItemPropertyAnyDefinition | DocsBlockLayout>,
    options?: {
      gap?: DocsBlockItemVariantLayoutGap | undefined
    }
  ): DocsBlockLayout {
    const propOrLayout: Array<string | DocsBlockLayout> = children.map((c) => {
      // From property definition, extract prop id
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      if (c.hasOwnProperty("id")) {
        return (c as DocsBlockItemPropertyAnyDefinition).id
      }

      // Otherwise this is layout definition, just return it
      return c as DocsBlockLayout
    })

    return {
      type: DocsBlockItemVariantLayoutType.row,
      gap: options?.gap,
      children: propOrLayout,
    }
  }

  /** Create an item variant to render the item in a specific way */
  variantUsingCustomLayout(options: {
    id: string
    name: string
    image: string
    layout: DocsBlockLayout
    description?: string
    defaultColumns?: number
    maxColumns?: number
  }): DocsBlockVariant {
    return new DocsBlockVariant({
      id: options.id,
      name: options.name,
      image: options.image,
      layout: options.layout,
      description: options.description ?? undefined,
      maxColumns: options.maxColumns ?? 1,
      defaultColumns: options.defaultColumns ?? 1,
    })
  }

  /** Create and item variant to render the item with default layout (all properties will be stacked into a single column) */
  variantUsingDefaultLayout(options: {
    id: string
    name: string
    image: string
    properties: Array<DocsBlockItemPropertyAnyDefinition>
    description?: string
    maxColumns?: number
    defaultColumns?: number
    appearance?: {
      isBordered?: boolean
      hasBackground?: boolean
      isEditorPresentationDifferent?: boolean
      showBlockHeaderInEditor?: boolean
    }
  }): DocsBlockVariant {
    return new DocsBlockVariant({
      id: options.id,
      name: options.name,
      image: options.image,
      layout: this.columnLayout(options.properties),
      description: options.description ?? undefined,
      maxColumns: options.maxColumns ?? 1,
      defaultColumns: options.defaultColumns ?? 1,
      appearance: {
        isBordered: options.appearance?.isBordered ?? undefined,
        hasBackground: options.appearance?.hasBackground ?? undefined,
        isEditorPresentationDifferent:
          options.appearance?.isEditorPresentationDifferent ?? undefined,
        showBlockHeaderInEditor:
          options.appearance?.showBlockHeaderInEditor ?? undefined,
      },
    })
  }

  /** Create and item variant to render the item with default layout (all properties will be stacked into a single column) */
  defaultVariantUsingDefaultLayout(options: {
    properties: Array<DocsBlockItemPropertyAnyDefinition>
    image?: string
    description?: string
    maxColumns?: number
    defaultColumns?: number
    appearance?: {
      isBordered?: boolean
      hasBackground?: boolean
      isEditorPresentationDifferent?: boolean
      /** When enabled, the header with block name, description, and header will be displayed
       * Use only for custom blocks that don't have a good content do be displayed in the editor (e.g. Release notes, Lottie, etc.)
       * */
      showBlockHeaderInEditor?: boolean
    }
  }): DocsBlockVariant {
    return new DocsBlockVariant({
      id: "default",
      name: "Default",
      image: options.image ?? undefined,
      description: options.description ?? undefined,
      layout: this.columnLayout(options.properties),
      maxColumns: options.maxColumns ?? 1,
      defaultColumns: options.defaultColumns ?? 1,
      appearance: {
        isBordered: options.appearance?.isBordered ?? undefined,
        hasBackground: options.appearance?.hasBackground ?? undefined,
        isEditorPresentationDifferent:
          options.appearance?.isEditorPresentationDifferent ?? undefined,
        showBlockHeaderInEditor:
          options.appearance?.showBlockHeaderInEditor ?? undefined,
      },
    })
  }

  /** Create block item with a single variant of rendering */
  singleVariantItem(configuration: {
    properties: Array<DocsBlockItemPropertyAnyDefinition>
    variant: DocsBlockVariant
    appearance?: {
      isBordered?: boolean
      hasBackground?: boolean
    }
  }): DocsBlockItemDefinition {
    const block = new DocsBlockItemDefinition({
      properties: [],
      appearance: {
        isBordered: configuration.appearance?.isBordered,
        hasBackground: configuration.appearance?.hasBackground,
      },
      variants: [configuration.variant] as PageBlockDefinitionItem["variants"],
      defaultVariantKey: configuration.variant.id,
    })

    block.properties = configuration.properties

    return block
  }

  /** Create block item with multiple variants of rendering. The first variant becomes the default one */
  multiVariantItem(configuration: {
    properties: Array<DocsBlockItemPropertyAnyDefinition>
    variants: Array<DocsBlockVariant>
    appearance?: {
      isBordered?: boolean
      hasBackground?: boolean
    }
  }): DocsBlockItemDefinition {
    const block = new DocsBlockItemDefinition({
      properties: [],
      appearance: {
        isBordered: configuration.appearance?.isBordered ?? true,
        hasBackground: configuration.appearance?.hasBackground ?? false,
      },
      variants: configuration.variants as PageBlockDefinitionItem["variants"],
      defaultVariantKey: configuration.variants[0]!.id,
    })

    block.properties = configuration.properties

    return block
  }

  /** Creates a core block definition which can't select from Supernova data, and its item can only be created once (Examples: Rich Text, Callout, Heading blocks) */
  singleItemBlock(configuration: {
    id: string
    name: string
    category: DocsBlockCategory
    icon: string
    description?: string
    item: DocsBlockItemDefinition
    linkable?: boolean
    appearance?: {
      isBordered?: boolean
      hasBackground?: boolean
      isEditorPresentationDifferent?: boolean
      showBlockHeaderInEditor?: boolean
    }
    searchKeywords?: Array<string>
    onboarding?: DocsBlockOnboarding
  }): DocsBlockDefinition {
    return new DocsBlockDefinition({
      id: configuration.id,
      name: configuration.name,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      description: configuration.description,
      category: configuration.category,
      icon: configuration.icon,
      // @ts-expect-error TS(2322): Type 'DocsBlockItem' is not assignable to type 'Do... Remove this comment to see the full error message
      item: configuration.item,
      searchKeywords: configuration.searchKeywords,
      behavior: {
        dataType: DocsBlockBehaviorDataType.item,
        items: {
          numberOfItems: 1,
          allowLinks: configuration.linkable ?? false,
        },
      },
      appearance: {
        isBordered: configuration.appearance?.isBordered ?? true,
        hasBackground: configuration.appearance?.hasBackground ?? false,
        isEditorPresentationDifferent:
          configuration.appearance?.isEditorPresentationDifferent ?? false,
        showBlockHeaderInEditor:
          configuration.appearance?.showBlockHeaderInEditor ?? false,
      },
      editorOptions: {
        // @ts-expect-error TS(2322): Type 'DocsBlockOnboarding | undefined' is not assi... Remove this comment to see the full error message
        onboarding: configuration.onboarding,
      },
    })
  }

  /** Creates a core block definition which can't select from Supernova data, and its items can be created multiple times (Examples: Shortcuts block) */
  multiItemBlock(configuration: {
    id: string
    name: string
    category: DocsBlockCategory
    icon: string
    item: DocsBlockItemDefinition
    maxItems?: number
    description?: string
    options?: {
      linkable?: boolean
      newItemLabel?: string
    }
    appearance?: {
      isBordered?: boolean
      hasBackground?: boolean
      isEditorPresentationDifferent?: boolean
      showBlockHeaderInEditor?: boolean
    }
    searchKeywords?: Array<string>
    onboarding?: DocsBlockOnboarding
  }): DocsBlockDefinition {
    return new DocsBlockDefinition({
      id: configuration.id,
      name: configuration.name,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      description: configuration.description,
      category: configuration.category,
      icon: configuration.icon,
      // @ts-expect-error TS(2322): Type 'DocsBlockItem' is not assignable to type 'Do... Remove this comment to see the full error message
      item: configuration.item,
      searchKeywords: configuration.searchKeywords,
      behavior: {
        dataType: DocsBlockBehaviorDataType.item,
        items: {
          numberOfItems: configuration.maxItems ?? -1,
          allowLinks: configuration.options?.linkable ?? false,
          newItemLabel: configuration.options?.newItemLabel ?? undefined,
        },
      },
      appearance: {
        isBordered: configuration.appearance?.isBordered ?? true,
        hasBackground: configuration.appearance?.hasBackground ?? false,
        isEditorPresentationDifferent:
          configuration.appearance?.isEditorPresentationDifferent ?? false,
        showBlockHeaderInEditor:
          configuration.appearance?.showBlockHeaderInEditor ?? false,
      },
      editorOptions: {
        // @ts-expect-error TS(2322): Type 'DocsBlockOnboarding | undefined' is not assi... Remove this comment to see the full error message
        onboarding: configuration.onboarding,
      },
    })
  }

  /** Creates a core block definition which will be allowed to select from Supernova data (Examples: Token, Asset block) */
  dataBlock(configuration: {
    id: string
    name: string
    icon: string
    category: DocsBlockCategory
    item: DocsBlockItemDefinition
    dataType: Exclude<DocsBlockBehaviorDataType, DocsBlockBehaviorDataType.item>
    selectionType: DocsBlockBehaviorSelectionType
    maxSelection?: number
    description?: string
    linkable?: boolean
    appearance?: {
      isBordered?: boolean
      hasBackground?: boolean
      isEditorPresentationDifferent?: boolean
      showBlockHeaderInEditor?: boolean
    }
    searchKeywords?: Array<string>
    onboarding?: DocsBlockOnboarding
  }): DocsBlockDefinition {
    return new DocsBlockDefinition({
      id: configuration.id,
      name: configuration.name,
      category: configuration.category,
      icon: configuration.icon,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      description: configuration.description,
      // @ts-expect-error TS(2322): Type 'DocsBlockItem' is not assignable to type 'Do... Remove this comment to see the full error message
      item: configuration.item,
      searchKeywords: configuration.searchKeywords,
      behavior: {
        dataType: configuration.dataType,
        entities: {
          selectionType: configuration.selectionType,
          maxSelected: configuration.maxSelection ?? 0,
        },
      },
      appearance: {
        isBordered: configuration.appearance?.isBordered ?? true,
        hasBackground: configuration.appearance?.hasBackground ?? false,
        isEditorPresentationDifferent:
          configuration.appearance?.isEditorPresentationDifferent ?? false,
        showBlockHeaderInEditor:
          configuration.appearance?.showBlockHeaderInEditor ?? false,
      },
      editorOptions: {
        // @ts-expect-error TS(2322): Type 'DocsBlockOnboarding | undefined' is not assi... Remove this comment to see the full error message
        onboarding: configuration.onboarding,
      },
    })
  }

  /** Creates onboarding entity */
  onboarding(configuration: {
    helpText: string
    documentationLink?: string
  }): DocsBlockOnboarding {
    return new DocsBlockOnboarding({
      helpText: configuration.helpText,
      documentationLink: configuration.documentationLink,
    })
  }

  /** Create a block property with type .text and its specific options */
  textProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockTextPropertyOptions
    variants?: { [key: string]: DocsBlockTextPropertyOptions }
  }): DocsBlockTextPropertyDefinition {
    return new DocsBlockTextPropertyDefinition({
      type: DocsBlockItemPropertyType.text,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .richText and its specific options */
  richTextProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockRichTextPropertyOptions
    variants?: { [key: string]: DocsBlockRichTextPropertyOptions }
  }): DocsBlockRichTextPropertyDefinition {
    return new DocsBlockRichTextPropertyDefinition({
      type: DocsBlockItemPropertyType.richText,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .multiRichText and its specific options */
  multiRichTextProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockMultiRichTextPropertyOptions
    variants?: { [key: string]: DocsBlockMultiRichTextPropertyOptions }
  }): DocsBlockRichTextPropertyDefinition {
    return new DocsBlockRichTextPropertyDefinition({
      type: DocsBlockItemPropertyType.richText,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .multiRichText and its specific options */
  richTextEditorProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockRichTextEditorPropertyOptions
    variants?: { [key: string]: DocsBlockRichTextEditorPropertyOptions }
  }): DocsBlockRichTextEditorPropertyDefinition {
    return new DocsBlockRichTextEditorPropertyDefinition({
      type: DocsBlockItemPropertyType.richTextEditor,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .boolean and its specific options */
  booleanProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockBooleanPropertyOptions
    variants?: { [key: string]: DocsBlockBooleanPropertyOptions }
  }): DocsBlockBooleanPropertyDefinition {
    return new DocsBlockBooleanPropertyDefinition({
      type: DocsBlockItemPropertyType.boolean,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .number and its specific options */
  numericProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockNumberPropertyOptions
    variants?: { [key: string]: DocsBlockNumberPropertyOptions }
  }): DocsBlockNumberPropertyDefinition {
    return new DocsBlockNumberPropertyDefinition({
      type: DocsBlockItemPropertyType.number,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .singleSelect and its specific options */
  singleSelectProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockSingleSelectPropertyOptions
    variants?: { [key: string]: DocsBlockSingleSelectPropertyOptions }
  }): DocsBlockSingleSelectPropertyDefinition {
    return new DocsBlockSingleSelectPropertyDefinition({
      type: DocsBlockItemPropertyType.singleSelect,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .multiSelect and its specific options */
  multiSelectProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockMultiSelectPropertyOptions
    variants?: { [key: string]: DocsBlockMultiSelectPropertyOptions }
  }): DocsBlockMultiSelectPropertyDefinition {
    return new DocsBlockMultiSelectPropertyDefinition({
      type: DocsBlockItemPropertyType.multiSelect,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .image and its specific options */
  imageProperty(configuration: {
    id: string
    name: string
    description?: string
    options?: DocsBlockImagePropertyOptions
    variants?: { [key: string]: DocsBlockImagePropertyOptions }
  }): DocsBlockImagePropertyDefinition {
    return new DocsBlockImagePropertyDefinition({
      type: DocsBlockItemPropertyType.image,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .token and its specific options */
  tokenProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockTokenPropertyOptions
    variants?: { [key: string]: DocsBlockTokenPropertyOptions }
  }): DocsBlockTokenPropertyDefinition {
    return new DocsBlockTokenPropertyDefinition({
      type: DocsBlockItemPropertyType.token,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .tokenType and its specific options */
  tokenTypeProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockTokenTypePropertyOptions
    variants?: { [key: string]: DocsBlockTokenTypePropertyOptions }
  }): DocsBlockTokenTypePropertyDefinition {
    return new DocsBlockTokenTypePropertyDefinition({
      type: DocsBlockItemPropertyType.tokenType,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .tokenProperty and its specific options */
  tokenPropertyProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockTokenPropertyPropertyOptions
    variants?: { [key: string]: DocsBlockTokenPropertyPropertyOptions }
  }): DocsBlockTokenPropertyPropertyDefinition {
    return new DocsBlockTokenPropertyPropertyDefinition({
      type: DocsBlockItemPropertyType.tokenProperty,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .component and its specific options */
  componentProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockComponentPropertyOptions
    variants?: { [key: string]: DocsBlockComponentPropertyOptions }
  }): DocsBlockComponentPropertyDefinition {
    return new DocsBlockComponentPropertyDefinition({
      type: DocsBlockItemPropertyType.component,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .componentProperty and its specific options */
  componentPropertyProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockComponentPropertyPropertyOptions
    variants?: { [key: string]: DocsBlockComponentPropertyPropertyOptions }
  }): DocsBlockComponentPropertyPropertyDefinition {
    return new DocsBlockComponentPropertyPropertyDefinition({
      type: DocsBlockItemPropertyType.componentProperty,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .figmaComponentProperty and its specific options */
  figmaComponentProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockFigmaComponentPropertyOptions
    variants?: { [key: string]: DocsBlockFigmaComponentPropertyOptions }
  }): DocsBlockFigmaComponentPropertyDefinition {
    return new DocsBlockFigmaComponentPropertyDefinition({
      type: DocsBlockItemPropertyType.figmaComponent,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .asset and its specific options */
  assetProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockAssetPropertyOptions
    variants?: { [key: string]: DocsBlockAssetPropertyOptions }
  }): DocsBlockAssetPropertyDefinition {
    return new DocsBlockAssetPropertyDefinition({
      type: DocsBlockItemPropertyType.asset,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .figmaNodeProperty and its specific options */
  figmaNodeProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockFigmaNodePropertyOptions
    variants?: { [key: string]: DocsBlockFigmaNodePropertyOptions }
  }): DocsBlockFigmaNodePropertyDefinition {
    return new DocsBlockFigmaNodePropertyDefinition({
      type: DocsBlockItemPropertyType.figmaNode,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .assetProperty and its specific options */
  assetPropertyProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockAssetPropertyPropertyOptions
    variants?: { [key: string]: DocsBlockAssetPropertyPropertyOptions }
  }): DocsBlockAssetPropertyPropertyDefinition {
    return new DocsBlockAssetPropertyPropertyDefinition({
      type: DocsBlockItemPropertyType.assetProperty,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .embedURL and its specific options */
  embedUrlProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockEmbedUrlPropertyOptions
    variants?: { [key: string]: DocsBlockEmbedUrlPropertyOptions }
  }): DocsBlockEmbedUrlPropertyDefinition {
    return new DocsBlockEmbedUrlPropertyDefinition({
      type: DocsBlockItemPropertyType.embedURL,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .markdown and its specific options */
  markdownProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockMarkdownPropertyOptions
    variants?: { [key: string]: DocsBlockMarkdownPropertyOptions }
  }): DocsBlockMarkdownPropertyDefinition {
    return new DocsBlockMarkdownPropertyDefinition({
      type: DocsBlockItemPropertyType.markdown,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .url and its specific options */
  urlProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockUrlPropertyOptions
    variants?: { [key: string]: DocsBlockUrlPropertyOptions }
  }): DocsBlockUrlPropertyDefinition {
    return new DocsBlockUrlPropertyDefinition({
      type: DocsBlockItemPropertyType.url,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .storybook and its specific options */
  storybookProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockStorybookPropertyOptions
    variants?: { [key: string]: DocsBlockStorybookPropertyOptions }
  }): DocsBlockStorybookPropertyDefinition {
    return new DocsBlockStorybookPropertyDefinition({
      type: DocsBlockItemPropertyType.storybook,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .code and its specific options */
  codeProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockCodePropertyOptions
    variants?: { [key: string]: DocsBlockCodePropertyOptions }
  }): DocsBlockCodePropertyDefinition {
    return new DocsBlockCodePropertyDefinition({
      type: DocsBlockItemPropertyType.code,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .codeSandbox and its specific options */
  codeSandboxProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockCodeSandboxPropertyOptions
    variants?: { [key: string]: DocsBlockCodeSandboxPropertyOptions }
  }): DocsBlockCodeSandboxPropertyDefinition {
    return new DocsBlockCodeSandboxPropertyDefinition({
      type: DocsBlockItemPropertyType.codeSandbox,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .table and its specific options */
  tableProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockTablePropertyOptions
    variants?: { [key: string]: DocsBlockTablePropertyOptions }
  }): DocsBlockTablePropertyDefinition {
    return new DocsBlockTablePropertyDefinition({
      type: DocsBlockItemPropertyType.table,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }

  /** Create a block property with type .divider and its specific options */
  dividerProperty(configuration: {
    id: string
    name: string
    description?: string
    options: DocsBlockDividerPropertyOptions
    variants?: { [key: string]: DocsBlockDividerPropertyOptions }
  }): DocsBlockDividerPropertyDefinition {
    return new DocsBlockDividerPropertyDefinition({
      type: DocsBlockItemPropertyType.divider,
      id: configuration.id,
      name: configuration.name,
      description: configuration.description,
      options: configuration.options,
      variantOptions: configuration.variants,
    })
  }
}
