//
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Resource, ResourceModel, ResourceType } from "./SDKResource"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

// eslint-disable-next-line @typescript-eslint/ban-types
export type ResourceFontModel = ResourceModel & {
  fontProperties?: Array<{
    family?: string
    subfamily?: string
    weight?: number
    isItalic?: boolean
    isOutlined?: boolean
    isStrikeout?: boolean
    isUnderscore?: boolean
  }>
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class ResourceFont extends Resource {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: ResourceType = ResourceType.font

  fontProperties: {
    family: string | null
    subfamily: string | null
    weight: number | null
    isItalic: boolean | null
    isOutlined: boolean | null
    isStrikeout: boolean | null
    isUnderscore: boolean | null
  } | null = null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: ResourceFontModel) {
    super(model)

    this.type = ResourceType.asset

    const fontProperties = model.fontProperties?.at(0)

    if (fontProperties) {
      this.fontProperties = {
        family: fontProperties.family ?? null,
        subfamily: fontProperties.subfamily ?? null,
        weight: fontProperties.weight ?? null,
        isItalic: fontProperties.isItalic ?? null,
        isOutlined: fontProperties.isOutlined ?? null,
        isStrikeout: fontProperties.isStrikeout ?? null,
        isUnderscore: fontProperties.isUnderscore ?? null,
      }
    }
  }
}
