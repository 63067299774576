//
//  Membership.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { UserRole } from "../enums/SDKUserRole"
import { Workspace, WorkspaceRemoteModel } from "../workspaces/SDKWorkspace"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type MembershipRemoteModel = {
  workspace: WorkspaceRemoteModel
  role: UserRole
  effectiveRole: UserRole
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class Membership {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Workspace of this membership. */
  workspace: Workspace

  /** Selected role of the user. */
  role: UserRole

  /** Actual role of the user. */
  effectiveRole: UserRole

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: MembershipRemoteModel) {
    this.workspace = new Workspace(model.workspace)
    this.role = model.role
    this.effectiveRole = model.effectiveRole
  }
}
