//
//  SDKDocsBlockComponentPropertyProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** ComponentProperty property - Additional non-value options */
export type DocsBlockComponentPropertyPropertyOptions = Record<string, never>

/** ComponentProperty property - Data */
export type DocsBlockComponentPropertyPropertyData =
  DocsBlockPropertyData<string> & Record<string, never>

/** ComponentProperty property - Definition model */
export type DocsBlockComponentPropertyPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockComponentPropertyPropertyOptions

/** ComponentProperty property - Definition */
export class DocsBlockComponentPropertyPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockComponentPropertyPropertyOptions,
  DocsBlockComponentPropertyPropertyData
> {
  type!: DocsBlockItemPropertyType.componentProperty
}
