//
//  SDKDocsPublishStatus.ts
//  Supernova SDK
//
//  Created by Vsevolod Krasnov.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Publishing status enum

export enum DocsPublishStatus {
  idle,
  inProgress,
}
