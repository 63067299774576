//
//  SDKDesignComponent.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTOFigmaComponent } from "@supernova-studio/client"

import { DesignComponentOrigin } from "../support/SDKDesignComponentOrigin"
import { Source } from "../support/SDKSource"

import {
  DesignComponentPropertyDefinition,
  resolveComponentPropertyDefinition,
} from "./SDKDesignComponentPropertyDefinition"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export abstract class DesignComponentBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  brandId: string

  thumbnailUrl: string | null

  svgUrl: string | null

  name: string

  description: string | null

  origin: DesignComponentOrigin | null

  createdAt: Date | null

  updatedAt: Date | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOFigmaComponent, sources: Array<Source>) {
    this.id = model.persistentId
    this.brandId = model.brandId
    this.thumbnailUrl = model.thumbnailUrl ?? null
    this.svgUrl = model.svgUrl ?? null
    this.name = model.meta.name
    this.description = model.meta.description ?? null
    // this.componentPropertyDefinitions = model.componentPropertyDefinitions
    //   ? resolveComponentPropertyDefinition(model.componentPropertyDefinitions)
    //   : null
    // this.variantPropertyValues = model.variantPropertyValues ?? null
    // this.childrenPersistentIds = model.childrenPersistentIds ?? null
    // this.parentComponentPersistentId = model.parentComponentPersistentId ?? null
    // @ts-expect-error TS(2345): Argument of type 'DesignComponentOriginModel | und... Remove this comment to see the full error message
    this.origin = new DesignComponentOrigin(model.originComponent, sources)
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
  }
}
