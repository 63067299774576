import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockRichTextPropertyStyle } from "../../../../../enums/SDKDocsBlockRichTextPropertyStyle"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.callout"
const name = "Callout"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v3/callout.svg"
const descriptionShort = "Highlight a section of text"
const descriptionFull = "Use to highlight a section of text."
const searchKeywords = ["banner", "alert", "note", "tip", "warning"]

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/text/callout-ZPlZObD1"

/**
 * 2. Block properties
 */
const properties = {
  text: b.richTextProperty({
    id: "text",
    name: "Text",
    options: {
      placeholder: "Highlight some information...",
      richTextStyle: DocsBlockRichTextPropertyStyle.callout,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.text],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.text],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  description: descriptionShort,
  category: DocsBlockCategory.text,
  item: blockItem,
  searchKeywords,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as Callout }
