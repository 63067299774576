//
//  SDKDocsBlockFigmaNodeProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockContainerSize } from "../../../enums/SDKDocsBlockContainerSize"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsColorRef } from "../support/SDKDocsColorRef"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** FigmaNode property - Additional non-value options */
export type DocsBlockFigmaNodePropertyOptions = Record<string, never>

/** FigmaNode property - Data */
export type DocsBlockFigmaNodePropertyData = DocsBlockPropertyData<
  Array<{
    entityId: string
    entityMeta?: {
      title?: string
      description?: string
      backgroundColor?: DocsColorRef | null
    }
  }>
> & {
  /** Behavior of container size */
  previewContainerSize?:
    | DocsBlockContainerSize.centered
    | DocsBlockContainerSize.naturalHeight
  /** If enabled, search is visible */
  showSearch?: boolean
  /** Number of columns */
  columns?: number
  /** Shows details of each frame, or hides them all */
  showFrameDetails?: boolean
}

/** FigmaNode property - Definition model */
export type DocsBlockFigmaNodePropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockFigmaNodePropertyOptions

/** FigmaNode property - Definition */
export class DocsBlockFigmaNodePropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockFigmaNodePropertyOptions,
  DocsBlockFigmaNodePropertyData
> {
  type!: DocsBlockItemPropertyType.figmaNode
}
