//
//  AreaBulkOperations.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BootstrapData } from "../../model/base/SDKBootstrapData"
import { Brand } from "../../model/base/SDKBrand"
import {
  Component, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ComponentRemoteModel,
} from "../../model/components/SDKComponent"
import { ElementProperty } from "../../model/elements/SDKElementProperty"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ComponentGroup } from "../../model/groups/SDKComponentGroup"
import { TokenGroup } from "../../model/groups/SDKTokenGroup"
import { ImportJob } from "../../model/jobs/SDKImportJob"
import { TokenTheme } from "../../model/themes/SDKTokenTheme"
import { TokenThemeOverrideTranserObject } from "../../model/themes/SDKTokenThemeOverride"
import { AnyToken } from "../../model/tokens/SDKTokenValue"
import { DataCore } from "../data/SDKDataCore"

import { RemoteVersionIdentifier } from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type TokenUpdateTransactionData = {
  token: AnyToken
  tokenParent: TokenGroup
  tokenMode: "create" | "update"
  propertyValuesToCreateOrUpdate?: Array<{
    property: ElementProperty
    value: string | boolean | number
  }>
  propertyValuesToDelete?: Array<string>
  themeValuesToCreate?: Array<{
    themeId: string
    themeOverrideTransferObject: TokenThemeOverrideTranserObject
  }>
  themeValuesToUpdate?: Array<{
    themeId: string
    themeOverrideTransferObject: TokenThemeOverrideTranserObject
  }>
  themeValuesToDelete?: Array<string>
}

export type ComponentUpdateTransactionData = {
  component: Component
  // componentParent: ComponentGroup
  componentMode: "create" | "update"
  propertyValuesToCreateOrUpdate?: Array<{
    property: ElementProperty
    value: string | boolean | number
  }>
  propertyValuesToDelete?: Array<string>
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaBulkOperations {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Bulk Operations

  /** Import tokens in bulk using new import routine
   *
   * @param designSystemId - Remote design system to write to
   * @param brandId - Remote brand to write to
   * @param sourceName - Name of the source, such as Figma file name
   * @param sourceId - Unique ID of the source, such as Figma file id
   * @param sourceType - Source from which import originates. Currently only "FigmaVariablesPlugin" is supported
   * @param payload - Payload to import. Types to come
   * @returns Nothing
   */
  async importTokens(
    designSystemId: string,
    brandId: string,
    sourceId: string,
    sourceName: string,
    sourceType: "FigmaVariablesPlugin",
    payload: any,
    isTokenTypeSplitEnabled?: boolean
  ): Promise<ImportJob> {
    return this.dataCore.importTokens(
      designSystemId,
      brandId,
      sourceId,
      sourceName,
      sourceType,
      payload,
      isTokenTypeSplitEnabled
    )
  }

  /** Batch updates, creates or deletes token data from Supernova at once. Use this method to perform multiple operations at once instead of chaining single update operations. Batch routine runs everything in a single transaction, so either all operations succeed or all fail.
   *
   * WARNING: This method is not yet complete and only supports limited set of operations. Also, doesn't work as transation just yet.
   * @param to - Remote version to write to
   * @param data - Update information. All keys except token and its parent are optional and any combination of optional properties is allowed.
   * @returns Nothing
   */
  async updateTokenData(
    to: RemoteVersionIdentifier,
    data: TokenUpdateTransactionData
  ): Promise<void> {
    return this.dataCore.bulkUpdateTokenData(
      to.designSystemId,
      to.versionId,
      data
    )
  }

  /** Batch updates, creates or deletes component data from Supernova at once. Use this method to perform multiple operations at once instead of chaining single update operations. Batch routine runs everything in a single transaction, so either all operations succeed or all fail.
   *
   * WARNING: This method is not yet complete and only supports limited set of operations. Also, doesn't work as transation just yet.
   * @param to - Remote version to write to
   * @param data - Update information. All keys except component and its parent are optional and any combination of optional properties is allowed.
   * @returns Nothing
   */
  async updateComponentData(
    to: RemoteVersionIdentifier,
    data: ComponentUpdateTransactionData
  ): Promise<void> {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.dataCore.bulkUpdateComponentData(
      to.designSystemId,
      to.versionId,
      data
    )
  }

  /** Fetches a chain of workspace, design system, version and brand, available to the current user.
   * @param preferredWorkspaceId - Preferred workspace ID to fetch
   * @param preferredDesignSystemId - Preferred design system ID to fetch
   * @param preferredVersionId - Preferred version ID to fetch
   * @param preferredBrandId - Preferred brand ID to fetch
   */
  async bootstrapData(
    preferredWorkspaceId?: string,
    preferredDesignSystemId?: string,
    preferredVersionId?: string,
    preferredBrandId?: string
  ): Promise<BootstrapData> {
    return this.dataCore.bootstrapData(
      preferredWorkspaceId,
      preferredDesignSystemId,
      preferredVersionId,
      preferredBrandId
    )
  }
}
