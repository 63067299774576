//
//  SDKWorkspaceNPMRegistryAuthType.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Workspace registry auth type enum

// Following are possible:   Basic, Bearer
export enum WorkspaceNPMRegistryAuthType {
  /** The workspace is configured to be able to access registry with Basic auth */
  basic = "Basic",

  /** The workspace is configured to be able to access registry using Bearer token */
  bearer = "Bearer",
}
