//
//  SDKDesignComponent.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTOFigmaComponent } from "@supernova-studio/client"

import { Source } from "../support/SDKSource"

import { DesignComponentBase } from "./SDKDesignComponentBase"
import {
  DesignComponentPropertyDefinition,
  resolveComponentPropertyDefinition,
} from "./SDKDesignComponentPropertyDefinition"
import { DesignComponentVariant } from "./SDKDesignComponentVariant"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DesignComponentRemoteModel = DTOFigmaComponent

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DesignComponent extends DesignComponentBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  componentPropertyDefinitions: DesignComponentPropertyDefinition[] | null

  childrenPersistentIds: Array<string> | null

  children: Array<DesignComponentVariant> | null = null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DesignComponentRemoteModel, sources: Array<Source>) {
    super(model, sources)
    this.componentPropertyDefinitions = model.componentPropertyDefinitions
      ? resolveComponentPropertyDefinition(model.componentPropertyDefinitions)
      : null
    this.childrenPersistentIds = model.childrenPersistentIds ?? null
  }
}
