//
//  SDKBootstrapData.ts
//  Supernova SDK
//
//  Created by Vsevolod Krasnov.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTODesignSystem } from "@supernova-studio/client"

import { Membership, MembershipRemoteModel } from "../membership/SDKMembership"

import { Brand, BrandRemoteModel } from "./SDKBrand"
import { DesignSystem } from "./SDKDesignSystem"
import {
  DesignSystemVersion,
  DesignSystemVersionRemoteModel,
} from "./SDKDesignSystemVersion"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type BootstrapDataRemoteModel = {
  id: string
  workspaceMembership: MembershipRemoteModel
  designSystem: DTODesignSystem
  version: DesignSystemVersionRemoteModel
  brand: BrandRemoteModel
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class BootstrapData {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Workspace membership available to the current user */
  workspaceMembership: Membership | null

  /** Design system available to the current user */
  designSystem: DesignSystem | null

  /** Design system version available to the current user */
  version: DesignSystemVersion | null

  /** Brand available to the current user */
  brand: Brand | null

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: BootstrapDataRemoteModel) {
    this.workspaceMembership = model.workspaceMembership
      ? new Membership(model.workspaceMembership)
      : null
    this.designSystem = model.designSystem
      ? new DesignSystem(model.designSystem)
      : null
    this.version = model.version ? new DesignSystemVersion(model.version) : null
    this.brand = model.brand ? new Brand(model.brand) : null
  }
}
