import { DTOTokenCollection } from "@supernova-studio/client"

export class DesignSystemCollection {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // Public properties
  id: string

  designSystemVersionId: string

  persistentId: string

  name: string

  description: string

  createdAt: Date | null

  updatedAt: Date | null

  originId: string | null

  originSourceId: string | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // Constructor

  constructor(model: DTOTokenCollection) {
    this.id = model.id
    this.designSystemVersionId = model.designSystemVersionId
    this.persistentId = model.persistentId
    this.name = model.meta.name
    this.description = model.meta.description ?? ""
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
    this.originId = model.origin?.id ?? null
    this.originSourceId = model.origin?.sourceId ?? null
  }
}
