import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockBehaviorDataType } from "../../../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../../../enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { TokenType } from "../../../../../enums/SDKTokenType"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.color-accessibility-grid"
const name = "Accessibility color grid"
const icon =
  "https://cdn-assets.supernova.io/blocks/icons/v3/token-accessibility-grid.svg"
const descriptionShort = "Visualize accessibility of your color tokens"

const keywords = ["tokens", "color", "accessibility", "grid", "contrast"]

const descriptionFull =
  "Visualize accessibility of your colors by automatically calculating their color contrast ratio."

const docsUrl = "https://learn.supernova.io"

/**
 * 2. Block properties
 */
const properties = {
  tokens: b.tokenProperty({
    id: "tokens",
    name: "Tokens",
    options: {
      allowedTypes: [TokenType.color],
      allowPropertySelection: false,
      allowThemeSelection: false,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.tokens],
  description: descriptionFull,
  image:
    "https://cdn-assets.supernova.io/blocks/variants/contrast-grid-large.svg",
  appearance: {
    isEditorPresentationDifferent: true,
  },
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.tokens],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.dataBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.token,
  description: descriptionShort,
  searchKeywords: keywords,
  dataType: DocsBlockBehaviorDataType.token,
  selectionType: DocsBlockBehaviorSelectionType.entityAndGroup,
  item: blockItem,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as AccessibilityGrid }
