//
//  SDKDocsBlockAssetProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Asset property property - Additional non-value options */
export type DocsBlockAssetPropertyPropertyOptions = Record<string, never>

/** Asset property property - Data */
export type DocsBlockAssetPropertyPropertyData = DocsBlockPropertyData<
  Array<string>
> &
  Record<string, never>

/** Asset property property - Definition model */
export type DocsBlockAssetPropertyPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockAssetPropertyPropertyOptions

/** Asset property property - Definition */
export class DocsBlockAssetPropertyPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockAssetPropertyPropertyOptions,
  DocsBlockAssetPropertyPropertyData
> {
  type!: DocsBlockItemPropertyType.assetProperty
}
