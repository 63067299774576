/* eslint-disable no-labels */

/* eslint-disable max-lines */
//
//  AreaTokens.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  AnyToken,
  AnyTokenValue,
  BorderToken,
  ColorToken,
  GradientToken,
  GradientTokenValue,
  LineHeightTokenValue,
  OpacityTokenValue,
  ShadowToken,
  ShadowTokenValue,
  TypographyToken,
} from "../../exports"
import { DesignSystemCollection } from "../../model/base/SDKDesignSystemCollection"
import { ElementDataView } from "../../model/elements/SDKElementDataView"
import { ElementDataViewColumn } from "../../model/elements/SDKElementDataViewColumn"
import {
  ElementProperty,
  ElementPropertyCreationModel,
  ElementPropertyTargetElementType,
  ElementPropertyUpdateModel,
} from "../../model/elements/SDKElementProperty"
import { ElementPropertyValue } from "../../model/elements/values/SDKElementPropertyValue"
import { ALL_TOKEN_TYPES, TokenType } from "../../model/enums/SDKTokenType"
import { TokenGroup } from "../../model/groups/SDKTokenGroup"
import { ThemeUtilities } from "../../model/themes/SDKThemeUtilities"
import { TokenTheme } from "../../model/themes/SDKTokenTheme"
import { Token } from "../../model/tokens/SDKToken"
import { TokenTypeMapToken, TokenUtils } from "../../utils/TokenUtils"
import { DataCore } from "../data/SDKDataCore"

import { AreaTokenValues } from "./SDKAreaTokenValues"
import {
  RemoteBrandIdentifier,
  RemoteVersionIdentifier,
} from "./SDKRemoteIdentifiers"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Tokens Area

export class AreaTokens {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  /** Update helpers for everything token related */
  values: AreaTokenValues = new AreaTokenValues()

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches all remote tokens in the version
   * @param from - Remote version to fetch from
   * @param filter - Optional filter to apply. Multiple filters can be used at once
   * @param filter.type - When provided, only tokens of the specified type will be returned
   * @param filter.brandId - When provided, only tokens of the specified brand will be returned
   * @returns Tokens in the specified version with filter applied
   */
  async getTokens(
    from: RemoteVersionIdentifier,
    filter?: {
      type?: TokenType
      brandId?: string
    }
  ): Promise<Array<Token>> {
    return this.dataCore.tokens(from.designSystemId, from.versionId, filter)
  }

  /** Retrieves all occurances of the specified token in the version - its usages in other tokens, components and documentation pages
   * @param from - Remote version to fetch from
   * @param idInVersion - Token to check for references
   * @returns Persistent identifiers of tokens, components, documentation pages that contain the specified token
   */
  async getTokenUsage(
    from: RemoteVersionIdentifier,
    idInVersion: string
  ): Promise<{
    tokens: Array<string>
    components: Array<string>
    documentationPages: Array<string>
  }> {
    return this.dataCore.getTokenUsage(
      from.designSystemId,
      from.versionId,
      idInVersion
    )
  }

  /** Fetches all remote token groups in this version
   * @param from - Remote version to fetch from
   * @param filter - Optional filter to apply. Multiple filters can be used at once
   * @param filter.type - When provided, only token groups of the specified type will be returned
   * @param filter.brandId - When provided, only token groups of the specified brand will be returned
   * @returns Token groups in the specified version with filter applied
   */
  async getTokenGroups(
    from: RemoteVersionIdentifier,
    filter?: {
      type?: TokenType
      brandId?: string
    }
  ): Promise<Array<TokenGroup>> {
    return this.dataCore.tokenGroups(
      from.designSystemId,
      from.versionId,
      filter
    )
  }

  /** Fetches all remote themes in the version
   * @param from - Remote version to fetch from
   * @returns All themes in the specified version
   */
  async getTokenThemes(
    from: RemoteVersionIdentifier
  ): Promise<Array<TokenTheme>> {
    return this.dataCore.themes(from.designSystemId, from.versionId)
  }

  /** Retrieves all remote property definitions for tokens
   * @param from - Remote version to fetch from
   * @returns All property definitions in the specified version
   */
  async getTokenProperties(
    from: RemoteVersionIdentifier
  ): Promise<Array<ElementProperty>> {
    return this.dataCore.elementPropertyDefinitions(
      from.designSystemId,
      from.versionId,
      ElementPropertyTargetElementType.token
    )
  }

  /** Retrieves all remote property definitions for tokens
   * @param from - Remote version to fetch from
   * @returns All property definitions in the specified version
   */
  async getTokenPropertyValues(
    from: RemoteVersionIdentifier
  ): Promise<Array<ElementPropertyValue>> {
    return this.dataCore.elementPropertyValues(
      from.designSystemId,
      from.versionId
    )
  }

  /** Retrieves all remote property views for tokens
   * @param from - Remote version to fetch from
   * @returns All property views in the specified version
   */
  async getTokenDataViews(
    from: RemoteVersionIdentifier
  ): Promise<Array<ElementDataView>> {
    return this.dataCore.elementViews(
      from.designSystemId,
      from.versionId,
      ElementPropertyTargetElementType.token
    )
  }

  /** Retrieves all token collections
   * @param from - Remote version to fetch from
   * @returns All token collections in the specified version
   */
  async getTokenCollections(
    from: RemoteVersionIdentifier
  ): Promise<Array<DesignSystemCollection>> {
    return this.dataCore.tokenCollections(from.designSystemId, from.versionId)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Creates remote token. This method can only be used once per token and will assign persistent id to the token. Calling this version on token that already has id assigned will result in error
   * @param to - Remote version to write to
   * @param token - Token to create
   * @param parentId - Id of the group to add the token to. This is id of the group, not the versioned id
   * @returns New remote token ids
   */
  async createToken(
    to: RemoteVersionIdentifier,
    token: Token,
    parentId: string
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    return this.dataCore.createToken(
      to.designSystemId,
      to.versionId,
      token,
      parentId
    )
  }

  /** Creates local token. This token doesn't have id associated with it on remote, and must be created once with `createToken` to be stored in design system
   * @param tokenType - Type of token to create
   * @returns New token of the specified type, filled with default values
   */
  createLocalToken<T extends TokenType>(
    tokenType: T,
    versionId: string,
    brandId: string,
    properties: Array<ElementProperty>
  ): TokenTypeMapToken[T] {
    return TokenUtils.createDefaultToken(
      tokenType,
      versionId,
      brandId,
      properties
    )
  }

  /** Creates local token override. This theme override doesn't have id associated with it on remote, and must be created once with `createThemeOverride` to be stored in design system
   * @param tokenType - Type of token to create
   * @returns New token of the specified type, filled with default values
   */
  createLocalThemeOverride<T extends AnyToken>(
    theme: TokenTheme,
    baseToken: T
  ): T {
    const replica = ThemeUtilities.replicateTokenWithValue(baseToken)

    replica.propertyValues = {}
    replica.themeId = theme.id
    replica.origin = null

    return replica
  }

  /** Creates remote token group. This method can only be used once per group and will assign persistent id to the group. Calling this version on group that already has id assigned will result in error
   * @param to - Remote version to write to
   * @param group - Group to create
   * @param parentId - Id of the group to add the group to. This is id of the group, not the versioned id
   * @returns New remote token group ids
   */
  async createTokenGroup(
    to: RemoteVersionIdentifier,
    group: TokenGroup,
    parentId: string
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    return this.dataCore.createTokenGroup(
      to.designSystemId,
      to.versionId,
      group,
      parentId
    )
  }

  /** Creates local token group. This group doesn't have id associated with it on remote, and must be created once with `createTokenGroup` to be stored in design system
   * @param tokenType - Type of token to create the group for
   * @param versionId - Id of the version to create the group in
   * @param brandId - Id of the brand to create the group in
   * @returns New group with id assigned
   */
  createLocalTokenGroup(
    tokenType: TokenType,
    versionId: string,
    brandId: string
  ): TokenGroup {
    return TokenUtils.createDefaultTokenGroup(tokenType, versionId, brandId)
  }

  /** Updates remote token. This method can only be used on tokens that already exist in the version
   * @param to - Remote version to write to
   * @param token - Token to update
   * @returns Nothing
   */
  async updateToken(to: RemoteVersionIdentifier, token: Token): Promise<void> {
    return this.dataCore.updateToken(to.designSystemId, to.versionId, token)
  }

  /** Updates remote token groups. This method can only be used on token groups that already exist in the version
   * @param to - Remote version to write to
   * @param group - Token group to update
   * @returns Nothing
   */
  async updateTokenGroup(
    to: RemoteVersionIdentifier,
    group: TokenGroup
  ): Promise<void> {
    return this.dataCore.updateTokenGroup(
      to.designSystemId,
      to.versionId,
      group
    )
  }

  /** Updates remote token property value. This also creates the property value if it doesn't exist
   * @param to - Remote version to write to
   * @param newValue - Value to set - this can be multiple things, from ID to raw string value to others
   * @param forToken - Token to assign the value to
   * @param forProperty - Property to assign the value to
   */
  async updateTokenPropertyValue(
    to: RemoteVersionIdentifier,
    newValue: string,
    forToken: Token,
    forProperty: ElementProperty
  ) {
    return this.dataCore.updateTokenPropertyValue(
      to.designSystemId,
      to.versionId,
      newValue,
      forToken,
      forProperty
    )
  }

  /** Deletes remote token property value
   * @param to - Remote version to write to
   * @param valueId - Value identifier
   */
  async deleteTokenPropertyValue(
    to: RemoteVersionIdentifier,
    valueId: string
  ): Promise<void> {
    return this.dataCore.deleteTokenPropertyValue(
      to.designSystemId,
      to.versionId,
      valueId
    )
  }

  /** Create token property
   * @param to - Remote version to write to
   * @param model - Property creation model to create the property from
   */
  async createTokenProperty(
    to: RemoteVersionIdentifier,
    model: ElementPropertyCreationModel
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    return this.dataCore.createTokenProperty(
      to.designSystemId,
      to.versionId,
      model
    )
  }

  /** Update token property
   * @param to - Remote version to write to
   * @param propertyIdInVersion - Remote element property to update
   * @param model - Property update model to apply to property
   */
  async updateTokenProperty(
    to: RemoteVersionIdentifier,
    propertyIdInVersion: string,
    model: ElementPropertyUpdateModel
  ): Promise<void> {
    return this.dataCore.updateTokenProperty(
      to.designSystemId,
      to.versionId,
      propertyIdInVersion,
      model
    )
  }

  /** Delete token property. This will remove all values associated with the property
   * @param to - Remote version to write to
   * @param propertyIdInVersion - Id of the property to delete
   */
  async deleteTokenProperty(
    to: RemoteVersionIdentifier,
    propertyIdInVersion: string
  ): Promise<void> {
    return this.dataCore.deleteTokenProperty(
      to.designSystemId,
      to.versionId,
      propertyIdInVersion
    )
  }

  /** Resize specific token column
   * @param to - Remote version to write to
   * @param columnIdInVersion - Id of the column to update
   * @param newWidth - New width of the column
   */
  async updateResizeTokenColumn(
    to: RemoteVersionIdentifier,
    view: ElementDataView,
    column: ElementDataViewColumn,
    newWidth: number
  ): Promise<void> {
    return this.dataCore.updateResizeTokenColumn(
      to.designSystemId,
      to.versionId,
      view,
      column,
      newWidth
    )
  }

  /** Move column to a new index and reorder all other columns around it
   * @param to - Remote version to write to
   * @param colum - Column object to move
   * @param newIndex - New index to move the token to
   */
  async updateReorderTokenColumn(
    to: RemoteVersionIdentifier,
    view: ElementDataView,
    column: ElementDataViewColumn,
    newIndex: number
  ): Promise<void> {
    return this.dataCore.updateReorderTokenColumn(
      to.designSystemId,
      to.versionId,
      view,
      column,
      newIndex
    )
  }

  /** Creates local token theme. This group doesn't have id associated with it on remote, and must be created once with `createTokenTheme` to be stored in design system
   * @param versionId - Id of the version to create the theme in
   * @param brandId - Id of the brand to create the theme in
   * @returns New group with id assigned
   */
  createLocalTokenTheme(versionId: string, brandId: string): TokenTheme {
    return TokenUtils.createDefaultTokenTheme(versionId, brandId)
  }

  /** Creates remote token theme. This method can only be used once per theme and will assign persistent id to the theme. Calling this version on theme that already has id assigned will result in error
   * @param to - Remote version to write to
   * @param theme - Theme to create
   * @returns New remote theme ids
   */
  async createTokenTheme(
    to: RemoteVersionIdentifier,
    theme: TokenTheme
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    return this.dataCore.createTokenTheme(
      to.designSystemId,
      to.versionId,
      theme
    )
  }

  /** Updates token theme. This method can only be used on themes that already exist in the version
   * @param to - Remote version to write to
   * @param theme - Token theme to update
   * @returns Nothing
   */
  async updateTokenTheme(
    to: RemoteVersionIdentifier,
    theme: TokenTheme
  ): Promise<void> {
    return this.dataCore.updateTokenTheme(
      to.designSystemId,
      to.versionId,
      theme
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  /** Deletes remote token. Calling this method on token that doesn't exist in the version will result in error
   * @param to - Remote version to write to
   * @param tokenIdInVersion - Token idInVersion to delete
   * @returns Nothing
   */
  async deleteToken(
    to: RemoteVersionIdentifier,
    tokenIdInVersion: string
  ): Promise<void> {
    return this.dataCore.deleteToken(
      to.designSystemId,
      to.versionId,
      tokenIdInVersion
    )
  }

  /** Deletes remote token group. This method will also fix the token group tree by moving any subgroups to parent - do note it will NOT delete contents of the group. Calling this method on group that doesn't exist in the version will result in error
   * @param to - Remote version to write to
   * @param tokenGroupIdInVersion - Token group idInVersion to delete
   * @returns Nothing
   */
  async ungroupTokenGroup(
    to: RemoteVersionIdentifier,
    tokenGroupIdInVersion: string
  ): Promise<void> {
    return this.dataCore.ungroupTokenGroup(
      to.designSystemId,
      to.versionId,
      tokenGroupIdInVersion
    )
  }

  /** Deletes remote token group and all its descendants. All items (groups and tokens) underneath will be deleted. Calling this method on group that doesn't exist in the version will result in error
   * @param to - Remote version to write to
   * @param tokenGroupToDelete - Token group to delete
   * @param tokens - All available tokens
   * @param tokenGroups - All available token groups
   * @returns Nothing
   */
  async deleteTokenGroup(
    to: RemoteVersionIdentifier,
    tokenGroupToDelete: TokenGroup,
    tokens: Array<Token>,
    tokenGroups: Array<TokenGroup>
  ): Promise<void> {
    return this.dataCore.deleteTokenGroup(
      to.designSystemId,
      to.versionId,
      tokenGroupToDelete,
      tokens,
      tokenGroups
    )
  }

  /** Deletes remote token theme. This method will also remove all theme overrides and disconnect theme from assigned column, all of which will be updated
   * @param to - Remote version to write to
   * @param idInVersion - Theme idInVersion to delete
   * @returns Nothing
   */
  async deleteTokenTheme(
    to: RemoteVersionIdentifier,
    idInVersion: string
  ): Promise<void> {
    return this.dataCore.deleteTokenTheme(
      to.designSystemId,
      to.versionId,
      idInVersion
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Bulk editing

  /** Warning: INTERNAL, DO NOT USE YET, NOT STABLE INTERFACE. Write tokens in bulk */
  async writeTokens(
    to: RemoteVersionIdentifier,
    tokens: Array<Token>,
    groups: Array<TokenGroup>,
    deleteTokens: Array<Token>,
    deleteTokenGroups: Array<TokenGroup>
  ): Promise<void> {
    return this.dataCore.writeTokenData(
      to.designSystemId,
      to.versionId,
      tokens,
      groups,
      deleteTokens,
      deleteTokenGroups
    )
  }

  /** Warning: INTERNAL, DO NOT USE YET, NOT STABLE INTERFACE. Write theme in bulk */
  async writeTheme(
    to: RemoteVersionIdentifier,
    theme: TokenTheme
  ): Promise<void> {
    return this.dataCore.writeTokenThemeData(
      to.designSystemId,
      to.versionId,
      theme
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Computing

  /** Resolves tokens by applying themes to them and retrieves new token set where values correctly correspond to that theme */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  computeTokensByApplyingThemes(
    allTokens: Array<Token>,
    tokens: Array<Token>,
    themes: Array<TokenTheme>
  ): Array<Token> {
    // Create hashes index of all tokens
    const tokensIndex: Map<string, Token> = new Map()
    for (const token of allTokens) {
      tokensIndex.set(
        token.id,
        ThemeUtilities.replicateTokenWithValue(token as AnyToken)
      )
    }

    // Create (crude) hashed search index that contains mapped themes <> tokens to make the lookup more performant
    const themeOverridesIndex: { [key: string]: Map<string, Token> } = {}

    for (const theme of themes) {
      const overrides = new Map<string, Token>()

      for (const override of theme.overriddenTokens) {
        overrides.set(
          override.id,
          ThemeUtilities.replicateTokenWithValue(override as AnyToken)
        )
      }

      themeOverridesIndex[theme.id] = overrides
    }

    // Calculate token overrides
    const resolvedTopLevelTokens = this.computeTokensByApplyingThemesEfficient(
      tokensIndex,
      themeOverridesIndex,
      tokens,
      themes
    )

    // At this point we have all tokens resolved from top level but some tokens also have references to other tokens (ie. typography -> font size etc.), so now the resolver will continue replacing those
    const resolvedFixedReferencedTokens =
      this.computeInnerReferencesByApplyingThemesEfficient(
        tokensIndex,
        themeOverridesIndex,
        resolvedTopLevelTokens,
        themes
      )

    return resolvedFixedReferencedTokens
  }

  computeInnerReferencesByApplyingThemesEfficient(
    tokensIndex: Map<string, Token>,
    themeOverridesIndex: { [key: string]: Map<string, Token> },
    tokens: Array<Token>,
    themes: Array<TokenTheme>
  ): Array<Token> {
    // For every token check if it is a complex token, then resolve each of its sub-values separately
    const resolvedTokens: Array<Token> = []

    for (const token of tokens) {
      if (token.tokenType === TokenType.typography) {
        const t = token as TypographyToken
        console.log(t.value)
        t.value = {
          fontFamily: t.value.fontFamily.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.fontFamily.referencedTokenId,
                themes
              )
            : t.value.fontFamily,
          fontWeight: t.value.fontWeight.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.fontWeight.referencedTokenId,
                themes
              )
            : t.value.fontWeight,
          fontSize: t.value.fontSize.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.fontSize.referencedTokenId,
                themes
              )
            : t.value.fontSize,
          textDecoration: t.value.textDecoration.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.textDecoration.referencedTokenId,
                themes
              )
            : t.value.textDecoration,
          textCase: t.value.textCase.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.textCase.referencedTokenId,
                themes
              )
            : t.value.textCase,
          letterSpacing: t.value.letterSpacing.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.letterSpacing.referencedTokenId,
                themes
              )
            : t.value.letterSpacing,
          lineHeight: t.value.lineHeight
            ? t.value.lineHeight.referencedTokenId
              ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                (this.computeSingleTokenValueFromReferenceByApplyingThemes(
                  tokensIndex,
                  themeOverridesIndex,
                  t.value.lineHeight.referencedTokenId,
                  themes
                ) as LineHeightTokenValue)
              : null
            : null,
          paragraphIndent: t.value.paragraphIndent.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.paragraphIndent.referencedTokenId,
                themes
              )
            : t.value.paragraphIndent,
          paragraphSpacing: t.value.paragraphSpacing.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.paragraphSpacing.referencedTokenId,
                themes
              )
            : t.value.paragraphSpacing,
          referencedTokenId: t.value.referencedTokenId,
        }

        resolvedTokens.push(t)
      } else if (token.tokenType === TokenType.border) {
        const t = token as BorderToken
        t.value = {
          width: t.value.width.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.width.referencedTokenId,
                themes
              )
            : t.value.width,
          color: t.value.color.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.color.referencedTokenId,
                themes
              )
            : t.value.color,
          style: t.value.style,
          position: t.value.position,
          referencedTokenId: t.value.referencedTokenId,
        }

        // Note: Second pass, resolve only opacity if exists
        if (t.value.color.opacity.referencedTokenId) {
          t.value = {
            ...t.value,
            color: {
              ...t.value.color,
              opacity:
                this.computeSingleTokenValueFromReferenceByApplyingThemes(
                  tokensIndex,
                  themeOverridesIndex,
                  t.value.color.opacity.referencedTokenId,
                  themes
                ),
            },
          }
        }

        resolvedTokens.push(t)
      } else if (token.tokenType === TokenType.color) {
        const t = token as ColorToken
        t.value = {
          color: t.value.color,
          opacity: t.value.opacity.referencedTokenId
            ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                tokensIndex,
                themeOverridesIndex,
                t.value.opacity.referencedTokenId,
                themes
              )
            : t.value.opacity,
          referencedTokenId: t.value.referencedTokenId,
        }
        resolvedTokens.push(t)
      } else if (token.tokenType === TokenType.shadow) {
        const t = token as ShadowToken
        t.value = t.value.map((layer) => {
          let value: ShadowTokenValue = {
            color: layer.color.referencedTokenId
              ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                  tokensIndex,
                  themeOverridesIndex,
                  layer.color.referencedTokenId,
                  themes
                )
              : layer.color,
            x: layer.x,
            y: layer.y,
            radius: layer.radius,
            spread: layer.spread,
            opacity:
              layer.opacity && layer.opacity.referencedTokenId
                ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                  (this.computeSingleTokenValueFromReferenceByApplyingThemes(
                    tokensIndex,
                    themeOverridesIndex,
                    layer.opacity.referencedTokenId,
                    themes
                  ) as OpacityTokenValue)
                : layer.opacity,
            type: layer.type,
            referencedTokenId: layer.referencedTokenId,
          }

          // Note: Second pass, resolve only opacity if exists
          if (value.color.opacity.referencedTokenId) {
            value = {
              ...value,
              color: {
                ...value.color,
                opacity:
                  this.computeSingleTokenValueFromReferenceByApplyingThemes(
                    tokensIndex,
                    themeOverridesIndex,
                    value.color.opacity.referencedTokenId,
                    themes
                  ),
              },
            }
          }

          return value
        })
        resolvedTokens.push(t)
      } else if (token.tokenType === TokenType.gradient) {
        const t = token as GradientToken
        t.value = t.value.map((layer) => {
          const value: GradientTokenValue = {
            to: layer.to,
            from: layer.from,
            aspectRatio: layer.aspectRatio,
            stops: layer.stops.map((stop) => {
              return {
                color: stop.color.referencedTokenId
                  ? this.computeSingleTokenValueFromReferenceByApplyingThemes(
                      tokensIndex,
                      themeOverridesIndex,
                      stop.color.referencedTokenId,
                      themes
                    )
                  : stop.color,
                position: stop.position,
              }
            }),
            type: layer.type,
            referencedTokenId: layer.referencedTokenId,
          }

          // Note: Second pass, resolve only opacity if exists
          value.stops = value.stops.map((stop) => {
            if (stop.color.opacity.referencedTokenId) {
              return {
                ...stop,
                color: {
                  ...stop.color,
                  opacity:
                    this.computeSingleTokenValueFromReferenceByApplyingThemes(
                      tokensIndex,
                      themeOverridesIndex,
                      stop.color.opacity.referencedTokenId,
                      themes
                    ),
                },
              }
            }
            return stop
          })

          return value
        })
      } else {
        resolvedTokens.push(token)
      }
    }

    return resolvedTokens
  }

  private computeSingleTokenValueFromReferenceByApplyingThemes<
    T extends AnyTokenValue
  >(
    tokensIndex: Map<string, Token>,
    themeOverridesIndex: { [key: string]: Map<string, Token> },
    token: string,
    themes: Array<TokenTheme>
  ): T {
    const resolvedTokens = this.computeTokensByApplyingThemesEfficient(
      tokensIndex,
      themeOverridesIndex,
      [tokensIndex.get(token) as Token],
      themes
    )

    if (resolvedTokens.length !== 1) {
      throw new Error(
        `Expected to resolve a single token but resolved ${resolvedTokens.length} tokens instead`
      )
    }

    return (resolvedTokens[0] as AnyToken).value as T
  }

  computeTokensByApplyingThemesEfficient(
    tokensIndex: Map<string, Token>,
    themeOverridesIndex: { [key: string]: Map<string, Token> },
    tokens: Array<Token>,
    themes: Array<TokenTheme>
  ): Array<Token> {
    // Select each correct token by first searching the overrides in reverse order, or fallback to default token if not found in any
    const reversedThemes = [...themes].reverse()
    const resolvedTokens: Array<Token> = []
    let processedThemes: Array<TokenTheme> = []

    for (const token of tokens) {
      processedThemes = []
      let override: Token | null = null
      const resolvedInitialCount = resolvedTokens.length

      /**
       * The following part handles the case where token value is pure
       */
      coreThemeLoop: for (const theme of reversedThemes) {
        processedThemes.push(theme)

        override = themeOverridesIndex[theme.id]?.get(token.id) ?? null
        if (override) {
          // If there is override, prioritize that
          // If the override is not a reference, just use it as is
          let possiblyReferencedValue = (override as AnyToken).value
          if (possiblyReferencedValue instanceof Array) {
            // In case value is array, just use its raw value and don't check for further references
            resolvedTokens.push(override)
            break
          }

          let reference = possiblyReferencedValue.referencedTokenId
          if (reference) {
            // If the token is reference, we need to check if the reference has override in any of the themes we have processed until this point
            let parentToken = tokensIndex.get(reference)
            while (parentToken) {
              // Token is referenced, check if that reference has override in any of the themes we have processed until this point
              for (const processedTheme of processedThemes) {
                override =
                  themeOverridesIndex[processedTheme.id]?.get(parentToken.id) ??
                  null
                if (override) {
                  resolvedTokens.push(override)
                  break coreThemeLoop
                }
              }

              // Do the same reference check for theme-related overrides
              override =
                themeOverridesIndex[theme.id]?.get(parentToken.id) ?? null
              if (override) {
                resolvedTokens.push(override)
                break coreThemeLoop
              }
              possiblyReferencedValue = (parentToken as AnyToken).value
              if (possiblyReferencedValue instanceof Array) {
                // This will not happen because any array value was already returned above but for type purposes we keep it
                break
              }
              reference = possiblyReferencedValue.referencedTokenId

              if (reference) {
                // Move on to check another level of reference if present
                parentToken = tokensIndex.get(reference)
              } else {
                resolvedTokens.push(parentToken)
                break coreThemeLoop
              }
            }
          } else {
            // No reference, just use the override as is
            resolvedTokens.push(override)
            break
          }
        }
      }

      override = null
      if (resolvedInitialCount !== resolvedTokens.length) {
        // Skip the next part if we have already resolved the token
        continue
      }

      /**
       * The following part handles the case where token value is a reference
       */
      coreThemeLoop: for (const theme of reversedThemes) {
        processedThemes.push(theme)

        if (!override) {
          // Check if token has reference and if it has, check if the reference has override in any of the themes that are going to be applied, recursively
          let possiblyReferencedValue = (token as AnyToken).value
          if (possiblyReferencedValue instanceof Array) {
            // This will not happen because any array value was already returned above but for type purposes we keep it
            break
          }
          let reference = possiblyReferencedValue.referencedTokenId

          if (reference) {
            let parentToken = tokensIndex.get(reference)
            while (parentToken) {
              for (const processedTheme of processedThemes) {
                override =
                  themeOverridesIndex[processedTheme.id]?.get(parentToken.id) ??
                  null
                if (override) {
                  resolvedTokens.push(override)
                  break coreThemeLoop
                }
              }
              override =
                themeOverridesIndex[theme.id]?.get(parentToken.id) ?? null
              if (override) {
                resolvedTokens.push(override)
                break coreThemeLoop
              }
              possiblyReferencedValue = (parentToken as AnyToken).value
              if (possiblyReferencedValue instanceof Array) {
                // This will not happen because any array value was already returned above but for type purposes we keep it
                override = null
                break coreThemeLoop
              }
              reference = possiblyReferencedValue.referencedTokenId

              if (reference) {
                parentToken = tokensIndex.get(reference)
              } else {
                parentToken = undefined
              }
            }
          }
        }
      }

      /**
       * There was no resolution to theme, so we return the token as is
       */
      if (resolvedInitialCount === resolvedTokens.length) {
        resolvedTokens.push(token)
      }

      /** Validate that we have exactly a single token resolved per run */
      if (resolvedInitialCount + 1 !== resolvedTokens.length) {
        throw new Error(
          `Token can only resolve into a single entity again but resolved into ${
            resolvedInitialCount - resolvedTokens.length
          } entities instead - resolved before: ${resolvedInitialCount}, in this pass: ${
            resolvedTokens.length
          }`
        )
      }
    }

    // Verify that all tokens are resolved
    if (resolvedTokens.length !== tokens.length) {
      throw new Error(
        `Not all tokens were resolved by applying themes. Resolved: ${resolvedTokens.length}, Expected: ${tokens.length}`
      )
    }

    // Create an array of original tokens with new values overriden by the resolution
    const finalTokens: Array<Token> = []
    tokens.forEach((t, index) => {
      const anyToken = t as AnyToken
      // Any types are necessary right now as we can't reliably type this later, to improve eventually
      const tokenWithoutValue =
        ThemeUtilities.replicateTokenWithoutValue(anyToken)
      const resolvedToken = resolvedTokens[index] as AnyToken | undefined
      let resolvedValue = resolvedToken?.value

      if (
        resolvedToken &&
        resolvedValue &&
        !Array.isArray(resolvedValue) &&
        // add reference to the resolved token if it's not the same as the original token
        resolvedToken.id !== anyToken.id
      ) {
        // add reference to the resolved token
        resolvedValue = {
          ...resolvedValue,
          referencedTokenId: resolvedToken.id,
        }
      }

      tokenWithoutValue.value = resolvedValue ?? anyToken.value
      finalTokens.push(tokenWithoutValue)
    })

    return finalTokens
  }

  /** Separates groups by types */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  async computeGroupTrees(
    groups: Array<TokenGroup>
  ): Promise<Map<TokenType, Array<TokenGroup>>> {
    const rootGroups = groups.filter((g) => g.isRoot)
    const trees = new Map<TokenType, Array<TokenGroup>>()

    for (const group of rootGroups) {
      const branch = trees.get(group.tokenType)

      if (branch) {
        branch.push(group)
        trees.set(group.tokenType, branch)
      } else {
        trees.set(group.tokenType, [group])
      }
    }

    return trees
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Mock Data

  /** Get determistic mocked token data. Must be used with mocked token groups */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  async getMockedTokens(from: RemoteBrandIdentifier): Promise<Array<Token>> {
    const tokens: Array<Token> = []

    ALL_TOKEN_TYPES.forEach((type) => {
      const token = this.createLocalToken(
        type,
        from.versionId,
        from.brandId,
        []
      )

      // Modify id for determinism
      token.id = `mocked-token-${type}`
      tokens.push(token)
    })

    return tokens
  }

  /** Get determistic mocked token group data. Must be used with mocked tokens */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  async getMockedTokenGroups(
    from: RemoteBrandIdentifier
  ): Promise<Array<TokenGroup>> {
    const rootGroups: Array<TokenGroup> = []

    ALL_TOKEN_TYPES.forEach((type) => {
      const group = this.createLocalTokenGroup(
        type,
        from.versionId,
        from.brandId
      )

      // Modify id for determinism, and mark all as root groups
      group.id = `mocked-group-${type}`
      group.childrenIds = [`mocked-token-${type}`]
      group.isRoot = true
      rootGroups.push(group)
    })

    return rootGroups
  }

  /** Get determistic mocked token data views. Must be used with mocked tokens */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  async getMockedTokenDataViews(
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from: RemoteVersionIdentifier
  ): Promise<Array<ElementDataView>> {
    return [
      new ElementDataView({
        id: "mocked-token-data-view",
        persistentId: "mocked-token-data-view-persistent-id",
        isDefault: true,
        targetElementType: ElementPropertyTargetElementType.token,
        columns: [],
        meta: {
          name: "Mocked token data view",
          description: "",
        },
      }),
    ]
  }
}
