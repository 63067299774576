import { DTOGitRepository } from "@supernova-studio/client"

export type GitProvidersRepositoriesRemoteModel = {
  repositories: Array<DTOGitRepository>
}

export class GitProvidersRepository implements DTOGitRepository {
  id: string

  name: string

  url: string

  defaultBranch: string | undefined

  slug: string

  constructor(model: DTOGitRepository) {
    this.id = model.id
    this.name = model.name
    this.url = model.url
    this.defaultBranch = model.defaultBranch
    this.slug = model.slug
  }
}

export class GitProvidersRepositories {
  repositories: Array<GitProvidersRepository>

  constructor(model: GitProvidersRepositoriesRemoteModel) {
    this.repositories = model.repositories.map(
      (repository) => new GitProvidersRepository(repository)
    )
  }
}
