//
//  SDKDocsBlockNumberProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Number property - Additional non-value options */
export type DocsBlockNumberPropertyOptions = {
  /** Default value to set when block is created */
  defaultValue: number
  /** Minimum value */
  min?: number
  /** Maximum value */
  max?: number
  /** Step value */
  step?: number
  /** Field placeholder */
  placeholder?: string
}

/** Number property - Data */
export type DocsBlockNumberPropertyData = DocsBlockPropertyData<number> &
  Record<string, never>

/** Number property - Definition model */
export type DocsBlockNumberPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockNumberPropertyOptions

/** Number property - Definition */
export class DocsBlockNumberPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockNumberPropertyOptions,
  DocsBlockNumberPropertyData
> {
  type!: DocsBlockItemPropertyType.number
}
