//
//  SDKTextCase.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Source type enum

export enum TextCase {
  original = "Original",
  upper = "Upper",
  lower = "Lower",
  camel = "Camel",
  smallCaps = "SmallCaps",
}
