//
//  SDKDocsBlockBooleanProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DocsBlockItemPropertyOptionRenderingStyle } from "../../../enums/SDKDocsBlockItemPropertyOptionRenderingStyle"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"

import {
  DocsBlockPropertyData,
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Boolean property - Additional non-value options */
export type DocsBlockBooleanPropertyOptions = {
  /** Default value to set when block is created */
  defaultValue?: boolean
  /** Visual representation of the property */
  booleanStyle?: Exclude<
    DocsBlockItemPropertyOptionRenderingStyle,
    DocsBlockItemPropertyOptionRenderingStyle.select
  >
}

/** Boolean property - Data */
export type DocsBlockBooleanPropertyData = DocsBlockPropertyData<boolean> &
  Record<string, never>

/** Boolean property - Definition model */
export type DocsBlockBooleanPropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockBooleanPropertyOptions

/** Boolean property - Definition */
export class DocsBlockBooleanPropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockBooleanPropertyOptions,
  DocsBlockBooleanPropertyData
> {
  type!: DocsBlockItemPropertyType.boolean
}
