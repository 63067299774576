//
//  SDKUserWorkspaceNotificationSettings.ts
//  Supernova SDK
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type LiveblocksNotificationSettings = {
  sendCommentNotificationEmails: boolean
}

export type UserWorkspaceNotificationSettingsRemoteModel = {
  liveblocksNotificationSettings: LiveblocksNotificationSettings
}

export type UserWorkspaceNotificationSettingsUpdateModel =
  UserWorkspaceNotificationSettingsRemoteModel

export class UserWorkspaceNotificationSettings {
  liveblocksNotificationSettings: LiveblocksNotificationSettings

  constructor(model: UserWorkspaceNotificationSettingsRemoteModel) {
    this.liveblocksNotificationSettings = model.liveblocksNotificationSettings
  }

  static initial(): UserWorkspaceNotificationSettings {
    return new UserWorkspaceNotificationSettings({
      liveblocksNotificationSettings: {
        sendCommentNotificationEmails: true,
      },
    })
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toUpdateObject(): UserWorkspaceNotificationSettingsUpdateModel {
    return {
      liveblocksNotificationSettings: this.liveblocksNotificationSettings,
    }
  }

  toRemote(): UserWorkspaceNotificationSettingsRemoteModel {
    return {
      liveblocksNotificationSettings: this.liveblocksNotificationSettings,
    }
  }

  toTransport(): UserWorkspaceNotificationSettingsRemoteModel {
    return this.toRemote()
  }

  static fromTransport(model: UserWorkspaceNotificationSettingsRemoteModel) {
    return new UserWorkspaceNotificationSettings(model)
  }
}
