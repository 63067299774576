/* eslint-disable max-lines */
//
//  SDKColorToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { TokenType } from "../enums/SDKTokenType"
import { Unit } from "../enums/SDKUnit"

import {
  ColorTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { ColorTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { OpacityToken } from "./SDKDimensionToken"
import { Token } from "./SDKToken"
import { ColorTokenValue } from "./SDKTokenValue"

import { parseToRgba, toHex } from "color2k"
// @ts-expect-error TS(7016): Could not find a declaration file for module 'pars... Remove this comment to see the full error message
import parseColor from "parse-color"
import { v4 as uuidv4 } from "uuid"

// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires
const matchAll = require("string.prototype.matchall")

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class ColorToken extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: ColorTokenValue

  tokenType: TokenType.color = TokenType.color

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: ColorTokenValue,
    alias: ColorToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value

    if (alias) {
      this.value.referencedTokenId = alias.id
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Static building

  static create(
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: string,
    alias: ColorToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): ColorToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type: TokenType.color,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      // Raw value
      const tokenValue = this.colorValueFromDefinition(value)

      return new ColorToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const tokenValue: ColorTokenValue = {
        color: {
          r: alias.value.color.r,
          g: alias.value.color.g,
          b: alias.value.color.b,
          referencedTokenId: alias.value.color.referencedTokenId,
        },
        opacity: alias.value.opacity,
        referencedTokenId: alias.id,
      }

      return new ColorToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "Unable to create color token, no value or alias provided"
    )
  }

  static colorValueFromDefinition(definition: string): ColorTokenValue {
    const normalizedDefinition = this.normalizeColor(definition)
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const result = parseColor(normalizedDefinition)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!result || !result.rgba) {
      console.log(
        `Unable to parse provided color value '${definition}'. Hex, RGB, HSL, HSV or CMYK are supported. Fix the error to import the color value properly.`
      )

      // Handle gracefully
      return {
        color: {
          r: 255,
          b: 255,
          g: 255,
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
          referencedTokenId: undefined,
        },
        opacity: {
          unit: Unit.raw,
          measure: 1,
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
          referencedTokenId: undefined,
        },
        // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
        referencedTokenId: undefined,
      }
    }

    const normalize = (val: any, max: any, min: any) => {
      return (val - min) / (max - min)
    }

    // For 6-length hex rgba has no alpha, and seems always has last item in array as 1
    // TODO: Pick 1 lib and remove normalizeColor, parseColor and half of this method
    // `parseToRgba` does not transform 128 to 0.5 unfortunately
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const a =
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      result.rgba[3] === 1 && result.rgba.length === 4 ? 255 : result.rgba[3]

    const opacityValue = Math.round(normalize(a, 255, 0) * 100) / 100

    const opacity = OpacityToken.dimensionValueFromDefinition(
      opacityValue,
      TokenType.opacity
    )

    return {
      color: {
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        r: result.rgba[0],
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        g: result.rgba[1],
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        b: result.rgba[2],
        referencedTokenId: null,
      },
      referencedTokenId: null,
      opacity,
    }
  }

  static normalizeColor(color: string): string | null {
    // This function is taken directly from figma tokens plugin, so the implementation aligns for the importer:
    // https://github.com/tokens-studio/figma-plugin/blob/main/src/utils/color/convertToRgb.ts
    // eslint-disable-next-line no-useless-catch
    try {
      if (typeof color !== "string") {
        return color
      }

      const hexRegex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/g
      const hslaRegex = /(hsla?\(.*?\))/g
      const rgbaRegex = /(rgba?\(.*?\))/g
      let returnedColor = color

      try {
        const matchesRgba = Array.from(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
          matchAll(returnedColor, rgbaRegex),
          // TODO:fix-sdk-eslint
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          (m) => m[0]
        )

        const matchesHsla = Array.from(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
          matchAll(returnedColor, hslaRegex),
          // TODO:fix-sdk-eslint
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          (m) => m[0]
        )

        if (matchesHsla.length > 0) {
          matchesHsla.forEach((match) => {
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            returnedColor = returnedColor.replace(match, toHex(match))
          })
        }

        if (matchesRgba.length > 0) {
          matchesRgba.forEach((match) => {
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const matchedString = match
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            const matchedColor = match.replace(/rgba?\(/g, "").replace(")", "")
            // TODO:fix-sdk-eslint
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            const matchesHexInsideRgba = matchedColor.match(hexRegex)
            let r
            let g
            let b
            let alpha = "1"

            if (matchesHexInsideRgba) {
              // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              ;[r, g, b] = parseToRgba(matchesHexInsideRgba[0])
              // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              alpha = matchedColor.split(",").pop()?.trim() ?? "0"
            } else {
              // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              ;[r, g, b, alpha = "1"] = matchedColor
                .split(",") // TODO:fix-sdk-eslint
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                .map((n: any) => n.trim())
            }

            const a = this.normalizeOpacity(alpha)

            returnedColor = returnedColor // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              .split(matchedString) // TODO:fix-sdk-eslint
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              .join(toHex(`rgba(${r}, ${g}, ${b}, ${a})`))
          })
        }
      } catch (e) {
        throw new Error(
          `Unable to parse provided color '${color}'. Supported formats are: \nrgb(r <number>, g <number>, b <number>)\nrgba(r <number>, g <number>, b <number>, a <number | percentage>\nhsl(h <number>, s <percentage>, l <percentage>>\nhsla(h <number>, s <percentage>, l <percentage>, a <number | percentage>\nred | blue | black ...)`
        )
      }

      return returnedColor
    } catch (e) {
      throw e
    }
  }

  static normalizeOpacity(value: string): number {
    // Matches 50%, 100%, etc.
    const matched = value.match(/(\d+%)/)

    if (matched) {
      return Number(matched[0].slice(0, -1)) / 100
    }

    return Number(value)
  }

  static normalizedHex(value: string | null): string | null {
    if (!value) {
      return null
    }

    if (value.startsWith("#")) {
      return value.toLowerCase()
    }

    return `#${value}`.toLowerCase()
  }

  static colorValueFromDefinitionOrReference(
    definition: string | object,
    referenceResolver: DTTokenReferenceResolver
  ): ColorTokenValue | undefined {
    if (referenceResolver.valueHasReference(definition)) {
      if (!referenceResolver.isBalancedReference(definition as string)) {
        // Internal syntax of reference corrupted
        throw new Error(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Invalid reference syntax in token value: ${definition}`
        )
      }

      if (referenceResolver.valueIsPureReference(definition)) {
        // When color is pure reference, we can immediately resolve it
        const reference = referenceResolver.lookupReferencedToken(
          definition as string
        ) as ColorToken

        if (!reference) {
          return undefined
        }

        return {
          referencedTokenId: reference.id,
          color: {
            r: reference.value.color.r,
            g: reference.value.color.g,
            b: reference.value.color.b,
            referencedTokenId: reference.value.color.referencedTokenId,
          },
          opacity: reference.value.opacity,
        }
      }

      // When color is not a pure reference, we must resolve it further before we can resolve it
      const references = referenceResolver.lookupAllReferencedTokens(
        definition as string
      )

      if (!references) {
        // Still unable to solve the reference, continue looking in some other tokens
        return undefined
      }

      // Resolved all internal references
      const resolvedValue = referenceResolver.replaceAllReferencedTokens(
        definition as string,
        references
      )

      return this.colorValueFromDefinition(resolvedValue)
    }

    return this.colorValueFromDefinition(definition as string)
  }

  toHex6(): string {
    return ColorToken.colorValueToHex6(this.value)
  }

  toHex8(): string {
    return ColorToken.colorValueToHex8(this.value)
  }

  static hexToColorValue(hex: string): ColorTokenValue {
    const normalizedHex = ColorToken.normalizedHex(hex)

    if (!normalizedHex) {
      throw SupernovaError.fromMessage(
        `Unable to parse provided color '${hex}'. Supported formats are: \nrgb(r <number>, g <number>, b <number>)\nrgba(r <number>, g <number>, b <number>, a <number | percentage>\nhsl(h <number>, s <percentage>, l <percentage>>\nhsla(h <number>, s <percentage>, l <percentage>, a <number | percentage>\nred | blue | black ...)`
      )
    }

    const rgba = parseToRgba(normalizedHex)

    const opacity = OpacityToken.dimensionValueFromDefinition(
      rgba[3],
      TokenType.opacity
    )

    return {
      color: {
        r: rgba[0],
        g: rgba[1],
        b: rgba[2],
        referencedTokenId: null,
      },
      opacity,
      referencedTokenId: null,
    }
  }

  static colorValueToHex6(value: ColorTokenValue): string {
    return `#${[value.color.r, value.color.g, value.color.b]
      .map((x) => {
        const hex = x.toString(16)

        return hex.length === 1 ? `0${hex}` : hex
      })
      .join("")
      .toLowerCase()}`
  }

  static colorValueToHex8(value: ColorTokenValue): string {
    return `#${[
      value.color.r,
      value.color.g,
      value.color.b,
      Math.round(value.opacity.measure * 255),
    ]
      .map((x) => {
        const hex = x.toString(16)

        return hex.length === 1 ? `0${hex}` : hex
      })
      .join("")
      .toLowerCase()}`
  }

  /** Normalizes any color value to server readable value, and ignores alpha value optionally */
  static colorStringToNormalizedServerValue(
    value: string | undefined | null,
    ignoreAlpha = false
  ): string | null {
    if (!value) {
      return null
    }

    const rgba = parseToRgba(value)

    const color = toHex(
      `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${ignoreAlpha ? "1" : rgba[3]})`
    ).toLowerCase()

    return color.length === 7 ? `${color}ff` : color
  }

  static normalizedDefaultServerValue(): string {
    return "#000000ff"
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Updates

  updateWithRawValue(value: Omit<ColorTokenValue, "referencedTokenId">): void {
    this.value = { ...value, referencedTokenId: null }
  }

  updateWithReferencedValue(value: ColorToken): void {
    this.value = {
      referencedTokenId: value.id,
      color: {
        r: value.value.color.r,
        g: value.value.color.g,
        b: value.value.color.b,
        referencedTokenId: value.value.referencedTokenId,
      },
      opacity: value.value.opacity,
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Writing

  toWriteObject(): ColorTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as ColorTokenRemoteModel

    specificData.data = ColorToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: ColorTokenValue): {
    aliasTo: string | undefined
    value: ColorTokenRemoteValue
  } {
    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type '{ color: string | { aliasTo: string; }; opac... Remove this comment to see the full error message
      value: value.referencedTokenId
        ? null
        : {
            color: value.color.referencedTokenId
              ? { aliasTo: value.color.referencedTokenId }
              : ColorToken.colorValueToHex8(value),
            opacity: {
              aliasTo: value.opacity.referencedTokenId ?? undefined,
              value: value.opacity.referencedTokenId
                ? null
                : {
                    measure: value.opacity.measure,
                    unit: value.opacity.unit,
                  },
            },
          },
    }
  }
}
