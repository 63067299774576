//
//  AreaNetwork.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DataCore } from "../data/SDKDataCore"

import { RequestInfo, RequestInit, Response } from "node-fetch"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Network Area

export class AreaNetwork {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Network

  /** Fires network request using node-fetch library */
  async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.dataCore.fetch(url, init)
  }
}
