//
//  SDKBorderPosition.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Source type enum

export enum BorderPosition {
  inside = "Inside",
  center = "Center",
  outside = "Outside",
}

export const ALL_BORDER_POSITIONS = [
  BorderPosition.inside,
  BorderPosition.center,
  BorderPosition.outside,
] as const
