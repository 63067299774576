export enum DocsBlockItemPropertyType {
  /** Rich text property used for core rich text blocks */
  richText = "RichText",
  /** Rich text property allows user to input multiple paragraphs of rich text (with additional attributes like bold, links etc.) */
  multiRichText = "MultiRichText",
  /** Text property allows user to input plain text */
  text = "Text",
  /** Rich text property to be used in custom blocks to input rich text (with additional attributes like bold, links etc.) */
  richTextEditor = "RichTextEditor",
  /** Boolean property allows user to input true/false value */
  boolean = "Boolean",
  /** Number property allows user to input number */
  number = "Number",
  /** Single-select property allows user to select one of the options */
  singleSelect = "SingleSelect",
  /** Multi-select property allows user to select multiple of the options */
  multiSelect = "MultiSelect",
  /** Image property allows user to select or upload image */
  image = "Image",
  /** Token type allows data access to the tokens defined in design system */
  token = "Token",
  /** Token type allows selection of type(s) such as color, typography etc., for further use such as filtering */
  tokenType = "TokenType",
  /** Token property allows selection of custom token properties (columns) */
  tokenProperty = "TokenProperty",
  /** Component type allows data access to the components defined in design system */
  component = "Component",
  /** Component property allows selection of custom component properties (columns) */
  componentProperty = "ComponentProperty",
  /** Asset type allows data access to the assets defined in design system */
  asset = "Asset",
  /** Asset property allows selection of custom asset properties (columns) */
  assetProperty = "AssetProperty",
  /** Page type allows data access to the pages defined in design system */
  // page = "Page",
  /** Page property allows selection of custom page properties (columns) */
  // pageProperty = "PageProperty",
  /** Embed property allows user to input page to be embedded via URL */
  embedURL = "EmbedURL",
  /** URL property allows user to input a valid URL */
  url = "URL",
  /** Embed property allows user to input page to be embedded via iframe */
  // embedFrame = "EmbedFrame",
  /** Markdown property allows user to add markdown to render */
  markdown = "Markdown",
  /** Code property allows user to add code to render */
  code = "Code",
  /** Sandbox property allows user to add code sandbox to render live component */
  codeSandbox = "CodeSandbox",
  /** Table property allows user to add table to render */
  table = "Table",
  /** Divider property that puts visual divider into the block. It has no user input */
  divider = "Divider",
  /** Storybook property allows user to add Storybook story to render */
  storybook = "Storybook",
  /** Color property allows users to select color for further use */
  color = "Color",
  /** Figma node property (frames, sections, etc.) */
  figmaNode = "FigmaNode",
  /** Figma component property (Figma components, variants, properties, etc.) */
  figmaComponent = "FigmaComponent",
}
