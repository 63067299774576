import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.embed-youtube"
const name = "YouTube"
const icon = "https://cdn-assets.supernova.io/blocks/icons/embed-youtube.svg"
const descriptionShort = "Embed a Youtube video"
const searchKeywords = ["embed", "video", "player", "upload"]
const helpText = "Embed a YouTube video to your documentation."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/media-and-assets/youtube-Gh8VUrSF"

/**
 * 2. Block properties
 */
const properties = {
  embedUrl: b.embedUrlProperty({
    id: "embed",
    name: "Youtube URL",
    options: {
      allowCaption: false,
      allowResize: false,
      defaultHeight: 400,
      urlValidationRegex: "^(https?://)?(www.)?(youtube.com|youtu.?be)/.+$",
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.embedUrl],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.embedUrl],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.media,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as EmbedYoutube }
