//
//  SDKDocsBlockBehavior.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { PageBlockDefinitionBehavior } from "@supernova-studio/model"

import { castEnum } from "../../../../utils/CommonUtils"
import { DocsBlockBehaviorDataType } from "../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../enums/SDKDocsBlockBehaviorSelectionType"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DocsBlockBehaviorModel = PageBlockDefinitionBehavior

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DocsBlockBehavior {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Defines what data block uses, and generates */
  dataType: DocsBlockBehaviorDataType

  /** This property only goes into effect when dataType === "Item" and is ignored otherwise */
  items: {
    /**
     * If numberOfItems is 0, then block can have multiple items, and unlimited amount of them
     * If numberOfItems === 1, then block has exactly one item and none other can be created
     * If numberOfItems > 1, then block can have multiple items, but they are limited by upper bound
     * This property must be set and any other values than mentioned above are invalid
     */
    numberOfItems: number
    /** When enabled, each item can additionally link to page/url * */
    allowLinks: boolean
    /** A label for a new item, used for multi-item blocks. If null, it takes the default one like "Add new item" */
    newItemLabel: string | null
  } | null

  /** This property only goes into effect when dataType !== "Item" */
  entities: {
    /**
     * If selectionType === "Entity", then only an entity of specific "dataType" can be selected (and no groups of that type)
     * If selectionType === "Group", then only a group of specific "dataType" can be selected (and no entity of that type)
     * If selectionType === "EntityAndGroup", then both can be selected, even together
     */
    selectionType: DocsBlockBehaviorSelectionType
    /**
     * Limits number of items that user can select.
     * Group counts as one (so 2 means 2 groups, or group and item etc.).
     * 0 means unlimited.
     * */
    maxSelected: number
  } | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DocsBlockBehaviorModel) {
    this.dataType = castEnum<DocsBlockBehaviorDataType>(model.dataType)

    this.items = model.items
      ? {
          numberOfItems: model.items.numberOfItems ?? 0,
          allowLinks: model.items.allowLinks ?? false,
          newItemLabel: model.items.newItemLabel ?? null,
        }
      : null
    this.entities = model.entities
      ? {
          selectionType: castEnum<DocsBlockBehaviorSelectionType>(
            model.entities.selectionType
          ),
          maxSelected: model.entities.maxSelected ?? 0,
        }
      : null
  }
}
