//
//  SDKDocsBlockLayout.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { PageBlockDefinitionLayout } from "@supernova-studio/model"

import { castEnum, castOptionalEnum } from "../../../../utils/CommonUtils"
import { DocsBlockItemVariantLayoutAlign } from "../../../enums/SDKDocsBlockItemVariantLayoutAlign"
import { DocsBlockItemVariantLayoutColumnResizing } from "../../../enums/SDKDocsBlockItemVariantLayoutColumnResizing"
import { DocsBlockItemVariantLayoutGap } from "../../../enums/SDKDocsBlockItemVariantLayoutGap"
import { DocsBlockItemVariantLayoutType } from "../../../enums/SDKDocsBlockItemVariantLayoutType"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DocsBlockLayoutModel = PageBlockDefinitionLayout

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DocsBlockLayout {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Type of a single node of the layout, either column for stacking vertically, or row for stacking horizontally */
  type: DocsBlockItemVariantLayoutType

  /** Children of the layout for building nesting layouts or for declaring what all properties should show in a single node, or combination of both */
  children: Array<DocsBlockLayout | string> // string here means key of a property

  /** Gap between children in the column/row */
  gap?: DocsBlockItemVariantLayoutGap

  /** Alignment of the column (rows ignore this property) */
  columnAlign?: DocsBlockItemVariantLayoutAlign

  /** Resizing of the column (rows ignore this property) */
  columnResizing?: DocsBlockItemVariantLayoutColumnResizing

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DocsBlockLayoutModel) {
    this.type = castEnum<DocsBlockItemVariantLayoutType>(model.type)
    this.children = model.children.map((child) => {
      if (typeof child === "string") {
        return child
      }

      return new DocsBlockLayout(child)
    })

    this.type = castEnum<DocsBlockItemVariantLayoutType>(model.type)

    this.columnAlign =
      castOptionalEnum<DocsBlockItemVariantLayoutAlign>(model.columnAlign) ??
      DocsBlockItemVariantLayoutAlign.start

    this.columnResizing =
      castOptionalEnum<DocsBlockItemVariantLayoutColumnResizing>(
        model.columnResizing
      ) ?? DocsBlockItemVariantLayoutColumnResizing.fill

    this.gap =
      castOptionalEnum<DocsBlockItemVariantLayoutGap>(model.gap) ??
      DocsBlockItemVariantLayoutGap.medium
  }
}
