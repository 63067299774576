import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.image"
const name = "Image"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v2/image.svg"
const descriptionShort = "Display an image or Figma image"
const searchKeywords = ["image", "figma", "frame", "picture", "photo"]
const helpText = "Use to display an image or Figma image."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/media-and-assets/image-Ue8VdT8B"

/**
 * 2. Block properties
 */
const properties = {
  image: b.imageProperty({
    id: "image",
    name: "Image",
    options: {
      allowCaption: true,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.image],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.image],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.media,
  description: descriptionShort,
  item: blockItem,
  appearance: {
    isBordered: false,
  },
  searchKeywords,
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as Image }
