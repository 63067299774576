//
//  SDKCustomDomain.ts
//  Supernova SDK
//
//  Created by Fiki Sulik
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { CustomDomainErrorCode } from "../enums/SDKCustomDomainErrorCode"
import { CustomDomainState } from "../enums/SDKCustomDomainState"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type CustomDomainRemoteModel = {
  id: string
  designSystemId: string
  supernovaDomain: string
  customerDomain: string
  state: CustomDomainState
  error: string | null
  errorCode: CustomDomainErrorCode
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class CustomDomain {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  designSystemId: string

  supernovaDomain: string

  customerDomain: string

  state: CustomDomainState

  error: string | null

  errorCode: CustomDomainErrorCode | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: CustomDomainRemoteModel) {
    this.id = model.id
    this.designSystemId = model.designSystemId
    this.supernovaDomain = model.supernovaDomain
    this.customerDomain = model.customerDomain ?? null
    this.state = model.state
    this.error = model.error
    this.errorCode = model.errorCode ?? null
  }
}
