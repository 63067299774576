//
//  SDKWorkspaceNPMRegistryType.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Workspace subscription status internal enum

// Following are possible:   NPMJS, GitHub, AzureDevOps, Artifactory, Custom
export enum WorkspaceNPMRegistryType {
  /** The workspace is configured to be able to access NPMJS registry */
  npmJS = "NPMJS",

  /** The workspace is configured to be able to access GitHub registry */
  gitHub = "GitHub",

  /** The workspace is configured to be able to access AzureDevOps registry */
  azureDevOps = "AzureDevOps",

  /** The workspace is configured to be able to access Artifactory registry */
  artifactory = "Artifactory",

  /** The workspace is configured to be able to access Custom registry */
  custom = "Custom",
}
