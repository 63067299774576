//
//  SDKBlurToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { TokenType } from "../enums/SDKTokenType"

import {
  BlurTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { BlurTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { DimensionToken } from "./SDKDimensionToken"
import { Token } from "./SDKToken"
import { BlurTokenValue } from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class BlurToken extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: BlurTokenValue

  blurLayers: Array<BlurToken>

  isVirtual: boolean

  tokenType: TokenType.blur = TokenType.blur

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: BlurTokenValue,
    alias: BlurToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value
    this.blurLayers = new Array<BlurToken>()
    this.isVirtual = false

    if (alias) {
      this.value.referencedTokenId = alias.id
    }
  }

  static create(
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: object,
    alias: BlurToken | null,
    referenceResolver: DTTokenReferenceResolver,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): BlurToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type: TokenType.blur,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      // Raw value
      const tokenValue = this.blurValueFromDefinition(value, referenceResolver)

      return new BlurToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const tokenValue: BlurTokenValue = {
        type: alias.value.type,
        radius: alias.value.radius,
        referencedTokenId: alias.id,
      }

      return new BlurToken(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "Border Token must be created using value or alias, but none was provided"
    )
  }

  static blurValueFromDefinition(
    definition: object,
    referenceResolver: DTTokenReferenceResolver
  ): BlurTokenValue {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = definition

    if (
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !definition.hasOwnProperty("radius") || // TODO:fix-sdk-eslint
      // eslint-disable-next-line no-prototype-builtins
      !definition.hasOwnProperty("type")
    ) {
      throw SupernovaError.fromMessage(
        `Border definition is missing one of required properties (color, width), was ${JSON.stringify(
          definition
        )}`
      )
    }

    const value = {} as BlurTokenValue // Empty container

    value.radius = DimensionToken.dimensionValueFromDefinitionOrReference(
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (definition as any).width,
      referenceResolver,
      TokenType.borderWidth
    )
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    value.type = (definition as any).type

    return value
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Writing

  toWriteObject(): BlurTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as BlurTokenRemoteModel

    specificData.data = BlurToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: BlurTokenValue): {
    aliasTo: string | undefined
    value: BlurTokenRemoteValue
  } {
    const valueObject = !value.referencedTokenId
      ? {
          radius: {
            aliasTo: undefined,
            value: {
              measure: value.radius.measure,
              unit: value.radius.unit,
            },
          },
          type: value.type,
        }
      : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type '{ radius: { aliasTo: undefined; value: { mea... Remove this comment to see the full error message
      value: valueObject,
    }
  }
}
