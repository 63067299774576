//
//  SDKWorkspaceIPWhitelistSettings.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type IPWhitelistEntry = {
  isEnabled: boolean
  name?: string
  range: string
}

export type WorkspaceIPWhitelistSettingsRemoteModel = {
  isEnabledForCloud: boolean
  isEnabledForDocs: boolean
  entries: Array<IPWhitelistEntry>
}

export type WorkspaceIPWhitelistSettingsUpdateModel =
  WorkspaceIPWhitelistSettingsRemoteModel

export type WorkspaceIPWhitelistSettingsTransportModel =
  WorkspaceIPWhitelistSettingsRemoteModel

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Object Definition

export class WorkspaceIPWhitelistSettings {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** IP Whitelisting is enforced for the cloud access */
  isEnabledForCloud: boolean

  /** IP Whitelisting is enforced for the docs access */
  isEnabledForDocs: boolean

  /** All allowed IP ranges */
  entries: Array<IPWhitelistEntry>

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: WorkspaceIPWhitelistSettingsRemoteModel) {
    this.isEnabledForCloud = model.isEnabledForCloud
    this.isEnabledForDocs = model.isEnabledForDocs
    this.entries = model.entries.map((entry) => ({
      isEnabled: entry.isEnabled,
      name: entry.name,
      range: entry.range,
    }))
  }

  static initial(): WorkspaceIPWhitelistSettings {
    return new WorkspaceIPWhitelistSettings({
      isEnabledForCloud: false,
      isEnabledForDocs: false,
      entries: [],
    })
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  toUpdateObject(): WorkspaceIPWhitelistSettingsUpdateModel {
    return {
      isEnabledForCloud: this.isEnabledForCloud,
      isEnabledForDocs: this.isEnabledForDocs,
      entries: this.entries,
    }
  }

  toRemote(): WorkspaceIPWhitelistSettingsTransportModel {
    return {
      isEnabledForCloud: this.isEnabledForCloud,
      isEnabledForDocs: this.isEnabledForDocs,
      entries: this.entries,
    }
  }

  toTransport(): WorkspaceIPWhitelistSettingsTransportModel {
    return this.toRemote()
  }

  static fromTransport(model: WorkspaceIPWhitelistSettingsTransportModel) {
    return new WorkspaceIPWhitelistSettings(model)
  }
}
