/* eslint-disable max-lines */
//
//  SDKDimensionToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2021 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
// TODO:fix-sdk-eslint
// eslint-disable-next-line max-classes-per-file
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { DTTokenReferenceResolver } from "../../tools/design-tokens/utilities/SDKDTTokenReferenceResolver"
import { DTExpressionParser } from "../../tools/design-tokens/utilities/expression/SDKDTExpressionParser"
import { TokenClassTypeMapToken } from "../../utils/TokenUtils"
import { UnreachableCaseError } from "../../utils/UnreachableCaseError"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import {
  DimensionTokenType,
  MS_DIMENSION_TOKEN_TYPES,
  RAW_DIMENSION_TOKEN_TYPES,
  TokenType,
} from "../enums/SDKTokenType"
import {
  LINE_HEIGHT_UNITS,
  LineHeightUnit,
  SIZE_UNITS,
  SizeUnit,
  Unit,
} from "../enums/SDKUnit"

import {
  DimensionTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { AnyDimensionTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { Token } from "./SDKToken"
import {
  AnyDimensionTokenValue,
  BorderWidthTokenValue,
  DimensionTokenValue,
  DurationTokenValue,
  FontSizeTokenValue,
  LetterSpacingTokenValue,
  LineHeightTokenValue,
  OpacityTokenValue,
  ParagraphSpacingTokenValue,
  RadiusTokenValue,
  SizeTokenValue,
  SpaceTokenValue,
  ZIndexTokenValue,
} from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DimensionCategoryToken<
  T extends AnyDimensionTokenValue
> extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: T

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: T,
    alias: DimensionCategoryToken<T> | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value

    if (alias) {
      this.value.referencedTokenId = alias.id
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Static building

  static create<
    TValue extends AnyDimensionTokenValue,
    TToken extends DimensionCategoryToken<TValue>
  >(
    this: new (...args: any[]) => TToken,
    type: DimensionTokenType,
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: string | number,
    alias: TToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): TToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      const tokenValue = DimensionCategoryToken.dimensionValueFromDefinition(
        value,
        type
      )

      return new this(
        versionId,
        baseToken,
        tokenValue,
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      if (alias.tokenType === type) {
        const tokenValue: AnyDimensionTokenValue = {
          unit: Unit.pixels,
          measure: 0,
          referencedTokenId: alias.id,
        }

        return new this(
          versionId,
          baseToken,
          tokenValue,
          undefined,
          properties,
          propertyValues
        )
      }

      // Inline alias, as SN does not support Space referencing Dimension, but TS plugin does
      console.log(
        `Inlining alias ${alias.id}: ${alias.tokenType} into ${name}:${type}`
      )

      const tokenValue: AnyDimensionTokenValue = {
        unit: DimensionCategoryToken.getCompatibleUnit(alias.value.unit, type),
        measure: DimensionCategoryToken.getCompatibleMeasure(
          alias.value.unit,
          type,
          alias.value.measure
        ),
        referencedTokenId: null,
      }

      const result = new this(
        versionId,
        baseToken,
        tokenValue,
        undefined,
        properties,
        propertyValues
      )

      result.origin = {
        ...(result.origin ?? { id: null, name: null, sourceId: null }),
        referencePersistentId: alias.id,
      }

      return result
    }

    throw SupernovaError.fromMessage(
      "Dimension Token must be created using value or alias, but none was provided"
    )
  }

  static getCompatibleUnit(unit: Unit, type: DimensionTokenType) {
    switch (type) {
      case TokenType.dimension:
        return unit
      case TokenType.size:
      case TokenType.space:
      case TokenType.fontSize:
      case TokenType.letterSpacing:
      case TokenType.paragraphSpacing:
      case TokenType.radius:
        return SIZE_UNITS.includes(unit as SizeUnit) ? unit : Unit.pixels
      case TokenType.lineHeight:
        return LINE_HEIGHT_UNITS.includes(unit as LineHeightUnit)
          ? unit
          : Unit.raw
      case TokenType.opacity:
        // TODO: Consider transforming percent measure if it is referenced
        return Unit.raw
      case TokenType.borderWidth:
        return Unit.pixels
      case TokenType.duration:
        return Unit.ms
      case TokenType.zIndex:
        return Unit.raw
      default:
        throw new UnreachableCaseError(type)
    }
  }

  static getCompatibleMeasure(
    unit: Unit,
    type: DimensionTokenType,
    measure: number
  ) {
    return type === TokenType.opacity && unit === Unit.percent
      ? measure / 100.0
      : measure
  }

  // If unitless and does not support Pixels use other default: Raw or Ms
  static getDefaultUnit(type: DimensionTokenType, parsedDefinition?: number) {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (RAW_DIMENSION_TOKEN_TYPES.includes(type as any)) {
      return Unit.raw
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (MS_DIMENSION_TOKEN_TYPES.includes(type as any)) {
      return Unit.ms
    }

    if (
      type === TokenType.lineHeight &&
      parsedDefinition !== undefined &&
      parsedDefinition <= 10
    ) {
      return Unit.raw
    }

    if (type === TokenType.dimension && parsedDefinition !== undefined) {
      return Unit.raw
    }

    return Unit.pixels
  }

  // TODO: Consider overloading
  static dimensionValueFromDefinition(
    definition: string | number,
    type: DimensionTokenType
  ): OpacityTokenValue
  static dimensionValueFromDefinition(
    definition: string | number,
    type: DimensionTokenType = TokenType.dimension
  ): AnyDimensionTokenValue {
    if (typeof definition === "number") {
      return {
        measure: definition,
        unit: this.getDefaultUnit(type),
        referencedTokenId: null,
      }
    }

    const result = this.parseDimension(definition, type)

    return {
      measure: result.measure,
      unit: result.unit,
      referencedTokenId: null,
    }
  }

  static parseDimension(
    definition: string,
    type: DimensionTokenType
  ): {
    measure: number
    unit: Unit
  } {
    if (typeof definition !== "string") {
      return {
        measure: 1,
        unit: this.getDefaultUnit(type),
      }
    }

    // Use expression parser to handle the expression
    const parsedDefinition =
      DTExpressionParser.reduceExpressionsToBaseForm(definition)

    if (typeof parsedDefinition === "number") {
      return {
        measure:
          Number.isNaN(parsedDefinition) ||
          parsedDefinition === undefined ||
          parsedDefinition === null
            ? 0
            : parsedDefinition,
        unit: this.getDefaultUnit(type, parsedDefinition),
      }
    }

    // Parse out unit
    let measure = parsedDefinition.replace(" ", "")
    let unit = this.getDefaultUnit(type)

    if (parsedDefinition.endsWith("px") || parsedDefinition.endsWith("pt")) {
      measure = measure.substring(0, measure.length - 2)
      unit = Unit.pixels
    } else if (parsedDefinition.endsWith("%")) {
      measure = measure.substring(0, measure.length - 1)
      unit = Unit.percent
    } else if (parsedDefinition.endsWith("em")) {
      measure = measure.substring(0, measure.length - 2)
      unit = Unit.rem
    } else if (parsedDefinition.endsWith("ms")) {
      measure = measure.substring(0, measure.length - 2)
      unit = Unit.ms
    }

    // Experimental: Units can now be everything, not just pixels. Enable this line to only use pixels
    // unit = Unit.pixels

    // Parse
    let parsedMeasure = parseFloat(measure)
    // Fallback to default unit if specific Dimension subtype doesn't support what was parsed
    const isUnitless = !Number.isNaN(Number(parsedDefinition))

    if ([TokenType.opacity, TokenType.zIndex].includes(type)) {
      if (unit === Unit.percent && type === TokenType.opacity) {
        parsedMeasure /= 100
      }

      unit = Unit.raw
    } else if ([TokenType.borderWidth].includes(type)) {
      unit = Unit.pixels
    } else if (type === TokenType.duration) {
      unit = Unit.ms
    } else if (
      [
        TokenType.size,
        TokenType.space,
        TokenType.fontSize,
        TokenType.paragraphSpacing,
        TokenType.letterSpacing,
        TokenType.radius,
      ].includes(type) &&
      !SIZE_UNITS.includes(unit as SizeUnit)
    ) {
      unit = Unit.pixels
    } else if (type === TokenType.dimension && isUnitless) {
      // Fallback to raw, for types that support raw and come unitless
      unit = Unit.raw
    } else if (
      type === TokenType.lineHeight &&
      (!LINE_HEIGHT_UNITS.includes(unit as LineHeightUnit) ||
        (isUnitless && parsedMeasure <= 10))
    ) {
      // If the value of line height is larger than 10, import as pixels
      // Some reasoning: usually, the raw is used for relative values like 1.4, 1.8, 2.5, etc. where pixels are used when define for absolute values like 12px, 16px, 36px etc
      unit = Unit.raw
    }

    return {
      measure:
        Number.isNaN(parsedMeasure) ||
        parsedMeasure === undefined ||
        parsedMeasure === null
          ? 0
          : parsedMeasure,
      unit,
    }
  }

  // TODO: Consider overloading
  static dimensionValueFromDefinitionOrReference(
    definition: any,
    referenceResolver: DTTokenReferenceResolver,
    tokenType: DimensionTokenType
  ): BorderWidthTokenValue
  static dimensionValueFromDefinitionOrReference(
    definition: any,
    referenceResolver: DTTokenReferenceResolver,
    tokenType: DimensionTokenType = TokenType.dimension
  ): AnyDimensionTokenValue {
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (referenceResolver.valueHasReference(definition)) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (!referenceResolver.isBalancedReference(definition)) {
        // Internal syntax of reference corrupted
        throw new Error(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Invalid reference syntax in token value: ${definition}`
        )
      }

      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (referenceResolver.valueIsPureReference(definition)) {
        // When color is pure reference, we can immediately resolve it
        const reference = referenceResolver.lookupReferencedToken(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          definition
        ) as DimensionToken

        if (!reference) {
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'AnyDim... Remove this comment to see the full error message
          return undefined
        }

        if (tokenType === reference.tokenType) {
          return {
            referencedTokenId: reference.id,
            measure: reference.value.measure,
            unit: reference.value.unit,
          }
        }

        //
        // If tokenType from args !== tokenType resolved, inline
        // Align units, if we reference dimension from Opacity, unit must become raw
        console.log(
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Inlining reference ${reference.id}: ${reference.tokenType} into ${definition}:${tokenType}`
        )

        return {
          unit: DimensionCategoryToken.getCompatibleUnit(
            reference.value.unit,
            tokenType
          ),
          measure: DimensionCategoryToken.getCompatibleMeasure(
            reference.value.unit,
            tokenType,
            reference.value.measure
          ),
          referencedTokenId: null,
        }
      }

      // When color is not a pure reference, we must resolve it further before we can resolve it
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const references = referenceResolver.lookupAllReferencedTokens(definition)

      if (!references) {
        // Still unable to solve the reference, continue looking in some other tokens
        // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'AnyDim... Remove this comment to see the full error message
        return undefined
      }

      // Resolved all internal references
      const resolvedValue = referenceResolver.replaceAllReferencedTokens(
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        definition,
        references
      )

      return this.dimensionValueFromDefinition(resolvedValue, tokenType)
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.dimensionValueFromDefinition(definition, tokenType)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Writing

  toString(): string {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return DimensionToken.valueToString(this.value)
  }

  static valueToString(
    value: AnyDimensionTokenValue,
    options: {
      maxNumberOfDecimals?: number
      clipTrailingZeroes?: boolean
    } = {
      maxNumberOfDecimals: 2,
      clipTrailingZeroes: true,
    }
  ): string {
    const measureString = this.numberToString(value.measure, options)

    return `${measureString}${this.unitToString(value.unit)}`
  }

  static numberToString(
    value: number,
    options: {
      maxNumberOfDecimals?: number
      clipTrailingZeroes?: boolean
    } = {
      maxNumberOfDecimals: 2,
      clipTrailingZeroes: true,
    }
  ): string {
    let measureString = value.toString()

    if (options?.maxNumberOfDecimals !== undefined) {
      const factor = 10 ** options.maxNumberOfDecimals
      const adjustedMeasure = Math.round(value * factor) / factor

      measureString = adjustedMeasure.toFixed(options.maxNumberOfDecimals)
    }

    if (options?.clipTrailingZeroes) {
      measureString = parseFloat(measureString).toString()
    }

    return `${measureString}`
  }

  static unitToString(unit: Unit): string {
    switch (unit) {
      case Unit.pixels:
        return `px`
      case Unit.percent:
        return `%`
      case Unit.rem:
        return `rem`
      case Unit.ms:
        return `ms`
      case Unit.raw:
        return ``
      default:
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unknown unit: ${unit}`)
    }
  }

  toWriteObject(): DimensionTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as DimensionTokenRemoteModel

    specificData.data = DimensionCategoryToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: AnyDimensionTokenValue): {
    aliasTo: string | undefined
    value: AnyDimensionTokenRemoteValue
  } {
    const valueObject = !value.referencedTokenId
      ? {
          measure:
            Number.isNaN(value.measure) ||
            value.measure === undefined ||
            value.measure === null
              ? 0
              : value.measure,
          unit: value.unit,
        }
      : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type '{ measure: number; unit: Unit; } | undefined... Remove this comment to see the full error message
      value: valueObject,
    }
  }

  static valueToInternalWriteObject(
    value: AnyDimensionTokenValue | null
  ): AnyDimensionTokenValue | null {
    // @ts-expect-error TS(2322): Type '{ unit: Unit; measure: number; referencedTok... Remove this comment to see the full error message
    return value
      ? {
          unit: value.unit,
          measure: value.measure,
          referencedTokenId: undefined,
        }
      : null
  }
}

export class DimensionToken extends DimensionCategoryToken<DimensionTokenValue> {
  tokenType: TokenType.dimension = TokenType.dimension
}
export class SizeToken extends DimensionCategoryToken<SizeTokenValue> {
  tokenType: TokenType.size = TokenType.size
}
export class SpaceToken extends DimensionCategoryToken<SpaceTokenValue> {
  tokenType: TokenType.space = TokenType.space
}
export class OpacityToken extends DimensionCategoryToken<OpacityTokenValue> {
  tokenType: TokenType.opacity = TokenType.opacity
}
export class FontSizeToken extends DimensionCategoryToken<FontSizeTokenValue> {
  tokenType: TokenType.fontSize = TokenType.fontSize
}
export class LineHeightToken extends DimensionCategoryToken<LineHeightTokenValue> {
  tokenType: TokenType.lineHeight = TokenType.lineHeight
}
export class LetterSpacingToken extends DimensionCategoryToken<LetterSpacingTokenValue> {
  tokenType: TokenType.letterSpacing = TokenType.letterSpacing
}
export class ParagraphSpacingToken extends DimensionCategoryToken<ParagraphSpacingTokenValue> {
  tokenType: TokenType.paragraphSpacing = TokenType.paragraphSpacing
}
export class BorderWidthToken extends DimensionCategoryToken<BorderWidthTokenValue> {
  tokenType: TokenType.borderWidth = TokenType.borderWidth
}
export class RadiusToken extends DimensionCategoryToken<RadiusTokenValue> {
  tokenType: TokenType.radius = TokenType.radius
}
export class DurationToken extends DimensionCategoryToken<DurationTokenValue> {
  tokenType: TokenType.duration = TokenType.duration
}
export class ZIndexToken extends DimensionCategoryToken<ZIndexTokenValue> {
  tokenType: TokenType.zIndex = TokenType.zIndex
}

export type AnyDimensionToken =
  | DimensionToken
  | SizeToken
  | SpaceToken
  | OpacityToken
  | FontSizeToken
  | LineHeightToken
  | LetterSpacingToken
  | ParagraphSpacingToken
  | BorderWidthToken
  | RadiusToken
  | DurationToken
  | ZIndexToken

// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TypeofAnyDimensionToken =
  | typeof DimensionToken
  | typeof SizeToken
  | typeof SpaceToken
  | typeof OpacityToken
  | typeof FontSizeToken
  | typeof LineHeightToken
  | typeof LetterSpacingToken
  | typeof ParagraphSpacingToken
  | typeof BorderWidthToken
  | typeof RadiusToken
  | typeof DurationToken
  | typeof ZIndexToken

export const resolveDimension = <TK extends DimensionTokenType>(
  type: TK
): TokenClassTypeMapToken[TK] => {
  switch (type) {
    case TokenType.dimension:
      return DimensionToken as TokenClassTypeMapToken[TK]
    case TokenType.size:
      return SizeToken as TokenClassTypeMapToken[TK]
    case TokenType.space:
      return SpaceToken as TokenClassTypeMapToken[TK]
    case TokenType.opacity:
      return OpacityToken as TokenClassTypeMapToken[TK]
    case TokenType.fontSize:
      return FontSizeToken as TokenClassTypeMapToken[TK]
    case TokenType.lineHeight:
      return LineHeightToken as TokenClassTypeMapToken[TK]
    case TokenType.letterSpacing:
      return LetterSpacingToken as TokenClassTypeMapToken[TK]
    case TokenType.paragraphSpacing:
      return ParagraphSpacingToken as TokenClassTypeMapToken[TK]
    case TokenType.borderWidth:
      return BorderWidthToken as TokenClassTypeMapToken[TK]
    case TokenType.radius:
      return RadiusToken as TokenClassTypeMapToken[TK]
    case TokenType.duration:
      return DurationToken as TokenClassTypeMapToken[TK]
    case TokenType.zIndex:
      return ZIndexToken as TokenClassTypeMapToken[TK]
    default:
      throw new UnreachableCaseError(type)
  }
}
