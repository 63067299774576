//
//  SDKBorderStyle.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Source type enum

export enum BorderStyle {
  dashed = "Dashed",
  dotted = "Dotted",
  solid = "Solid",
  groove = "Groove",
}

export const ALL_BORDER_STYLES = [
  BorderStyle.dashed,
  BorderStyle.dotted,
  BorderStyle.solid,
  BorderStyle.groove,
] as const
