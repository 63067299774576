import { DTOGitBranch } from "@supernova-studio/client"

export type GitProviderBranchesRemoteModel = {
  branches: Array<DTOGitBranch>
}

export class GitProvidersBranch implements DTOGitBranch {
  name: string

  lastCommitId: string

  constructor(model: DTOGitBranch) {
    this.name = model.name
    this.lastCommitId = model.lastCommitId
  }
}

export class GitProvidersBranches {
  branches: Array<GitProvidersBranch>

  constructor(model: GitProviderBranchesRemoteModel) {
    this.branches = model.branches.map(
      (branch) => new GitProvidersBranch(branch)
    )
  }
}
