//
//  Brand.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type BrandRemoteModel = {
  id: string
  designSystemVersionId: string
  persistentId: string
  meta: {
    name: string
    description?: string
  }
}

export type BrandUpdateModel = {
  name: string
  description?: string
}

type BrandTransportModel = Pick<
  Brand,
  "idInVersion" | "id" | "designSystemVersionId" | "name" | "description"
>

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class Brand {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Unique identifier of the brand in this specific version */
  idInVersion: string

  /** Unique identifier of the brand */
  id: string

  /** Version in which the brand is located */
  designSystemVersionId: string

  /** Brand name */
  name: string

  /** Brand description */
  description: string

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: BrandRemoteModel) {
    this.idInVersion = model.id
    this.id = model.persistentId
    this.designSystemVersionId = model.designSystemVersionId
    this.name = model.meta.name
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.description = model.meta.description
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): BrandRemoteModel {
    return {
      id: this.idInVersion,
      persistentId: this.id,
      designSystemVersionId: this.designSystemVersionId,
      meta: {
        name: this.name,
        description: this.description,
      },
    }
  }

  /** Constructs representation that can be used to transport the instantiated object as JSON, for example for SSR <> Client use-cases. Reconstruct to class instance using `fromTransport` */
  toTransport(): BrandTransportModel {
    return {
      idInVersion: this.idInVersion,
      id: this.id,
      designSystemVersionId: this.designSystemVersionId,
      name: this.name,
      description: this.description,
    }
  }

  /** Reconstructs class from the transport model */
  static fromTransport(model: BrandTransportModel) {
    return new Brand({
      id: model.idInVersion,
      persistentId: model.id,
      designSystemVersionId: model.designSystemVersionId,
      meta: {
        name: model.name,
        description: model.description,
      },
    })
  }
}
