import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockRichTextPropertyStyle } from "../../../../../enums/SDKDocsBlockRichTextPropertyStyle"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.title3"
const name = "Heading 3"
const icon = "https://cdn-assets.supernova.io/blocks/icons/v2/title-3.svg"
const descriptionShort = "Further subsections"
const searchKeywords = ["title", "h3"]

const helpText =
  "Use for further subsections, detailing specific topics within the major subsections."

const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/text/heading-MUKlJ7dY"

/**
 * 2. Block properties
 */
const properties = {
  text: b.richTextProperty({
    id: "text",
    name: "Text",
    options: {
      placeholder: "Heading 3",
      richTextStyle: DocsBlockRichTextPropertyStyle.title3,
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.text],
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.text],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.singleItemBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.text,
  description: descriptionShort,
  item: blockItem,
  searchKeywords,
  onboarding: b.onboarding({
    helpText,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as Title3 }
