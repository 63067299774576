import { DocsBlockBuilder } from "../../../../../../tools/docs-block-builder/SDKDocsBlockBuilder"
import { DocsBlockBehaviorDataType } from "../../../../../enums/SDKDocsBlockBehaviorDataType"
import { DocsBlockBehaviorSelectionType } from "../../../../../enums/SDKDocsBlockBehaviorSelectionType"
import { DocsBlockCategory } from "../../../../../enums/SDKDocsBlockCategory"
import { DocsBlockItemDefinition } from "../../SDKDocsBlockItemDefinition"
import { DocsBlockVariant } from "../../SDKDocsBlockVariant"

const b = new DocsBlockBuilder()
/**
 * 1. Block metadata
 */
const id = "io.supernova.block.component-health"
const name = "Component health"
const icon =
  "https://cdn-assets.supernova.io/blocks/icons/v2/component-health.svg"
const descriptionShort = "Show component health and other attributes"
const keywords = ["components", "health", "properties", "overview", "status"]
const descriptionFull = "Show component health and additional attributes"
const docsUrl =
  "https://learn.supernova.io/latest/documentation/types-of-blocks/component/component-health-sezSxEED"

/**
 * 2. Block properties
 */
const properties = {
  components: b.componentProperty({
    id: "components",
    name: "Components",
    options: {
      renderLayoutAs: "List",
    },
  }),
}

/**
 * 3. Block variants
 */
const defaultVariant: DocsBlockVariant = b.defaultVariantUsingDefaultLayout({
  properties: [properties.components],
  image: "https://cdn-assets.supernova.io/blocks/variants/component-health.svg",
  appearance: {
    isEditorPresentationDifferent: true,
  },
})

/**
 * 4. Block item
 */
const blockItem: DocsBlockItemDefinition = b.singleVariantItem({
  properties: [properties.components],
  variant: defaultVariant,
})

/**
 * 5. Block definition
 */
const block = b.dataBlock({
  id,
  name,
  icon,
  category: DocsBlockCategory.component,
  description: descriptionShort,
  searchKeywords: keywords,
  dataType: DocsBlockBehaviorDataType.component,
  selectionType: DocsBlockBehaviorSelectionType.entity,
  maxSelection: 1,
  item: blockItem,
  onboarding: b.onboarding({
    helpText: descriptionFull,
    documentationLink: docsUrl,
  }),
})

/** 6. Block export */
export { block as ComponentHealth }
