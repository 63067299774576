//
//  SDKTextToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2021 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { SupernovaError } from "../../core/errors/SDKSupernovaError"
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { TextCase } from "../enums/SDKTextCase"
import { TokenType } from "../enums/SDKTokenType"

import {
  TextCaseTokenRemoteModel,
  TokenRemoteModel,
} from "./remote/SDKRemoteTokenModel"
import { TextCaseTokenRemoteValue } from "./remote/SDKRemoteTokenValue"

import { Token } from "./SDKToken"
import { TextCaseTokenValue } from "./SDKTokenValue"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class TextCaseToken extends Token {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  value: TextCaseTokenValue

  tokenType: TokenType.textCase = TokenType.textCase

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    versionId: string,
    baseToken: TokenRemoteModel,
    value: TextCaseTokenValue,
    alias: TextCaseToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    super(baseToken, versionId, properties, propertyValues)
    this.value = value

    if (alias) {
      this.value.referencedTokenId = alias.id
    }
  }

  static create(
    versionId: string,
    brandId: string,
    name: string,
    description: string,
    value: string,
    alias: TextCaseToken | null,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ): TextCaseToken {
    const baseToken: TokenRemoteModel = {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      id: undefined, // Ommited id will create new token
      persistentId: uuidv4(),
      brandId,
      designSystemVersionId: versionId,
      type: TokenType.textCase,
      meta: {
        name,
        description,
      },
      data: {},
      customPropertyOverrides: [],
    }

    if (value !== null && value !== undefined) {
      const tokenValue = this.textCaseFromDefinition(value)

      return new this(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    if (alias) {
      // Aliased value - copy and create raw from reference
      const tokenValue = {
        value: alias.value?.value,
        options: alias.value?.options,
        referencedTokenId: alias.id,
      }

      return new this(
        versionId,
        baseToken,
        tokenValue,
        // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        undefined,
        properties,
        propertyValues
      )
    }

    throw SupernovaError.fromMessage(
      "Text Case Token must be created using value or alias, but none was provided"
    )
  }

  static textCaseFromDefinition(textCase: string) {
    let textCaseValue = TextCase.original

    if (textCase && typeof textCase === "string") {
      const caseToCheck = textCase.toLowerCase().trim()

      if (
        caseToCheck === "camel" ||
        caseToCheck === "camelcase" ||
        caseToCheck === "capitalize"
      ) {
        textCaseValue = TextCase.camel
      } else if (caseToCheck === "lower" || caseToCheck === "lowercase") {
        textCaseValue = TextCase.lower
      } else if (caseToCheck === "upper" || caseToCheck === "uppercase") {
        textCaseValue = TextCase.upper
      } else if (
        caseToCheck === "smallcaps" ||
        caseToCheck === "small_caps" ||
        caseToCheck === "small_caps_forced"
      ) {
        textCaseValue = TextCase.smallCaps
      } else {
        textCaseValue = TextCase.original
      }
    } else {
      textCaseValue = TextCase.original
    }

    return {
      value: textCaseValue,
      referencedTokenId: null,
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Write

  toWriteObject(): TextCaseTokenRemoteModel {
    const baseData = this.toBaseWriteObject()
    const specificData = baseData as TextCaseTokenRemoteModel

    specificData.data = TextCaseToken.valueToWriteObject(this.value)
    return specificData
  }

  static valueToWriteObject(value: TextCaseTokenValue): {
    aliasTo: string | undefined
    value: TextCaseTokenRemoteValue
  } {
    const valueObject = !value.referencedTokenId ? value.value : undefined

    return {
      aliasTo: value.referencedTokenId ?? undefined,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      value: valueObject,
    }
  }
}
