/* eslint-disable max-lines */
//
//  AreaWorkspaces.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Acl } from "../../model/acl/SDKAcl"
import { UserRole } from "../../model/enums/SDKUserRole"
import { Membership } from "../../model/membership/SDKMembership"
import { UserMembership } from "../../model/users/SDKUserMembership"
import {
  UserWorkspaceNotificationSettings,
  UserWorkspaceNotificationSettingsUpdateModel,
} from "../../model/users/SDKUserWorkspaceNotificationSettings"
import { Workspace } from "../../model/workspaces/SDKWorkspace"
import {
  WorkspaceIPWhitelistSettings,
  WorkspaceIPWhitelistSettingsUpdateModel,
} from "../../model/workspaces/SDKWorkspaceIPWhitelistSettings"
import {
  WorkspaceInvitation,
  WorkspaceInvitationUpdateModel,
} from "../../model/workspaces/SDKWorkspaceInvitation"
import { WorkspaceInvoice } from "../../model/workspaces/SDKWorkspaceInvoice"
import { WorkspaceNPMRegistryAccessTokenRemoteModel } from "../../model/workspaces/SDKWorkspaceNPMRegistryAccessToken"
import {
  WorkspaceNPMRegistrySettings,
  WorkspaceNPMRegistrySettingsUpdateModel,
} from "../../model/workspaces/SDKWorkspaceNPMRegistrySettings"
import {
  WorkspaceOnboarding,
  WorkspaceOnboardingUpdateModel,
} from "../../model/workspaces/SDKWorkspaceOnboarding"
import { WorkspaceProduct } from "../../model/workspaces/SDKWorkspaceProduct"
import { SubscriptionProductFeatures } from "../../model/workspaces/SDKWorkspaceProductFeatures"
import { WorkspaceProfileUpdateModel } from "../../model/workspaces/SDKWorkspaceProfile"
import {
  WorkspaceSSOSettings,
  WorkspaceSSOSettingsUpdateModel,
} from "../../model/workspaces/SDKWorkspaceSSOSettings"
import {
  WorkspaceSubscription,
  WorkspaceSubscriptionUpdateModel,
} from "../../model/workspaces/SDKWorkspaceSubscription"
import { DataCore } from "../data/SDKDataCore"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area
export class AreaWorkspaces {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches one specific workspace by provided id
   * @param workspaceId - ID of the workspace
   * @returns Full workspace object
   */
  async workspace(workspaceId: string): Promise<Workspace> {
    return this.dataCore.workspace(workspaceId)
  }

  /** Fetches all workspaces user has access to. Use `memberships` instead if you want to also know which role the current user has in which workspace
   * @param userId - ID of the user
   * @returns All workspaces user is part of
   */
  async workspaces(userId: string): Promise<Array<Workspace>> {
    const memberships = await this.dataCore.memberships(userId)

    return memberships.map((m) => m.workspace)
  }

  /** Fetches all memberships user is part of. Each memberships contains workspace and user role.
   * @param userId - ID of the user
   * @returns All workspaces user is part of
   */
  async memberships(userId: string): Promise<Array<Membership>> {
    const memberships = await this.dataCore.memberships(userId)

    return memberships
  }

  /** Fetches all users that are part of the workspace. Each user contains user role as well.
   * @param workspaceId - ID of the workspace
   * @returns All users that are members of the provided workspace
   */
  async userMemberships(workspaceId: string): Promise<Array<UserMembership>> {
    const memberships = await this.dataCore.userMemberships(workspaceId)

    return memberships
  }

  /** Fetches user workspace notification settings
   * @param userId - ID of the user
   * @param workspaceId - ID of the workspace
   * @returns All user workspace notification settings
   */
  async userWorkspaceNotificationSettings(
    userId: string,
    workspaceId: string
  ): Promise<UserWorkspaceNotificationSettings> {
    const notificationSettings =
      await this.dataCore.userWorkspaceNotificationSettings(userId, workspaceId)

    return notificationSettings
  }

  /** Fetches product features grouped by product code
   * @param workspaceId - ID of the workspace
   * @returns Product features
   */
  async workspaceProductFeatures(
    workspaceId: string
  ): Promise<SubscriptionProductFeatures> {
    return this.dataCore.workspaceProductFeatures(workspaceId)
  }

  async acls(): Promise<Acl> {
    return this.dataCore.acls()
  }

  /** Fetches workspace onboarding status
   * @param workspaceId - ID of the workspace
   * @returns Workspace onboarding status
   */
  async workspaceOnboarding(workspaceId: string): Promise<WorkspaceOnboarding> {
    return this.dataCore.workspaceOnboarding(workspaceId)
  }

  /** Fetches workspace IP whitelist settings
   * @param workspaceId - ID of the workspace
   * @returns Workspace IP whitelist settings or null if the workspace is not using IP whitelist
   */
  async workspaceIPWhitelistSettings(
    workspaceId: string
  ): Promise<WorkspaceIPWhitelistSettings | null> {
    return this.dataCore.workspaceIPWhitelistSettings(workspaceId)
  }

  /** Fetches workspace NPM registry settings
   * @param workspaceId - ID of the workspace
   * @returns Workspace NPM registry settings or null if the workspace is not using NPM registry
   */
  async workspaceNPMRegistrySettings(
    workspaceId: string
  ): Promise<WorkspaceNPMRegistrySettings | null> {
    return this.dataCore.workspaceNPMRegistrySettings(workspaceId)
  }

  /** Fetches workspace NPM registry access token
   * @param workspaceId - ID of the workspace
   * @returns Workspace NPM registry access token
   */
  async workspaceNPMRegistryAccessToken(
    workspaceId: string
  ): Promise<WorkspaceNPMRegistryAccessTokenRemoteModel | null> {
    return this.dataCore.workspaceNPMRegistryAccessToken(workspaceId)
  }

  /** Fetches workspace SSO settings
   * @param workspaceId - ID of the workspace
   * @returns Workspace SSO settings or null if the workspace is not using SSO
   */
  async workspaceSSOSettings(
    workspaceId: string
  ): Promise<WorkspaceSSOSettings | null> {
    return this.dataCore.workspaceSSOSettings(workspaceId)
  }

  /** Fetches all invitations that are part of the workspace.
   * @param workspaceId - ID of the workspace
   * @returns All invitations of the provided workspace
   */
  async workspaceInvitations(
    workspaceId: string
  ): Promise<Array<WorkspaceInvitation>> {
    return this.dataCore.workspaceInvitations(workspaceId)
  }

  /** Fetches all invoices for the workspace.
   * @param workspaceId - ID of the workspace
   * @returns All invoices of the provided workspace
   */
  async workspaceInvoices(
    workspaceId: string
  ): Promise<Array<WorkspaceInvoice>> {
    return this.dataCore.workspaceInvoices(workspaceId)
  }

  /** Fetches a subscription of the workspace.
   * @param workspaceId - ID of the workspace
   * @returns A subscription of the provided workspace
   */
  async workspaceSubscription(
    workspaceId: string
  ): Promise<WorkspaceSubscription> {
    return this.dataCore.workspaceSubscription(workspaceId)
  }

  /** Fetches all products that are available to the workspace.
   * @param workspaceId - ID of the workspace
   * @returns All available products
   */
  async workspaceAvailableProducts(
    workspaceId: string
  ): Promise<Array<WorkspaceProduct>> {
    return this.dataCore.workspaceAvailableProducts(workspaceId)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Creates new workspace and retrieves the newly created workspace object. The user who creates the workspace becomes its owner
   * @param name - Name of the workspace
   * @returns Newly created workspace object
   */
  async createWorkspace(name: string): Promise<Workspace> {
    return this.dataCore.createWorkspace(name)
  }

  /** Updates workspace profile for a specific workspace
   * @param workspaceId - ID of the workspace
   * @param update - New profile data
   * @returns Nothing
   */
  async updateWorkspaceProfile(
    workspaceId: string,
    update: WorkspaceProfileUpdateModel
  ): Promise<void> {
    return this.dataCore.updateWorkspaceProfile(workspaceId, update)
  }

  /** tests accessibility of IP allowlist settings
   * @param workspaceId - ID of the workspace
   * @param ipWhitelistSettings - settings to test
   * @returns information if workspace is accesible by your IP
   */
  async testIpWhitelistSettings(
    workspaceId: string,
    ipWhitelistSettings: WorkspaceIPWhitelistSettingsUpdateModel
  ): Promise<{ accessible: boolean }> {
    return this.dataCore.testIpWhitelistSettings(
      workspaceId,
      ipWhitelistSettings
    )
  }

  /** Updates workspace onboarding state for a specific workspace
   * @param workspaceId - ID of the workspace
   * @param update - New onboarding state
   * @returns Nothing
   */
  async updateWorkspaceOnboarding(
    workspaceId: string,
    update: WorkspaceOnboardingUpdateModel
  ): Promise<void> {
    return this.dataCore.updateWorkspaceOnboarding(workspaceId, update)
  }

  /** Updates workspace avatar for a specific workspace
   * @param workspaceId - ID of the workspace
   * @param avatar - New avatar
   * @returns Nothing
   */
  async updateWorkspaceAvatar(
    workspaceId: string,
    avatar: File
  ): Promise<void> {
    return this.dataCore.updateWorkspaceAvatar(workspaceId, avatar)
  }

  /** Updates workspace SSO settings for a specific workspace
   * @param workspaceId - ID of the workspace
   * @param profile - New SSO configuration
   * @returns Nothing
   */
  async updateWorkspaceSSOSettings(
    workspaceId: string,
    update: WorkspaceSSOSettingsUpdateModel
  ): Promise<void> {
    return this.dataCore.updateWorkspaceSSOSettings(workspaceId, update)
  }

  /** Updates workspace IP whitelist settings for a specific workspace
   * @param workspaceId - ID of the workspace
   * @param profile - New IP whitelist configuration
   * @returns Nothing
   */
  async updateWorkspaceIPWhitelistSettings(
    workspaceId: string,
    ipWhitelistSettings: WorkspaceIPWhitelistSettingsUpdateModel
  ): Promise<void> {
    return this.dataCore.updateWorkspaceIPWhitelistSettings(
      workspaceId,
      ipWhitelistSettings
    )
  }

  /** Updates workspace NPM registry settings for a specific workspace
   * @param workspaceId - ID of the workspace
   * @param profile - New NPM registry configuration
   * @returns Nothing
   */
  async updateWorkspaceNPMRegistrySettings(
    workspaceId: string,
    npmRegistrySettings: WorkspaceNPMRegistrySettingsUpdateModel
  ): Promise<void> {
    return this.dataCore.updateWorkspaceNPMRegistrySettings(
      workspaceId,
      npmRegistrySettings
    )
  }

  /** Updates notification settings
   * @param userId - ID of the user
   * @param workspaceId - ID of the workspace
   * @returns Nothing
   */
  async updateUserWorkspaceNotificationSettings(
    userId: string,
    workspaceId: string,
    notificationSettings: UserWorkspaceNotificationSettingsUpdateModel
  ): Promise<void> {
    return this.dataCore.updateUserWorkspaceNotificationSettings(
      userId,
      workspaceId,
      notificationSettings
    )
  }

  /** Transfers an ownership of the workspace.
   * Old owner receives new owner's previous role.
   * @param workspaceId - ID of the workspace
   * @param newOwnerUserId - ID of the user to transfer the ownership to
   * @returns Nothing
   */
  async updateWorkspaceOwnership(
    workspaceId: string,
    newOwnerUserId: string
  ): Promise<void> {
    return this.dataCore.updateWorkspaceOwnership(workspaceId, newOwnerUserId)
  }

  /** Updates a role of a member.
   * @param workspaceId - ID of the workspace
   * @param userId - ID of the user
   * @param newRole - New role of the user
   * @returns Nothing
   */
  async updateWorkspaceMemberRole(
    workspaceId: string,
    userId: string,
    newRole: UserRole
  ): Promise<void> {
    return this.dataCore.updateWorkspaceMemberRole(workspaceId, userId, newRole)
  }

  /** Updates a role of an invitation.
   * @param workspaceId - ID of the workspace
   * @param invitationId - ID of the invitation
   * @param newRole - New role
   * @returns Nothing
   */
  async updateWorkspaceInvitationRole(
    workspaceId: string,
    invitationId: string,
    newRole: UserRole
  ): Promise<void> {
    return this.dataCore.updateWorkspaceInvitationRole(
      workspaceId,
      invitationId,
      newRole
    )
  }

  /** Invites new members to the workspace
   * @param workspaceId - ID of the workspace
   * @param invitations - array of invitations
   * @returns Nothing
   */
  async addWorkspaceInvitations(
    workspaceId: string,
    invitations: Array<WorkspaceInvitationUpdateModel>,
    designSystemId: string | undefined
  ): Promise<void> {
    return this.dataCore.addWorkspaceInvitations(
      workspaceId,
      invitations,
      designSystemId
    )
  }

  /** Creates a new Stripe checkout session.
   * @param workspaceId - ID of the workspace
   * @param priceId - ID of the Stripe price to be applied to the workspace
   * @param successUrl - URL to redirect to after successful payment
   * @param cancelUrl - URL to redirect to after failed payment
   * @param quantity - number of seats to be pre-selected in the checkout
   * @returns string - URL to the Stripe checkout
   */
  async createCheckoutSession(
    workspaceId: string,
    priceId: string,
    successUrl: string,
    cancelUrl: string,
    quantity?: number | null
  ): Promise<string> {
    return this.dataCore.createCheckoutSession(
      workspaceId,
      priceId,
      successUrl,
      cancelUrl,
      quantity
    )
  }

  /** Creates a new Stripe customer portal session.
   * @param workspaceId - ID of the workspace
   * @param returnUrl - URL to redirect to from the portal
   * @param update - describes the subscription updates to be applied
   * @param cancel - describes if the subscription must be cancelled
   * @returns string - URL to the Stripe customer portal
   */
  async createPortalSession(
    workspaceId: string,
    returnUrl: string,
    update?: WorkspaceSubscriptionUpdateModel | null,
    cancel?: boolean | null
  ): Promise<string> {
    return this.dataCore.createPortalSession(
      workspaceId,
      returnUrl,
      update,
      cancel
    )
  }

  /** Resend an invitation to the workspace.
   * @param workspaceId - ID of the workspace
   * @param invitationId - ID of the invitation
   * @returns Nothing
   */
  async resendWorkspaceInvitation(
    workspaceId: string,
    invitationId: string,
    designSystemId: string | undefined
  ): Promise<void> {
    return this.dataCore.resendWorkspaceInvitation(
      workspaceId,
      invitationId,
      designSystemId
    )
  }

  /** Restore a cancelled workspace subscription.
   * @param workspaceId - ID of the workspace
   * @returns Nothing
   */
  async restoreWorkspaceSubscription(workspaceId: string): Promise<void> {
    return this.dataCore.restoreWorkspaceSubscription(workspaceId)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  /** Deletes a single workspace. This action is irreversible, all data will be lost, and can only be performed by the workspace owner
   * @param workspaceId - ID of the workspace
   * @returns Nothing
   */
  async deleteWorkspace(workspaceId: string): Promise<void> {
    return this.dataCore.deleteWorkspace(workspaceId)
  }

  /** Deletes workspace avatar. This action is irreversible
   * @param workspaceId - ID of the workspace
   * @returns Nothing
   */
  async deleteWorkspaceAvatar(workspaceId: string): Promise<void> {
    return this.dataCore.deleteWorkspaceAvatar(workspaceId)
  }

  /** Deletes a workspace registry settings for a specific workspace
   * @param workspaceId - ID of the workspace
   * @returns Nothing
   */
  async deleteWorkspaceNPMRegistrySettings(workspaceId: string): Promise<void> {
    return this.dataCore.deleteWorkspaceNPMRegistrySettings(workspaceId)
  }

  /** Deletes a member from the workspace.
   * @param workspaceId - ID of the workspace
   * @param userId - ID of the member
   * @returns Nothing
   */
  async deleteWorkspaceMember(
    workspaceId: string,
    userId: string
  ): Promise<void> {
    return this.dataCore.deleteWorkspaceMember(workspaceId, userId)
  }

  /** Deletes an invitation from the workspace.
   * @param workspaceId - ID of the workspace
   * @param invitationId - ID of the invitation
   * @returns Nothing
   */
  async deleteWorkspaceInvitation(
    workspaceId: string,
    invitationId: string
  ): Promise<void> {
    return this.dataCore.deleteWorkspaceInvitation(workspaceId, invitationId)
  }
}
