//
//  SDKComponent.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type ComponentRemoteModel = {
  id: string
  persistentId: string
  brandId: string
  meta: {
    name: string
    description: string
  }
  createdAt?: string
  updatedAt?: string
  groupPersistentId?: string
}

export type ComponentUpdateRemoteModel = Omit<ComponentRemoteModel, "brandId">

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class Component {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  idInVersion: string

  id: string

  // @ts-expect-error TS(2564): Property 'designSystemVersionId' has no initialize... Remove this comment to see the full error message
  designSystemVersionId: string

  brandId: string

  name: string

  description: string

  properties: Array<ElementProperty>

  propertyValues: { [key: string]: string | boolean | number }

  createdAt: Date | null

  updatedAt: Date | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    model: ComponentRemoteModel,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>
  ) {
    this.idInVersion = model.id
    this.id = model.persistentId
    this.brandId = model.brandId
    this.name = model.meta.name
    this.description = model.meta.description
    this.properties = properties
    this.propertyValues = {}

    for (const value of propertyValues) {
      if (value.targetElementId === this.id) {
        // Property value refers to this element
        for (const property of properties) {
          if (property.id === value.definitionId) {
            // Property value refers to the correct property, we get codeName from it and add it to quick-access
            this.propertyValues[property.codeName] = value.value
          }
        }
      }
    }

    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
  }

  toWriteObject(): ComponentRemoteModel {
    return {
      id: this.idInVersion,
      persistentId: this.id,
      brandId: this.brandId,
      meta: {
        name: this.name,
        description: this.description ?? "",
      },
    }
  }

  toWriteUpdateObject(): ComponentUpdateRemoteModel {
    const model = this.toWriteObject()

    // @ts-expect-error TS(2790): The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
    delete model.brandId

    return model
  }
}
