//
//  SDKTokenGroup.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DesignComponentGroupRemoteModel = {
  id: string
  designSystemVersionId: string
  persistentId: string
  brandId: string
  isRoot: boolean
  meta: {
    name: string
    description: string
  }
  childrenIds: string[]
  createdAt?: string
  updatedAt?: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DesignComponentGroup {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  brandId: string

  name: string

  description: string

  path: Array<string>

  subgroupIds: Array<string>

  isRoot: boolean

  childrenIds: Array<string>

  componentIds: Array<string>

  parentGroupId: string | null

  createdAt: Date | null

  updatedAt: Date | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DesignComponentGroupRemoteModel) {
    this.id = model.persistentId
    this.brandId = model.brandId
    this.name = model.meta.name
    this.description = model.meta.description
    this.isRoot = model.isRoot
    this.childrenIds = model.childrenIds
    this.path = new Array<string>()
    this.componentIds = new Array<string>()
    this.subgroupIds = new Array<string>()
    this.parentGroupId = null
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Convenience

  addChild(groupId: string) {
    this.subgroupIds.push(groupId)
  }

  addChildren(groupsIds: Array<string>) {
    this.subgroupIds = this.subgroupIds.concat(groupsIds)
  }

  setPath(segments: Array<string>) {
    this.path = segments
  }

  setParentGroupId(parentGroupId: string | null) {
    this.parentGroupId = parentGroupId ?? null
  }
}
